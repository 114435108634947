/* DEPENDENCIAS */
import React, { useState } from 'react';
import { Button, Row, Col, Modal, Form, Container } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import * as yup from "yup";

import { IEtiquetaProducto, IImpresionInsertable, etiquetaProductoModelDefault } from '../../../tipos/ColaImpresionType';
import { queryListarImpresoras, queryObtenerPlantillaImpresion } from '../../../util/querys/ImpresionesQuerys';
import { PLANTILLA_PRODUCTO_ID } from '../../../util/tsx-ayuda/Constantes';
import { format } from 'date-fns';
import { IMovimientoStock } from '../../../tipos/MovimientosStockType';
import { impresionModelDefault } from './ColaImpresion';
import PanelCargando from '../../../componentes-com/PanelCargando';
import PanelSinResultados from '../../../componentes-com/PanelSinResultados';
import { TransformarPlantillaProducto } from '../../../util/tsx-ayuda/Funciones';

/* DECLARACIONES */
type Props = {
    mostrarModal: boolean,
    listaMovimientoStock: IMovimientoStock[] | undefined,
    guardarDatos: (datosModelo: IImpresionInsertable[]) => void
    handlerCancelar: () => void
}
    
/* COMPONENTE */
export default function EtiquetaProductosModal({listaMovimientoStock, mostrarModal, guardarDatos, handlerCancelar} : Props) {
    /* definiciones */
    const { data: opcionesImpresoras, isLoading: isLoadingImpresoras } = useQuery(queryListarImpresoras());
    const { data: datosPlantilla, isLoading: isLoadingPlantilla } = useQuery(queryObtenerPlantillaImpresion(PLANTILLA_PRODUCTO_ID));
    const [idImpresoraSeleccionada, setIDImpresoraSeleccionada] = useState<number>(0);
    const [errorImpresora, setErrorImpresora] = useState<string | null>(null);    
    const [impresionesEtiquetas, setImpresionesEtiquetas] = useState<IEtiquetaProducto[]>(new Array(0));


    /* funciones */
    const InicializarEtiquetas = () => {
        if (datosPlantilla && listaMovimientoStock) {
            let listadoEtiquetas = listaMovimientoStock.map(itemMovimiento => {
                return {
                    ...etiquetaProductoModelDefault,
                    datosMovimiento: itemMovimiento,
                    copias: Math.floor(itemMovimiento.cantidad),
                    plantilla: datosPlantilla
                };
            });
            setImpresionesEtiquetas(listadoEtiquetas);
        }
    }
    
    const onChangeCopias = (idProducto:number, idLote:number, copias:number) => {
        let listadoEtiquetas : IEtiquetaProducto[] = impresionesEtiquetas
            .map(item => {
            if (item.datosMovimiento?.idProducto === idProducto && item.datosMovimiento?.idLote === idLote)
                item.copias = copias;
            return item;
            });
        setImpresionesEtiquetas(listadoEtiquetas);
    }
  
    const onClickGuardar = () => {
        let impresionesProductos : IImpresionInsertable[] = new Array(0);
        //recorremos el listado de impresiones para guardarlas y enviarlas a la impresora...
        if (datosPlantilla && idImpresoraSeleccionada > 0) {
            setErrorImpresora(null);
            impresionesEtiquetas.forEach(item => {
                if (item.copias !== 0) {
                    item.idImpresora = idImpresoraSeleccionada;
                    item.fecha = new Date();
                    item.plantilla = datosPlantilla;
                }
            });

            impresionesProductos = impresionesEtiquetas.map(itemEtiqueta => {
                let itemImpresion : IImpresionInsertable = {
                    id: 0,
                    idImpresora: itemEtiqueta.idImpresora,
                    idPlantilla: datosPlantilla.id,
                    nombrePlantilla: itemEtiqueta.plantilla?.nombre,
                    fecha:  new Date(),
                    textoImpresion: TransformarPlantillaProducto(itemEtiqueta),
                    fechaImpresion: null,
                }; 
                return itemImpresion;
            });

            if (impresionesProductos.length > 0) 
                guardarDatos(impresionesProductos);
        }
        else if (idImpresoraSeleccionada === 0)
            setErrorImpresora("Debe seleccionar una impresora para imprimir las etiquetas.");
    }
    
    /* efectos */
    React.useEffect(() => {
        InicializarEtiquetas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listaMovimientoStock]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal} size="lg" fullscreen={true}
            onHide={handlerCancelar}
            backdrop="static"
            keyboard={false}>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Impresión de etiquetas de productos
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col sm={2} className="text-warning align-middle">
                                <span className="material-symbols-outlined fs-3">print_connect</span>
                            </Col>
                            <Col sm={10} className="text-warning d-flex align-items-center mt-2">
                                <p>De cada producto se precarga el número de unidades guardado en la entrada de mercancía para imprimir el número de copias. Se puede cambiar en cualquier momento antes de guardar las impresiones de etiquetas.</p>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col sm={12}>
                                <Form.Group className="mb-3" controlId="selImpresora">
                                    <Form.Label>Impresora</Form.Label>
                                    <Form.Select isInvalid={!!errorImpresora}
                                        onChange={(e) => {setErrorImpresora(null); setIDImpresoraSeleccionada(parseInt(e.target.value));}}>
                                        {isLoadingImpresoras && (<option value="">cargando...</option>)}
                                        {opcionesImpresoras && 
                                            [
                                                ...[{id:0, nombre: "seleccione una impresora..."}],
                                                ...opcionesImpresoras
                                            ].map((option) => {
                                            return (
                                                <option key={option.id?? 0} value={option.id?? 0}>{option.nombre}</option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>
                                        {errorImpresora}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th style={{width: 150}}>CÓDIGO</th>
                                    <th style={{width: 150}}>EAN</th>
                                    <th>Nombre</th>
                                    <th style={{width: 150}}>Lote</th>
                                    <th style={{width: 150}}>Caducidad</th>
                                    <th style={{width: 150}}>Copias</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                { isLoadingImpresoras && isLoadingPlantilla && (<tr><td colSpan={6}><PanelCargando /></td></tr>)}
                                { (!isLoadingImpresoras && !isLoadingPlantilla && impresionesEtiquetas.length === 0) && (
                                <tr>
                                    <td colSpan={6} className="text-center">
                                        <PanelSinResultados mensaje="--- no hay productos para imprimir etiquetas ---" />
                                    </td>
                                </tr>
                                )}
                                {impresionesEtiquetas.map(filaImpresion => (
                                    <tr key={filaImpresion.datosMovimiento?.id}>
                                        <td>{filaImpresion.datosMovimiento?.producto.codigo?? "-"}</td>
                                        <td>{filaImpresion.datosMovimiento?.producto.codigoEAN?? "-"}</td>
                                        <td>{filaImpresion.datosMovimiento?.producto.nombreCorto?? "-"}</td>
                                        <td>{filaImpresion.datosMovimiento?.codigoLote?? "-"}</td>
                                        <td>{filaImpresion.datosMovimiento?.caducidadLote? format(new Date(filaImpresion.datosMovimiento?.caducidadLote), "dd/MM/yyyy") : "-"}</td>
                                        <td>
                                        <Form.Control type="number" style={{width:"100px"}} maxLength={3} value={filaImpresion.copias}
                                            required={true} 
                                            onChange={(e) => {
                                                onChangeCopias(filaImpresion.datosMovimiento?.idProducto?? 0, 
                                                    filaImpresion.datosMovimiento?.idLote?? 0, 
                                                    parseInt(e.target.value));
                                                }}/>
                                        </td>
                                    </tr>
                                ))}                      
                            </tbody>
                        </table>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={handlerCancelar}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={onClickGuardar} disabled={datosPlantilla === null}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}