//constante con la URL Base que depende del entorno (dev, pro)
const ENDPOINTS_API_BASE = process.env.REACT_APP_API_URL;

//endpoints utilizados...
export const URL_API_FAMILIAS = `${ENDPOINTS_API_BASE}/FamiliasProductos`;
export const URL_API_FAMILIAS_PAGINACION = `${ENDPOINTS_API_BASE}/FamiliasProductos/paginacion`;
export const URL_API_TIPOSUNIDAD = `${ENDPOINTS_API_BASE}/TiposUnidades`;
export const URL_API_TIPOSUNIDAD_PAGINACION = `${ENDPOINTS_API_BASE}/TiposUnidades/paginacion`;
export const URL_API_SECCIONESALMACEN = `${ENDPOINTS_API_BASE}/Secciones`;
export const URL_API_SECCIONESALMACEN_PAGINACION = `${ENDPOINTS_API_BASE}/Secciones/paginacion`;
export const URL_API_PROVEEDORES = `${ENDPOINTS_API_BASE}/Proveedores`;
export const URL_API_PROVEEDORES_PAGINACION = `${ENDPOINTS_API_BASE}/Proveedores/paginacion`;
export const URL_API_IMPRESORAS = `${ENDPOINTS_API_BASE}/Impresoras`;
export const URL_API_IMPRESORAS_PAGINACION = `${ENDPOINTS_API_BASE}/Impresoras/paginacion`;
export const URL_API_PLANTILLASIMPRESION = `${ENDPOINTS_API_BASE}/PlantillasImpresion`;
export const URL_API_PLANTILLASIMPRESION_PAGINACION = `${ENDPOINTS_API_BASE}/PlantillasImpresion/paginacion`;
export const URL_API_COLAIMPRESION = `${ENDPOINTS_API_BASE}/ColaImpresion`;
export const URL_API_COLAIMPRESION_PAGINACION = `${ENDPOINTS_API_BASE}/ColaImpresion/paginacion`;
export const URL_API_PRODUCTOS = `${ENDPOINTS_API_BASE}/Productos`;
export const URL_API_PRODUCTOS_PAGINACION = `${ENDPOINTS_API_BASE}/Productos/paginacion`;
export const URL_API_CAMAS = `${ENDPOINTS_API_BASE}/Camas`;
export const URL_API_CAMAS_PAGINACION = `${ENDPOINTS_API_BASE}/Camas/paginacion`;
export const URL_API_HABITACIONES = `${ENDPOINTS_API_BASE}/Habitaciones`;
export const URL_API_HABITACIONES_PAGINACION = `${ENDPOINTS_API_BASE}/Habitaciones/paginacion`;
export const URL_API_RECEPCIONES_MERCANCIA = `${ENDPOINTS_API_BASE}/RecepcionesMercancia`;
export const URL_API_RECEPCIONES_MERCANCIA_PAGINACION = `${ENDPOINTS_API_BASE}/RecepcionesMercancia/paginacion`;
export const URL_API_MOVIMIENTOS_STOCK = `${ENDPOINTS_API_BASE}/MovimientosStock`;
export const URL_API_MOVIMIENTOS_STOCK_PAGINACION = `${ENDPOINTS_API_BASE}/MovimientosStock/paginacion`;
export const URL_API_LOTES = `${ENDPOINTS_API_BASE}/Lotes`;
export const URL_API_LOTES_PAGINACION = `${ENDPOINTS_API_BASE}/Lotes/paginacion`;
export const URL_API_USUARIOS = `${ENDPOINTS_API_BASE}/Usuarios`;
export const URL_API_USUARIOS_PAGINACION = `${ENDPOINTS_API_BASE}/Usuarios/paginacion`;
export const URL_API_USUARIOS_PERFILES = `${ENDPOINTS_API_BASE}/Usuarios/listarPerfiles`;
export const URL_API_USUARIOS_AUTENTIFICAR = `${ENDPOINTS_API_BASE}/Usuarios/autentificar`;