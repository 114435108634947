/* DEPENDENCIAS */
import { Dispatch, SetStateAction, useState } from 'react';
import { ButtonGroup, Col, Container, InputGroup, Row, Button, Modal, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { IMovimientoStockInsertable, IMovimientoStockInsertableForm, movimientoStockEntradaModelDefault } from '../../../tipos/MovimientosStockType';
import ProductosSeleccionModal from '../productos/ProductosSeleccionModal';
import { IProducto, productoModelDefault } from '../../../tipos/ProductosType';
import { ETipoIVAToNumber, ObtenerCssBadgeStock } from '../../../util/tsx-ayuda/Funciones';
import { ILoteInsertable, loteInsertableModelDefault } from '../../../tipos/LotesType';
import { format } from 'date-fns';
import LotesProductoSeleccionModal from '../lotes/LotesProductoSeleccionModal';

/* DECLARACIONES */ 
const schemaValidacion = yup.object().shape({
    idProducto: yup.number()
        .min(1, "Seleccione un producto")
        .required("El campo es obligatorio"),
    usaControlLotes: yup.boolean(),
    lote: yup.object().when("usaControlLotes", {
        is: true,
        then: (schema) => yup.object().shape({
            codigo: yup.string().required("El campo es obligatorio")
        }),
        otherwise: (schema) => yup.object().nullable(),
    }),
    cantidad: yup.number().positive()
        .required("El campo es obligatorio"),
    precio: yup.number().positive()
        .required("El campo es obligatorio")
});

type RecepcionProductoModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: IMovimientoStockInsertable | null,
    guardarDatos: (datosModelo: IMovimientoStockInsertable) => void
}

/* COMPONENTE */
export default function RecepcionProductoModal({datosIniciales, mostrarModal, setMostrarModal, guardarDatos} : RecepcionProductoModalProps) {
    /* definiciones */
    const { register, handleSubmit, formState: {errors}, getValues, setValue, reset, watch } = useForm<IMovimientoStockInsertableForm>({
        mode: "onSubmit",
        defaultValues: movimientoStockEntradaModelDefault,
        values: datosIniciales?? movimientoStockEntradaModelDefault,
        resolver: yupResolver(schemaValidacion),
        reValidateMode: "onChange",
    });
    const [datosProducto, setDatosProducto] = useState<IProducto>(productoModelDefault);
    const [visibleModalProductos, setVisibleModalProductos] = useState(false);

    const [nuevoLote, setNuevoLote] = useState(true);
    const [visibleModalLotes, setVisibleModalLotes] = useState(false);
    
    
    /* funciones */
    const onClickSeleccionarProducto = () => {
        setVisibleModalProductos(true);
    }

    const onClickCancelar = () => {
        reset(movimientoStockEntradaModelDefault);
        setDatosProducto(productoModelDefault);
        setMostrarModal(!mostrarModal);
    }


    const handlerProductoSeleccionado = (datosModal: IProducto) => {
        setVisibleModalProductos(false);
        setDatosProducto({
            ...datosProducto,
            ...datosModal
        });

        setValue("idProducto", datosModal.id);
        setValue("usaControlLotes", datosModal.usaControlLotes);
        setValue("precio", datosModal.precio);
        if (datosModal.usaControlLotes) setValue("lote", {
            ...loteInsertableModelDefault,
            idProducto: datosModal.id
        });
        else setValue("lote", null);
    }
    
    const onClickSeleccionarLote = () => {
        setNuevoLote(false);
        setVisibleModalLotes(true);
    }

    const onClickCancelarLote = () => {
        setNuevoLote(true);
        setVisibleModalLotes(false);
    }


    const handlerLoteSeleccionado = (datosModal: ILoteInsertable) => {
        setVisibleModalLotes(false);
        setValue("lote", datosModal);
    }
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={onClickCancelar}
            backdrop="static"
            keyboard={false}
            fullscreen={true}>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { (getValues().id === 0)  && "Añadir producto de la entrada de mercancía"}
                        { (getValues().id > 0)  && "Editar producto de la entrada de mercancía"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="txtProducto">
                                    <InputGroup className="mb-3">
                                        <Button variant="outline-info" id="btnSeleccionarProducto" onClick={onClickSeleccionarProducto}>
                                            seleccione un producto
                                        </Button>
                                        <Form.Control type="hidden" value={datosProducto.id} {...register("idProducto")} />
                                        <Form.Control readOnly value={datosProducto.nombre}
                                            required={true}   isInvalid={!!errors.idProducto}
                                            aria-label="Seleccione un producto para añadir"
                                            aria-describedby="btnSeleccionarProducto"
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.idProducto?.message}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    <Form.Text className="text-muted" hidden={!datosProducto.usaControlLotes}>
                                        Este producto usa control por lotes, debe seleccionar uno existente o crear uno nuevo.
                                    </Form.Text>
                                </Form.Group>
                                <ProductosSeleccionModal
                                    mostrarModal={visibleModalProductos}
                                    setMostrarModal={setVisibleModalProductos}
                                    seleccionarProducto={handlerProductoSeleccionado}
                                />
                            </Col>
                        </Row>
                        
                        {datosProducto.usaControlLotes && (
                        <Row>
                            <Col>
                                <LotesProductoSeleccionModal
                                    idProducto={watch("idProducto")}
                                    mostrarModal={visibleModalLotes}
                                    setMostrarModal={setVisibleModalLotes}
                                    handlerCancelar={onClickCancelarLote}
                                    seleccionarLote={handlerLoteSeleccionado}
                                />
                                <ButtonGroup className="mb-3" aria-label="seleccione el lote">
                                    <input type="radio" className="btn-check " name="rbLote" id="btnNuevo" checked={nuevoLote} onClick={() => setNuevoLote(true)} />
                                    <label className="btn btn-outline-info" htmlFor="btnNuevo">nuevo lote</label>

                                    <input type="radio" className="btn-check" name="rbLote" id="btnSeleccionaLote" checked={!nuevoLote} onClick={onClickSeleccionarLote} />
                                    <label className="btn btn-outline-info" htmlFor="btnSeleccionaLote">seleccionar lote</label>
                                </ButtonGroup>
                                {nuevoLote && (
                                    <fieldset className="form-group border p-3 mb-3">
                                        <legend className='w-auto px-2'>DATOS DEL LOTE</legend>
                                        <Container fluid className="m-0">
                                            <Row>
                                                <Col sm={4}>
                                                    <Form.Group className="mb-3" controlId="txtCodigo">
                                                        <Form.Label>Código</Form.Label>
                                                        <Form.Control type="text" maxLength={20} {...register("lote.codigo")}
                                                            required={true}  isInvalid={!!errors.lote?.codigo}
                                                            placeholder="escriba el código..." />
                                                        <Form.Text className="text-muted" hidden={(watch('lote.codigo')?.length??0) === 0}>
                                                            {`${watch('lote.codigo')?.length?? 0}/20`}
                                                        </Form.Text>
                                                        <Form.Control.Feedback type='invalid'>
                                                            {errors.lote?.codigo?.message}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group className="mb-3" controlId="txtCaducidad">
                                                        <Form.Label>Caducidad</Form.Label>
                                                        <Form.Control type="date" maxLength={20} {...register("lote.caducidad", {
                                                            valueAsDate: true,
                                                        })}
                                                            required={false}  isInvalid={!!errors.lote?.caducidad} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {errors.lote?.caducidad?.message}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>                                                
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group className="mb-3" controlId="txtConsumoPreferente">
                                                        <Form.Label>Consumo preferente</Form.Label>
                                                        <Form.Control type="date" maxLength={20} {...register("lote.consumoPreferente", {
                                                            valueAsDate: true,
                                                        })}
                                                            required={false}  isInvalid={!!errors.lote?.consumoPreferente} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {errors.lote?.consumoPreferente?.message}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>                                                 
                                                </Col>
                                            </Row>
                                        </Container>
                                    </fieldset>
                                )}
                                {!nuevoLote && (
                                    <fieldset className="form-group border p-3 mb-3">
                                        <legend className='w-auto px-2'>DATOS DEL LOTE</legend>
                                        <Container fluid className="m-0">
                                            <Row>
                                                <Col sm={4}>
                                                    <Form.Group className="mb-3" controlId="txtCodigo">
                                                        <Form.Label>Código</Form.Label>
                                                        <Form.Control plaintext readOnly value={watch("lote.codigo")} />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2}>
                                                    <label htmlFor="txtStock">Stock</label>
                                                    <span id="txtStock" className="form-control-plaintext">
                                                        <span className={`badge ${ObtenerCssBadgeStock(watch("lote.stock"), datosProducto.stockMinimo)}`}>
                                                            {watch("lote.stock")}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group className="mb-3" controlId="txtCaducidad">
                                                        <Form.Label>Caducidad</Form.Label>
                                                        <Form.Control plaintext readOnly value={
                                                            (watch("lote.caducidad"))? format(watch("lote.caducidad")?? new Date(), "dd/MM/yyyy") : "-"
                                                            } />
                                                    </Form.Group>                                                
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group className="mb-3" controlId="txtConsumoPreferente">
                                                        <Form.Label>Consumo preferente</Form.Label>
                                                        <Form.Control plaintext readOnly value={
                                                            (watch("lote.consumoPreferente"))? format(watch("lote.consumoPreferente")?? new Date(), "dd/MM/yyyy") : "-"
                                                            } />
                                                    </Form.Group>                                                 
                                                </Col>
                                            </Row>
                                        </Container>
                                    </fieldset>
                                )}
                            </Col>
                        </Row>
                        )}
                        {(datosProducto.id > 0) && (
                        <Row>
                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="txtCantidad">
                                    <Form.Label>Cantidad</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="number" pattern="^[0-9]+" {...register("cantidad")}
                                            required={true}   isInvalid={!!errors.cantidad}/>
                                        <InputGroup.Text>{datosProducto.abreviaturaTipoUnidad}</InputGroup.Text>
                                    </InputGroup>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.cantidad?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="txtPrecio">
                                    <Form.Label>Precio/{datosProducto.abreviaturaTipoUnidad}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="number" {...register("precio")}
                                            required={true}   isInvalid={!!errors.precio}/>
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.precio?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="txtIVA">
                                    <Form.Label>IVA</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text"
                                            readOnly={true} value={ETipoIVAToNumber(datosProducto.tipoIVA)} />
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className="mb-3" controlId="txtSubtotal">
                                    <Form.Label>Subtotal</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="number"
                                            readOnly={true} value={datosProducto.getSubtotalNeto(watch("cantidad"), watch("precio")?? 0).toFixed(2)} />
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className="mb-3" controlId="txtSubtotal">
                                    <Form.Label>Subtotal con IVA</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="number"
                                            readOnly={true} value={datosProducto.getSubtotalBruto(watch("cantidad"), watch("precio")?? 0, datosProducto.getIVA(datosProducto.tipoIVA)).toFixed(2)} />
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        )}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                <Button type="button" variant="light" onClick={onClickCancelar}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button type="button" variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
      </Modal>
    );
}