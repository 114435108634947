/**
 * Interfaz que define un objeto de familia de productos en la IU.
 */
interface IFamiliaProducto {
    id: number | null,
    nombre: string,
}

const familiaProductoModelDefault : IFamiliaProducto = {
    id:0,
    nombre: ""
}

export type {IFamiliaProducto}

export {
    familiaProductoModelDefault
}