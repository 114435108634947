/* DEPENDENCIAS */
import * as APIMovimientosStock from '../../servicios/APIMovimientosStock'
import { QueryClient } from '@tanstack/react-query';
import { 
    IMovimientoStock, IFiltrosMovimientosStock
} from '../../tipos/MovimientosStockType';


/* QUERYS KEYS */
const KEYPMovimientosStockLista = "listaMovimientosStock";
const KEYPSalidasStockLista = "listaSalidasStock";
const KEYPMovimientosStockListaPaginada = "listaMovimientosStockPaginacion";
const KEYPMovimientoStockDatos = "datosMovimientoStock";

export {
    KEYPMovimientosStockLista,
    KEYPSalidasStockLista,
    KEYPMovimientosStockListaPaginada,
    KEYPMovimientoStockDatos
}

/* QUERYS */
/* movimientos de stock */
const queryListarMovimientosStock = (filtros: IFiltrosMovimientosStock) => ({
    queryKey: [KEYPMovimientosStockLista, filtros],
    queryFn: () => APIMovimientosStock.ListarMovimientosStock(filtros),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
});

const queryListarMovimientosStockPaginacion = (filtros: IFiltrosMovimientosStock) => ({
    queryKey: [KEYPMovimientosStockListaPaginada, filtros],
    queryFn: ({ pageParam = 0 }) => APIMovimientosStock.ListarMovimientosStockPaginacion(pageParam, 20, filtros),
    getNextPageParam: (lastPage: IMovimientoStock[], allPages: IMovimientoStock[][]) => allPages.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

const queryListarSalidasStockPaginacion = (filtros: IFiltrosMovimientosStock) => ({
    queryKey: [KEYPSalidasStockLista, filtros],
    queryFn: ({ pageParam = 0 }) => APIMovimientosStock.ListarMovimientosStockPaginacion(pageParam, 20, filtros),
    getNextPageParam: (lastPage: IMovimientoStock[], allPages: IMovimientoStock[][]) => allPages.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

const queryObtenerMovimientoStock = (id : number, queryClient: QueryClient) => ({
    queryKey: [KEYPMovimientoStockDatos, id],
    queryFn: () => APIMovimientosStock.ObtenerMovimientoStock(id),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!id
});
 

/* EXPORTACIONES */
export {
    queryListarMovimientosStock, queryListarMovimientosStockPaginacion,
    queryListarSalidasStockPaginacion,
    queryObtenerMovimientoStock,
}