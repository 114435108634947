/* DEPENDENCIAS */
import { clienteAxios } from "./APIAxiosInstancia";
import { URL_API_RECEPCIONES_MERCANCIA, URL_API_RECEPCIONES_MERCANCIA_PAGINACION } from "./Endpoints";
import { IRecepcionMercancia, IFiltrosRecepcionesMercancia } from "../tipos/RecepcionesMercanciaType";
import { IDocumentoRecepcionUpload } from "../tipos/DocumentosType";

/* DECLARACIONES */
/* API */
/**
 * Función que obtiene el listado de las recepciones de mercancías.
 */
export const ListarRecepcionesMercancia = async (filtros: IFiltrosRecepcionesMercancia): Promise<IRecepcionMercancia[]> => {
    try {     
        const respuesta = await clienteAxios.get<IRecepcionMercancia[]>(
            URL_API_RECEPCIONES_MERCANCIA,
            { params: filtros}
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene el listado de las recepciones de mercancías.
 */
export const ListarRecepcionesMercanciaPaginacion = async (pagina:number, filas:number, filtros: IFiltrosRecepcionesMercancia): Promise<IRecepcionMercancia[]> => {
    try {     
        const respuesta = await clienteAxios.get<IRecepcionMercancia[]>(
            URL_API_RECEPCIONES_MERCANCIA_PAGINACION,
            { params: {
                ...filtros,
                numeroPagina: pagina,
                tamanhoPagina: filas,
            }}
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene los datos de una recepción de mercancía.
 */
export const ObtenerRecepcionMercancia = async (id: number): Promise<IRecepcionMercancia> => {
    try {  
        const respuesta = await clienteAxios.get<IRecepcionMercancia>(
            `${URL_API_RECEPCIONES_MERCANCIA}/${id}`
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que guarda los datos de una nueva recepción de mercancía.
 */
export const AgregarRecepcionMercancia = async (datos : IRecepcionMercancia): Promise<IRecepcionMercancia> => {
    try {     
        const respuesta = await clienteAxios.post(
            URL_API_RECEPCIONES_MERCANCIA, datos
        );
        return respuesta.data;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al agregar una habitacion.");
    }
}

/**
 * Función que guarda los datos de edición de una recepción de mercancía.
 */
export const EditarRecepcionMercancia = async (datos : IRecepcionMercancia): Promise<void> => {
    try {     
        await clienteAxios.put(URL_API_RECEPCIONES_MERCANCIA + `/${datos.id}`, datos);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al editar una recepción de mercancía.");
    }
}

/**
 * Función que elimina los datos de una recepción de mercancía.
 */
export const EliminarRecepcionMercancia = async (id : number): Promise<void> => {
    try {     
        await clienteAxios.delete(URL_API_RECEPCIONES_MERCANCIA + `/${id}`);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al eliminar una recepción de mercancía.");
    }
}

/**
 * Función que guarda los datos del documento de una recepción de mercancia.
 */
export const GuardarDocumento = async (datos : IDocumentoRecepcionUpload): Promise<IDocumentoRecepcionUpload> => {
    try {    
        const respuesta = await clienteAxios.post(URL_API_RECEPCIONES_MERCANCIA + "/agregarDocumento", datos, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                responseType: "blob"
            }
        );
        const iddocumento = respuesta.headers['x-iddocumento'];        
        return {
            ...datos,
            idDocumento: iddocumento,
        };
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al guardar un documento de una recepción de mercancía.");
    }
}

/**
 * Función que obtiene los datos de una recepción de mercancía.
 */
export const ObtenerDocumentoRecepcionMercancia = async (id: number): Promise<File> => {
    try {  
        let file : File;
        const respuesta = await clienteAxios.get<File>(
            `${URL_API_RECEPCIONES_MERCANCIA}/documentoRecepcion/${id}`,  {
                responseType: "blob"
            }
        );
        const fileName = respuesta.headers['content-disposition']?.split('filename=')[1].split(';')[0]?? "undefined";
        const type = respuesta.headers['content-type'];

        const blob = new Blob([respuesta.data], { type: type });    //Cambia el tipo MIME según el archivo
        file = new File([blob], fileName, { type: blob.type });
        return file;
    } catch(error: any) {
        let mensajeError:string;
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que elimina los datos del documento de una recepción de mercancía.
 */
export const EliminarDocumentoRecepcionMercancia = async (id : number): Promise<void> => {
    try {     
        await clienteAxios.delete(URL_API_RECEPCIONES_MERCANCIA + `/documentoRecepcion/${id}`);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al eliminar una recepción de mercancía.");
    }
}
