/* DEPENDENCIAS */
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'bootstrap/';
import './util/estilos/App.scss';
import Enrutador from './rutas/Enrutador';
//import reportWebVitals from './reportWebVitals';

/* DEFINICIONES */
const root = createRoot(document.getElementById('mainApp') as HTMLElement);
const queryClient = new QueryClient();

/* COMPONENTE */
root.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={Enrutador} />      
      </QueryClientProvider>
  </React.StrictMode> 
);

/* informe de web-vitals */
//reportWebVitals(console.log);