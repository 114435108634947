/* DEPENDENCIAS */
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import CamasInstance from "./CamasInstance";
import { ICama, IFiltrosCamas } from "../../../tipos/HabitacionesType";
import { queryListarCamas } from "../../../util/querys/HabitacionesQuerys";

/* DEFINICIONES */
type ICamasQueryProps = {
  filtrosQuery : IFiltrosCamas,
  pintarMasFilas : boolean,
  handlerImprimirEtiqueta : (datosModelo: ICama) => void,
  handlerEditar : (datosModelo: ICama) => void,
  handlerEliminar : (datosModelo : ICama) => void,
}

/* COMPONENTE */
const CamasQuery = ({filtrosQuery, pintarMasFilas, handlerImprimirEtiqueta, handlerEditar, handlerEliminar} : ICamasQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useInfiniteQuery<ICama[], Error>(queryListarCamas(filtrosQuery?.idHabitacion?? 0));
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
          
    
    /* renderizado */    
    return (
      <CamasInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerEditar={handlerEditar}
        handlerImprimirEtiqueta={handlerImprimirEtiqueta}
        handlerEliminar={handlerEliminar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default CamasQuery;