/* DEPENDENCIAS */
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import RecepcionesMercanciaInstance from "./RecepcionesMercanciaInstance";
import { IRecepcionMercancia, IFiltrosRecepcionesMercancia } from "../../../tipos/RecepcionesMercanciaType";
import { queryListarRecepcionMercanciaesPaginacion } from "../../../util/querys/RecepcionesMercanciaQuerys";

/* DEFINICIONES */
type IRecepcionesMercanciaQueryProps = {
  filtrosQuery : IFiltrosRecepcionesMercancia,
  pintarMasFilas : boolean,
  handlerEditar : (datosModelo: IRecepcionMercancia) => void,
  handlerEliminar : (datosModelo : IRecepcionMercancia) => void,
}

/* COMPONENTE */
const RecepcionesMercanciaQuery = ({filtrosQuery, pintarMasFilas, handlerEditar, handlerEliminar} : IRecepcionesMercanciaQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useListarRecepcionesMercancia(filtrosQuery);
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
    
    /* funciones */
    function useListarRecepcionesMercancia(filtrosQuery: IFiltrosRecepcionesMercancia) {
        return useInfiniteQuery<IRecepcionMercancia[], Error>(queryListarRecepcionMercanciaesPaginacion(filtrosQuery));
    }        
    
    /* renderizado */    
    return (
      <RecepcionesMercanciaInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerEditar={handlerEditar}
        handlerEliminar={handlerEliminar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default RecepcionesMercanciaQuery;