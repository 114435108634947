/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useState} from 'react';
import { Button, Row, Col, FloatingLabel, Modal, Form, Container } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useQuery } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { IEtiquetaHabitacion, etiquetaHabitacionModelDefault } from '../../../tipos/ColaImpresionType';
import { queryListarImpresoras, queryObtenerPlantillaImpresion } from '../../../util/querys/ImpresionesQuerys';
import { PLANTILLA_HABITACION_ID } from '../../../util/tsx-ayuda/Constantes';
import { ICama } from '../../../tipos/HabitacionesType';

/* DECLARACIONES */
const schemaValidacion = yup.object().shape({
    idImpresora: yup.number()
        .min(1, "Seleccione una impresora")
        .required("El campo es obligatorio"),
    copias: yup.number()
        .min(1, "Debe solicitar al menos 1 copia")
        .required("El campo es obligatorio"),
    textoDieta1: yup.string()
        .required("El campo es obligatorio")
});
  

type Props = {
    mostrarModal: boolean,
    datosCama: ICama | undefined,
    guardarDatos: (datosModelo: IEtiquetaHabitacion) => void
    handlerCancelar: () => void
}
    
/* COMPONENTE */
export default function EtiquetaHabitacionModal({datosCama, mostrarModal, guardarDatos, handlerCancelar} : Props) {
    /* definiciones */
    const [cama, setCama] = useState(datosCama);
    const { data: opcionesImpresoras, isLoading: isLoadingImpresoras } = useQuery(queryListarImpresoras());
    const { data: datosPlantilla, isLoading: isLoadingPlantilla } = useQuery(queryObtenerPlantillaImpresion(PLANTILLA_HABITACION_ID));
    const { register, handleSubmit, formState: {errors}, getValues, setValue, reset, watch } = useForm<IEtiquetaHabitacion>({
        mode: "onSubmit", reValidateMode: "onChange",
        defaultValues: etiquetaHabitacionModelDefault,
        values: etiquetaHabitacionModelDefault,
        resolver: yupResolver(schemaValidacion),
    });

    /* funciones */
    
    
    /* efectos */ 
    React.useEffect(() => {      
        setValue("cama", datosCama?? null);
        setCama(datosCama);  
    }, [datosCama, setValue]); 

    React.useEffect(() => {
        if (!isLoadingPlantilla) {            
            setValue("plantilla", datosPlantilla?? null); 
        }
    }, [datosPlantilla, isLoadingPlantilla, setValue]);  
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal} size="lg"
            onHide={() => {reset(etiquetaHabitacionModelDefault); handlerCancelar();}}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Impresión de etiqueta de habitación
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <Form.Group className="mb-3" controlId="txtPlantilla">
                                    <Form.Label column sm="2">Habitación:</Form.Label>
                                    <Form.Control plaintext readOnly className="text-info"
                                        defaultValue={cama?.nombreHabitacion?? "-"} />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group className="mb-3" controlId="txtPlantilla">
                                    <Form.Label column sm="2">Cama:</Form.Label>
                                    <Form.Control plaintext readOnly className="text-info"
                                        defaultValue={cama?.nombre?? "-"} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={10}>
                                <Form.Group className="mb-3" controlId="selImpresora">
                                    <Form.Label>Impresora</Form.Label>
                                    <Form.Select {...register("idImpresora")} required={true} isInvalid={!!errors.idImpresora}>
                                        {isLoadingImpresoras && (<option value="">cargando...</option>)}
                                        {opcionesImpresoras && 
                                            [
                                                ...[{id:0, nombre: "seleccione una impresora..."}],
                                                ...opcionesImpresoras
                                            ].map((option) => {
                                            return (
                                                <option key={option.id?? 0} value={option.id?? 0}>{option.nombre}</option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.idImpresora?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="txtCopias">
                                    <Form.Label>Nº de copias</Form.Label>
                                    <Form.Control type="number" style={{width:"100px"}} maxLength={3} {...register("copias")}
                                        required={true} isInvalid={!!errors.copias}/>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.copias?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="txtTexto" label="texto de la primera fila de la dieta">
                                    <Form.Control 
                                        {...register("textoDieta1")}
                                        required={true} maxLength={25}  isInvalid={!!errors.textoDieta1}
                                        placeholder="escribe texto para imprimir en la etiqueta..."/>
                                    <Form.Text className="text-muted" hidden={watch('textoDieta1').length === 0}>
                                        {`${watch('textoDieta1')?.length?? 0}/25`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.textoDieta1?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="txtTexto" label="texto de la segunda fila de la dieta">
                                    <Form.Control 
                                        {...register("textoDieta2")} maxLength={25}  isInvalid={!!errors.textoDieta2}
                                        placeholder="escribe texto para imprimir en la etiqueta..."/>
                                    <Form.Text className="text-muted" hidden={watch('textoDieta2').length === 0}>
                                        {`${watch('textoDieta2')?.length?? 0}/25`}
                                    </Form.Text>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() => { reset(etiquetaHabitacionModelDefault); handlerCancelar();}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)} disabled={datosPlantilla === null}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}