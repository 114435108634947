/* DEPENDENCIAS */
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import { ILote, IFiltrosLotes } from "../../../tipos/LotesType";
import { queryListarLotesPaginacion } from "../../../util/querys/LotesQuerys";
import LotesInstance from "./LotesInstance";

/* DEFINICIONES */
type ILoteInstanceQueryProps = {
  filtrosQuery : IFiltrosLotes,
  pintarMasFilas : boolean,
  handlerEditar? : (datosModelo: ILote) => void,
  handlerRegularizarStock : (datosModelo: ILote) => void,
  handlerRegistrarSalida : (datosModelo: ILote) => void,
  handlerVerTrazabilidad : (datosModelo: ILote) => void,
  handlerImprimirEtiquetaLote? : (datosModelo: ILote) => void,
}

/* COMPONENTE */
const LotesQuery = ({
  filtrosQuery, pintarMasFilas, 
  handlerEditar, 
  handlerRegularizarStock, 
  handlerRegistrarSalida, 
  handlerVerTrazabilidad,
  handlerImprimirEtiquetaLote
} : ILoteInstanceQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useInfiniteQuery<ILote[], Error>(queryListarLotesPaginacion(filtrosQuery));
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
    
    /* funciones */      
    
    /* renderizado */    
    return (
      <LotesInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerEditar={handlerEditar}
        handlerRegularizarStock={handlerRegularizarStock}
        handlerRegistrarSalida={handlerRegistrarSalida}
        handlerVerTrazabilidad={handlerVerTrazabilidad}
        handlerImprimirEtiquetaLote={handlerImprimirEtiquetaLote}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default LotesQuery;