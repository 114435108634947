/* DEPENDENCIAS */
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { format } from "date-fns";
import { useMutation } from "@tanstack/react-query";

import * as APIUsuarios from '../servicios/APIUsuarios'
import Menu from './_diseño/Menu';
import Header from './_diseño/Header';
import Contenido from './_diseño/Contenido';
import Notificacion, { ETipoNotificacion, infoNotificacionDefault } from "../componentes-com/Notificacion";
import { IAPICambiarClave, IFormCambiarClave, IUsuarioSesion, modelUsuarioSesionDefault } from "../tipos/UsuariosType";
import { VAR_SESION_USUARIO } from "../tipos/CommonTypes";
import { useLocalStorage } from "../hooks/useLocalStorage";

/* COMPONENTE */
export default function App() { 
    /* variables */
    const { getItem } = useLocalStorage();
    const [usuarioSesion, setUsuarioSesion] = useState<IUsuarioSesion>(modelUsuarioSesionDefault);
    const [mostrarMenu, setMostrarMenu] = useState(false);
    
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const cambiarClave = useCambiarClave();

    /* funciones */
    const guardarDatosCambiarClave = (datosForm: IFormCambiarClave) => {
        let infoCambioClave : IAPICambiarClave = {
            id: usuarioSesion.id,
            clave: datosForm.clave,
        }
        cambiarClave.mutate(infoCambioClave);
    }

    function useCambiarClave() {
        return useMutation({
          mutationFn: (datos: IAPICambiarClave) => {
            return APIUsuarios.CambiarClave(datos);
          },
          onSuccess: () => {         
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Cambiar contraseña",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>La contraseña se ha cambiado correctamente.</span>`
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Cambiar contraseña",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error cambiando la contraseña.</span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    /* efectos */
    useEffect(() => {
        const user = getItem(VAR_SESION_USUARIO);
        if (user) {
            setUsuarioSesion(JSON.parse(user));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    /* renderizado */
    return (
        <Container fluid className="px-0">
            <Row>
                <Header 
                    mostrarMenu={mostrarMenu} 
                    setMostrarMenu={setMostrarMenu}
                    guardarDatosNuevaClave={guardarDatosCambiarClave} />
            </Row>
            <Container fluid id="content-wrapper" className="px-0 d-flex">
                <section id="sidebar-wrapper" className="p-0 m-0">
                    <Menu 
                    mostrarMenu={mostrarMenu} 
                    setMostrarMenu={setMostrarMenu} />
                </section>
                <section id="page-content-wrapper">
                    <Contenido/>
                </section>         
                <Notificacion
                        tipo={infoNotificacion.tipo}
                        titulo={infoNotificacion.titulo}
                        fecha={infoNotificacion.fecha}
                        mensaje={infoNotificacion.mensaje}
                        mostrar={visibleNotificacion}
                        setMostrar={setVisibleNotificacion} />
                </Container>
        </Container>
    );
}