//dependencias
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser';

//declaraciones
type ConfirmacionModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    mensajeConfirmacion: string,
    handlerNO?: ()=> void,
    handlerSI: ()=> void,
}

  
//componente
export default function ConfirmacionModal({
        mostrarModal,
        setMostrarModal,
        mensajeConfirmacion,
        handlerNO,
        handlerSI,
    } : ConfirmacionModalProps) {
    /* definiciones */
    const [mensaje, setMensaje] = useState("");

    /* funciones */ 
    const onClickNO = () => {
        setMostrarModal(!mostrarModal);
        handlerNO?.();
    }
    
    const onClickSI = () => {
        setMostrarModal(!mostrarModal);
        handlerSI();
    }

    /* efectos */
    useEffect(() => {
        setMensaje(mensajeConfirmacion);
    }, [mensajeConfirmacion])

    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Modal.Header>
                <Modal.Title className='m-0'>Confirmar acción</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-2 text-center text-danger align-top">
                            <span className="material-symbols-outlined fs-3">
                                emergency_home
                            </span>
                        </div>
                        <div className="col-10 py-2">
                            <h5>¿Está usted seguro?</h5>
                            <p>{parse(mensaje)}</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="light" onClick={onClickNO}>
                No
            </Button>
            <Button variant="danger" onClick={onClickSI}>
                Si
            </Button>
            </Modal.Footer>
      </Modal>
    );
}