/* DEPENDENCIAS */
import { IFiltrosImpresion, IImpresion } from '../../tipos/ColaImpresionType';
import * as APIImpresiones from '../../servicios/APIImpresiones'


/* QUERYS KEYS */
const KEYImpresorasListaSelect = "listaImpesorasSelect";
const KEYPlantillaImpresionDatos = "datosPlantillaImpresion";
const KEYPColaImpresionListaPaginada = "listaColaImpresion";

export {
    KEYImpresorasListaSelect,
    KEYPlantillaImpresionDatos,
    KEYPColaImpresionListaPaginada
}

/* QUERYS */
/* impresoras */
const queryListarImpresoras = () => ({
    queryKey: [KEYImpresorasListaSelect],
    queryFn: () => APIImpresiones.ListarImpresoras(undefined),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

/* plantillas */
const queryObtenerPlantillaImpresion = (id: number) => ({
    queryKey: [KEYPlantillaImpresionDatos, id],
    queryFn: () => APIImpresiones.ObtenerPlantillaImpresion(id),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

/* colaimpresion */
const queryListarColaImpresionPaginada = (filtrosQuery: IFiltrosImpresion) => ({
    queryKey: [KEYPColaImpresionListaPaginada, filtrosQuery],
    queryFn: ({ pageParam = 0 }) => APIImpresiones.ListarColaImpresionPaginacion(pageParam, 20, filtrosQuery),
    getNextPageParam: (lastPage: IImpresion[], allPages:IImpresion[][]) => allPages.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});


export {
    queryListarImpresoras,
    queryObtenerPlantillaImpresion,
    queryListarColaImpresionPaginada
}