/* DEPENDENCIAS */
import { clienteAxios } from "./APIAxiosInstancia";
import { URL_API_COLAIMPRESION, URL_API_COLAIMPRESION_PAGINACION, URL_API_IMPRESORAS, URL_API_IMPRESORAS_PAGINACION, URL_API_PLANTILLASIMPRESION, URL_API_PLANTILLASIMPRESION_PAGINACION } from "./Endpoints";
import { IImpresoraInsertable } from "../tipos/ImpresorasType";
import { IPlantillaImpresionInsertable } from "../tipos/PlantillasImpresionType";
import { IFiltrosImpresion, IImpresion, IImpresionInsertable } from "../tipos/ColaImpresionType";

/* DECLARACIONES */

/* API */
/**
 * Función que obtiene el listado de impresoras.
 */
export const ListarImpresoras = async (filtro: string | undefined): Promise<IImpresoraInsertable[]> => {
    try {     
        const respuesta = filtro? 
            await clienteAxios.get<IImpresoraInsertable[]>(
                URL_API_IMPRESORAS,
                { params: {filtro: filtro}}
            ) :
            await clienteAxios.get<IImpresoraInsertable[]>(URL_API_IMPRESORAS);
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene el listado de impresoras con paginación.
 */
export const ListarImpresorasPaginacion = async (pagina:number, filas:number, filtro: string | undefined): Promise<IImpresoraInsertable[]> => {
    try {     
        const respuesta = filtro? 
            await clienteAxios.get<IImpresoraInsertable[]>(
                URL_API_IMPRESORAS_PAGINACION,
                { params: {
                    filtro: filtro, 
                    numeroPagina: pagina,
                    tamanhoPagina: filas,
                }}
            ) :
            await clienteAxios.get<IImpresoraInsertable[]>(URL_API_IMPRESORAS_PAGINACION,
                { params: {
                    numeroPagina: pagina,
                    tamanhoPagina: filas,
                }});
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que guarda los datos de una nueva impresora.
 */
export const AgregarImpresora = async (datos : IImpresoraInsertable): Promise<IImpresoraInsertable> => {
    try {     
        const respuesta = await clienteAxios.post(
            URL_API_IMPRESORAS, datos
        );
        return respuesta.data;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al agregar una impresora.");
    }
}

/**
 * Función que guarda los datos de edición de una impresora.
 */
export const EditarImpresora = async (datos : IImpresoraInsertable): Promise<void> => {
    try {     
        await clienteAxios.put(URL_API_IMPRESORAS + `/${datos.id}`, datos);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al editar una impresora.");
    }
}

/**
 * Función que elimina los datos de una impresora.
 */
export const EliminarImpresora = async (id : number): Promise<void> => {
    try {     
        await clienteAxios.delete(URL_API_IMPRESORAS + `/${id}`);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al eliminar una impresora.");
    }
}

/**
 * Función que obtiene el listado de plantillas de impresión.
 */
export const ListarPlantillasImpresion = async (filtro: string | undefined): Promise<IPlantillaImpresionInsertable[]> => {
    try {     
        const respuesta = filtro? 
            await clienteAxios.get<IPlantillaImpresionInsertable[]>(
                URL_API_PLANTILLASIMPRESION,
                { params: {filtro: filtro}}
            ) :
            await clienteAxios.get<IPlantillaImpresionInsertable[]>(URL_API_PLANTILLASIMPRESION);
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene el listado de plantillas de impresión con paginación.
 */
export const ListarPlantillasImpresionPaginacion = async (pagina:number, filas:number, filtro: string | undefined): Promise<IPlantillaImpresionInsertable[]> => {
    try {     
        const respuesta = filtro? 
            await clienteAxios.get<IPlantillaImpresionInsertable[]>(
                URL_API_PLANTILLASIMPRESION_PAGINACION,
                { params: {
                    filtro: filtro, 
                    numeroPagina: pagina,
                    tamanhoPagina: filas,
                }}
            ) :
            await clienteAxios.get<IPlantillaImpresionInsertable[]>(URL_API_PLANTILLASIMPRESION_PAGINACION,
                { params: {
                    numeroPagina: pagina,
                    tamanhoPagina: filas,
                }});
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que guarda los datos de edición de una plantilla de impresión.
 */
export const ObtenerPlantillaImpresion = async (id : number): Promise<IPlantillaImpresionInsertable> => {
    try {     
        const respuesta = await clienteAxios.get(URL_API_PLANTILLASIMPRESION + `/${id}`);
        return respuesta.data;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al obtener los datos de una plantilla de impresión.");
    }
}

/**
 * Función que guarda los datos de edición de una plantilla de impresión.
 */
export const EditarPlantillaImpresion = async (datos : IPlantillaImpresionInsertable): Promise<void> => {
    try {     
        await clienteAxios.put(URL_API_PLANTILLASIMPRESION + `/${datos.id}`, datos);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al editar una plantilla de impresión.");
    }
}

/**
 * Función que obtiene el listado de la cola de impresión.
 */
export const ListarColaImpresion = async (filtros: IFiltrosImpresion): Promise<IImpresion[]> => {
    try {     
        const respuesta = await clienteAxios.get<IImpresion[]>(
            URL_API_COLAIMPRESION,
            { params: filtros}
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene el listado de la cola de impresión.
 */
export const ListarColaImpresionPaginacion = async (pagina:number, filas:number, filtros: IFiltrosImpresion): Promise<IImpresion[]> => {
    try {     
        const respuesta = await clienteAxios.get<IImpresion[]>(
            URL_API_COLAIMPRESION_PAGINACION,
            { params: {
                ...filtros,
                numeroPagina: pagina,
                tamanhoPagina: filas,
            }}
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que guarda los datos de una nueva impresora.
 */
export const AgregarImpresion = async (datos : IImpresionInsertable): Promise<IImpresionInsertable> => {
    try {     
        const respuesta = await clienteAxios.post(
            URL_API_COLAIMPRESION, datos
        );
        return respuesta.data;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al agregar una impresión a la cola de impresiones.");
    }
}