/* DEPENDENCIAS */
import React, { Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
import { Col, Container, FloatingLabel, Row } from 'react-bootstrap';
import { format } from 'date-fns';

import { ObtenerCssBadgeStock, ObtenerCssFecha, ObtenerCssStock } from '../../../util/tsx-ayuda/Funciones';
import { IProducto } from '../../../tipos/ProductosType';
import { ILote } from "../../../tipos/LotesType";
import * as Rutas from '../../../rutas/Rutas';

/* DECLARACIONES */
type LoteEstadoModalProps = {
    mostrarModal: boolean,
    datosProducto: IProducto,
    datosLote: ILote,
    handlerCancelar: () => void,
}

  
/* COMPONENTE */
export default function LoteEstadoModal({
    datosProducto, datosLote, 
    mostrarModal,
    handlerCancelar,
} : LoteEstadoModalProps) {
    /* definiciones */
    
    /* funciones */
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={handlerCancelar}
            backdrop="static"
            keyboard={false}
            fullscreen>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {datosProducto.usaControlLotes && ("Estado de lote")}
                        {!datosProducto.usaControlLotes && ("Estado de producto")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid> 
                        <Row>
                            <Col sm={10}><h6 className="display-6 text-primary">Información del producto</h6></Col>
                            <Col sm={2} className=" d-flex align-items-center  justify-content-end">
                                <Link to={`${Rutas.RUTA_PRODUCTOS_EDICION}${datosProducto.id}`} className="nav-link ">
                                    <div className="d-flex align-items-center text-primary">
                                        ficha&nbsp;&nbsp;
                                        <span className="material-symbols-outlined">east</span>
                                    </div>
                                </Link>   
                            </Col>    
                        </Row>                       
                        
                        <hr /> 
                        <Row>
                            <Col sm={5}>
                                <FloatingLabel className="mb-3" controlId="txtNombreProducto" label="Producto">
                                    <Form.Control plaintext className="text-dark" value={datosProducto.nombre} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={4}>
                                <FloatingLabel className="mb-3" controlId="txtFamilia" label="Familia">
                                    <Form.Control plaintext className="text-dark" value={datosProducto.nombreFamilia} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={3}>
                                <FloatingLabel className="mb-3" controlId="txtSeccion" label="Seccion">
                                    <Form.Control plaintext className="text-dark" value={datosProducto.nombreSeccion} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <FloatingLabel className="mb-3" controlId="txtCodigo" label="Código">
                                    <Form.Control plaintext className="text-dark" value={datosProducto.codigo?? "-"} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={3}>
                                <FloatingLabel className="mb-3" controlId="txtCodigoEAN" label="Código EAN">
                                    <Form.Control plaintext className="text-dark" value={datosProducto.codigoEAN?? "-"} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={7}>
                                <FloatingLabel className="mb-3" controlId="txtModelo" label="Modelo">
                                    <Form.Control plaintext className="text-dark" value={datosProducto.modelo?? "-"} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={2}>
                                <FloatingLabel className="mb-3" controlId="txtPrecio" label="Precio (€)">
                                    <Form.Control plaintext className="text-dark" value={datosProducto.precio?? "-"} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={3}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label={`Stock mínimo (${datosProducto.abreviaturaTipoUnidad})`}>
                                    <Form.Control plaintext className="text-dark" value={datosProducto.stockMinimo} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={4}>
                                <Form.Floating>
                                    <span id="txtStock" className="form-control-plaintext">
                                        <span className={`badge ${ObtenerCssBadgeStock(datosLote.stock, datosProducto.stockMinimo)}`}>
                                        {datosLote.stock}
                                        </span>
                                    </span>
                                    <label htmlFor="txtStock">{`Stock (${datosProducto.abreviaturaTipoUnidad})`}</label>
                                </Form.Floating>
                            </Col>
                            <Col sm={3}>
                                <Form.Floating>
                                    <div className="form-check form-switch form-control-plaintext">
                                        <input className="form-check-input" type="checkbox" role="switch" id="cbUsaControlLotes" disabled
                                            checked={datosProducto.usaControlLotes}
                                            style={{marginLeft:0}} />
                                    </div>
                                    <label htmlFor="cbUsaControlLotes">Usa control por lotes</label>
                                </Form.Floating>
                            </Col>
                        </Row>
                        {datosProducto.usaControlLotes && (
                        <Fragment>
                        <h6 className="display-6 text-primary">Información del lote</h6>
                        <hr />  
                        <Row>
                            <Col sm={4}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Código">
                                    <Form.Control plaintext className="text-dark" value={datosLote.codigo} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={4}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Caducidad">
                                    <Form.Control plaintext  className={ObtenerCssFecha(datosLote.caducidad?? null)} 
                                        value={(datosLote.caducidad)? format(new Date(datosLote.caducidad), "dd/MM/yyyy") : "-"} />
                                </FloatingLabel>                                              
                            </Col>
                            <Col sm={4}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Consumo preferente">
                                    <Form.Control plaintext  className={ObtenerCssFecha(datosLote.consumoPreferente?? null)} 
                                        value={(datosLote.consumoPreferente)? format(new Date(datosLote.consumoPreferente), "dd/MM/yyyy") : "-"} />
                                </FloatingLabel>                                               
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col sm={12}>
                                <Form.Floating>
                                    <h3 className={`display-3 form-control-plaintext ${ObtenerCssStock(datosLote.stock, datosProducto.stockMinimo)}`}>{datosLote.stock}</h3>
                                    <label htmlFor="cbUsaControlLotes">Stock actual</label>
                                </Form.Floating>                                
                            </Col>
                        </Row>
                        </Fragment>
                        )}
                    </Container>                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handlerCancelar}>
                        <div className="d-flex">
                            <span className="material-symbols-outlined">block</span>&nbsp;
                            Cancelar
                        </div>
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}