/* DEPENDENCIAS */
import { add, compareAsc, format } from "date-fns";
import Base64 from 'crypto-js/enc-base64';
import { IEtiquetaHabitacion, IEtiquetaLaboratorio, IEtiquetaProducto } from "../../tipos/ColaImpresionType";
import { 
    PLANTILLA_PRODUCTO_ID 
} from "./Constantes";
import { EFuncionalidad, ETipoIVA, JWTHeaderDefault, JWTPayload, JWTPayloadDefault } from "../../tipos/CommonTypes";
import { IFuncionalidad } from "../../tipos/UsuariosType";
import * as jose from 'jose'

var CryptoJS = require("crypto-js");

/* FUNCIONES */

/* patrones para sustituir en las plantillas de impresión */
const patronQR = "##QR##";
const patronCopias = "##COPIAS##";
const patronFecha = "##FECHA##";
const patronTextoFila1 = "##TEXTO_FILA1##";
const patronTextoFila2 = "##TEXTO_FILA2##";
const patronHabitacion = "##HABITACION##";
const patronCama = "##CAMA##";
const patronDieta1 = "##DIETA1##";
const patronDieta2 = "##DIETA2##";
const patronNombreCorto = "##NOMBRE_CORTO##";
const patronLote = "##LOTE##";
const patronCodigo = "##CODIGO##";
const patronCodigoEAN = "##CODIGOEAN##";

/**
 * Función que transforma los datos de una etiqueta de laboratorio en un texto para enviar a la impresora de etiquetas.
 * @param datosEtiqueta Objeto con la información de la etiqueta.
 * @returns Devuelve una cadena en lenguaje interpretable por la impresora de etiquetas.
 */
const TransformarPlantillaLaboratorio = (datosEtiqueta: IEtiquetaLaboratorio) : string => {
    //Campos.
    let cadenaTransformada : string = datosEtiqueta.plantilla?.texto?? "";

    //Método.
    //de momento sin QR
    //cadenaTransformada = cadenaTransformada.replaceAll(patronQR, `${PLANTILLA_LABORATORIO_ID} | `);
    cadenaTransformada = cadenaTransformada.replaceAll(patronCopias, datosEtiqueta.copias.toString());
    cadenaTransformada = cadenaTransformada.replaceAll(patronFecha, format(datosEtiqueta.fecha, "dd/MM/yyyy HH:mm"));
    cadenaTransformada = cadenaTransformada.replaceAll(patronTextoFila1, datosEtiqueta.textoFila1);
    cadenaTransformada = cadenaTransformada.replaceAll(patronTextoFila2, datosEtiqueta.textoFila2);
    return cadenaTransformada;
}

/**
 * Función que transforma los datos de una etiqueta de habitación en un texto para enviar a la impresora de etiquetas.
 * @param datosEtiqueta Objeto con la información de la etiqueta.
 * @returns Devuelve una cadena en lenguaje interpretable por la impresora de etiquetas.
 */
const TransformarPlantillaHabitacion = (datosEtiqueta: IEtiquetaHabitacion) : string => {
    //Campos.
    let cadenaTransformada : string = datosEtiqueta.plantilla?.texto?? "";

    //Método.
    //de momento sin QR
    //cadenaTransformada = cadenaTransformada.replaceAll(patronQR, `${PLANTILLA_HABITACION_ID} | `);
    cadenaTransformada = cadenaTransformada.replaceAll(patronCopias, datosEtiqueta.copias.toString());
    cadenaTransformada = cadenaTransformada.replaceAll(patronHabitacion, datosEtiqueta.cama?.nombreHabitacion?? "-");
    cadenaTransformada = cadenaTransformada.replaceAll(patronCama, datosEtiqueta.cama?.nombre?? "-");
    cadenaTransformada = cadenaTransformada.replaceAll(patronFecha, format(datosEtiqueta.fecha, "dd/MM/yyyy"));
    cadenaTransformada = cadenaTransformada.replaceAll(patronDieta1, datosEtiqueta.textoDieta1);
    cadenaTransformada = cadenaTransformada.replaceAll(patronDieta2, datosEtiqueta.textoDieta2);
    return cadenaTransformada;
}

/**
 * Función que transforma los datos de una etiqueta de producto en un texto para enviar a la impresora de etiquetas.
 * @param datosEtiqueta Objeto con la información de la etiqueta.
 * @returns Devuelve una cadena en lenguaje interpretable por la impresora de etiquetas.
 */
const TransformarPlantillaProducto = (datosEtiqueta: IEtiquetaProducto) : string => {
    //Campos.
    let cadenaTransformada : string = datosEtiqueta.plantilla?.texto?? "";
    const datosMovimiento = datosEtiqueta.datosMovimiento;

    //Método.
    cadenaTransformada = cadenaTransformada.replaceAll(patronQR, `[BOF]${PLANTILLA_PRODUCTO_ID}|${datosMovimiento?.idProducto}|${datosMovimiento?.idLote}[EOF]`);
    cadenaTransformada = cadenaTransformada.replaceAll(patronCopias, datosEtiqueta.copias.toString());
    cadenaTransformada = cadenaTransformada.replaceAll(patronNombreCorto, datosMovimiento?.producto.nombreCorto?? "");
    cadenaTransformada = cadenaTransformada.replaceAll(patronCodigo, datosMovimiento?.producto.codigo?? "");
    cadenaTransformada = cadenaTransformada.replaceAll(patronCodigoEAN, datosMovimiento?.producto.codigoEAN?? "");
    cadenaTransformada = cadenaTransformada.replaceAll(patronLote, datosMovimiento?.codigoLote?? "");
    cadenaTransformada = cadenaTransformada.replaceAll(patronFecha, (datosMovimiento?.caducidadLote!==null)? format(new Date(datosMovimiento?.caducidadLote?? new Date()), "dd/MM/yyyy") : "");
    return cadenaTransformada;
}

/**
 * 
 * @param tipo Función que transforma un valor del tipo enumerado ETipoIVA en un texto para mostrar en la aplicación.
 * @returns Devuelve una cadena con el texto correspondiente al valor.
 */
const ETipoIVAToString = (tipo: ETipoIVA | unknown) : string => {
    //Método.
    switch (tipo){
        case ETipoIVA.EXENTO: return "Exento (0%)";
        case ETipoIVA.GENERAL: return "General (21%)";
        case ETipoIVA.REDUCIDO: return "Reducido (10%)";
        case ETipoIVA.SUPERREDUCIDO: return "Superreducido (4%)";
        case ETipoIVA.SUPERREDUCIDO_2: return "Superreducido (5%)";
        case ETipoIVA.SUPERREDUCIDO_3: return "Superreducido (0%)";
        default: return "desconocido";
    }
}

/**
 * 
 * @param tipo Función que transforma un valor del tipo enumerado ETipoIVA en su valor numérico para realizar cálculos.
 * @returns Devuelve el valor numérico correspondiente a cada tipo de IVA.
 */
const ETipoIVAToNumber = (tipo: ETipoIVA | unknown) : number => {
    //Método.
    switch (tipo){
        case ETipoIVA.EXENTO: return 0;
        case ETipoIVA.GENERAL: return 21;
        case ETipoIVA.REDUCIDO: return 10;
        case ETipoIVA.SUPERREDUCIDO: return 4;
        case ETipoIVA.SUPERREDUCIDO_2: return 5;
        case ETipoIVA.SUPERREDUCIDO_3: return 0;
        default: return 21;
    }
}

/**
 * 
 * @param tipo Función que hace el cálculo del subtotal según la cantidad y precio unitario.
 * @returns Devuelve el valor numérico correspondiente al cálculo.
 */
const CalcularSubtotal = (cantidad : number, precio : number) : number => {
    //Método.
    return (cantidad*precio);
}

/**
 * 
 * @param tipo Función que hace el cálculo del subtotal según la cantidad y precio unitario.
 * @returns Devuelve el valor numérico correspondiente al cálculo.
 */
const CalcularSubtotalIVA = (cantidad : number, precio : number, iva : number) : number => {
    //Campos.
    let precioBruto = precio*(1 + iva/100);
    //Método.
    return (cantidad*precioBruto);
}

const ObtenerCssFecha = (fecha: Date | null) : string => {
    //campos.
    const fechaActual = new Date();
    const fechaWarning = add(new Date(), { days: 7 });
    let css = "";

    //Método.
    if (fecha !== null) {
        if (compareAsc(new Date(fecha), fechaActual) <= 0) css="text-danger";
        else if (compareAsc(new Date(fecha), fechaWarning) <= 0) css="text-warning"; 
    }

    //devolvemos el resultado...
    return css;
}

const ObtenerCssStock = (stock: number | null, stockMinimo: number) : string => {
    //campos.
    let css = "";

    //Método.
    if (stock !== null) {
        if (stock <= 0) css="text-danger";
        else if (stock <= stockMinimo) css="text-warning"; 
    }

    //devolvemos el resultado...
    return css;
}

const ObtenerCssBadgeStock = (stock: number | null, stockMinimo: number) : string => {
    //campos.
    let css = "text-bg-info";

    //Método.
    if (stock !== null) {
        if (stock <= 0) css="text-bg-danger";
        else if (stock <= stockMinimo) css="text-bg-warning"; 
    }

    //devolvemos el resultado...
    return css;
}

/**
 * Función que comprueba si una funcionalidad tiene acceso a la lista de funcionalidades activas de un perfil.
 * @param funcionalidadesPerfil Lista de funcionalidades activas de un perfil.
 * @param funcionalidad Funcionalidad que se quiere comprobar en la lista.
 * @returns Devuelve el valor numérico correspondiente a cada tipo de IVA.
 */
const TienePermiso = (funcionalidadesPerfil: IFuncionalidad[], funcionalidad: EFuncionalidad) : boolean => {
    //Campos.
    let resultado : boolean = false;

    //Método.
    if (funcionalidadesPerfil) resultado = funcionalidadesPerfil.find(item => item.id === funcionalidad) !== undefined;
    else resultado = false;

    return resultado;
}

function limpiarTextoJWT(source : string) {
    // Encode in classical base64
  
    // Remove padding equal characters
    let encodedSource : string = source.replace(/=+$/, '');
  
    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
  
    return encodedSource;
  }

/**
 * JWT=base64URLEncode(header)+’.’+base64URLEncode(payload)+’.’+base64URLEncode(signature)
 */
const ObtenerJWT_old = () : string => {
    //Campos.
    const SECRET_KEY : string = process.env.REACT_APP_JWT_SECRET?? "";
    let encHeader : string = ""; 
    let payload : JWTPayload = JWTPayloadDefault;
    let encPayload : string = "";
    let token : string, tokenFirmado : string, firmaToken : string;

    //Método.
    //calculamos el header...
    encHeader = Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(JWTHeaderDefault)));

    //calculamos el encoded-payload..
    payload.exp = (Date.now() / 1000) + 10*3600;
    encPayload = Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(payload)));

    //calculamos la firma...
    token = limpiarTextoJWT(`${encHeader}.${encPayload}`);
    firmaToken = CryptoJS.HmacSHA256(token, SECRET_KEY);
    firmaToken = limpiarTextoJWT(Base64.stringify(CryptoJS.HmacSHA256(token, SECRET_KEY)));
    tokenFirmado = `${token}.${firmaToken}`;

    //devolvemos el token...
    return tokenFirmado;
}

/**
 * JWT=base64URLEncode(header)+’.’+base64URLEncode(payload)+’.’+base64URLEncode(signature)
 */
const ObtenerJWT = async () : Promise<string> => {
    //Campos.
    const SECRET_KEY : string = process.env.REACT_APP_JWT_SECRET?? "";
    const secret = new TextEncoder().encode(SECRET_KEY);

    //Método.
    return await new jose.SignJWT(JWTPayloadDefault)
        .setProtectedHeader(JWTHeaderDefault)
        .setExpirationTime('10m')
        .sign(secret);
}


/* EXPORTACIONES */
export {
    TransformarPlantillaLaboratorio,
    TransformarPlantillaHabitacion,
    TransformarPlantillaProducto,
    ETipoIVAToString, ETipoIVAToNumber,
    CalcularSubtotal, CalcularSubtotalIVA,
    ObtenerCssFecha, ObtenerCssStock, ObtenerCssBadgeStock,
    TienePermiso,
    ObtenerJWT, ObtenerJWT_old
}
