/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { IProveedor, proveedorModelDefault } from "../../../tipos/ProveedoresType";
import { Col, Container, Row } from 'react-bootstrap';

/* DECLARACIONES */
type ProveedoresModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: IProveedor | null,
    guardarDatos: (datosModelo: IProveedor) => void
}
  
const mensajesError = {
    requerido: "Este campo es obligatorio",
};
  
/* COMPONENTE */
export default function ProveedoresModal({datosIniciales, mostrarModal, setMostrarModal, guardarDatos} : ProveedoresModalProps) {
    /* definiciones */
    const { register, handleSubmit, formState: {errors}, getValues, reset } = useForm<IProveedor>({
        mode: "onTouched",
        defaultValues: proveedorModelDefault,
        values: datosIniciales?? proveedorModelDefault
    });

    /* funciones */
    useEffect(() => {
        reset(datosIniciales?? proveedorModelDefault);
    }, [reset, datosIniciales]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            size="lg"
            fullscreen="md-down">
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { (getValues().id === 0)  && "Añadir nuevo proveedor"}
                        { (getValues().id > 0)  && "Editar proveedor"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="txtNombre">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" {...register("nombre", {
                                            required: mensajesError.requerido
                                        })}
                                        required={true}  isInvalid={!!errors.nombre}
                                        placeholder="escribe el nombre del proveedor..." />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.nombre?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col sm={12} md={9}>
                                <Form.Group className="mb-3" controlId="txtNombreFiscal">
                                    <Form.Label>Nombre fiscal</Form.Label>
                                    <Form.Control type="text" {...register("nombreFiscal", {
                                            required: mensajesError.requerido
                                        })}
                                        required={true}  isInvalid={!!errors.nombre}
                                        placeholder="escribe el nombre fiscal del proveedor..." />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.nombreFiscal?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={3}>
                                <Form.Group className="mb-3" controlId="txtCif">
                                    <Form.Label>CIF</Form.Label>
                                    <Form.Control type="text" {...register("cif")}
                                        placeholder="escribe el CIF del proveedor..." />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="txtDireccion">
                                    <Form.Label>Dirección</Form.Label>
                                    <Form.Control type="text" {...register("domicilio")}
                                        placeholder="escribe la dirección del proveedor..." />
                                </Form.Group>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="txtPoblacion">
                                    <Form.Label>Población</Form.Label>
                                    <Form.Control type="text" {...register("poblacion")}
                                        placeholder="escribe la población fiscal del proveedor..." />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="txtProvincia">
                                    <Form.Label>Provincia</Form.Label>
                                    <Form.Control type="text" {...register("provincia")}
                                        placeholder="escribe la provincia del proveedor..." />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={8}>
                                <Form.Group className="mb-3" controlId="txtPais">
                                    <Form.Label>País</Form.Label>
                                    <Form.Control type="text" {...register("pais")}
                                        placeholder="escribe el pais fiscal del proveedor..." />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={4}>
                                <Form.Group className="mb-3" controlId="txtCodigoPostal">
                                    <Form.Label>C.P.</Form.Label>
                                    <Form.Control type="text" {...register("codigoPostal")}
                                        placeholder="escribe el C.P. del proveedor..." />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="txtEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" {...register("email")}
                                        placeholder="escribe el email del proveedor..." />
                                </Form.Group>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="txtTelefonos">
                                    <Form.Label>Teléfonos</Form.Label>
                                    <Form.Control type="text" {...register("telefonos")}
                                        placeholder="escribe los teléfonos del proveedor..." />
                                </Form.Group>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="txtFormaPago">
                                    <Form.Label>Forma de pago</Form.Label>
                                    <Form.Control type="text" {...register("formaPago")}
                                        placeholder="escribe la forma de pago del proveedor..." />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="txtModoPago">
                                    <Form.Label>Modo de pago</Form.Label>
                                    <Form.Control type="text" {...register("modoPago")}
                                        placeholder="escribe el modo de pago del proveedor..." />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() => { reset(proveedorModelDefault); setMostrarModal(!mostrarModal)}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}