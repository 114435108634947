/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { IImpresion } from "../../../tipos/ColaImpresionType";
import { Col, FloatingLabel, Row } from 'react-bootstrap';
import { impresionModelDefault } from './ColaImpresion';
import format from 'date-fns/format';

/* DECLARACIONES */
type ColaImpresionModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: IImpresion
}
  
/* COMPONENTE */
export default function ColaImpresionModal({datosIniciales, mostrarModal, setMostrarModal} : ColaImpresionModalProps) {
    /* definiciones */
    const { register, reset, getValues } = useForm<IImpresion>({
        mode: "onTouched",
        defaultValues: impresionModelDefault,
        values: datosIniciales?? impresionModelDefault
    });

    /* funciones */
    const getFechaImpresionTexto = (fecha: Date | null) => {
        if (fecha === null) return "-";
        else return format(new Date(fecha), "dd/MM/yyyy HH:mm");
    }

    useEffect(() => {
        reset(datosIniciales?? impresionModelDefault);
    }, [reset, datosIniciales]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            size='lg'
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>Datos de la impresión</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <FloatingLabel className="mb-3" controlId="txtFecha" label="fecha">
                                <Form.Control plaintext className="text-dark" {...register('fecha')}
                                    defaultValue={format(new Date(getValues().fecha), "dd/MM/yyyy HH:mm")} />
                            </FloatingLabel>
                        </Col>
                        <Col sm={6}>
                            <FloatingLabel className="mb-3" controlId="txtFechaImpresion" label="fecha impresión">
                                <Form.Control plaintext className="text-dark"
                                    value={getFechaImpresionTexto(getValues().fechaImpresion)} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FloatingLabel className="mb-3" controlId="txtImpresora" label="impresora">
                                <Form.Control plaintext className="text-dark" {...register('nombreImpresora')}
                                    defaultValue={getValues().nombreImpresora?? "-"} />
                            </FloatingLabel>
                        </Col>
                        <Col sm={6}>
                            <FloatingLabel className="mb-3" controlId="txtPlantilla" label="plantilla de impresión">
                                <Form.Control plaintext className="text-dark" {...register('nombrePlantilla')}
                                    defaultValue={getValues().nombrePlantilla?? "-"} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FloatingLabel className="mb-3" controlId="txtDescripción" label="texto">
                                <Form.Control as="textarea" readOnly rows={20} disabled  {...register('textoImpresion')}
                                    defaultValue={getValues().textoImpresion}
                                    style={{ height: '300px' }} />
                            </FloatingLabel>
                        </Col>
                    </Row>                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={() => { reset(impresionModelDefault); setMostrarModal(!mostrarModal)}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">done</span>&nbsp;
                        OK
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}