/* DEPENDENCIAS */
import { useNavigate } from "react-router-dom";
import { ReactChild, ReactElement, ReactFragment, ReactPortal, useEffect } from "react";
import { RUTA_LOGIN } from "../rutas/Rutas";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { IUsuarioSesion } from "../tipos/UsuariosType";
import { VAR_SESION_USUARIO } from "../tipos/CommonTypes";

/* DECLARACIONES */
type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;
type ProtectedRouteProps = {
    children: ReactNode
}

/* COMPONENTE */
export const ProtectedRoute = ({ children } : ProtectedRouteProps) => {
    /* definiciones */
    const { getItem } = useLocalStorage();
    const navigate = useNavigate();

    useEffect(() => {
        const jsonUser = getItem(VAR_SESION_USUARIO);
        let usuarioSesion : IUsuarioSesion | null = null;
        
        if (jsonUser) {
            usuarioSesion = JSON.parse(jsonUser);
            if (!(usuarioSesion?.logueado?? false)) {
                navigate(RUTA_LOGIN);
            }
        }
    }, [getItem, navigate]);
    

    /* renderizado */
    return children as ReactElement;
};