/* dependencias */
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import * as Rutas from '../../../rutas/Rutas';
import ColaImpresionQuery from './ColaImpresionQuery';
import { useCallback, useState } from 'react';
import ColaImpresionModal from './ColaImpresionModal';
import { IImpresion, IFiltrosImpresion } from '../../../tipos/ColaImpresionType';
import React from 'react';
import useResizeObserver from '../../../hooks/useResizeObserver';
import { IImpresoraInsertable } from '../../../tipos/ImpresorasType';
import * as APIImpresion from '../../../servicios/APIImpresiones'
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';


/* declaraciones */
export const impresionModelDefault : IImpresion = {
    id: 0,
    idImpresora: 0,
    nombreImpresora: "",
    nombrePlantilla: "",
    fecha: new Date(),
    textoImpresion: "",
    fechaImpresion: null,
}

const filtrosDefault : IFiltrosImpresion = {
    IDImpresora: undefined,
    FechaDesde: null,
    FechaHasta: null,
    FechaImpresionDesde: null,
    FechaImpresionHasta: null,
    Impreso: false
}

const opcionesImpresorasDefault: IImpresoraInsertable[] = [
    {
        id: -1,
        nombre: "--- todas las impresoras ---",
        descripcion: "",
        ubicacion: "",
    }
]

/* componente */
export default function ColaImpresion() { 
    /* definiciones */
    const [filtros, setFiltros] = useState<IFiltrosImpresion>(filtrosDefault);
    const [opcionesImpresoras, setOpcionesImpresoras] = useState<IImpresoraInsertable[]>(opcionesImpresorasDefault);
    const [datos, setDatos] = useState(impresionModelDefault);
    const [visibleModal, setVisibleModal] = useState(false);
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    const { register, reset, getValues, handleSubmit } = useForm<IFiltrosImpresion>({
        mode: "onTouched",
        defaultValues: filtrosDefault,
    });

    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
      
    const handlerBuscar = () => {
        let filtrosForm : IFiltrosImpresion = {
            IDImpresora: ((getValues().IDImpresora?? -1) > 0)? getValues().IDImpresora : undefined,
            FechaDesde: (getValues().FechaDesde !== null)? getValues().FechaDesde : undefined,
            FechaHasta: (getValues().FechaHasta !== null)? getValues().FechaHasta : undefined,
            FechaImpresionDesde: (getValues().FechaImpresionDesde !== null)? getValues().FechaImpresionDesde : undefined,
            FechaImpresionHasta: (getValues().FechaImpresionHasta !== null)? getValues().FechaImpresionHasta : undefined,
            Impreso: getValues().Impreso
        }
        setFiltros(filtrosForm);
    };

    const handlerResetFiltros = () => {
        reset(filtrosDefault);
        setFiltros(filtrosDefault);
    };

    const handlerVer = (datosModelo : IImpresion) => {
        setDatos(datosModelo);
        setVisibleModal(true);
    }
    
    async function ObtenerListadoImpresoras() {
        const listaImpresoras = await APIImpresion.ListarImpresoras(undefined);
        setOpcionesImpresoras(
            [
                ...opcionesImpresorasDefault,
                ...listaImpresoras
            ]
        );
    }

    /* efectos */
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        ObtenerListadoImpresoras();
    }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="accordion" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="accordion-item">
                    <Breadcrumb className="px-4 pt-3">
                        <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                        <Breadcrumb.Item>Impresiones</Breadcrumb.Item>
                        <Breadcrumb.Item active>Cola de impresión</Breadcrumb.Item>
                    </Breadcrumb>
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelCabecera"
                            aria-expanded="true" aria-controls="panelCabecera">
                            <div className="container-fluid px-0"><h2>Cola de impresión</h2></div>
                        </button>
                    </h2>
                    <div id="panelCabecera" className="accordion-collapse collapse show" data-bs-parent="#contenedorCabeceraListado">
                        <div className="accordion-body">
                            <div className="row g-sm-2">
                                <div className="col-lg-6 col-md-12">
                                    <div className="input-group">
                                        <span className="input-group-text">Fecha</span>
                                        <input type="date" aria-label="fecha desde" className="form-control" {...register("FechaDesde")} />
                                        <input type="date" aria-label="fecha hasta" className="form-control" {...register("FechaHasta")} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="input-group">
                                        <span className="input-group-text">Fecha impresión</span>
                                        <input type="date" aria-label="fecha desde" className="form-control" {...register("FechaImpresionDesde")} />
                                        <input type="date" aria-label="fecha hasta" className="form-control" {...register("FechaImpresionHasta")} />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <select className="form-select" aria-label="Impresoras" {...register("IDImpresora")}>
                                        {opcionesImpresoras.map((option) => {
                                        return (
                                            <option key={option.id} value={option.id}>{option.nombre}</option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <select className="form-select" aria-label="Impresos?" {...register("Impreso")}>
                                        <option value="false">pendientes</option>
                                        <option value="true">impresas</option>
                                        <option value="">pendientes/impresas</option>
                                    </select>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <div className="d-flex justify-content-end">
                                        <Button variant='link' className="nav-link me-3" onClick={handleSubmit(handlerResetFiltros)}>
                                        <div className='d-flex'>
                                                <span className="material-symbols-outlined">filter_alt_off</span>
                                                reiniciar filtros
                                        </div> 
                                        </Button>
                                        <Button variant='info' className='mx-1' onClick={handleSubmit(handlerBuscar)}>
                                        <div className='d-flex'>
                                                <span className="material-symbols-outlined">filter_alt</span>
                                                buscar
                                        </div> 
                                        </Button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}
                onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                <ColaImpresionQuery 
                    filtrosQuery={filtros} 
                    pintarMasFilas= {pintarMasFilas}
                    handlerVer={handlerVer}
                />
                <ColaImpresionModal 
                    datosIniciales={datos}
                    mostrarModal={visibleModal} 
                    setMostrarModal={setVisibleModal}  
                />
            </aside>
        </section>
    );
};