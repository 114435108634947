import { Component, Dispatch, SetStateAction, useState } from 'react';
import { Link } from "react-router-dom";
import MenuUsuario from './MenuUsuario';
import * as Rutas from '../../rutas/Rutas';
import {RxHamburgerMenu} from "react-icons/rx";
import RutaLogoFREMAP from '../../util/imagenes/fremap-trazabilidad_logo.png'
import { IFormCambiarClave } from '../../tipos/UsuariosType';

/* DECLARACIONES */
interface IHeaderProps {
    mostrarMenu: boolean,
    setMostrarMenu: Dispatch<SetStateAction<boolean>>,
    guardarDatosNuevaClave: (datosModelo: IFormCambiarClave) => void
}


/* COMPONENTE */
export default function Header({mostrarMenu, setMostrarMenu, guardarDatosNuevaClave} : IHeaderProps) {
    
    /* renderizado */ 
    return (
        <header className="navbar navbar-top fixed-top navbar-expand-md p-0">
            <div className="container-fluid px-3">
                <div className="navbar-header d-flex">
                    <button type="button" className={`navbar-toggler ${mostrarMenu? "" : "collapsed"}`}
                        data-bs-toggle="collapse" data-bs-target="#navbarMenuPrincipal"
                        aria-label="Menú" aria-controls="navbarMenuPrincipal" aria-expanded={mostrarMenu}
                        onClick={() => setMostrarMenu(!mostrarMenu)}>
                        <RxHamburgerMenu />
                    </button>
                    <Link to={Rutas.RUTA_RAIZ} className="navbar-brand">
                        <h1><img id="logoH1" src={RutaLogoFREMAP} alt="Logo FREMAP Trazabilidad" /></h1>
                    </Link>
                </div>
                <nav className="d-flex pe-3">
                    <MenuUsuario 
                        guardarDatosNuevaClave={guardarDatosNuevaClave} />
                </nav>
            </div>
        </header>
    );
}