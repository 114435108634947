/* DEPENDENCIAS */
import * as APILotes from '../../servicios/APILotes';
import { IFiltrosLotes, ILote } from '../../tipos/LotesType';


/* QUERYS KEYS */
const KEYPLotesLista = "listaLotesPaginacion";
const KEYPLotesObtener = "datosLote";
const KEYPLoteGeneralObtener = "datosLoteGeneral";
const KEYPObtenerCabeceraCaducidades = "datosCabeceraCaducidades";

export {
    KEYPLotesLista,KEYPLotesObtener,KEYPLoteGeneralObtener,
}

/* QUERYS */
const queryListarLotesPaginacion = (filtros: IFiltrosLotes) => ({
    queryKey: [KEYPLotesLista, filtros],
    queryFn: ({ pageParam = 0 }) => APILotes.ListarLotesPaginacion(pageParam, 20, filtros),
    getNextPageParam: (lastPage: ILote[], allPages: ILote[][]) => allPages.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

const queryObtenerLote = (id:number | null) => ({
    queryKey: [KEYPLotesObtener, id],
    queryFn: () => (id)? APILotes.ObtenerLote(id) : null,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!id,
});

const queryObtenerLoteGeneralProducto = (idProducto:number | null) => ({
    queryKey: [KEYPLoteGeneralObtener, idProducto],
    queryFn: () => (idProducto)? APILotes.ObtenerLoteGeneralProducto(idProducto) : null,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!idProducto,
});

const queryObtenerCabeceraCaducidades = () => ({
    queryKey: [KEYPObtenerCabeceraCaducidades],
    queryFn: () => APILotes.ObtenerCabeceraCaducidades(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});


export {
    queryListarLotesPaginacion, queryObtenerLote, queryObtenerLoteGeneralProducto,
    queryObtenerCabeceraCaducidades,
}