import { useRouteError } from "react-router-dom";

export default function Error() {
    const error = useRouteError();

    return (
        <div className="fondoError">
            <header className="top-header"></header>
            <div className="lamp__wrap">
                <div className="lamp">
                    <div className="cable"></div>
                    <div className="cover"></div>
                    <div className="in-cover">
                        <div className="bulb"></div>
                    </div>
                    <div className="light"></div>
                </div>
            </div>
            <section className="error">
                <div className="error__content">
                    <div className="error__message message">
                        <h1 className="message__title">Página no encontrada</h1>
                        <p className="message__text">
                            Lo sentimos, pero la página a la que quiere acceder no está disponible.
                            El enlace al que ha accedido no se encuentra o no existe.
                            Inténtelo de nuevo o vuelva a la página de inicio para acceder a otra página.
                        </p>
                    </div>
                    <div className="error__nav e-nav">
                        <a href="/" className="e-nav__link">&nbsp;</a>
                    </div>
                </div>
            </section>
        </div>
    );
}
