/* DEPENDENCIAS */
import { clienteAxios } from "./APIAxiosInstancia";
import { URL_API_TIPOSUNIDAD, URL_API_TIPOSUNIDAD_PAGINACION } from "./Endpoints";
import { ITipoUnidad } from "../tipos/TiposUnidadesType";

/* DECLARACIONES */

/* API */
/**
 * Función que obtiene el listado de tipos de unidades.
 */
export const ListarTiposUnidades = async (filtro: string | undefined): Promise<ITipoUnidad[]> => {
    try {     
        const respuesta = filtro? 
            await clienteAxios.get<ITipoUnidad[]>(
                URL_API_TIPOSUNIDAD,
                { params: {filtro: filtro}}
            ) :
            await clienteAxios.get<ITipoUnidad[]>(URL_API_TIPOSUNIDAD);
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene el listado de tipos de unidades con paginación.
 */
export const ListarTiposUnidadesPaginacion = async (pagina:number, filas:number, filtro: string | undefined): Promise<ITipoUnidad[]> => {
    try {     
        const respuesta = filtro? 
            await clienteAxios.get<ITipoUnidad[]>(
                URL_API_TIPOSUNIDAD_PAGINACION,
                { params: {
                    filtro: filtro, 
                    numeroPagina: pagina,
                    tamanhoPagina: filas,
                }}
            ) :
            await clienteAxios.get<ITipoUnidad[]>(URL_API_TIPOSUNIDAD_PAGINACION,
                { params: {
                    numeroPagina: pagina,
                    tamanhoPagina: filas,
                }});
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que guarda los datos de un nuevo tipo de unidad.
 */
export const AgregarTipoUnidad = async (datos : ITipoUnidad): Promise<ITipoUnidad> => {
    try {     
        const respuesta = await clienteAxios.post(
            URL_API_TIPOSUNIDAD, datos
        );
        return respuesta.data;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al agregar un tipo de unidad.");
    }
}

/**
 * Función que guarda los datos de edición de un tipo de unidad.
 */
export const EditarTipoUnidad = async (datos : ITipoUnidad): Promise<void> => {
    try {     
        await clienteAxios.put(URL_API_TIPOSUNIDAD + `/${datos.id}`, datos);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al editar un tipo de unidad.");
    }
}

/**
 * Función que elimina los datos de un tipo de unidad.
 */
export const EliminarTipoUnidad = async (id : number): Promise<void> => {
    try {     
        await clienteAxios.delete(URL_API_TIPOSUNIDAD + `/${id}`);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al eliminar un tipo de unidad.");
    }
}