/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction} from 'react';
import { Button, Row, Col, FloatingLabel, Modal, Form, Container } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useQuery } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { IEtiquetaLaboratorio, etiquetaLaboratorioModelDefault } from '../../../tipos/ColaImpresionType';
import { IProducto } from '../../../tipos/ProductosType';
import { queryListarImpresoras, queryObtenerPlantillaImpresion } from '../../../util/querys/ImpresionesQuerys';
import { PLANTILLA_LABORATORIO_ID } from '../../../util/tsx-ayuda/Constantes';
import { format } from 'date-fns';

/* DECLARACIONES */
const schemaValidacion = yup.object().shape({
    idImpresora: yup.number()
        .min(1, "Seleccione una impresora")
        .required("El campo es obligatorio"),
    copias: yup.number()
        .min(1, "Debe solicitar al menos 1 copia")
        .required("El campo es obligatorio"),
    textoFila1: yup.string()
        .required("El campo es obligatorio"),
});

type EtiquetaLaboratorioModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosProducto: IProducto | undefined,
    guardarDatos: (datosModelo: IEtiquetaLaboratorio) => void
}
  
/* COMPONENTE */
export default function EtiquetaLaboratorioModal({datosProducto, mostrarModal, setMostrarModal, guardarDatos} : EtiquetaLaboratorioModalProps) {
    /* definiciones */
    const { data: opcionesImpresoras, isLoading: isLoadingImpresoras } = useQuery(queryListarImpresoras());
    const { data: datosPlantilla, isLoading: isLoadingPlantilla } = useQuery(queryObtenerPlantillaImpresion(PLANTILLA_LABORATORIO_ID));
    const { register, handleSubmit, formState: {errors}, getValues, setValue, reset, watch } = useForm<IEtiquetaLaboratorio>({
        mode: "onSubmit", reValidateMode: "onChange",
        defaultValues: etiquetaLaboratorioModelDefault,
        values: etiquetaLaboratorioModelDefault,
        resolver: yupResolver(schemaValidacion),
    });

    /* funciones */
    
    
    /* efectos */ 
    React.useEffect(() => {
        setValue("producto", datosProducto?? null);
    }, [datosProducto, setValue]); 

    React.useEffect(() => {
        if (!isLoadingPlantilla) {            
            setValue("plantilla", datosPlantilla?? null); 
        }
    }, [datosPlantilla, isLoadingPlantilla, setValue]);  
    
    React.useEffect(() => {
        if (mostrarModal) {            
            setValue("fecha", new Date()); 
        }
    }, [mostrarModal, setValue]);  

    /* renderizado */
    return (
        <Modal
            show={mostrarModal} size="lg"
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Impresión de etiqueta de laboratorio
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group as={Row} className="mb-3" controlId="txtPlantilla">
                                    <Col sm="2">
                                        <Form.Label column sm="2">Producto:</Form.Label>
                                    </Col>
                                    <Col sm="10">
                                        <Form.Control plaintext readOnly className="text-info"
                                            defaultValue={getValues().producto?.nombre?? "-"} />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group className="mb-3" controlId="txtFecha">
                                    <Form.Label column sm="2">Fecha:</Form.Label>
                                        <Form.Control plaintext readOnly className="text-info"
                                            defaultValue={format(getValues().fecha, "dd/MM/yyyy HH:mm")} />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group className="mb-3" controlId="txtCopias">
                                    <Form.Label>Nº de copias</Form.Label>
                                    <Form.Control type="number" style={{width:"100px"}} maxLength={3} {...register("copias")}
                                        required={true} isInvalid={!!errors.copias}/>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.copias?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="selImpresora">
                                    <Form.Label>Impresora</Form.Label>
                                    <Form.Select {...register("idImpresora")} required={true} isInvalid={!!errors.idImpresora}>
                                        {isLoadingImpresoras && (<option value="">cargando...</option>)}
                                        {opcionesImpresoras && 
                                            [
                                                ...[{id:0, nombre: "seleccione una impresora..."}],
                                                ...opcionesImpresoras
                                            ].map((option) => {
                                            return (
                                                <option key={option.id?? 0} value={option.id?? 0}>{option.nombre}</option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.idImpresora?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="txtTexto" label="texto de la primera fila">
                                    <Form.Control 
                                        {...register("textoFila1")}
                                        required={true} maxLength={30}  isInvalid={!!errors.textoFila1}
                                        placeholder="escribe texto para imprimir en la etiqueta..."/>
                                    <Form.Text className="text-muted" hidden={watch('textoFila1').length === 0}>
                                        {`${watch('textoFila1')?.length?? 0}/30`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.textoFila1?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="txtTexto" label="texto de la segunda fila">
                                    <Form.Control 
                                        {...register("textoFila2")} maxLength={30}  isInvalid={!!errors.textoFila2}
                                        placeholder="escribe texto para imprimir en la etiqueta..."/>
                                    <Form.Text className="text-muted" hidden={watch('textoFila2').length === 0}>
                                        {`${watch('textoFila2')?.length?? 0}/30`}
                                    </Form.Text>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() => { reset(etiquetaLaboratorioModelDefault); setMostrarModal(!mostrarModal)}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}