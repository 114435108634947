/* DEPENDENCIAS */
import React, { useCallback, useState} from 'react';
import {Breadcrumb, FloatingLabel} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import * as Rutas from '../../../rutas/Rutas';
import { habitacionModelDefault } from './Habitaciones';
import { ICama, IFiltrosCamas, IHabitacion } from "../../../tipos/HabitacionesType";
import * as APIHabitaciones from '../../../servicios/APIHabitaciones';
import * as APIImpresiones from '../../../servicios/APIImpresiones';
import useResizeObserver from '../../../hooks/useResizeObserver';
import Notificacion, { ETipoNotificacion, InfoNotificacion } from '../../../componentes-com/Notificacion';
import { format } from 'date-fns';
import ConfirmacionModal from '../../../componentes-com/ConfirmacionModal';
import { IEtiquetaHabitacion, IImpresionInsertable } from '../../../tipos/ColaImpresionType';
import { KEYPColaImpresionListaPaginada } from '../../../util/querys/ImpresionesQuerys';
import { PLANTILLA_LABORATORIO_ID } from '../../../util/tsx-ayuda/Constantes';
import { TienePermiso, TransformarPlantillaHabitacion } from '../../../util/tsx-ayuda/Funciones';
import { KEYPCamasLista, KEYPHabitacionesListaPaginada } from '../../../util/querys/HabitacionesQuerys';
import CamasQuery from './CamasQuery';
import CamasModal, { camaModelDefault } from './CamasModal';
import EtiquetaHabitacionModal from '../colaimpresion/EtiquetaHabitacionModal';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { IUsuarioSesion } from '../../../tipos/UsuariosType';
import { EFuncionalidad, VAR_SESION_USUARIO } from '../../../tipos/CommonTypes';

/* DECLARACIONES */  
const schemaValidacion = yup.object().shape({
    nombre: yup.string()
        .required("El campo es obligatorio"),
});


type HabitacionEdicionProps = {
    datos: IHabitacion | undefined,
    esEdicion: boolean
}

/* COMPONENTE */
export default function HabitacionEdicion({datos, esEdicion} : HabitacionEdicionProps) {
    /* definiciones */
    const [estadoForm, setEstadoForm] = useState<HabitacionEdicionProps>({datos, esEdicion});
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: {errors}, getValues, reset, watch } = useForm<IHabitacion>({
        mode: "onSubmit",
        defaultValues: datos?? habitacionModelDefault,
        values: datos,
        resolver: yupResolver(schemaValidacion),
        reValidateMode: "onChange",
    });
    const agregarHabitacion = useAgregarHabitacion();
    const editarHabitacion = useEditarHabitacion();
    const eliminarHabitacion = useEliminarHabitacion();
    const agregarImpresionEtiquetaHabitacion = useAgregarImpresionEtiquetaHabitacion();
    const [visibleModalConfirmacion, setVisibleModalConfirmacion] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState("");
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const [heightListado, setHeightListado] = useState<number>(0);
    const formCabeceraRef = React.useRef<HTMLFormElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);

    
    const [filtros, setFiltros] = useState<IFiltrosCamas>({idHabitacion: estadoForm.datos?.id});
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    const [visibleModalCama, setVisibleModalCama] = useState(false);
    const camaModelDefaultInit : ICama = {
        ...camaModelDefault,
        idHabitacion: datos?.id?? 0,
    }
    const [datosCama, setDatosCama] = useState(camaModelDefaultInit);
    const agregarCama = useAgregarCama();
    const editarCama = useEditarCama();
    const eliminarCama = useEliminarCama();
    const [visibleModalConfirmacionCama, setVisibleModalConfirmacionCama] = useState(false);

    
    const [visibleModalEtiquetaHabitacion, setVisibleModalEtiquetaHabitacion] = useState(false);
    
    const infoNotificacionDefault : InfoNotificacion = {
        tipo: ETipoNotificacion.INFO,
        titulo: "",
        fecha: "",
        mensaje: ""
    }
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    const { getItem } = useLocalStorage();
    const [accesoEditar, setAccesoEditar] = useState<boolean>(false);


    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
       
    function useAgregarHabitacion() {
        return useMutation({
          mutationFn: (datosModelo : IHabitacion) => {
            return APIHabitaciones.AgregarHabitacion(datosModelo);
          },
          onSuccess: (datosRespuesta: IHabitacion) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPHabitacionesListaPaginada]});
            setEstadoForm({
                ...estadoForm,
                datos: datosRespuesta,
                esEdicion: true
            });
            setFiltros({
                ...filtros,
                idHabitacion: datosRespuesta.id
            });
            reset(datosRespuesta);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>habitación</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>habitación</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    function useEditarHabitacion() {
        return useMutation({
          mutationFn: (datosModelo : IHabitacion) => {
            return APIHabitaciones.EditarHabitacion(datosModelo);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPHabitacionesListaPaginada]});
            setEstadoForm({
                ...estadoForm,
                datos: getValues(),
            });
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Editar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>habitación</strong> se ha editado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error editando el <strong>habitación</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    function useEliminarHabitacion() {
        return useMutation({
          mutationFn: (datosModelo : IHabitacion) => {
            return APIHabitaciones.EliminarHabitacion(datosModelo.id);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPHabitacionesListaPaginada]});
            reset(habitacionModelDefault);
            setEstadoForm({
                datos: habitacionModelDefault,
                esEdicion: false
            });
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Eliminar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>El <strong>habitación</strong> se ha eliminado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Eliminar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error eliminando el <strong>habitación</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
      
    function useAgregarImpresionEtiquetaHabitacion() {
        return useMutation({
          mutationFn: (datosModelo : IImpresionInsertable) => {
            return APIImpresiones.AgregarImpresion(datosModelo);
          },
          onSuccess: (datosRespuesta: IImpresionInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPColaImpresionListaPaginada]});
            setVisibleModalEtiquetaHabitacion(false);

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>impresión de etiqueta de laboratorio</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>impresión de etiqueta de laboratorio</strong>:${error.message}</span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
      
    const guardarDatos = (datosModelo : IHabitacion) => {
        if (!esEdicion) {
            agregarHabitacion.mutate(datosModelo);
        } else {
            editarHabitacion.mutate(datosModelo);
        }
    }

    const handlerEliminar = () => {
        setMensajeConfirmacion(`Se va a eliminar la habitación: <strong>${getValues().nombre}</strong>`);
        setVisibleModalConfirmacion(true);
    }
    
    const handlerConfirmarEliminar = () => {
        eliminarHabitacion.mutate(getValues());
    }
           
    const handlerNuevaCama = () => {
        let datos = camaModelDefault;
        datos.idHabitacion = estadoForm?.datos?.id?? 0;
        setDatosCama(datos);
        setVisibleModalCama(true);
    }

    function useAgregarCama() {
        return useMutation({
          mutationFn: (datosModelo : ICama) => {
            return APIHabitaciones.AgregarCama(datosModelo);
          },
          onSuccess: (datosRespuesta: ICama) => {
            //actualizamos la lista...
            setDatosCama(datosRespuesta);
            queryClient.invalidateQueries({ queryKey: [KEYPCamasLista, datosRespuesta.idHabitacion]});

            //actualizamos el form...
            let datos = estadoForm.datos;
            if (datos) datos.numeroCamas += 1;
            setEstadoForm({
                ...estadoForm,
                datos
            });

            //cerramos el modal...
            setVisibleModalCama(false);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar cama",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>cama</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar cama",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>cama</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    const handlerEditarCama = (datosModelo : ICama) => {
        setDatosCama(datosModelo);
        setVisibleModalCama(true);
    }

    function useEditarCama() {
        return useMutation({
          mutationFn: (datosModelo : ICama) => {
            return APIHabitaciones.EditarCama(datosModelo);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPCamasLista, estadoForm.datos?.id]});
            setDatosCama(camaModelDefault);
            
            //cerramos el modal...
            setVisibleModalCama(false);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Editar cama",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>cama</strong> se ha editado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error editando la <strong>cama</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
  
    const guardarDatosCama = (datosModelo : ICama) => {
        if (datosModelo.id === 0) {
            agregarCama.mutate(datosModelo);
        } else {
            editarCama.mutate(datosModelo);
        }
    }

    function useEliminarCama() {
        return useMutation({
          mutationFn: (datosModelo : ICama) => {
            return APIHabitaciones.EliminarCama(datosModelo.id);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPCamasLista]});
            setDatosCama(camaModelDefault);
            
            //actualizamos el form...
            let datos = estadoForm.datos;
            if (datos) datos.numeroCamas -= 1;
            setEstadoForm({
                ...estadoForm,
                datos
            });

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Eliminar cama",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>cama</strong> se ha eliminado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Eliminar cama",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error eliminando la <strong>cama</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
     
    const handlerEliminarCama = (datosModelo : ICama) => {
        setDatosCama(datosModelo);
        setMensajeConfirmacion(`Se va a eliminar la cama: <strong>${datosModelo.nombre}</strong>`);
        setVisibleModalConfirmacionCama(true);
    }
    
    const handlerConfirmarEliminarCama = () => {
        eliminarCama.mutate(datosCama);
    }
    
    const onClickCancelarImprimirEtiqueta = () => {
        setDatosCama(camaModelDefault);
        setVisibleModalEtiquetaHabitacion(false);
    }

    const onClickImprimirEtiqueta = (datosModelo : ICama) => {
        setDatosCama(datosModelo);
        setVisibleModalEtiquetaHabitacion(true);
    }

    const guardarEtiquetaHabitacion = (datosModelo : IEtiquetaHabitacion) => {
        let nuevaImpresion : IImpresionInsertable = {
            id: 0,
            idImpresora: datosModelo.idImpresora,
            idPlantilla: PLANTILLA_LABORATORIO_ID, //plantilla - laboratorio.
            fecha:  new Date(),
            textoImpresion: TransformarPlantillaHabitacion(datosModelo),
            fechaImpresion: null
        };
        agregarImpresionEtiquetaHabitacion.mutate(nuevaImpresion);
    }


    /* efectos */
    React.useEffect(() => {
        reset(datos);
    }, [datos, reset]);
    
    React.useEffect(() => {
        if (agregarHabitacion.isSuccess) {
            
        }
    }, [agregarHabitacion]);
    
    React.useEffect(() => {
        if (!visibleNotificacion && eliminarHabitacion.isSuccess) {
            //navegamos al listado...
            navigate(Rutas.RUTA_HABITACIONES);
        }
    }, [visibleNotificacion, eliminarHabitacion, navigate]);
        
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    React.useEffect(() => {
        let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
        if (usuarioSesion) {
            setAccesoEditar(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.HOSPITAL_HABITACIONES_EDICION));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    /* renderizado */
    return (
        <Form noValidate id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="container-fluid py-2" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="row">
                    <div className="col col-sm-12 col-md-8">
                        <Breadcrumb>
                            <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">Hospital</Breadcrumb.Item>
                            <Breadcrumb.Item href={Rutas.RUTA_HABITACIONES}>Habitaciones</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edición</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="col col-sm-2 col-md-4 d-flex  justify-content-end">
                        <Link to={Rutas.RUTA_HABITACIONES} className="nav-link link-dark cursor-pointer d-flex align-center text-right">
                            <span className="material-symbols-outlined">west</span>&nbsp;
                            volver al listado
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-sm-12 col-md-7">
                        <h3>Detalles de la habitación</h3>
                        {!estadoForm.esEdicion && <h5 className="text-info">HABITACIÓN NUEVA</h5>}
                        {estadoForm.esEdicion && 
                            <>
                                <h5><span className="text-info">{estadoForm.datos?.codigo?? "SIN CÓDIGO"}</span>&nbsp;-&nbsp;{estadoForm.datos?.nombre}</h5>
                                <h6><span className="text-secondary">Camas</span>({estadoForm.datos?.numeroCamas})</h6>
                            </>
                        }
                    </div>
                    <div className="col col-sm-12 col-md-5 d-flex justify-content-end align-items-end">
                        { accesoEditar &&
                        (<>
                        <button className="btn btn-primary" onClick={handleSubmit(guardarDatos)}>
                            <div className=" d-flex align-center">
                                <span className="material-symbols-outlined">edit_square</span>&nbsp;
                                guardar
                            </div>
                        </button>
                        {estadoForm.esEdicion && (<>
                        <button type="button" className="btn btn-light ms-2" onClick={ () => { handlerEliminar(); } }>
                            <div className="d-flex align-center text-danger">
                                <span className="material-symbols-outlined">delete</span>&nbsp;
                                eliminar
                            </div>
                        </button>                            
                        <button type="button" className="btn btn-light ms-2 dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="d-flex align-center">
                                <span className="material-symbols-outlined">more_horiz</span>
                            </div>
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                <button type="button" className="dropdown-item d-flex align-items-center"
                                     onClick={handlerNuevaCama}>
                                    <span className="material-symbols-outlined">single_bed</span>&nbsp;
                                    añadir nueva cama
                                </button>
                            </li>
                        </ul>
                        </>)}
                        </>)}
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid pt-3 scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button type="button" id="pills-datos-tab" className="nav-link active" 
                            data-bs-toggle="pill" data-bs-target="#pills-datos" role="tab" 
                            aria-controls="pills-datos" aria-selected="true">Datos</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button type="button" id="pills-camas-tab" className="nav-link"  
                            data-bs-toggle="pill" data-bs-target="#pills-camas" role="tab" 
                            aria-controls="pills-camas" aria-selected="false">Camas ({estadoForm.datos?.numeroCamas?? 0})</button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContenido">
                    <div className="tab-pane fade show active" id="pills-datos" role="tabpanel" aria-labelledby="pills-datos-tab">
                        <div className="row">
                            <div className="col col-sm-12 col-md-8">
                                <Form.Group className="mb-3" controlId="txtNombre">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" maxLength={300} {...register("nombre")}
                                        required={true} isInvalid={!!errors.nombre}
                                        placeholder="escribe el nombre de la habitación..." />
                                    <Form.Text className="text-muted" hidden={watch('nombre')?.length === 0}>
                                        {`${watch('nombre')?.length?? 0}/300`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.nombre?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col col-sm-12 col-md-4">
                                <Form.Group className="mb-3" controlId="txtCodigo">
                                    <Form.Label>Código</Form.Label>
                                    <Form.Control type="text" maxLength={20} {...register("codigo")}
                                        required={false}  isInvalid={!!errors.codigo}
                                        placeholder="escribe el código dla habitación..." />
                                    <Form.Text className="text-muted" hidden={(watch('codigo')?.length??0) === 0}>
                                        {`${watch('codigo')?.length?? 0}/20`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.codigo?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-sm-12">
                                <FloatingLabel className="mb-3" controlId="txtUbicacion" label="ubicación de la habitación">
                                    <Form.Control as="textarea" rows={50} {...register("ubicacion")}
                                        required={false} maxLength={2000}  isInvalid={!!errors.ubicacion}
                                        style={{ height: '100px' }} />
                                    <Form.Text className="text-muted" hidden={watch('ubicacion')?.length === 0}>
                                        {`${watch('ubicacion')?.length?? 0}/2000`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.ubicacion?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </div>
                        </div> 
                    </div>
                    <div className="tab-pane fade" id="pills-camas" role="tabpanel" aria-labelledby="pills-camas-tab">
                    <CamasQuery 
                        filtrosQuery={filtros} 
                        handlerImprimirEtiqueta={onClickImprimirEtiqueta}
                        handlerEditar= {handlerEditarCama}
                        handlerEliminar= {handlerEliminarCama}
                        pintarMasFilas= {pintarMasFilas}
                    />
                    <CamasModal 
                        datosIniciales={datosCama}
                        mostrarModal={visibleModalCama} 
                        setMostrarModal={setVisibleModalCama}  
                        guardarDatos={guardarDatosCama} 
                        />
                    <EtiquetaHabitacionModal
                            datosCama={datosCama}
                            mostrarModal={visibleModalEtiquetaHabitacion}
                            handlerCancelar={onClickCancelarImprimirEtiqueta}
                            guardarDatos={guardarEtiquetaHabitacion}
                        />
                    </div>
                </div>              
            </aside>
            <Notificacion
                tipo={infoNotificacion.tipo}
                titulo={infoNotificacion.titulo}
                fecha={infoNotificacion.fecha}
                mensaje={infoNotificacion.mensaje}
                mostrar={visibleNotificacion}
                setMostrar={setVisibleNotificacion} />
            <ConfirmacionModal 
                mostrarModal = {visibleModalConfirmacion}
                setMostrarModal = {setVisibleModalConfirmacion}
                mensajeConfirmacion = {mensajeConfirmacion}
                handlerSI = {handlerConfirmarEliminar}/>
            <ConfirmacionModal 
                mostrarModal = {visibleModalConfirmacionCama}
                setMostrarModal = {setVisibleModalConfirmacionCama}
                mensajeConfirmacion = {mensajeConfirmacion}
                handlerSI = {handlerConfirmarEliminarCama}/>
        </Form>
    );
}