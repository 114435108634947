/* DEPENDENCIAS */
import { InfiniteData, QueryClient } from '@tanstack/react-query';
import * as APIHabitaciones from '../../servicios/APIHabitaciones'
import { 
    IHabitacion, IFiltrosHabitaciones
} from '../../tipos/HabitacionesType';


/* QUERYS KEYS */
const KEYPHabitacionesListaPaginada = "listaHabitacionesPaginacion";
const KEYPHabitacionDatos = "datosHabitacion";
const KEYPCamasLista = "listaCamas";

export {
    KEYPHabitacionesListaPaginada,
    KEYPHabitacionDatos,
    KEYPCamasLista
}

/* QUERYS */
/* habitaciones */
const queryListarHabitacionesPaginacion = (filtros: IFiltrosHabitaciones) => ({
    queryKey: [KEYPHabitacionesListaPaginada],
    queryFn: ({ pageParam = 0 }) => APIHabitaciones.ListarHabitacionesPaginacion(pageParam, 20, filtros),
    getNextPageParam: (lastPage: IHabitacion[], allPages: IHabitacion[][]) => allPages.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

const queryObtenerHabitacion = (id : number, queryClient: QueryClient) => ({
    queryKey: [KEYPHabitacionDatos, id],
    queryFn: () => APIHabitaciones.ObtenerHabitacion(id),
    initialData: () => {
        let datosIniciales : IHabitacion | undefined = undefined;
        let cacheHabitacionesData = queryClient.getQueryData<InfiniteData<IHabitacion>>([KEYPHabitacionesListaPaginada]);
        if (cacheHabitacionesData) {
            datosIniciales = cacheHabitacionesData?.pages.flatMap(page => page)?.find((d:IHabitacion) => d.id === id);            
        } 
        return datosIniciales;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!id
});
 
/* camas */
const queryListarCamasHospital = () => ({
    queryKey: [KEYPCamasLista],
    queryFn: () => APIHabitaciones.ListarCamas({ }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

const queryListarCamas = (idHabitacion: number) => ({
    queryKey: [KEYPCamasLista, idHabitacion],
    queryFn: () => APIHabitaciones.ListarCamas({
        idHabitacion: idHabitacion
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});


/* EXPORTACIONES */
export {
    queryListarHabitacionesPaginacion,
    queryObtenerHabitacion,
    queryListarCamas,
    queryListarCamasHospital,
}