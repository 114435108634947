/* DEPENDENCIAS */
import { clienteAxios } from "./APIAxiosInstancia";
import { URL_API_LOTES, URL_API_LOTES_PAGINACION } from "./Endpoints";
import { ILote, IFiltrosLotes, ILoteInsertable, ICabeceraCaducidades } from "../tipos/LotesType";

/* DECLARACIONES */

/* API */
/**
 * Función que obtiene el listado de los lotes.
 */
export const ListarLotes = async (filtros: IFiltrosLotes): Promise<ILote[]> => {
    try {     
        const respuesta = await clienteAxios.get<ILote[]>(
            URL_API_LOTES,
            { params: filtros}
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene el listado de los lotes.
 */
export const ListarLotesPaginacion = async (pagina:number, filas:number, filtros: IFiltrosLotes): Promise<ILote[]> => {
    try {     
        const respuesta = await clienteAxios.get<ILote[]>(
            URL_API_LOTES_PAGINACION,
            { params: {
                ...filtros,
                numeroPagina: pagina,
                tamanhoPagina: filas,
            }}
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene los datos del lote por su ID.
 */
export const ObtenerCabeceraCaducidades = async (): Promise<ICabeceraCaducidades> => {
    try {  
        const respuesta = await clienteAxios.get<ICabeceraCaducidades>(
            `${URL_API_LOTES}/informacionCaducidades`
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene los datos del lote por su ID.
 */
export const ObtenerLote = async (id: number): Promise<ILoteInsertable> => {
    try {  
        const respuesta = await clienteAxios.get<ILoteInsertable>(
            `${URL_API_LOTES}/${id}`
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene los datos del lote general de un producto que no está gestionado por lotes por el ID del producto.
 */
export const ObtenerLoteGeneralProducto = async (idProducto: number): Promise<ILote> => {
    try {  
        const respuesta = await clienteAxios.get<ILote>(
            `${URL_API_LOTES}/generalProducto/${idProducto}`
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que guarda los datos de un nuevo lote.
 */
export const AgregarLote = async (datos : ILote): Promise<ILoteInsertable> => {
    try {     
        const respuesta = await clienteAxios.post(
            URL_API_LOTES, datos
        );
        return respuesta.data;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al agregar un lote.");
    }
}

/**
 * Función que guarda los datos de edición de un lote.
 */
export const EditarLote = async (datos : ILoteInsertable): Promise<void> => {
    try {     
        await clienteAxios.put(URL_API_LOTES + `/${datos.id}`, datos);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al editar un lote.");
    }
}

/**
 * Función que elimina los datos de un lote.
 */
export const EliminarLote = async (id : number): Promise<void> => {
    try {     
        await clienteAxios.delete(URL_API_LOTES + `/${id}`);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al eliminar un lote.");
    }
}