/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ICama } from "../../../tipos/HabitacionesType";
import { FloatingLabel } from 'react-bootstrap';

/* DECLARACIONES */
type CamasModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: ICama | null,
    guardarDatos: (datosModelo: ICama) => void
}
export const camaModelDefault : ICama = {
    id: 0,
    idHabitacion: 0,
    nombreHabitacion: "",
    nombre: "",
    codigo: null,
    ubicacion: null
}
  
const mensajesError = {
    requerido: "Este campo es obligatorio",
};
  
/* COMPONENTE */
export default function CamasModal({datosIniciales, mostrarModal, setMostrarModal, guardarDatos} : CamasModalProps) {
    /* definiciones */
    const { register, handleSubmit, formState: {errors}, getValues, reset, watch } = useForm<ICama>({
        mode: "onTouched",
        defaultValues: camaModelDefault,
        values: datosIniciales?? camaModelDefault
    });

    /* funciones */
    useEffect(() => {
        reset(datosIniciales?? camaModelDefault);
    }, [reset, datosIniciales]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { (getValues().id === 0)  && "Añadir nueva cama"}
                        { (getValues().id > 0)  && "Editar cama"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="txtNombre">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" {...register("nombre", {
                                required: mensajesError.requerido
                            })}
                            required={true}  isInvalid={!!errors.nombre}
                            placeholder="escribe el nombre de la cama..." />
                        <Form.Control.Feedback type='invalid'>
                            {errors.nombre?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="txtCodigo">
                        <Form.Label>Código</Form.Label>
                        <Form.Control type="text" {...register("codigo")} maxLength={20}
                            placeholder="escribe el código de la cama..." />
                        <Form.Text className="text-muted" hidden={watch('codigo')?.length === 0}>
                            {`${watch('codigo')?.length?? 0}/20`}
                        </Form.Text>
                    </Form.Group>
                    <FloatingLabel className="mb-3" controlId="txtDescripción" label="ubicación">
                        <Form.Control as="textarea" rows={20} {...register("ubicacion")}
                            required={false} maxLength={2000}
                            style={{ height: '300px' }} />
                        <Form.Text className="text-muted" hidden={watch('ubicacion')?.length === 0}>
                            {`${watch('ubicacion')?.length?? 0}/2000`}
                        </Form.Text>
                        <Form.Control.Feedback type='invalid'>
                            {errors.ubicacion?.message}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() => { reset(camaModelDefault); setMostrarModal(!mostrarModal)}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}