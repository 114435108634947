/* DEPENDENCIAS */
import React, { useState } from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { ColumnDef, SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Button } from "react-bootstrap";
import {TiArrowUnsorted} from "react-icons/ti";
import { format } from "date-fns";

import { IMovimientoStock } from "../../../tipos/MovimientosStockType";
import MovimientosStockTable from "./MovimientosStockTable";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { IUsuarioSesion } from "../../../tipos/UsuariosType";
import { EFuncionalidad, VAR_SESION_USUARIO } from "../../../tipos/CommonTypes";
import { TienePermiso } from "../../../util/tsx-ayuda/Funciones";

/* DEFINICIONES */
type MovimientosStockInstanceProps = {
  tableData: IMovimientoStock[],
  isError: boolean,
  error: Error | null,
  isLoading: boolean,
  isFetching: boolean,
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<IMovimientoStock[], Error>>,
  pintarMasFilas : boolean,
  handlerImprimir : (datosModelo : IMovimientoStock) => void,
  handlerEliminar : (datosModelo : IMovimientoStock) => void,
}

/* COMPONENTE */
const MovimientosStockInstance = ({ 
    tableData, 
    isError, error, isLoading, 
    isFetching, fetchNextPage,
    pintarMasFilas,
    handlerImprimir,
    handlerEliminar,
  }: MovimientosStockInstanceProps) => {
    /* definiciones */
    const [sorting, setSorting] = React.useState<SortingState>([{
      id: "nombreProducto",
      desc: true
    }]); 
    const { getItem } = useLocalStorage();
    const [accesoEditar, setAccesoEditar] = useState<boolean>(false);
    const [accesoImprimirProducto, setAccesoImprimirProducto] = useState<boolean>(false);

    const columnsDef: ColumnDef<IMovimientoStock>[] = [
      {
        id: "nombreProducto",
        accessorKey: "producto.nombre",
        header: () => {
          return(
            <div className="d-flex cursor-pointer">
              <span>Nombre&nbsp;</span>
              <TiArrowUnsorted style={{ marginTop:2 }} />
            </div>
          );
        },
        cell: info => info.getValue(), 
        meta: {
          className: "align-middle",
        }      
      },
      {
        accessorKey: "cantidad",
        header: "Cantidad",
        size: 100,
        cell: info => `${info.getValue()} (${info.row.original.producto.abreviaturaTipoUnidad})`, 
        meta: {
          className: "align-middle",
        }      
      },
      {
        accessorKey: "precio",
        header: 'Precio',
        size: 100,
        cell: info => `${info.getValue()} (€)`,
        meta: {
          headerClassName: "text-end",
          className: "align-middle text-end"
        }
      },
      {
        accessorKey: "codigoLote",
        header: "Lote",
        cell: info => (info.row.original.producto.usaControlLotes)? info.getValue() : "", 
        size: 155, 
        meta: {
          className: "align-middle",
        }      
      },
      {
        accessorKey: "caducidadLote",
        header: 'Caducidad',
        size: 110,
        cell: info => (info.row.original.caducidadLote)? format(new Date(info.row.original.caducidadLote), "dd/MM/yyyy") : "-",
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center"
        }
      },
      {
        id: 'opciones',
        header: '',
        size: 50,
        cell: (info) => {
          const datos = info.row.original;
          return (
          <div className="d-flex justify-content-end">
            <span className={`${!accesoImprimirProducto? 'disabled-button-wrapper' : ''}`}>
              <Button type="button" variant="outline-info" size="sm" className="me-1" disabled={!accesoImprimirProducto}
                title="imprimir etiqueta"
                onClick={ () => { handlerImprimir(datos); } }>
                  <span className="material-symbols-outlined">print</span>
              </Button>
            </span>
            <span className={`${!accesoEditar? 'disabled-button-wrapper' : ''}`}>
              <Button type="button" variant="outline-warning" size="sm" title="eliminar proveedor" disabled={!accesoEditar}
                onClick={ () => { handlerEliminar(datos); } }>
                  <span className="material-symbols-outlined">delete</span>
              </Button>
            </span>
          </div>);
        }, 
        meta: {
          className: "align-middle",
        }      
      },
    ];

    const tableInstance = useReactTable({ 
      data: tableData, 
      columns: columnsDef,
      state: {
        sorting,
      },
      enableSortingRemoval: false,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });
  
    /* efectos */    
    React.useEffect(() => {
      let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
      if (usuarioSesion) {
        setAccesoEditar(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_ENTRADAMERCANCIA_EDICION));
        setAccesoImprimirProducto(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_PRODUCTOS_IMPRIMIR_ETIQUETA));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    /* renderizado */
    return (
      <MovimientosStockTable instanciaTabla={tableInstance}
        isError={isError} error={error} isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        pintarMasFilas={pintarMasFilas} />
    );
  }
  export default MovimientosStockInstance;