/* DEPENDENCIAS */
import { useInfiniteQuery } from "@tanstack/react-query";
import ProductosInstance from "./ProductosInstance";
import { IProducto, IFiltrosProductos } from "../../../tipos/ProductosType";
import * as APIProductos from '../../../servicios/APIProductos';
import React from "react";
import { queryListarProductosPaginacion } from "../../../util/querys/ProductosQuerys";

/* DEFINICIONES */
type IProductosQueryProps = {
  filtrosQuery : IFiltrosProductos,
  pintarMasFilas : boolean,
  handlerEditar : (datosModelo: IProducto) => void,
  handlerEliminar : (datosModelo : IProducto) => void,
}

/* COMPONENTE */
const ProductosQuery = ({filtrosQuery, pintarMasFilas, handlerEditar, handlerEliminar} : IProductosQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useInfiniteQuery<IProducto[], Error>(queryListarProductosPaginacion(filtrosQuery));
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
    
    /* funciones */
          
    
    /* renderizado */    
    return (
      <ProductosInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerEditar={handlerEditar}
        handlerEliminar={handlerEliminar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default ProductosQuery;