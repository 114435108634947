/* DEPENDENCIAS */
import React, { ChangeEvent, useCallback, useState} from 'react';
import {Breadcrumb, Button, Col, Container, InputGroup, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import * as Rutas from '../../../rutas/Rutas';
import { IProducto, productoLoteDefault, productoModelDefault } from "../../../tipos/ProductosType";
import * as APIProductos from '../../../servicios/APIProductos';
import * as APIImpresiones from '../../../servicios/APIImpresiones';
import * as APIFamilias from '../../../servicios/APIFamiliasProductos';
import * as APISecciones from '../../../servicios/APISeccionesAlmacen';
import * as APITiposUnidades from '../../../servicios/APITiposUnidades';
import * as APIMovimientosStock from '../../../servicios/APIMovimientosStock'

import useResizeObserver from '../../../hooks/useResizeObserver';
import Notificacion, { ETipoNotificacion, InfoNotificacion } from '../../../componentes-com/Notificacion';
import { format } from 'date-fns';
import ConfirmacionModal from '../../../componentes-com/ConfirmacionModal';
import EtiquetaLaboratorioModal from '../colaimpresion/EtiquetaLaboratorioModal';
import { IEtiquetaLaboratorio, IEtiquetaProducto, IImpresionInsertable } from '../../../tipos/ColaImpresionType';
import { KEYPColaImpresionListaPaginada } from '../../../util/querys/ImpresionesQuerys';
import { PLANTILLA_LABORATORIO_ID, PLANTILLA_PRODUCTO_ID } from '../../../util/tsx-ayuda/Constantes';
import { ETipoIVAToString, TienePermiso, TransformarPlantillaLaboratorio, TransformarPlantillaProducto } from '../../../util/tsx-ayuda/Funciones';
import { IFiltrosMovimientosStock, IMovimientoStock, IMovimientoStockInsertable, movimientoStockModelDefault } from '../../../tipos/MovimientosStockType';
import TrazabilidadProductoQuery from '../trazabilidad/TrazabilidadProductoQuery';
import LotesProductoSeleccionModal from '../lotes/LotesProductoSeleccionModal';
import { ILote, ILoteInsertable, loteModelDefault } from '../../../tipos/LotesType';
import LoteRegularizarModal from '../lotes/LoteRegularizarModal';
import { queryObtenerLoteGeneralProducto } from '../../../util/querys/LotesQuerys';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { IUsuarioSesion } from '../../../tipos/UsuariosType';
import { EFuncionalidad, ETipoIVA, VAR_SESION_USUARIO } from '../../../tipos/CommonTypes';
import EtiquetaProductoModal from '../colaimpresion/EtiquetaProductoModal';

/* DECLARACIONES */  
const schemaValidacion = yup.object().shape({
    idFamilia: yup.number()
        .min(1, "Seleccione una familia")
        .required("El campo es obligatorio"),
    idSeccion: yup.number()
        .min(1, "Seleccione una sección")
        .required("El campo es obligatorio"),
    nombre: yup.string()
        .required("El campo es obligatorio"),
    idTipoUnidad: yup.number()
        .min(1, "Seleccione un tipo de unidad")
        .required("El campo es obligatorio"),
    stockMinimo: yup.number()
        .min(1, "El stock mínimo tiene que ser mayor que 0.")
        .required("El campo es obligatorio"),
    precio: yup.number()
        .transform((value) => Number.isNaN(value) ? undefined : value )
        .positive()
});


const queryListarFamilias = () => ({
    queryKey: ['listaFamiliasSelect'],
    queryFn: () => APIFamilias.ListarFamiliasProductos(undefined),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
 
const queryListarSecciones = () => ({
    queryKey: ['listaSeccionesSelect'],
    queryFn: () => APISecciones.ListarSeccionesAlmacen(undefined),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

const queryListarTiposUnidad = () => ({
    queryKey: ['listaTiposUnidadSelect'],
    queryFn: () => APITiposUnidades.ListarTiposUnidades(undefined),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

type ProductoEdicionProps = {
    datos: IProducto | undefined,
    esEdicion: boolean
}

/* COMPONENTE */
export default function ProductoEdicion({datos, esEdicion} : ProductoEdicionProps) {
    /* definiciones */
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: {errors}, setValue, getValues, reset, watch } = useForm<IProducto>({
        mode: "onSubmit",
        defaultValues: datos?? productoModelDefault,
        values: datos,
        resolver: yupResolver(schemaValidacion),
        reValidateMode: "onChange",
    });
    const [datosLote, setDatosLote] = useState<ILote>(loteModelDefault);
    
    const agregarProducto = useAgregarProducto();
    const editarProducto = useEditarProducto();
    const eliminarProducto = useEliminarProducto();
    const agregarMovimientoStock = useAgregarMovimientoStock();    
    const agregarImpresionEtiquetaLaboratorio = useAgregarImpresionEtiquetaLaboratorio();
    
    const [visibleModalConfirmacion, setVisibleModalConfirmacion] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState("");
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const [visibleModalEtiquetaLaboratorio, setVisibleModalEtiquetaLaboratorio] = useState(false);
    const [visibleModalEtiquetaProducto, setVisibleModalEtiquetaProducto] = useState(false);
    
    const { data: datosLoteQuery, isLoading: isLoadingLoteGeneral } = useQuery(queryObtenerLoteGeneralProducto(datos?.id?? null));
    const { data: opcionesFamilias, isLoading: isLoadingFamilias } = useQuery(queryListarFamilias());
    const { data: opcionesSecciones, isLoading: isLoadingSecciones } = useQuery(queryListarSecciones());
    const { data: opcionesTiposUnidad, isLoading: isLoadingTiposUnidad } = useQuery(queryListarTiposUnidad());
    const [heightListado, setHeightListado] = useState<number>(0);
    const formCabeceraRef = React.useRef<HTMLFormElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    
    const infoNotificacionDefault : InfoNotificacion = {
        tipo: ETipoNotificacion.INFO,
        titulo: "",
        fecha: "",
        mensaje: ""
    }
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    const [filtrosTrazabilidad, setFiltrosTrazabilidad] = useState<IFiltrosMovimientosStock>({
        idProducto: datos?.id,
        fechaFin: new Date(),
    });
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    const [visibleModalLotes, setVisibleModalLotes] = useState(false);
    const [visibleModalRegularizacionLote, setVisibleModalRegularizacionLote] = useState(false);
    
    const { getItem } = useLocalStorage();
    const [accesoEditar, setAccesoEditar] = useState<boolean>(false);
    const [accesoRegularizarStock, setAccesoRegularizarStock] = useState<boolean>(false);
    const [accesoImprimirLaboratorio, setAccesoImprimirLaboratorio] = useState<boolean>(false);
    const [accesoImprimirProducto, setAccesoImprimirProducto] = useState<boolean>(false);
    const [accesoTrazabilidad, setAccesoTrazabilidad] = useState<boolean>(false);

    
    const [datosMovimientoStock, setDatosMovimientoStock] = useState<IMovimientoStock>(movimientoStockModelDefault);
    const agregarImpresionEtiquetaProducto = useAgregarImpresionEtiquetaProducto();

    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
       
    function useAgregarProducto() {
        return useMutation({
          mutationFn: (datosModelo : IProducto) => {
            return APIProductos.AgregarProducto(datosModelo);
          },
          onSuccess: (datosRespuesta: IProducto) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: ['listadoProductos']});
            reset(productoModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>El <strong>producto</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando el <strong>producto</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    function useEditarProducto() {
        return useMutation({
          mutationFn: (datosModelo : IProducto) => {
            return APIProductos.EditarProducto(datosModelo);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: ['listadoProductos']});
            reset(productoModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Editar producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>El <strong>producto</strong> se ha editado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error editando el <strong>producto</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    function useEliminarProducto() {
        return useMutation({
          mutationFn: (datosModelo : IProducto) => {
            return APIProductos.EliminarProducto(datosModelo.id);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: ['listadoProductos']});
            reset(productoModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Eliminar producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>El <strong>producto</strong> se ha eliminado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Eliminar producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error eliminando el <strong>producto</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
      
    function useAgregarImpresionEtiquetaLaboratorio() {
        return useMutation({
          mutationFn: (datosModelo : IImpresionInsertable) => {
            return APIImpresiones.AgregarImpresion(datosModelo);
          },
          onSuccess: (datosRespuesta: IImpresionInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPColaImpresionListaPaginada]});
            setVisibleModalEtiquetaLaboratorio(false);

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar impresión de laboratorio",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>impresión de etiqueta de laboratorio</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar impresión de laboratorio",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>impresión de etiqueta de laboratorio</strong>:${error.message}</span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
     
    function useAgregarImpresionEtiquetaProducto() {
        return useMutation({
          mutationFn: (datosModelo : IImpresionInsertable) => {
            return APIImpresiones.AgregarImpresion(datosModelo);
          },
          onSuccess: (datosRespuesta: IImpresionInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPColaImpresionListaPaginada]});
            setVisibleModalEtiquetaProducto(false);

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar impresión de producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>impresión de etiqueta del producto</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar impresión de producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>impresión de etiqueta del producto</strong>:${error.message}</span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
       
    const onClickCancelarImprimirEtiquetaProducto = () => {
        setDatosMovimientoStock(movimientoStockModelDefault);
        setVisibleModalEtiquetaProducto(false);
    }

    const onClickImprimirEtiquetaProducto = () => {
        let datosModelo : IMovimientoStock;

        if (datos && datosLoteQuery) {
            datosModelo = {
                ...movimientoStockModelDefault,
                idProducto:datos.id,
                idLote: datosLoteQuery.id,
                codigoLote: datosLoteQuery.codigo,
                caducidadLote: datosLoteQuery.caducidad?? null,
                producto: {
                    ...productoLoteDefault,
                    ...datos,
                }
            }
            setDatosMovimientoStock(datosModelo);
            setVisibleModalEtiquetaProducto(true);
        }
    }

    const guardarEtiquetaProducto = (datosModelo : IEtiquetaProducto) => {
        let nuevaImpresion : IImpresionInsertable = {
            id: 0,
            idImpresora: datosModelo.idImpresora,
            idPlantilla: PLANTILLA_PRODUCTO_ID, //plantilla - producto.
            fecha: new Date(),
            textoImpresion: TransformarPlantillaProducto(datosModelo),
            fechaImpresion: null
        };
        console.log(nuevaImpresion);
        agregarImpresionEtiquetaProducto.mutate(nuevaImpresion);
    }

    const guardarDatos = (datosModelo : IProducto) => {
        if (!esEdicion) {
            agregarProducto.mutate(datosModelo);
        } else {
            editarProducto.mutate(datosModelo);
        }
    }

    const handlerEliminar = () => {
        setMensajeConfirmacion(`Se va a eliminar el producto: <strong>${getValues().nombre}</strong>`);
        setVisibleModalConfirmacion(true);
    }
    
    const handlerConfirmarEliminar = () => {
        eliminarProducto.mutate(getValues());
    }
      
    const guardarEtiquetaLaboratorio = (datosModelo : IEtiquetaLaboratorio) => {
        let nuevaImpresion : IImpresionInsertable = {
            id: 0,
            idImpresora: datosModelo.idImpresora,
            idPlantilla: PLANTILLA_LABORATORIO_ID, //plantilla - laboratorio.
            fecha:  new Date(),
            textoImpresion: TransformarPlantillaLaboratorio(datosModelo),
            fechaImpresion: null
        };
        agregarImpresionEtiquetaLaboratorio.mutate(nuevaImpresion);
    }

    const onChangeFechaInicio = (event: ChangeEvent<HTMLInputElement>) => {
        setFiltrosTrazabilidad({
            ...filtrosTrazabilidad,
            fechaInicio: new Date(event.currentTarget.value),
        })
    }

    const onChangeFechaFin = (event: ChangeEvent<HTMLInputElement>) => {
        setFiltrosTrazabilidad({
            ...filtrosTrazabilidad,
            fechaFin: new Date(event.currentTarget.value),
        })
    }
    
    const onClickSeleccionarLote = () => {
        setVisibleModalLotes(true);
    }

    const onClickCancelarLote = () => {
        setVisibleModalLotes(false);
    }

    const handlerLoteSeleccionado = (datosModal: ILoteInsertable) => {
        setFiltrosTrazabilidad({
            ...filtrosTrazabilidad,
            codigoLote: datosModal.codigo?? undefined,
        })
        setVisibleModalLotes(false);
    }
    
    function onClickEliminarFiltroLote(): void {
        setFiltrosTrazabilidad({
            ...filtrosTrazabilidad,
            codigoLote: undefined,
        })
        setVisibleModalLotes(false);
    }

    const onClickCancelarRegularizacion = () => {
        setVisibleModalRegularizacionLote(false);
        setDatosLote(loteModelDefault);
    }

    const onClickRegularizarStock = () => {
        if (!isLoadingLoteGeneral && datosLoteQuery) {
            setDatosLote(datosLoteQuery);     
            setVisibleModalRegularizacionLote(true);
        }
    }

    const guardarDatosRegularizacionLote = (datosModelo : IMovimientoStockInsertable) => {        
        setVisibleModalRegularizacionLote(false);
        setDatosLote(loteModelDefault);
        agregarMovimientoStock.mutate(datosModelo);
    }

    function useAgregarMovimientoStock() {
        return useMutation({
          mutationFn: (datosModelo : IMovimientoStockInsertable) => {
            return APIMovimientosStock.AgregarMovimientoStock(datosModelo);
          },
          onSuccess: (datosRespuesta: IMovimientoStockInsertable) => {         
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar regularización de stock",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>La regularización de stock del lote <strong>${datosRespuesta.lote?.codigo}</strong> se ha hecho correctamente.</span>`
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar regularización de stock",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error guardando una <strong>regularización de stock</strong>: <em>${error.message}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    /* efectos */     
    React.useEffect(() => {
        if (datos != null) {
            filtrosTrazabilidad.idProducto=datos.id; 
        }
    }, [datos, filtrosTrazabilidad]);
        
    React.useEffect(() => {
        if (!isLoadingFamilias && (opcionesFamilias?.length??-1) > 0) {
            if (datos?.idFamilia) setValue("idFamilia", datos?.idFamilia?? 0); 
        }
    }, [isLoadingFamilias, opcionesFamilias, setValue, datos]);
      
    React.useEffect(() => {
        if (!isLoadingSecciones && (opcionesSecciones?.length??-1) > 0) {
            if (datos?.idSeccion) setValue("idSeccion", datos?.idSeccion?? 0); 
        }
    }, [isLoadingSecciones, opcionesSecciones, setValue, datos]);
      
    React.useEffect(() => {
        if (!isLoadingTiposUnidad && (opcionesTiposUnidad?.length??-1) > 0) {
            if (datos?.idTipoUnidad) setValue("idTipoUnidad", datos?.idTipoUnidad?? 0); 
        }
    }, [isLoadingTiposUnidad, opcionesTiposUnidad, setValue, datos]);
    
    React.useEffect(() => {
        if (!visibleNotificacion && agregarProducto.isSuccess) {
            //navegamos al listado...
            navigate(Rutas.RUTA_PRODUCTOS);
        }
    }, [visibleNotificacion, agregarProducto, navigate]);
    
    React.useEffect(() => {
        if (!visibleNotificacion && editarProducto.isSuccess) {
            //navegamos al listado...
            navigate(Rutas.RUTA_PRODUCTOS);
        }
    }, [visibleNotificacion, editarProducto, navigate]);
    
    React.useEffect(() => {
        if (!visibleNotificacion && eliminarProducto.isSuccess) {
            //navegamos al listado...
            navigate(Rutas.RUTA_PRODUCTOS);
        }
    }, [visibleNotificacion, eliminarProducto, navigate]);
    
    React.useEffect(() => {
        let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
        if (usuarioSesion) {
            setAccesoEditar(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_PRODUCTOS_EDICION));
            setAccesoRegularizarStock(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_PRODUCTOS_STOCK_REGULARIZAR));
            setAccesoImprimirProducto(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_PRODUCTOS_IMPRIMIR_ETIQUETA));
            setAccesoImprimirLaboratorio(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_LABORATORIO_IMPRIMIR_ETIQUETA));
            setAccesoTrazabilidad(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_PRODUCTOS_VER_TRAZABILIDAD));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /* renderizado */
    return (
        <Form noValidate id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="container-fluid py-2" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="row">
                    <div className="col col-sm-12 col-md-8">
                        <Breadcrumb>
                            <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">Almacén</Breadcrumb.Item>
                            <Breadcrumb.Item href={Rutas.RUTA_PRODUCTOS}>Productos</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edición</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="col col-sm-2 col-md-4 d-flex  justify-content-end">
                        <Link to={Rutas.RUTA_PRODUCTOS} className="nav-link link-dark cursor-pointer d-flex align-center text-right">
                            <span className="material-symbols-outlined">west</span>&nbsp;
                            volver al listado
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-sm-12 col-md-7">
                        <h3>Detalles del producto</h3>
                        {!esEdicion && <h5 className="text-info">PRODUCTO NUEVO</h5>}
                        {esEdicion && <h5><span className="text-info">{getValues().codigoEAN?? "SIN EAN"}</span>&nbsp;-&nbsp;{getValues().nombre}</h5>}
                    </div>
                    <div className="col col-sm-12 col-md-5 d-flex justify-content-end align-items-end">
                        {accesoEditar && (
                        <button className="btn btn-primary" onClick={handleSubmit(guardarDatos)}>
                            <div className=" d-flex align-center">
                                <span className="material-symbols-outlined">edit_square</span>&nbsp;
                                guardar
                            </div>
                        </button>)}       
                        {esEdicion && (<>
                        {accesoEditar && (
                        <button type="button" className="btn btn-light ms-2" onClick={ () => { handlerEliminar(); } }>
                            <div className="d-flex align-center text-danger">
                                <span className="material-symbols-outlined">delete</span>&nbsp;
                                eliminar
                            </div>
                        </button>)}
                        {(accesoImprimirLaboratorio || accesoImprimirProducto || accesoRegularizarStock) && (<>
                        <button type="button" className="btn btn-light ms-2 dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="d-flex align-center">
                                <span className="material-symbols-outlined">more_horiz</span>
                            </div>
                        </button>
                        <ul className="dropdown-menu">
                            {accesoImprimirLaboratorio && (
                            <li>
                                <button type="button" className="dropdown-item d-flex align-items-center"
                                     onClick={ () => { setVisibleModalEtiquetaLaboratorio(true); } }>
                                    <span className="material-symbols-outlined">print_add</span>&nbsp;
                                    Imprimir etiqueta laboratorio
                                </button>
                            </li>)}
                            {accesoImprimirProducto && (
                            <li>
                                <button type="button" className="dropdown-item d-flex align-items-center"
                                     onClick={onClickImprimirEtiquetaProducto}>
                                    <span className="material-symbols-outlined">print_add</span>&nbsp;
                                    Imprimir etiqueta producto
                                </button>
                            </li>)}
                            {accesoRegularizarStock && (
                            <li>
                                <button type="button" className="dropdown-item d-flex align-items-center"
                                     onClick={onClickRegularizarStock}>
                                    <span className="material-symbols-outlined">score</span>&nbsp;
                                    regularizar stock
                                </button>
                            </li>)}
                        </ul></>)}
                        </>)}
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid pt-3 scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button type="button" id="pills-datos-tab" className="nav-link active" 
                            data-bs-toggle="pill" data-bs-target="#pills-datos" role="tab" 
                            aria-controls="pills-datos" aria-selected="true">Datos</button>
                    </li>
                    {accesoTrazabilidad && (
                    <li className="nav-item" role="presentation">
                        <button type="button" id="pills-trazabilidad-tab" className="nav-link"  
                            data-bs-toggle="pill" data-bs-target="#pills-trazabilidad" role="tab" 
                            aria-controls="pills-trazabilidad" aria-selected="false">Trazabilidad</button>
                    </li>)}
                </ul>
                <div className="tab-content" id="pills-tabContenido">
                    <div className="tab-pane fade show active" id="pills-datos" role="tabpanel" aria-labelledby="pills-datos-tab">       
                        <div className="row">
                            <div className="col col-sm-12 col-md-6">
                                <Form.Group className="mb-3" controlId="selFamilia">
                                    <Form.Label>Familia</Form.Label>
                                    <Form.Select {...register("idFamilia")} required={true} isInvalid={!!errors.idFamilia}>
                                        {isLoadingFamilias && (<option value="">cargando...</option>)}
                                        {opcionesFamilias && 
                                            [
                                                ...[{id:0, nombre: "seleccione una familia..."}],
                                                ...opcionesFamilias
                                            ].map((option) => {
                                            return (
                                                <option key={option.id?? 0} value={option.id?? 0}>{option.nombre}</option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.idFamilia?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col col-sm-12 col-md-6">
                                <Form.Group className="mb-3" controlId="selSeccion">
                                    <Form.Label>Sección de almacén</Form.Label>
                                    <Form.Select {...register("idSeccion")} required={true}  isInvalid={!!errors.idSeccion}>
                                        {isLoadingSecciones && (<option value="">cargando...</option>)}
                                        {opcionesSecciones && 
                                            [
                                                ...[{id:0, nombre: "seleccione una sección de almacén..."}],
                                                ...opcionesSecciones
                                            ].map((option) => {
                                            return (
                                                <option key={option.id?? 0} value={option.id?? 0}>{option.nombre}</option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.idSeccion?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-sm-12">
                                <Form.Group className="mb-3" controlId="txtNombre">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" maxLength={300} {...register("nombre")}
                                        required={true} isInvalid={!!errors.nombre}
                                        placeholder="escribe el nombre del producto..." />
                                    <Form.Text className="text-muted" hidden={watch('nombre')?.length === 0}>
                                        {`${watch('nombre')?.length?? 0}/300`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.nombre?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-sm-12 col-md-8">
                                <Form.Group className="mb-3" controlId="txtNombreCorto">
                                    <Form.Label>Nombre corto</Form.Label>
                                    <Form.Control type="text" maxLength={100} {...register("nombreCorto")}
                                        required={false}  isInvalid={!!errors.nombreCorto}
                                        placeholder="escribe el nombre corto del producto..." />
                                    <Form.Text className="text-muted" hidden={(watch('nombreCorto')?.length??0) === 0}>
                                        {`${watch('nombreCorto')?.length?? 0}/100`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.nombreCorto?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col col-sm-12 col-md-4">
                                <Form.Group className="mb-3" controlId="selTiposUnidad">
                                    <Form.Label>Tipo de unidad</Form.Label>
                                    <Form.Select {...register("idTipoUnidad")} required={true} isInvalid={!!errors.idTipoUnidad}>
                                        {isLoadingTiposUnidad && (<option value="">cargando...</option>)}
                                        {opcionesTiposUnidad && 
                                            [
                                                ...[{id:0, nombre: "seleccione un tipo..."}],
                                                ...opcionesTiposUnidad
                                            ].map((option) => {
                                            return (
                                                <option key={option.id?? 0} value={option.id?? 0}>{option.nombre}</option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.idTipoUnidad?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-sm-12 col-md-6">
                                <Form.Group className="mb-3" controlId="txtCodigo">
                                    <Form.Label>Código</Form.Label>
                                    <Form.Control type="text" maxLength={20} {...register("codigo")}
                                        required={false}  isInvalid={!!errors.codigo}
                                        placeholder="se genera automáticamente si se deja vacío..." />
                                    <Form.Text className="text-muted" hidden={(watch('codigo')?.length??0) === 0}>
                                        {`${watch('codigo')?.length?? 0}/20`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.codigo?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col col-sm-12 col-md-6">
                                <Form.Group className="mb-3" controlId="txtCodigoEAN">
                                    <Form.Label>Código EAN</Form.Label>
                                    <Form.Control type="text" maxLength={13} {...register("codigoEAN")}
                                        required={false}  isInvalid={!!errors.nombreCorto}
                                        placeholder="se genera automáticamente si se deja vacío..." />
                                    <Form.Text className="text-muted" hidden={(watch('codigoEAN')?.length??0) === 0}>
                                        {`${watch('codigoEAN')?.length?? 0}/13`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.nombreCorto?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col col-sm-12">
                                <Form.Group className="mb-3" controlId="txtModelo">
                                    <Form.Label>Modelo</Form.Label>
                                    <Form.Control type="text" maxLength={20} {...register("modelo")}
                                        required={false}  isInvalid={!!errors.modelo}
                                        placeholder="escribe el modelo/marca del producto..." />
                                    <Form.Text className="text-muted" hidden={(watch('modelo')?.length??0) === 0}>
                                        {`${watch('modelo')?.length?? 0}/20`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.modelo?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col col-sm-12 col-md-4">
                                <Form.Group className="mb-3" controlId="txtStockMinimo">
                                    <Form.Label>Stock mínimo</Form.Label>
                                    <Form.Control type="number" style={{width:"100px"}} maxLength={5} {...register("stockMinimo")}
                                        required={true} isInvalid={!!errors.stockMinimo}/>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.stockMinimo?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col col-sm-12 col-md-4">
                                <Form.Group className="mb-3" controlId="txtPrecio">
                                    <Form.Label>Precio concertado</Form.Label>
                                    <Form.Control type="number" style={{width:"100px"}} maxLength={5} {...register("precio")}
                                        isInvalid={!!errors.precio}/>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.precio?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col col-sm-12 col-md-4">                            
                                <Form.Group className="mb-3" controlId="selTipoIVA">
                                    <Form.Label>Tipo de IVA</Form.Label>
                                    <Form.Select {...register("tipoIVA", {
                                            valueAsNumber: true,
                                        })}
                                        required={true}  isInvalid={!!errors.tipoIVA}>
                                        <option value={ETipoIVA.EXENTO}>{ETipoIVAToString(ETipoIVA.EXENTO)}</option>
                                        <option value={ETipoIVA.GENERAL}>{ETipoIVAToString(ETipoIVA.GENERAL)}</option>
                                        <option value={ETipoIVA.REDUCIDO}>{ETipoIVAToString(ETipoIVA.REDUCIDO)}</option>
                                        <option value={ETipoIVA.SUPERREDUCIDO} selected>{ETipoIVAToString(ETipoIVA.SUPERREDUCIDO)}</option>
                                        <option value={ETipoIVA.SUPERREDUCIDO_2}>{ETipoIVAToString(ETipoIVA.SUPERREDUCIDO_2)}</option>
                                        <option value={ETipoIVA.SUPERREDUCIDO_3}>{ETipoIVAToString(ETipoIVA.SUPERREDUCIDO_3)}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.tipoIVA?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col col-sm-6 col-md-4">
                                <Form.Group className="mb-3" controlId="cboxUsaControlLotes">
                                    <Form.Label>Control de stock por lotes</Form.Label>
                                    <Form.Check {...register("usaControlLotes")}
                                        type="switch"
                                        id="cbUsaControlLotes"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col col-sm-6 col-md-4">
                                <Form.Group className="mb-3" controlId="cboxEsBaja">
                                    <Form.Label>¿Es baja?</Form.Label>
                                    <Form.Check {...register("esBaja")}
                                        type="switch"
                                        id="cboxEsBaja"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-trazabilidad" role="tabpanel" aria-labelledby="pills-trazabilidad-tab">
                        <Container fluid>
                            <Row>
                                <Col sm={12} lg={6}>
                                    <div className="input-group">
                                        <span className="input-group-text">Fecha</span>
                                        <input type="date" aria-label="fecha desde" className="form-control" value={
                                            (filtrosTrazabilidad.fechaInicio)? 
                                                format(new Date(filtrosTrazabilidad.fechaInicio), "yyyy-MM-dd") : ""} 
                                            onChange={onChangeFechaInicio}/>
                                        <input type="date" aria-label="fecha hasta" className="form-control" value={
                                            (filtrosTrazabilidad.fechaFin)? 
                                                format(new Date(filtrosTrazabilidad.fechaFin), "yyyy-MM-dd") : 
                                                format(new Date(), "yyyy-MM-dd")} 
                                            onChange={onChangeFechaFin}/>
                                    </div>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="txtProducto">
                                        <InputGroup className="mb-3">
                                            <Button variant="outline-info" id="btnSeleccionarLote" onClick={onClickSeleccionarLote}>
                                                seleccione un lote
                                            </Button>
                                            <Form.Control type="hidden" />
                                            <Form.Control readOnly
                                                value={filtrosTrazabilidad.codigoLote?? ""}
                                                aria-label="Seleccione un lote para añadir"
                                                aria-describedby="btnSeleccionarLote"
                                            />
                                            <Button variant="outline-secondary" id="btnEliminarSeleccionLote" onClick={onClickEliminarFiltroLote}>
                                            <span className="material-symbols-outlined">close</span>
                                            </Button>
                                        </InputGroup>
                                    </Form.Group>
                                    <LotesProductoSeleccionModal
                                        idProducto={datos?.id?? 0}
                                        mostrarModal={visibleModalLotes}
                                        setMostrarModal={setVisibleModalLotes}
                                        handlerCancelar={onClickCancelarLote}
                                        seleccionarLote={handlerLoteSeleccionado}
                                    />
                                </Col>
                            </Row>
                        </Container>                        
                        <hr/>
                        <Container fluid>
                            <TrazabilidadProductoQuery
                                filtrosQuery={filtrosTrazabilidad}
                                pintarMasFilas= {pintarMasFilas}
                            />
                        </Container>
                    </div>
                </div>
            </aside>            
            <LoteRegularizarModal
                datosIniciales={datosLote}
                mostrarModal={visibleModalRegularizacionLote}
                setMostrarModal={setVisibleModalRegularizacionLote}
                handlerCancelar={onClickCancelarRegularizacion}
                guardarDatos={guardarDatosRegularizacionLote}
            />
            <EtiquetaLaboratorioModal 
                datosProducto={datos}
                mostrarModal={visibleModalEtiquetaLaboratorio} 
                setMostrarModal={setVisibleModalEtiquetaLaboratorio}  
                guardarDatos={guardarEtiquetaLaboratorio} 
            />
            <EtiquetaProductoModal
                mostrarModal={visibleModalEtiquetaProducto}
                setMostrarModal={setVisibleModalEtiquetaProducto}
                datosMovimientoStock={datosMovimientoStock}
                handlerCancelar={onClickCancelarImprimirEtiquetaProducto}
                guardarDatos={guardarEtiquetaProducto}
            />
            <Notificacion
                tipo={infoNotificacion.tipo}
                titulo={infoNotificacion.titulo}
                fecha={infoNotificacion.fecha}
                mensaje={infoNotificacion.mensaje}
                mostrar={visibleNotificacion}
                setMostrar={setVisibleNotificacion} />
            <ConfirmacionModal 
                mostrarModal = {visibleModalConfirmacion}
                setMostrarModal = {setVisibleModalConfirmacion}
                mensajeConfirmacion = {mensajeConfirmacion}
                handlerSI = {handlerConfirmarEliminar}/>
        </Form>
    );
}