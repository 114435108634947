/* DEPENDENCIAS */
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns'

import useResizeObserver from '../../../hooks/useResizeObserver';
import * as Rutas from '../../../rutas/Rutas';
import ProductosQuery from './ProductosQuery';
import { IFiltrosProductos, IProducto, IResumenStocks, filtrosProductoDefault, filtrosStocksMInimosDefault, productoModelDefault } from '../../../tipos/ProductosType';
import * as APIProductos from '../../../servicios/APIProductos';
import * as APIFamilias from '../../../servicios/APIFamiliasProductos';
import * as APISecciones from '../../../servicios/APISeccionesAlmacen';
import Notificacion, { ETipoNotificacion } from '../../../componentes-com/Notificacion';
import ConfirmacionModal from '../../../componentes-com/ConfirmacionModal';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { IFamiliaProducto } from '../../../tipos/FamiliasProductosType';
import { ISeccionAlmacen } from '../../../tipos/SeccionesAlmacenType';
import { ERangoStocksMinimos } from '../../../tipos/CommonTypes';
import { queryObtenerInformacionStocks } from '../../../util/querys/ProductosQuerys';


/* DECLARACIONES */
type InfoNotificacion = {
    tipo: ETipoNotificacion,
    titulo: string,
    fecha: string,
    mensaje: string,
}

/* componente */
export default function StocksMinimos() { 
    /* definiciones */
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [filtros, setFiltros] = useState<IFiltrosProductos>(filtrosStocksMInimosDefault);
    const [datos, setDatos] = useState(productoModelDefault);
    const [visibleModalConfirmacion, setVisibleModalConfirmacion] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState("");
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const [opcionesFamilias, setOpcionesFamilias] = useState<IFamiliaProducto[]>([]);
    const [opcionesSecciones, setOpcionesSecciones] = useState<ISeccionAlmacen[]>([]);
    const eliminarProducto = useEliminarProducto();
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    const { register, reset, getValues, handleSubmit } = useForm<IFiltrosProductos>({
        defaultValues: filtrosProductoDefault,
    });
    
    const {data:datosCabecera, isLoading } = useQuery<IResumenStocks>(queryObtenerInformacionStocks());

    const [subtituloHeader, setSubtituloHeader] = useState<string>("Productos en stock mínimo");

    const infoNotificacionDefault : InfoNotificacion = {
        tipo: ETipoNotificacion.INFO,
        titulo: "",
        fecha: "",
        mensaje: ""
    }
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
        
    const onClickEnlaceListado = (rangoStocks : ERangoStocksMinimos) => {
        switch (rangoStocks) {
             case ERangoStocksMinimos.ACTIVOS:
                 setFiltros({
                     ...filtros, 
                     stockConsumido: false,
                     stockBajoMinimos: undefined,
                     distanciaStockMinimo: undefined,
                 });
                 reset({
                     ...filtros,
                     stockConsumido: false,
                     stockBajoMinimos: undefined,
                     distanciaStockMinimo: undefined,
                 }); 
                 setSubtituloHeader("Productos activos (con stock)");
                 break;
                 
             case ERangoStocksMinimos.ACTIVOS_REVISAR:
                setFiltros({
                    ...filtros, 
                    stockConsumido: false,
                    stockBajoMinimos: undefined,
                    distanciaStockMinimo: 10,
                });
                reset({
                    ...filtros,
                    stockConsumido: false,
                    stockBajoMinimos: undefined,
                    distanciaStockMinimo: 10,
                });                
                setSubtituloHeader("Productos activos (con stock cercano al stock mínimo)");
                break;
                 
             case ERangoStocksMinimos.MINIMOS:
                 setFiltros({
                     ...filtros, 
                     stockConsumido: false,
                     stockBajoMinimos: true,
                     distanciaStockMinimo: undefined
                 });
                 reset({
                     ...filtros,
                     stockConsumido: false,
                     stockBajoMinimos: true,
                     distanciaStockMinimo: undefined
                 }); 
                 setSubtituloHeader("Productos en stock mínimo");
                 break;
                 
             case ERangoStocksMinimos.CONSUMIDO:
                 setFiltros({
                     ...filtros, 
                     stockConsumido: true,
                     stockBajoMinimos: undefined,
                     distanciaStockMinimo: undefined
                 });
                 reset({
                     ...filtros,
                     stockConsumido: true,
                     stockBajoMinimos: undefined,
                     distanciaStockMinimo: undefined
                 }); 
                 setSubtituloHeader("Productos sin stock");
                 break;
        } 
     }
 
    const handlerBuscar = () => {
        let filtrosForm : IFiltrosProductos = {
            ...filtros,
            IDFamilia: ((getValues().IDFamilia?? -1) > 0)? getValues().IDFamilia : null,
            IDSeccion: (getValues().IDSeccion?? -1)? getValues().IDSeccion : null,
            Texto: (getValues().Texto !== "")? getValues().Texto : null,
            CodigoEAN: (getValues().CodigoEAN !== "")? getValues().CodigoEAN : null,
            UsaControlLotes: getValues().UsaControlLotes,
        }
        setFiltros(filtrosForm);
    };

    const handlerResetFiltros = () => {
        reset({
            ...filtrosProductoDefault,
            stockConsumido: false,
            stockBajoMinimos: true,
            distanciaStockMinimo: undefined
        });
        setFiltros({
            ...filtrosProductoDefault, 
            stockConsumido: false,
            stockBajoMinimos: true,
            distanciaStockMinimo: undefined
        });
        setSubtituloHeader("Productos en stock mínimo");
    };

    const handlerEditar = (datosModelo : IProducto) => {
        navigate(`${Rutas.RUTA_PRODUCTOS_EDICION}${datosModelo.id}`);
    }

    const handlerEliminar = (datosModelo : IProducto) => {
        setDatos(datosModelo);
        setMensajeConfirmacion(`Se va a eliminar el producto: <strong>${datosModelo.nombre}</strong>`);
        setVisibleModalConfirmacion(true);
    }
    
    const handlerConfirmarEliminar = () => {
        eliminarProducto.mutate(datos);
    }
    
    function useEliminarProducto() {
        return useMutation({
          mutationFn: (datosModelo : IProducto) => {
            return APIProductos.EliminarProducto(datosModelo.id);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: ['listadoProductos']});
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Eliminar producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>El <strong>producto</strong> se ha eliminado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Eliminar producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error eliminando el <strong>producto</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    async function ObtenerListadoFamilias() {
        const listaFamilias = await APIFamilias.ListarFamiliasProductos(undefined);
        setOpcionesFamilias(
            [
                ...[{id: null, nombre: "filtrar por familia..."}],
                ...listaFamilias
            ]);
    }
 
    async function ObtenerListadoSecciones() {
        const listaSecciones = await APISecciones.ListarSeccionesAlmacen(undefined);
        setOpcionesSecciones(
            [
                ...[{id: null, nombre: "filtrar por sección de almacén..."}],
                ...listaSecciones
            ]);
    }


    const onChangeDistanciaStockMinimo = (event: ChangeEvent<HTMLInputElement>) => {
        setFiltros({
            ...filtros,
            distanciaStockMinimo: parseInt(event.currentTarget.value),
        })
    }

    /* efectos */
    React.useEffect(() => {
        ObtenerListadoFamilias();
        ObtenerListadoSecciones(); 
    }, []);
    
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="accordion" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="accordion-item">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-7 pt-3 ps-4">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Almacén</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Stocks mínimos</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelCabecera"
                            aria-expanded="true" aria-controls="panelCabecera">
                            <div className="container-fluid px-0">
                                <h2>Stocks mínimos</h2>
                                <p className="lead">{subtituloHeader}</p>
                            </div>
                        </button>
                    </h2>
                    <div id="panelCabecera" className="accordion-collapse collapse show" data-bs-parent="#contenedorCabeceraListado">
                        <div className="accordion-body">
                            <Row>
                                <Col md={6} lg={3}>
                                    <Button variant="link" style={{padding:"0.3rem"}} onClick={()=> {onClickEnlaceListado(ERangoStocksMinimos.ACTIVOS);}}>
                                        Productos con stock
                                    </Button>(
                                        {isLoading && <>...</>}
                                        {datosCabecera && <>{datosCabecera.abiertos}</>})
                                </Col>
                                <Col md={6} lg={3}>
                                    <Button variant="link" style={{padding:"0.3rem"}} onClick={()=> {onClickEnlaceListado(ERangoStocksMinimos.MINIMOS);}}>
                                        Stocks mínimos
                                    </Button>(
                                        {isLoading && <>...</>}
                                        {datosCabecera && <>{datosCabecera.stocksMinimos}</>})
                                </Col>
                                <Col md={6} lg={3}>
                                    <Button variant="link" style={{padding:"0.3rem"}} onClick={()=> {onClickEnlaceListado(ERangoStocksMinimos.ACTIVOS_REVISAR);}}>
                                        Stocks a revisar
                                    </Button>(
                                        {isLoading && <>...</>}
                                        {datosCabecera && <>{datosCabecera.stocksRevisables}</>})
                                </Col>
                                <Col md={6} lg={3}>
                                    <Button variant="link" style={{padding:"0.3rem"}} onClick={()=> {onClickEnlaceListado(ERangoStocksMinimos.CONSUMIDO);}}>
                                        Productos sin stock
                                    </Button>(
                                        {isLoading && <>...</>}
                                        {datosCabecera && <>{datosCabecera.consumidos}</>})
                                </Col>
                            </Row>
                            <div className="row g-sm-2">
                                <div className="col-lg-6 col-md-12">
                                    <select className="form-select" aria-label="Familias" {...register("IDFamilia")}>
                                        {opcionesFamilias.map((option) => {
                                        return (
                                            <option key={option.id?? ""} value={option.id?? ""}>{option.nombre}</option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <select className="form-select" aria-label="Secciones de almacén" {...register("IDSeccion")}>
                                        {opcionesSecciones.map((option) => {
                                        return (
                                            <option key={option.id?? ""} value={option.id?? ""}>{option.nombre}</option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <Form.Control type="text" maxLength={300} {...register("Texto")}
                                        placeholder="filtrar por nombre, nombre corto..." />
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <Form.Control type="text" maxLength={300} {...register("CodigoEAN")}
                                        placeholder="filtrar por codigo EAN..." />
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <FloatingLabel controlId="selControlPorLotes" label="¿control por lotes?">
                                        <Form.Select aria-label="¿control por lotes?" {...register("UsaControlLotes")}>
                                            <option value="">Todos</option>
                                            <option value="true">Si</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>
                                {filtros.distanciaStockMinimo && (
                                <div className="col-lg-3 col-md-4">
                                    <FloatingLabel controlId="txtDistancia" label="distancia al stock mínimo">
                                        <Form.Control type="number" maxLength={5}
                                            value={filtros.distanciaStockMinimo}
                                            onChange={onChangeDistanciaStockMinimo} />
                                    </FloatingLabel>
                                </div>
                                )}
                                <div className={`${filtros.distanciaStockMinimo? "col-lg-5" : "col-lg-8"} col-md-12 d-flex align-items-end  justify-content-end`}>
                                    <div className="d-flex">
                                        <Button variant='link' className="nav-link me-3" onClick={handleSubmit(handlerResetFiltros)}>
                                            <div className='d-flex'>
                                                    <span className="material-symbols-outlined">filter_alt_off</span>
                                                    reiniciar filtros
                                            </div>
                                        </Button>
                                        <Button variant='info' className='mx-1' onClick={handleSubmit(handlerBuscar)}>
                                            <div className='d-flex'>
                                                    <span className="material-symbols-outlined">filter_alt</span>
                                                    buscar
                                            </div> 
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}
                onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                <ProductosQuery 
                    filtrosQuery={filtros} 
                    handlerEditar= {handlerEditar}
                    handlerEliminar= {handlerEliminar}
                    pintarMasFilas= {pintarMasFilas}
                />
                <Notificacion
                    tipo={infoNotificacion.tipo}
                    titulo={infoNotificacion.titulo}
                    fecha={infoNotificacion.fecha}
                    mensaje={infoNotificacion.mensaje}
                    mostrar={visibleNotificacion}
                    setMostrar={setVisibleNotificacion} />
                <ConfirmacionModal 
                    mostrarModal = {visibleModalConfirmacion}
                    setMostrarModal = {setVisibleModalConfirmacion}
                    mensajeConfirmacion = {mensajeConfirmacion}
                    handlerSI = {handlerConfirmarEliminar}
                />
            </aside>
        </section>
    );
};