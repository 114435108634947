/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useEffect, useState} from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ILote, loteInsertableModelDefault, loteModelDefault } from "../../../tipos/LotesType";
import { Col, Container, FloatingLabel, Row } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { ObtenerCssBadgeStock } from '../../../util/tsx-ayuda/Funciones';
import { format } from 'date-fns';

/* DECLARACIONES */
const schemaValidacion = yup.object().shape({
    codigo: yup.string()
        .required("El campo es obligatorio"),
});

type LoteEdicionModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: ILote,
    handlerCancelar: () => void
    guardarDatos: (datosModelo: ILote) => void
}

  
/* COMPONENTE */
export default function LoteEdicionModal({datosIniciales, mostrarModal, setMostrarModal, handlerCancelar, guardarDatos} : LoteEdicionModalProps) {
    /* definiciones */
    const [datosLote, setDatosLote] = useState(loteModelDefault);
    const { register, handleSubmit, formState: {errors}, reset, setValue } = useForm<ILote>({
        mode: "onTouched",
        defaultValues: loteModelDefault,
        values: datosIniciales?? loteModelDefault,
        resolver: yupResolver(schemaValidacion),
    });
    const [fechaCaducidadInput, setFechaCaducidadInput] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    const [fechaConsumoPreferenteInput, setFechaConsumoPreferenteInput] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    
    const handleCambioFechaCaducidad = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            setValue("caducidad", new Date(event.target.value));
            setDatosLote({...datosLote, caducidad: new Date(event.target.value)});
            setFechaCaducidadInput(format(new Date(event.target.value), "yyyy-MM-dd"));
        }
        else setValue("caducidad", null);
    }

    const handleCambioFechaConsumoPreferente = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            setValue("consumoPreferente", new Date(event.target.value));
            setDatosLote({...datosLote, consumoPreferente: new Date(event.target.value)});
            setFechaConsumoPreferenteInput(format(new Date(event.target.value), "yyyy-MM-dd"));
        }
        else setValue("consumoPreferente", null);
    }

    
    /* funciones */
    React.useEffect(() => {
        setDatosLote(datosIniciales);
        reset(datosIniciales?? loteModelDefault);
    }, [reset, datosIniciales, setDatosLote]);
    
    React.useEffect(() => {
        if (datosLote?.caducidad) {
            setValue("caducidad", datosLote?.caducidad);
            setFechaCaducidadInput(format(new Date(datosLote?.caducidad), "yyyy-MM-dd"));
        } 
    }, [fechaCaducidadInput, datosLote, setValue]);

    React.useEffect(() => {
        if (datosLote?.consumoPreferente) {
            setValue("consumoPreferente", datosLote?.consumoPreferente);
            setFechaConsumoPreferenteInput(format(new Date(datosLote?.consumoPreferente), "yyyy-MM-dd"));
        } 
    }, [fechaConsumoPreferenteInput, datosLote, setValue]);

    /* renderizado */
    return (
        <Modal
            show={mostrarModal} size="lg"
            onHide={() => {reset(loteInsertableModelDefault); handlerCancelar();}}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Lote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col sm={7}>
                                <FloatingLabel className="mb-3" controlId="txtNombreProducto" label="Producto">
                                    <Form.Control plaintext className="text-dark" value={datosLote.producto.nombre} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={3}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Stock mínimo">
                                    <Form.Control plaintext className="text-dark" value={datosLote.producto.stockMinimo} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={2}>
                                <Form.Floating>
                                    <span id="txtStock" className="form-control-plaintext">
                                        <span className={`badge ${ObtenerCssBadgeStock(datosLote.stock, datosLote.producto.stockMinimo)}`}>
                                        {datosLote.stock}
                                        </span>
                                    </span>
                                    <label htmlFor="txtStock">Stock</label>
                                </Form.Floating>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="txtCodigo">
                                    <Form.Label>Código</Form.Label>
                                    <Form.Control type="text" {...register("codigo")}
                                        required={true}  isInvalid={!!errors.codigo}
                                        placeholder="escribe el código..." />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.codigo?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="txtCaducidad">
                                    <Form.Label>Caducidad</Form.Label>
                                    <Form.Control type="date" 
                                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => {handleCambioFechaCaducidad(e);}}
                                        required={false}  isInvalid={!!errors.caducidad}
                                        value={fechaCaducidadInput} />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.caducidad?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>                                                
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="txtConsumoPreferente">
                                    <Form.Label>Consumo preferente</Form.Label>
                                    <Form.Control type="date"
                                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => {handleCambioFechaConsumoPreferente(e);}}
                                        required={false}  isInvalid={!!errors.consumoPreferente}
                                        value={fechaConsumoPreferenteInput} />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.consumoPreferente?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>                                                 
                            </Col>
                        </Row>
                    </Container>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() =>  {reset(loteInsertableModelDefault); handlerCancelar();}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}