/* dependencias */
import React, { ChangeEvent, useCallback, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, } from 'react-router-dom';
import { format } from 'date-fns';

import * as Rutas from '../../../rutas/Rutas';
import useResizeObserver from '../../../hooks/useResizeObserver';
import * as APIMovimientosStock from '../../../servicios/APIMovimientosStock'
import Notificacion, { ETipoNotificacion, InfoNotificacion } from '../../../componentes-com/Notificacion';
import ConfirmacionModal from '../../../componentes-com/ConfirmacionModal';
import { ETipoMovimientoStock, IFiltrosMovimientosStock, IMovimientoStock, IMovimientoStockInsertable, filtrosMovimientosStockDefault, movimientoStockSalidaModelDefault } from '../../../tipos/MovimientosStockType';
import { KEYPMovimientosStockLista, KEYPSalidasStockLista } from '../../../util/querys/MovimientosStockQuerys';
import SalidasStockQuery from './SalidasStockQuery';
import SalidaProductoModal from './SalidaProductoModal';
import { IProducto, productoModelDefault } from '../../../tipos/ProductosType';
import { ILoteInsertable } from '../../../tipos/LotesType';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { IUsuarioSesion, modelUsuarioSesionDefault } from '../../../tipos/UsuariosType';
import { EFuncionalidad, VAR_SESION_USUARIO } from '../../../tipos/CommonTypes';
import { TienePermiso } from '../../../util/tsx-ayuda/Funciones';


/* declaraciones */
const infoNotificacionDefault : InfoNotificacion = {
    tipo: ETipoNotificacion.INFO,
    titulo: "",
    fecha: "",
    mensaje: ""
}

/* componente */
export default function SalidasMercancia() { 
    /* definiciones */
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [datos, setDatos] = useState<IMovimientoStockInsertable | null>(null);
    const [datosProducto, setDatosProducto] = useState<IProducto | null>(null);
    const [datosLote, setDatosLote] = useState<ILoteInsertable | null>(null);
    const [filtros, setFiltros] = useState<IFiltrosMovimientosStock>({
        ...filtrosMovimientosStockDefault,
        tipo: ETipoMovimientoStock.SALIDA,
        fecha: new Date(),  //hoy
    });
    
    const [visibleModalSalidaStock, setVisibleModalSalidaStock] = useState(false);
    const agregarSalidaMercancia = useAgregarSalidaMercancia();
    const editarSalidaMercancia = useEditarSalidaMercancia();
    const eliminarSalidaMercancia = useEliminarSalidaMercancia();
    

    const [visibleModalConfirmacion, setVisibleModalConfirmacion] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState("");
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    
    
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    
    const { getItem } = useLocalStorage();
    const [accesoNuevo, setAccesoNuevo] = useState<boolean>(false);

    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
      
    const onClickHoy = () => {
        setFiltros({...filtros, fecha: new Date() })
    }

    const onChangeFiltroFecha = (event: ChangeEvent<HTMLInputElement>) => {
        setFiltros({
            ...filtros,
            fecha: new Date(event.currentTarget.value),
        })
    }

    const onClickNuevaSalida = () => {
        setDatos({
            ...movimientoStockSalidaModelDefault,
            fecha: new Date(),
        });
        setDatosProducto(null);
        setDatosLote(null);
        setVisibleModalSalidaStock(true);
    }

    function onClickEditarSalida(datosModelo: IMovimientoStock): void {  
        let datosEdicion : IMovimientoStockInsertable =   {
            ...movimientoStockSalidaModelDefault,
            ...datosModelo,
        };   
        let datosInicialesProducto : IProducto = {
            ...productoModelDefault,
            ...datosModelo.producto,
            id: datosModelo.idProducto,
        }
        let datosInicialesLote : ILoteInsertable | null = null;

        if (datosModelo.idLote > 0) {
            datosEdicion.lote = {
                id: datosModelo.idLote,
                idProducto: datosModelo.id,
                codigo: datosModelo.codigoLote,
                caducidad: datosModelo.caducidadLote,
                stock: 0,
                general: false,
            }
            datosInicialesLote = datosEdicion.lote;
        }
        setDatos(datosEdicion);
        setDatosProducto(datosInicialesProducto);
        setDatosLote(datosInicialesLote);
        setVisibleModalSalidaStock(true);
    }

    function guardarDatosNuevaSalidaStock(datosModelo: IMovimientoStockInsertable): void {
        if (datosModelo.id === 0) agregarSalidaMercancia.mutate(datosModelo);
        else editarSalidaMercancia.mutate(datosModelo);
    }
  
    function useAgregarSalidaMercancia() {
        return useMutation({
          mutationFn: (datosModelo : IMovimientoStockInsertable) => {
            return APIMovimientosStock.AgregarMovimientoStock(datosModelo);
          },
          onSuccess: (datosRespuesta: IMovimientoStockInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPSalidasStockLista]});            
            setVisibleModalSalidaStock(false);

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar salida de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>salida de mercancía</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar salida de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>salida de mercancía</strong>.</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    function useEditarSalidaMercancia() {
        return useMutation({
            mutationFn: (datosModelo : IMovimientoStockInsertable) => {
              return APIMovimientosStock.EditarMovimientoStock(datosModelo);
            },
            onSuccess: () => {
              //actualizamos la lista...
              queryClient.invalidateQueries({ queryKey: [KEYPSalidasStockLista]});           
              setVisibleModalSalidaStock(false);          
              
              //lanzamos aviso...
              setInfoNotificacion({
                  ...infoNotificacion,
                  tipo: ETipoNotificacion.EXITO,
                  titulo: "Editar salida de mercancía",
                  fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                  mensaje: "<span>La <strong>salida de mercancía</strong> se ha modificado correctamente.</span>"
              });
              setVisibleNotificacion(true);
            },
            onError: (error: any, variables, context: any) => {
              setInfoNotificacion({
                  ...infoNotificacion,
                  tipo: ETipoNotificacion.ERROR,
                  titulo: "Editar salida de mercancía",
                  fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                  mensaje: `<span>Error modificando la <strong>salida de mercancía</strong>.</span>`
              });
              setVisibleNotificacion(true);
            }
          });
    } 

    
    function onClickEliminar(datosModelo: IMovimientoStock): void {
        setDatos(datosModelo);
        setVisibleModalConfirmacion(true);
    }

    function handlerConfirmarEliminar(): void {
        eliminarSalidaMercancia.mutate({
            ...movimientoStockSalidaModelDefault,
            ...datos
        });
    }

    function useEliminarSalidaMercancia() {
        return useMutation({
          mutationFn: (datosModelo : IMovimientoStockInsertable) => {
            return APIMovimientosStock.EliminarMovimientoStock(datosModelo.id);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPSalidasStockLista]});
            setDatos(movimientoStockSalidaModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Eliminar salida de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>salida de mercancía</strong> se ha eliminado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Eliminar entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error eliminando la <strong>salida de mercancía</strong>.</span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
      
       
    /* efectos */
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    React.useEffect(() => {
        let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
        if (usuarioSesion) {
            setAccesoNuevo(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.GENERAL_SALIDA_MERCANCIA));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="accordion" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="accordion-item">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-7 pt-3 ps-4">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                                    <Breadcrumb.Item>Almacén</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Salidas de mercancía</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="col-sm-12 col-md-5 p-3 d-flex justify-content-end">
                                <div className={`${!accesoNuevo? 'disabled-button-wrapper' : ''}`}>
                                    <Button type="button" variant="primary" className={`btn btn-primary ${!accesoNuevo? 'disabled' : ''}`} aria-disabled={!accesoNuevo}
                                        onClick={onClickNuevaSalida}>
                                        <div className="d-flex align-items-center">
                                            <span className="material-symbols-outlined">add</span>&nbsp;
                                            nueva salida
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelCabecera"
                            aria-expanded="true" aria-controls="panelCabecera">
                            <div className="container-fluid px-0"><h2>Salidas de mercancía</h2></div>
                        </button>
                    </h2>
                    <div id="panelCabecera" className="accordion-collapse collapse show" data-bs-parent="#contenedorCabeceraListado">
                        <div className="accordion-body">
                            <Row>
                                <Col md={6} lg={3}>
                                    <Button variant="link" style={{padding:"0.3rem"}} onClick={onClickHoy}>
                                        Registros de hoy
                                    </Button>
                                </Col>
                                <Col md={6} lg={3}>
                                    <Form.Group className="mb-3" controlId="txtFecha">
                                        <Form.Label>Fecha</Form.Label>
                                        <Form.Control type="date" value={(filtros.fecha)? 
                                            format(new Date(filtros.fecha), "yyyy-MM-dd") : ""}
                                            onChange={onChangeFiltroFecha} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="row g-sm-2">
                                <div className="cols-sm-12 col-md-6">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}
                onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                <SalidasStockQuery
                    filtrosQuery={filtros}
                    pintarMasFilas={pintarMasFilas}
                    handlerEditar={onClickEditarSalida}
                    handlerEliminar={onClickEliminar}
                />
                <SalidaProductoModal 
                    datosIniciales={datos}
                    datosInicialesProducto={datosProducto}
                    datosInicialesLote={datosLote}
                    mostrarModal={visibleModalSalidaStock}                        
                    setMostrarModal={setVisibleModalSalidaStock}  
                    guardarDatos={guardarDatosNuevaSalidaStock} 
                />
                <Notificacion
                    tipo={infoNotificacion.tipo}
                    titulo={infoNotificacion.titulo}
                    fecha={infoNotificacion.fecha}
                    mensaje={infoNotificacion.mensaje}
                    mostrar={visibleNotificacion}
                    setMostrar={setVisibleNotificacion} />
                <ConfirmacionModal 
                    mostrarModal = {visibleModalConfirmacion}
                    setMostrarModal = {setVisibleModalConfirmacion}
                    mensajeConfirmacion = {mensajeConfirmacion}
                    handlerSI = {handlerConfirmarEliminar}
                />
            </aside>
        </section>
    );
};