/* DEPENDENCIAS */
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import HabitacionesInstance from "./HabitacionesInstance";
import { IHabitacion, IFiltrosHabitaciones } from "../../../tipos/HabitacionesType";
import { queryListarHabitacionesPaginacion } from "../../../util/querys/HabitacionesQuerys";

/* DEFINICIONES */
type IHabitacionesQueryProps = {
  filtrosQuery : IFiltrosHabitaciones,
  pintarMasFilas : boolean,
  handlerEditar : (datosModelo: IHabitacion) => void,
  handlerEliminar : (datosModelo : IHabitacion) => void,
}

/* COMPONENTE */
const HabitacionesQuery = ({filtrosQuery, pintarMasFilas, handlerEditar, handlerEliminar} : IHabitacionesQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useListarHabitaciones(filtrosQuery);
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
    
    /* funciones */
    function useListarHabitaciones(filtrosQuery: IFiltrosHabitaciones) {
        return useInfiniteQuery<IHabitacion[], Error>(queryListarHabitacionesPaginacion(filtrosQuery));
    }        
    
    /* renderizado */    
    return (
      <HabitacionesInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerEditar={handlerEditar}
        handlerEliminar={handlerEliminar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default HabitacionesQuery;