/* DEPENDENCIAS */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import RutaLogoFREMAP from '../util/imagenes/fremap-trazabilidad_logo.png'
import { IUsuarioSesion } from "../tipos/UsuariosType";
import { RUTA_ADMIN } from "../rutas/Rutas";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Autentificar } from "../servicios/APIUsuarios";
import { VAR_SESION_USUARIO } from "../tipos/CommonTypes";
import { ObtenerJWT_old } from "../util/tsx-ayuda/Funciones";

/* DECLARACIONES */
const schemaValidacion = yup.object().shape({
    login: yup.string()
        .required("El campo login es obligatorio"),
    clave: yup.string()
        .required("El campo clave es obligatorio"),
});

type LoginFormType = {
    login: string,
    clave: string,
}

const modelInitForm : LoginFormType = {
    login: "",
    clave: "",
}

/* COMPONENTE */
export default function Login() {
    /* definiciones */
    const contenedorRef = React.useRef<HTMLDivElement>(null);
    const btnAccederRef = React.useRef<HTMLButtonElement>(null);
    const { setItem } = useLocalStorage();
    const navigate = useNavigate();
    const { reset, getValues, register, handleSubmit } = useForm<LoginFormType>({
        mode: "onTouched",
        defaultValues: modelInitForm,
        resolver: yupResolver(schemaValidacion),
    });    

    ObtenerJWT_old();

    const [isLogging, setIsLogging] = useState<boolean>(false);
    const [mensajeAviso, setMensajeAviso] = useState<string>(" ");
    const [colorAvisoCSS, setColorAvisoCSS] = useState<string>("");

    const useMutationAutentificar = useMutation((userData: LoginFormType) => 
        Autentificar(userData.login, userData.clave), {
            onMutate(datosLogin : LoginFormType) {
                setIsLogging(true);
                setColorAvisoCSS("text-secondary");
                setMensajeAviso("comprobando credenciales...");
            },
            onSuccess: (datosUsuario : IUsuarioSesion) => {
                if (datosUsuario.logueado) {
                    setItem(VAR_SESION_USUARIO, JSON.stringify(datosUsuario));
                    setMensajeAviso("");
                    setColorAvisoCSS("text-secondary");
                    navigate(RUTA_ADMIN);
                    reset();
                } else {
                    setColorAvisoCSS("text-danger");
                    setMensajeAviso(datosUsuario.mensaje?? " ");                    
                }
                setIsLogging(false);
            },
            onError: (error: any) => {
                console.log("errorLogin:", error);
                setIsLogging(false);
                setMensajeAviso(error.message?? " ");
            },
        }
    );

    /* funciones */
    const onClickAcceder = () => {
        useMutationAutentificar.mutate(getValues());
    };


    function onKeyDownInput(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        if (e.key === 'Enter') {
            btnAccederRef.current?.click();
        } else setMensajeAviso(" ");
    }

    /* renderizado */
    return (
        <Container fluid className="px-0 text-center" ref={contenedorRef} style={{height: "100%"}}>
            <Row className="align-items-center justify-content-center" style={{height: "100%"}}>
                <Col sm={12} className="align-self-center">
                    <Container style={{width:250}}>
                        <h1 className="mb-3"><img id="logoH1" src={RutaLogoFREMAP} alt="Logo FREMAP Trazabilidad" /></h1>
                        <Form>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="txtUsuario">
                                    <span className="material-symbols-outlined">person</span>
                                </InputGroup.Text>
                                <Form.Control  {...register("login")}
                                    placeholder="Usuario"
                                    aria-label="Usuario"
                                    aria-describedby="txtUsuario"
                                    onKeyDown={(e) => onKeyDownInput(e) }
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="txtClave">
                                    <span className="material-symbols-outlined">key</span>
                                </InputGroup.Text>
                                <Form.Control
                                    type="password" {...register("clave")}
                                    placeholder="Clave"
                                    aria-label="Clave"
                                    aria-describedby="txtClave"
                                    onKeyDown={(e) => onKeyDownInput(e) }
                                />
                            </InputGroup>
                            <Button type="button" variant="danger" disabled={isLogging} ref={btnAccederRef}
                                onClick={handleSubmit(onClickAcceder)}>
                                <div className="d-flex align-items-center">
                                    acceder&nbsp;
                                    <span className="material-symbols-outlined">login</span>
                                </div>
                            </Button>
                            <h6 className={`pt-3 ${colorAvisoCSS}`} style={{height:30}}>{mensajeAviso}</h6>
                        </Form>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}
