/* DEPENDENCIAS */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery,useQueryClient } from '@tanstack/react-query';

import PanelError from '../../../componentes-com/PanelError';
import PanelCargando from '../../../componentes-com/PanelCargando';
import HabitacionEdicion from './HabitacionEdicion';
import { habitacionModelDefault } from './Habitaciones';
import { queryObtenerHabitacion } from '../../../util/querys/HabitacionesQuerys';

/* DECLARACIONES */ 

/* COMPONENTE */
export default function HabitacionSwitch() {
    /* definiciones */
    const queryClient = useQueryClient();
    const routerParams = useParams();
    const id : number = Number(routerParams?.ID);
    const esEdicion : boolean = !!id;
    const  { data, isLoading, isError } = useObtenerHabitacion(id);
    
    /* funciones */
    function useObtenerHabitacion(id: number) {
        return useQuery(queryObtenerHabitacion(id, queryClient)); 
    } 
    
    /* renderizado */
    if (isError) <PanelError mensaje={"Error cargando los datos de la habitación"} />
    if (isLoading) <PanelCargando />
    return <HabitacionEdicion datos={data?? habitacionModelDefault} esEdicion={esEdicion} />;
}