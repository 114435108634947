/* dependencias */
import axios from "axios";
import { ObtenerJWT } from "../util/tsx-ayuda/Funciones";

/* definiciones */
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const clienteAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',  
    }
});

clienteAxios.interceptors.request.use(
    async (config) => {
        const jwt = await ObtenerJWT();
        config.headers['Authorization'] = `Bearer ${jwt}`;
        return config;            
    },
    error => {
        return Promise.reject(error);
    }
);