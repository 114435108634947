/* dependencias */
import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

import Notificacion, { ETipoNotificacion, InfoNotificacion } from '../../../componentes-com/Notificacion';
import { IProducto, IProductoLote, productoLoteDefault, productoModelDefault } from '../../../tipos/ProductosType';
import { ILote, loteModelDefault } from '../../../tipos/LotesType';
import { PLANTILLA_PRODUCTO_ID } from '../../../util/tsx-ayuda/Constantes';
import { queryObtenerLote, queryObtenerLoteGeneralProducto } from '../../../util/querys/LotesQuerys';
import { queryObtenerProducto } from '../../../util/querys/ProductosQuerys';
import PanelCargando from '../../../componentes-com/PanelCargando';
import LoteEstadoModal from '../lotes/LoteEstadoModal';


/* declaraciones */
const infoNotificacionDefault : InfoNotificacion = {
    tipo: ETipoNotificacion.INFO,
    titulo: "",
    fecha: "",
    mensaje: ""
}

/* componente */
export default function QRRevisarLote() { 
    /* definiciones */
    const queryClient = useQueryClient();
    const [textoInformacion, setTextoInformacion] = useState<string>("Por favor, lea la etiqueta con el scanner");
    const [inputQRValue, setInputQRValue] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    let datosProductoLoteFetch : IProductoLote = productoLoteDefault
    const [datosProducto, setDatosProducto] = useState<IProducto>(productoModelDefault);
    const [datosLote, setDatosLote] = useState<ILote>(loteModelDefault);
    
    const [visibleModalEstadoLote, setVisibleModalEstadoLote] = useState(false);
    

    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const inputQRRef = React.useRef<HTMLInputElement>(null);
    
    /* funciones */ 
    const ObtenerInformacionProducto = async (id:number) => {
        try {
            setTextoInformacion("Cargando datos del producto...");
            setIsLoading(true);
            const dataFetchProducto = await queryClient.fetchQuery(queryObtenerProducto(id));
            setDatosProducto(dataFetchProducto);
            if (dataFetchProducto != null) {
                datosProductoLoteFetch = {
                    ...productoLoteDefault,
                    ...dataFetchProducto,
                };
            }
            else {
                //lanzamos aviso...
                setInfoNotificacion({
                    ...infoNotificacion,
                    tipo: ETipoNotificacion.EXITO,
                    titulo: "Estado de producto/lote",
                    fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                    mensaje: "<span>No se encuentra el <strong>producto</strong> leido a través de QR para la salida de mercancía.</span>"
                });
                setVisibleNotificacion(true);
            }
          } catch (e:any) {
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Estado de producto/lote",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error al cargar los datos del <strong>producto</strong> leido a través de QR para la salida de mercancía:${e.message}</span>`
            });
            setVisibleNotificacion(true);
          }
          setIsLoading(false);
    }
 
    const ObtenerInformacionLote = async (id:number) => {
        try {
            setTextoInformacion("Cargando datos del lote...");
            setIsLoading(true);
            if (id > 0) {
                const dataFetchLote = await queryClient.fetchQuery(queryObtenerLote(id));
                if (dataFetchLote != null) {
                    setDatosLote({
                        ...loteModelDefault,
                        ...dataFetchLote,
                        producto: datosProductoLoteFetch,
                    });
                }
                else {
                    //lanzamos aviso...
                    setInfoNotificacion({
                        ...infoNotificacion,
                        tipo: ETipoNotificacion.EXITO,
                        titulo: "Estado de producto/lote",
                        fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                        mensaje: "<span>No se encuentra el <strong>lote</strong> leido a través de QR para la salida de mercancía.</span>"
                    });
                    setVisibleNotificacion(true);
                }
            } else {
                //solución a cagada de etiquetas con idLote=0 (03/10/2023)
                const dataFetchLote = await queryClient.fetchQuery(queryObtenerLoteGeneralProducto(datosProducto?.id?? null));
                if (dataFetchLote != null) {
                    setDatosLote({
                        ...loteModelDefault,
                        ...dataFetchLote,
                        producto: datosProductoLoteFetch,
                    });
                }
                else {
                    //lanzamos aviso...
                    setInfoNotificacion({
                        ...infoNotificacion,
                        tipo: ETipoNotificacion.EXITO,
                        titulo: "Estado de producto/lote",
                        fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                        mensaje: "<span>No se encuentra el <strong>lote</strong> leido a través de QR para la salida de mercancía.</span>"
                    });
                    setVisibleNotificacion(true);
                }
            }
          } catch (e:any) {
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Estado de producto/lote",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error al cargar los datos del <strong>lote</strong> leido a través de QR para la salida de mercancía:${e.message}</span>`
            });
            setVisibleNotificacion(true);
          }
          setIsLoading(false);
    }
    
    const onClickCancelar = () => {
        setVisibleModalEstadoLote(false);
        datosProductoLoteFetch = productoLoteDefault;
        setDatosProducto(productoModelDefault);
        setDatosLote(loteModelDefault);
        setInputQRValue("");
    }

    /*const detectarKeyDown = (ev: KeyboardEvent) => {
        if (inputQRRef.current) {
            inputQRRef.current.value += ev.key;
        }
    }*/
    

    /* efectos */
    React.useEffect(() => {
        inputQRRef.current?.focus();
        //document.addEventListener('keydown', detectarKeyDown, true);
    }, []);

    React.useEffect(() => {
        const onChangeInputQR = async () => {
            let cadenaEntrada = inputQRValue;
            let arrayQR : string[];
            if (cadenaEntrada.slice(0,5) === "[BOF]" && cadenaEntrada.slice(cadenaEntrada.length-5) === "[EOF]") {
                cadenaEntrada = cadenaEntrada.replace("[BOF]","");
                cadenaEntrada = cadenaEntrada.replace("[EOF]","");
                cadenaEntrada = cadenaEntrada.trim();
                arrayQR = cadenaEntrada.split('|');
                if (parseInt(arrayQR[0]) !== PLANTILLA_PRODUCTO_ID) {
                    setInputQRValue("");
    
                    //lanzamos aviso...
                    setInfoNotificacion({
                        ...infoNotificacion,
                        tipo: ETipoNotificacion.EXITO,
                        titulo: "Agregar salida de mercancía",
                        fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                        mensaje: "<span>La <strong>etiqueta</strong> leida a través de QR no se corresponde con una etiqueta de producto.</span>"
                    });
                    setVisibleNotificacion(true);
                } else {
                    await ObtenerInformacionProducto(parseInt(arrayQR[1]));
                    await ObtenerInformacionLote(parseInt(arrayQR[2]));
                    setTextoInformacion("Por favor, lea la etiqueta con el scanner");
                    setVisibleModalEstadoLote(true);
                }
            } else setInputQRValue("");
        }

        if (inputQRValue.slice(0,5) === "[BOF]" && inputQRValue.slice(inputQRValue.length-5) === "[EOF]")
            onChangeInputQR();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputQRValue]);

    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid" ref={formCabeceraRef} style={{height:"100%"}}>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay p-3 m-0" 
                ref={contenidoFormularioRef} style={{height:"100%"}}>
                <h5 className='text-center'>ESTADO DE PRODUCTO/LOTE CON [QR]</h5>
                <Container fluid className="text-center">
                    <Row className="align-items-center">
                        <Col sm={12} className="align-self-center">
                            <span className="material-symbols-outlined" style={{fontSize:"5rem"}}>qr_code_2_add</span>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col sm={12} className="align-self-center">
                        <p className="lead">                            
                            { isLoading && (<PanelCargando texto={textoInformacion} />)}
                            { !isLoading && textoInformacion}
                        </p>
                        <Form.Control type="text" ref={inputQRRef}
                            value={inputQRValue} onChange={(e)=> setInputQRValue(e.currentTarget.value)}
                        />
                        </Col>
                    </Row>
                </Container>                
                <LoteEstadoModal
                    datosProducto={datosProducto}
                    datosLote={datosLote}
                    mostrarModal={visibleModalEstadoLote}
                    handlerCancelar={onClickCancelar}
                />
                <Notificacion
                    tipo={infoNotificacion.tipo}
                    titulo={infoNotificacion.titulo}
                    fecha={infoNotificacion.fecha}
                    mensaje={infoNotificacion.mensaje}
                    mostrar={visibleNotificacion}
                    setMostrar={setVisibleNotificacion} />
            </aside>
        </section>
    );
};

