/* RUTAS GENÉRICAS */
export const RUTA_RAIZ = "/";
export const RUTA_LOGIN = "/login";
export const RUTA_ADMIN = "/admin";
export const RUTA_COMODIN = "*";

/* RUTAS HOSPITAL */
export const RUTA_HABITACIONES = "/admin/habitaciones";
export const RUTA_HABITACIONES_NUEVO = "/admin/habitaciones/nuevo";
export const RUTA_HABITACIONES_EDICION = "/admin/habitaciones/edicion/";
export const RUTA_HABITACIONES_EDICION_ROUTER = "/admin/habitaciones/edicion/:ID";
export const RUTA_HABITACIONES_IMPRIMIR_ETIQUETAS = "/admin/habitaciones/imprimir_etiquetas";

/* RUTAS PRODUCTOS */
export const RUTA_PRODUCTOS = "/admin/productos";
export const RUTA_PRODUCTOS_NUEVO = "/admin/productos/nuevo";
export const RUTA_PRODUCTOS_EDICION = "/admin/productos/edicion/";
export const RUTA_PRODUCTOS_EDICION_ROUTER = "/admin/productos/edicion/:ID";
export const RUTA_LOTES = "/admin/lotes";
export const RUTA_CADUCIDADES = "/admin/caducidades";
export const RUTA_STOCKSMINIMOS = "/admin/stocksminimos";
export const RUTA_ENTRADAS = "/admin/entradas";
export const RUTA_ENTRADAS_NUEVO = "/admin/entradas/nueva";
export const RUTA_ENTRADAS_EDICION = "/admin/entradas/edicion/";
export const RUTA_ENTRADAS_EDICION_ROUTER = "/admin/entradas/edicion/:ID";
export const RUTA_SALIDAS = "/admin/salidas/";
export const RUTA_SALIDAS_EDICION = "/admin/salidas/:salidaID";
export const RUTA_QR_SALIDA = "/admin/qr-salida";
export const RUTA_QR_ESTADO_LOTE = "/admin/qr-estadolote";

/* RUTAS IMPRESIONES */
export const RUTA_IMPRESORAS = "/admin/impresoras";
export const RUTA_PLANTILLAS_IMPRESION = "/admin/plantillas_impresion";
export const RUTA_COLA_IMPRESION = "/admin/colaimpresion";
export const RUTA_IMPRIMIR_ETIQUETA_LABORATORIO = "/admin/etiquetas/laboratorio";

/* RUTAS INFORMES */
export const RUTA_INFORME_CONSUMOS = "/admin/informe_consumos";
export const RUTA_INFORME_TRAZABILIDAD = "/admin/informe_trazabilidad";

/* RUTAS CONFIGURACIÓN */
export const RUTA_FAMILIAS = "/admin/familias";
export const RUTA_TIPOS_UNIDADES = "/admin/tipos_unidades";
export const RUTA_SECCIONES = "/admin/secciones";
export const RUTA_PROVEEDORES = "/admin/proovedores";