/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import CamasEtiquetasInstance from "./CamasEtiquetasInstance";
import { ICama } from "../../../tipos/HabitacionesType";
import { queryListarCamasHospital } from "../../../util/querys/HabitacionesQuerys";
import { IEtiquetaHabitacion } from "../../../tipos/ColaImpresionType";

/* DEFINICIONES */
type CamasEtiquetasQueryProps = {
  arrayEtiquetas: IEtiquetaHabitacion[],
  cargarListaImpresiones: (_listaImpresiones: IEtiquetaHabitacion[]) => void,
  onChangeTextoDieta1: (idCama:number, idHabitacion:number, textoDieta1:string) => void,
  onChangeTextoDieta2: (idCama:number, idHabitacion:number, textoDieta2:string) => void,
  onChangeCopias: (idCama:number, idHabitacion:number, copias:number) => void
}

/* COMPONENTE */
const CamasEtiquetasQuery = ({
    arrayEtiquetas, cargarListaImpresiones,
    onChangeTextoDieta1, onChangeTextoDieta2, onChangeCopias
  } : CamasEtiquetasQueryProps) => {
    /* definiciones */
    const  { data, isFetching, isLoading, error, isError } = useQuery<ICama[], Error>(queryListarCamasHospital());
             
    

    /* funciones */

    /* efectos */
    React.useEffect(() => {
      if (!isFetching && data) {
        let listadoEtiquetas : IEtiquetaHabitacion[] = data.map((datosItem) => {
          let nuevaEtiqueta : IEtiquetaHabitacion = {
              plantilla: null,
              idImpresora: 0,
              fecha: new Date(),
              cama: datosItem,
              copias:1,
              textoDieta1: "",
              textoDieta2: "",
          };
          return nuevaEtiqueta;
        });
        cargarListaImpresiones(listadoEtiquetas);
      }
  }, [cargarListaImpresiones, data, isFetching]);

    /* renderizado */    
    return (
      <CamasEtiquetasInstance tableData={data?? []}
        arrayEtiquetas={arrayEtiquetas}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        onChangeTextoDieta1={onChangeTextoDieta1}
        onChangeTextoDieta2={onChangeTextoDieta2}
        onChangeCopias={onChangeCopias}
      />
    );
  }
  export default CamasEtiquetasQuery;