/* DEPENDENCIAS */
import { useInfiniteQuery } from "@tanstack/react-query";
import ColaImpresionInstance from "./ColaImpresionInstance";
import { IFiltrosImpresion, IImpresion } from "../../../tipos/ColaImpresionType";
import React from "react";
import { queryListarColaImpresionPaginada } from "../../../util/querys/ImpresionesQuerys";

/* DEFINICIONES */
type IColaImpresionQueryProps = {
  filtrosQuery : IFiltrosImpresion,
  pintarMasFilas : boolean,
  handlerVer : (datosModelo: IImpresion) => void,
}

/* COMPONENTE */
const ColaImpresionQuery = ({filtrosQuery, pintarMasFilas, handlerVer} : IColaImpresionQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useListarColaImpresion(filtrosQuery);
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
    
    /* funciones */
    function useListarColaImpresion(filtrosQuery: IFiltrosImpresion) {
        return useInfiniteQuery<IImpresion[], Error>(queryListarColaImpresionPaginada(filtrosQuery));
    }        
    
    /* renderizado */    
    return (
      <ColaImpresionInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerVer={handlerVer}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default ColaImpresionQuery;