/* DEPENDENCIAS */
import { clienteAxios } from "./APIAxiosInstancia";
import { URL_API_USUARIOS, URL_API_USUARIOS_AUTENTIFICAR, URL_API_USUARIOS_PAGINACION, URL_API_USUARIOS_PERFILES } from "./Endpoints";
import { IAPICambiarClave, IFiltrosUsuarios, IPerfil, IUsuario, IUsuarioSesion, modelUsuarioSesionDefault } from "../tipos/UsuariosType";

/* API */
/**
 * Función que obtiene el listado de usuarios.
 */
export const ListarUsuarios = async (filtros: IFiltrosUsuarios | undefined): Promise<IUsuario[]> => {
    try {     
        const respuesta = filtros? 
            await clienteAxios.get<IUsuario[]>(
                URL_API_USUARIOS,
                { params: {filtro: filtros}},
            ) :
            await clienteAxios.get<IUsuario[]>(URL_API_USUARIOS);
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene el listado de perfiles.
 */
export const ListarPerfiles = async (): Promise<IPerfil[]> => {
    try {     
        const respuesta = await clienteAxios.get<IPerfil[]>(URL_API_USUARIOS_PERFILES);
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene el listado de usuarios con paginación.
 */
export const ListarUsuariosPaginacion = async (pagina:number, filas:number, filtros: IFiltrosUsuarios | undefined): Promise<IUsuario[]> => {
    try {     
        const respuesta = filtros?
            await clienteAxios.get<IUsuario[]>(
                URL_API_USUARIOS_PAGINACION,
                { params: {
                    filtro: filtros, 
                    numeroPagina: pagina,
                    tamanhoPagina: filas,
                }}
            ) :
            await clienteAxios.get<IUsuario[]>(URL_API_USUARIOS_PAGINACION,
                { params: {
                    numeroPagina: pagina,
                    tamanhoPagina: filas,
                }});
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que guarda los datos de un nuevo usuario.
 */
export const AgregarUsuario = async (datos : IUsuario): Promise<IUsuario> => {
    try {     
        const respuesta = await clienteAxios.post(
            URL_API_USUARIOS, datos
        );
        return respuesta.data;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al agregar un usuario.");
    }
}

/**
 * Función que guarda los datos de edición de un usuario.
 */
export const EditarUsuario = async (datos : IUsuario): Promise<void> => {
    try {     
        await clienteAxios.put(URL_API_USUARIOS + `/${datos.id}`, datos);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al editar un usuario.");
    }
}

/**
 * Función que actualiza la contraseña de un usuario.
 */
export const CambiarClave = async (datos : IAPICambiarClave): Promise<void> => {
    try {     
        await clienteAxios.put(URL_API_USUARIOS + `/cambiarclave/${datos.id}`, datos);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al cambiar la contraseña de usuario.");
    }
}

/**
 * Función que elimina los datos de un usuario.
 */
export const EliminarUsuario = async (id : number): Promise<void> => {
    try {     
        await clienteAxios.delete(URL_API_USUARIOS + `/${id}`);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al eliminar un usuario.");
    }
}

/**
 * Función que obtiene el listado de usuarios.
 */
export const Autentificar = async (login: string, clave: string): Promise<IUsuarioSesion> => {
    try {     
        const respuesta = await clienteAxios.get<IUsuarioSesion>(
                URL_API_USUARIOS_AUTENTIFICAR,
                { params: {login: login, clave: clave} }
            );
        return respuesta.data;
    } catch(error: any) {
        let  datosUsuario = modelUsuarioSesionDefault;
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            datosUsuario.status = error.response.status;
            datosUsuario.mensaje = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            console.log("response:", error);
            datosUsuario.status = 500;
            datosUsuario.mensaje = "El servidor no responde por algún motivo. Disculpen las molestias.";
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            datosUsuario.status = 500;
            datosUsuario.mensaje = "La petición no se pudo realizar por un motivo desconocido. Disculpen las molestias.";
        }
        console.log("config:", error.config);
        return datosUsuario;
    }
}
