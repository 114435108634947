/* dependencias */
import { useInfiniteQuery } from "@tanstack/react-query";
import TiposUnidadesInstance from "./TiposUnidadesInstance";
import { ITipoUnidad } from "../../../tipos/TiposUnidadesType";
import * as APITiposUnidades from '../../../servicios/APITiposUnidades';
import React from "react";

/* DEFINICIONES */
type ITiposUnidadesQueryProps = {
  filtroQuery : string | undefined,
  pintarMasFilas : boolean,
  handlerEditar : (datosModelo: ITipoUnidad) => void,
  handlerEliminar : (datosModelo : ITipoUnidad) => void,
}

/* COMPONENTE */
const TiposUnidadesQuery = ({filtroQuery, pintarMasFilas, handlerEditar, handlerEliminar} : ITiposUnidadesQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useListarTiposUnidades(filtroQuery);
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
    
    /* funciones */
    function useListarTiposUnidades(filtroQuery: string | undefined) {
        return useInfiniteQuery<ITipoUnidad[], Error>({
          queryKey: ['listadoTiposUnidades', filtroQuery],
          queryFn: ({ pageParam = 0 }) => APITiposUnidades.ListarTiposUnidadesPaginacion(pageParam, 20, filtroQuery),
          getNextPageParam: (lastPage, allPages) => allPages.length,
          keepPreviousData: true,
          refetchOnWindowFocus: false,
        });
    }        
    
    /* renderizado */    
    return (
      <TiposUnidadesInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerEditar={handlerEditar}
        handlerEliminar={handlerEliminar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default TiposUnidadesQuery;