/* DEPENDENCIAS */
import React, { Fragment } from "react";
import { ColumnDef, SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import {TiArrowUnsorted} from "react-icons/ti";

import LotesTable from "./LotesTable";
import { ILote } from "../../../tipos/LotesType";

/* DEFINICIONES */
type LotesInstanceSeleccionarProps = {
  tableData: ILote[],
  isError: boolean,
  error: Error | null,
  isLoading: boolean,
  isFetching: boolean,
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<ILote[], Error>>,
  pintarMasFilas : boolean,
  handlerSeleccionar : (datosModelo: ILote) => void
}

/* COMPONENTE */
const LotesInstanceSeleccionar = ({ 
    tableData, 
    isError, error, isLoading, 
    isFetching, fetchNextPage,
    pintarMasFilas,
    handlerSeleccionar
  }: LotesInstanceSeleccionarProps) => {
    /* definiciones */
    const [sorting, setSorting] = React.useState<SortingState>([{
      id: "codigo",
      desc: false
    }]); 
    const columnsDef: ColumnDef<ILote>[] = [
      {
        accessorKey: "codigo",
        header: () => {
          return(
            <div className="d-flex cursor-pointer">
              <span>Código&nbsp;</span>
              <TiArrowUnsorted style={{ marginTop:2 }} />
            </div>
          );
        },
        size: 120,
        cell: info => info.getValue(),
        meta: {
          className: "align-middle",
        }
      },
      {
        accessorKey: "caducidad",
        header: 'Caducidad',
        size: 120,
        cell: info => (info.row.original.caducidad)? format(new Date(info.row.original.caducidad), "dd/MM/yyyy") : "-",
        meta: {
          className: "align-middle text-truncate",
          style: {maxWidth:120}
        }
      },
      {
        accessorKey: "consumoPreferente",
        header: 'Preferente',
        size: 120,
        cell: info =>  (info.row.original.consumoPreferente)? format(new Date(info.row.original.consumoPreferente), "dd/MM/yyyy") : "-",
        meta: {
          className: "align-middle text-truncate",
          style: {maxWidth:120}
        }
      },
      {
        accessorKey: "stock",
        header: 'Stock',
        size: 100,
        cell: info => info.getValue(),
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center",
        }
      },
      {
        id: 'opciones',
        header: '',
        size: 50,
        cell: (info) => {
          const datos = info.row.original;
          return (
          <Fragment>
            <Button type="button" variant="primary" size="sm" className="me-1" title="seleccionar lote"
              onClick={ () => { handlerSeleccionar(datos); } }>
              <div className="d-flex">
                  <span className="material-symbols-outlined">task_alt</span>
              </div>
            </Button>
          </Fragment>);
        }, 
        meta: {
          className: "align-middle",
        }      
      },
    ];

    const tableInstance = useReactTable({ 
      data: tableData, 
      columns: columnsDef,
      state: {
        sorting,
      },
      enableSortingRemoval: false,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });
  
    /* renderizado */
    return (
      <LotesTable instanciaTabla={tableInstance}
        isError={isError} error={error} isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        pintarMasFilas={pintarMasFilas} />
    );
  }
  export default LotesInstanceSeleccionar;