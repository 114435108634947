/* DEPENDENCIAS */
import { createBrowserRouter } from 'react-router-dom';
import App from '../paginas/App';
import Error from '../paginas/Error';
import * as Rutas from './Rutas'
import FamiliasProductos from "../paginas/panelcontrol/familiasproductos/FamiliasProductos";
import TiposUnidades from "../paginas/panelcontrol/tiposunidades/TiposUnidades";
import SeccionesAlmacen from '../paginas/panelcontrol/seccionesalmacen/SeccionesAlmacen';
import Proveedores from '../paginas/panelcontrol/proveedores/Proveedores';
import Impresoras from '../paginas/panelcontrol/impresoras/Impresoras';
import PlantillasImpresion from '../paginas/panelcontrol/plantillasimpresion/PlantillasImpresion';
import ColaImpresion from '../paginas/panelcontrol/colaimpresion/ColaImpresion';
import Productos from '../paginas/panelcontrol/productos/Productos';
import ProductoSwitch from '../paginas/panelcontrol/productos/ProductoSwitch';
import Habitaciones from '../paginas/panelcontrol/habitaciones/Habitaciones';
import HabitacionSwitch from '../paginas/panelcontrol/habitaciones/HabitacionSwitch';
import RecepcionesMercancia from '../paginas/panelcontrol/recepcionesmercancia/RecepcionesMercancia';
import RecepcionMercanciaSwitch from '../paginas/panelcontrol/recepcionesmercancia/RecepcionMercanciaSwitch';
import Lotes from '../paginas/panelcontrol/lotes/Lotes';
import Caducidades from '../paginas/panelcontrol/lotes/Caducidades';
import StocksMinimos from '../paginas/panelcontrol/productos/StocksMinimos';
import SalidasMercancia from '../paginas/panelcontrol/salidasmercancia/SalidasMercancia';
import QRSalidaMercancia from '../paginas/panelcontrol/qr/QRSalidaMercancia';
import QRRevisarLote from '../paginas/panelcontrol/qr/QRRevisarLote';
import InformeConsumos from '../paginas/panelcontrol/informes/InformeConsumos';
import InformeTrazabilidad from '../paginas/panelcontrol/informes/InformeTrazabilidad';
import Login from '../paginas/Login';
import { ProtectedRoute } from '../componentes-com/ProtectedRoute';
import EtiquetaLaboratorio from '../paginas/panelcontrol/colaimpresion/EtiquetaLaboratorio';
import HabitacionesEtiquetas from '../paginas/panelcontrol/habitaciones/HabitacionesEtiquetas';

/* COMPONENTE */
const Enrutador = createBrowserRouter([
    {
        path: Rutas.RUTA_RAIZ,
        element: <Login />
    },
    {
        path: Rutas.RUTA_LOGIN,
        element: <Login />
    },
    {
        path: Rutas.RUTA_ADMIN,
        element: <ProtectedRoute><App /></ProtectedRoute>,
        errorElement: <Error />,
        children: [
            {
                path: Rutas.RUTA_QR_SALIDA,
                element: <QRSalidaMercancia />,
            },
            {
                path: Rutas.RUTA_QR_ESTADO_LOTE,
                element: <QRRevisarLote />,
            },
            {
                path: Rutas.RUTA_IMPRIMIR_ETIQUETA_LABORATORIO,
                element: <EtiquetaLaboratorio />,
            },
            {
                path: Rutas.RUTA_HABITACIONES,
                element: <Habitaciones />,
            },
            {
                path: Rutas.RUTA_HABITACIONES_NUEVO,
                element: <HabitacionSwitch />,
            },
            {
                path: Rutas.RUTA_HABITACIONES_EDICION_ROUTER,
                element: <HabitacionSwitch />,
            },
            {
                path: Rutas.RUTA_HABITACIONES_IMPRIMIR_ETIQUETAS,
                element: <HabitacionesEtiquetas />,
            },
            {
                path: Rutas.RUTA_PRODUCTOS,
                element: <Productos />,
            },
            {
                path: Rutas.RUTA_PRODUCTOS_NUEVO,
                element: <ProductoSwitch />,
            },
            {
                path: Rutas.RUTA_PRODUCTOS_EDICION_ROUTER,
                element: <ProductoSwitch />,
            },
            {
                path: Rutas.RUTA_LOTES,
                element: <Lotes />,
            },
            {
                path: Rutas.RUTA_CADUCIDADES,
                element: <Caducidades />,
            },
            {
                path: Rutas.RUTA_STOCKSMINIMOS,
                element: <StocksMinimos />,
            },
            {
                path: Rutas.RUTA_ENTRADAS,
                element: <RecepcionesMercancia />,
            },
            {
                path: Rutas.RUTA_ENTRADAS_NUEVO,
                element: <RecepcionMercanciaSwitch />,
            },
            {
                path: Rutas.RUTA_ENTRADAS_EDICION_ROUTER,
                element: <RecepcionMercanciaSwitch />,
            },
            {
                path: Rutas.RUTA_SALIDAS,
                element: <SalidasMercancia />,
            },
            {
                path: Rutas.RUTA_IMPRESORAS,
                element: <Impresoras />,
            },
            {
                path: Rutas.RUTA_PLANTILLAS_IMPRESION,
                element: <PlantillasImpresion />,
            },
            {
                path: Rutas.RUTA_COLA_IMPRESION,
                element: <ColaImpresion />,
            },
            {
                path: Rutas.RUTA_INFORME_CONSUMOS,
                element: <InformeConsumos />,
            },
            {
                path: Rutas.RUTA_INFORME_TRAZABILIDAD,
                element: <InformeTrazabilidad />,
            },
            {
                path: Rutas.RUTA_PROVEEDORES,
                element: <Proveedores />,
            },
            {
                path: Rutas.RUTA_FAMILIAS,
                element: <FamiliasProductos />,
            },
            {
                path: Rutas.RUTA_SECCIONES,
                element: <SeccionesAlmacen />,
            },
            {
                path: Rutas.RUTA_TIPOS_UNIDADES,
                element: <TiposUnidades />,
            },
        ],
    },
    {
        path: Rutas.RUTA_COMODIN,
        element: <Error />
    }
]);
export default Enrutador;