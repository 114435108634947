/* DEPENDENCIAS */
import { useState } from "react";

/* COMPONENTE (HOOK) */
export const useLocalStorage = () => {
  /* definiciones */
  const [value, setValue] = useState<string | null>(null);

  /* funciones */
  const setItem = (key: string, value: string) => {
    localStorage.setItem(key, value);
    setValue(value);
  };

  const getItem = (key: string) => {
    const value = localStorage.getItem(key);
    setValue(value);
    return value;
  };

  const removeItem = (key: string) => {
    localStorage.removeItem(key);
    setValue(null);
  };

  /* renderizado */
  return { value, setItem, getItem, removeItem };
};