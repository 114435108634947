import { CalcularSubtotal, CalcularSubtotalIVA, ETipoIVAToNumber } from "../util/tsx-ayuda/Funciones"
import { ETipoIVA } from "./CommonTypes"

/**
 * Interfaz que define un objeto de un proveedor en la IU.
 */
interface IProducto {
    id: number,
    idFamilia: number,
    nombreFamilia: string,
    idSeccion: number,
    nombreSeccion: string,
    tipoIVA: ETipoIVA,
    idTipoUnidad: number,
    nombreTipoUnidad: string,
    abreviaturaTipoUnidad: string,
    nombre: string,
    nombreCorto: string | null,
    codigo: string | null,
    codigoEAN: string | null,
    modelo: string | null,
    stockMinimo: number,
    stock: number,
    precio? : number,
    usaControlLotes: boolean,
    esBaja: boolean,

    getIVA: (tipoIVA: ETipoIVA) => number,
    getSubtotalNeto: (cantidad:number, precio:number) => number,
    getSubtotalBruto: (cantidad:number, precio:number, iva:number) => number,
 }


 
const productoModelDefault : IProducto = {
   id: 0,
   idFamilia: 0,
   nombreFamilia: "",
   idSeccion: 0,
   nombreSeccion: "",
   tipoIVA: ETipoIVA.GENERAL,
   idTipoUnidad: 0,
   nombreTipoUnidad: "",
   abreviaturaTipoUnidad: "",
   nombre: "",
   nombreCorto: null,
   codigo: null,
   codigoEAN: null,
   modelo: null,
   stockMinimo: 1,
   stock: 0,
   precio: undefined,
   usaControlLotes: false,
   esBaja: false,

   getIVA: ETipoIVAToNumber,
   getSubtotalNeto: CalcularSubtotal,
   getSubtotalBruto: CalcularSubtotalIVA,
}

 interface IFiltrosProductos {
    IDFamilia?: number | null,
    IDSeccion?: number | null,
    Texto?: string | null,
    CodigoEAN?: string | null,
    UsaControlLotes?: boolean | null,
    stockConsumido?: boolean | null,
    stockBajoMinimos?: boolean | null,
    distanciaStockMinimo?: number,
    esBaja?: boolean | null,
 }

const filtrosProductoDefault : IFiltrosProductos = {
   IDFamilia: null,
   IDSeccion: null,
   Texto: null,
   CodigoEAN: null,
   UsaControlLotes: undefined,
   stockConsumido: false,
   esBaja: false,
}

const filtrosStocksMInimosDefault : IFiltrosProductos = {
   IDFamilia: null,
   IDSeccion: null,
   Texto: null,
   CodigoEAN: null,
   stockConsumido: false,
   stockBajoMinimos: false,
   esBaja: false,
}

/**
 * Interfaz que define un objeto de un proveedor en la IU.
 */
interface IProductoLote {
   nombreTipoUnidad: string,
   abreviaturaTipoUnidad: string,
   nombre: string,
   nombreCorto: string | null,
   codigo: string | null,
   codigoEAN: string | null,
   stockMinimo: number,
   precio? : number,
   usaControlLotes: boolean,
   esBaja: boolean,
}

const productoLoteDefault : IProductoLote = {
   nombreTipoUnidad: "",
   abreviaturaTipoUnidad: "",
   nombre: "",
   nombreCorto: null,
   codigo: null,
   codigoEAN: null,
   stockMinimo: 1,
   precio: undefined,
   usaControlLotes: false,
   esBaja: false,
}

/**
 * Interfaz que define un objeto de la información de los stocks en la IU.
 */
interface IResumenStocks {
   abiertos: number,
   consumidos: number,
   stocksMinimos: number,
   stocksRevisables: number,
}

const stocksMinimosModelDefault : IResumenStocks = {
   abiertos: 0,
   consumidos: 0,
   stocksMinimos: 0,
   stocksRevisables: 0,
}

/* EXPORTS */
export type { 
   IProducto, IFiltrosProductos,
   IProductoLote,
   IResumenStocks 
}

export {
   productoModelDefault,
   productoLoteDefault,
   filtrosProductoDefault, filtrosStocksMInimosDefault,
   stocksMinimosModelDefault
}