/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import useResizeObserver from '../../../hooks/useResizeObserver';
import { IFiltrosProductos, IProducto, filtrosProductoDefault } from '../../../tipos/ProductosType';
import * as APIFamilias from '../../../servicios/APIFamiliasProductos';
import * as APISecciones from '../../../servicios/APISeccionesAlmacen';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { IFamiliaProducto } from '../../../tipos/FamiliasProductosType';
import { ISeccionAlmacen } from '../../../tipos/SeccionesAlmacenType';
import ProductosQuerySeleccionar from './ProductosQuerySeleccionar';


/* DECLARACIONES */
type ProductosSeleccionModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    seleccionarProducto: (datosModelo: IProducto) => void
}


/* componente */
export default function ProductosSeleccionModal({mostrarModal, setMostrarModal, seleccionarProducto} : ProductosSeleccionModalProps) { 
    /* definiciones */
    const [filtros, setFiltros] = useState<IFiltrosProductos>(filtrosProductoDefault);
    const [opcionesFamilias, setOpcionesFamilias] = useState<IFamiliaProducto[]>([]);
    const [opcionesSecciones, setOpcionesSecciones] = useState<ISeccionAlmacen[]>([]);
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    const { register, reset, getValues, handleSubmit } = useForm<IFiltrosProductos>({
        defaultValues: filtrosProductoDefault,
    });

    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
       
    const handlerBuscar = () => {
        let filtrosForm : IFiltrosProductos = {
            IDFamilia: ((getValues().IDFamilia?? -1) > 0)? getValues().IDFamilia : null,
            IDSeccion: (getValues().IDSeccion?? -1)? getValues().IDSeccion : null,
            Texto: (getValues().Texto !== "")? getValues().Texto : null,
            CodigoEAN: (getValues().CodigoEAN !== "")? getValues().CodigoEAN : null,
            UsaControlLotes: getValues().UsaControlLotes,
            stockConsumido: getValues().stockConsumido
        }
        setFiltros(filtrosForm);
    };

    const handlerResetFiltros = () => {
        reset(filtrosProductoDefault);
        setFiltros(filtrosProductoDefault);
    };

    const handlerSeleccionar = (datosModelo : IProducto) => {
        seleccionarProducto(datosModelo);
        setMostrarModal(!mostrarModal);
    }
    
    async function ObtenerListadoFamilias() {
        const listaFamilias = await APIFamilias.ListarFamiliasProductos(undefined);
        setOpcionesFamilias(
            [
                ...[{id: null, nombre: "filtrar por familia..."}],
                ...listaFamilias
            ]);
    }
 
    async function ObtenerListadoSecciones() {
        const listaSecciones = await APISecciones.ListarSeccionesAlmacen(undefined);
        setOpcionesSecciones(
            [
                ...[{id: null, nombre: "filtrar por sección de almacén..."}],
                ...listaSecciones
            ]);
    }


    /* efectos */
    React.useEffect(() => {
        ObtenerListadoFamilias();
        ObtenerListadoSecciones(); 
    }, []);

    React.useEffect(() => {   
        if (mostrarModal) {
            const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
            setHeightListado(heightCalc);
        }
    }, [contenedorCabeceraRef, mostrarModal]);
    
    const fetchMas = React.useCallback((containerRefElement?: HTMLDivElement | null) => {
        if (containerRefElement) {
            const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
            setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
        }
    }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>Seleccionar producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <section id="form-cabecera" className="content-fluid px-0 mb-2" style={{height: "100%"}} ref={formCabeceraRef}>
                <div className="row g-sm-2" ref={contenedorCabeceraRef}>
                    <div className="col-lg-6 col-md-12">
                        <select className="form-select" aria-label="Familias" {...register("IDFamilia")}>
                            {opcionesFamilias.map((option) => {
                            return (
                                <option key={option.id?? ""} value={option.id?? ""}>{option.nombre}</option>
                            );
                            })}
                        </select>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <select className="form-select" aria-label="Secciones de almacén" {...register("IDSeccion")}>
                            {opcionesSecciones.map((option) => {
                            return (
                                <option key={option.id?? ""} value={option.id?? ""}>{option.nombre}</option>
                            );
                            })}
                        </select>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <Form.Control type="text" maxLength={300} {...register("Texto")}
                            placeholder="filtrar por nombre, nombre corto..." />
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <Form.Control type="text" maxLength={300} {...register("CodigoEAN")}
                            placeholder="filtrar por codigo EAN..." />
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <FloatingLabel controlId="selControlPorLotes" label="¿control por lotes?">
                            <Form.Select aria-label="¿control por lotes?" {...register("UsaControlLotes")}>
                                <option value="">Todos</option>
                                <option value="true">Si</option>
                                <option value="false">No</option>
                            </Form.Select>
                        </FloatingLabel>
                    </div>   
                    <div className="col-md-4 col-sm-6">
                        <FloatingLabel controlId="selConStock" label="¿con stock?">
                            <Form.Select aria-label="¿con stock?" {...register("stockConsumido")}>
                                <option value="">Todos</option>
                                <option value="false">Si</option>
                                <option value="true">No</option>
                            </Form.Select>
                        </FloatingLabel>
                    </div>                                
                    <div className="col-md-4 col-sm-12 d-flex align-items-end  justify-content-end">
                        <div className="d-flex">
                            <Button variant='link' className="nav-link me-3" onClick={handleSubmit(handlerResetFiltros)}>
                                <div className='d-flex'>
                                        <span className="material-symbols-outlined">filter_alt_off</span>
                                        reiniciar filtros
                                </div> 
                            </Button>
                            <Button variant='info' className='mx-1' onClick={handleSubmit(handlerBuscar)}>
                                <div className='d-flex'>
                                        <span className="material-symbols-outlined">filter_alt</span>
                                        buscar
                                </div> 
                            </Button>
                        </div>
                    </div>
                </div>
                <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                    ref={contenidoFormularioRef} style={{height:heightListado}}
                    onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                    <ProductosQuerySeleccionar 
                        filtrosQuery={filtros} 
                        handlerSeleccionar= {handlerSeleccionar}
                        pintarMasFilas= {pintarMasFilas}
                    />
                </aside>
            </section>
            </Modal.Body>
      </Modal>
    );
};