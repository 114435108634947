/* DEPENDENCIAS */
import React, { useCallback, useState} from 'react';
import {Breadcrumb, Button, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

import * as Rutas from '../../../rutas/Rutas';
import * as APIRecepcionesMercancia from '../../../servicios/APIRecepcionesMercancia';
import * as APIMovimientosStock from '../../../servicios/APIMovimientosStock';
import * as APIImpresiones from '../../../servicios/APIImpresiones';
import useResizeObserver from '../../../hooks/useResizeObserver';
import Notificacion, { ETipoNotificacion, InfoNotificacion } from '../../../componentes-com/Notificacion';
import ConfirmacionModal from '../../../componentes-com/ConfirmacionModal';
import { IEtiquetaProducto, IImpresionInsertable } from '../../../tipos/ColaImpresionType';
import { IRecepcionMercancia, recepcionMercanciaModelDefault } from '../../../tipos/RecepcionesMercanciaType';
import { KEYRecepcionesMercanciaListaPaginada } from '../../../util/querys/RecepcionesMercanciaQuerys';
import { IProveedor } from '../../../tipos/ProveedoresType';
import { queryListarProveedores } from '../../../util/querys/ProveedoresQuerys';
import RecepcionProductoModal from './RecepcionProductoModal';
import { IFiltrosMovimientosStock, IMovimientoStock, IMovimientoStockInsertable, movimientoStockEntradaModelDefault, movimientoStockModelDefault } from '../../../tipos/MovimientosStockType';
import { KEYPMovimientosStockListaPaginada, queryListarMovimientosStock } from '../../../util/querys/MovimientosStockQuerys';
import MovimientosStockQuery from './MovimientosStockQuery';
import { IDocumentoRecepcionUpload } from '../../../tipos/DocumentosType';
import EtiquetaProductoModal from '../colaimpresion/EtiquetaProductoModal';
import { PLANTILLA_PRODUCTO_ID } from '../../../util/tsx-ayuda/Constantes';
import { TienePermiso, TransformarPlantillaProducto } from '../../../util/tsx-ayuda/Funciones';
import { KEYPColaImpresionListaPaginada } from '../../../util/querys/ImpresionesQuerys';
import EtiquetaProductosModal from '../colaimpresion/EtiquetaProductosModal';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { IUsuarioSesion } from '../../../tipos/UsuariosType';
import { EFuncionalidad, VAR_SESION_USUARIO } from '../../../tipos/CommonTypes';

/* DECLARACIONES */  
const schemaValidacion = yup.object().shape({
    idProveedor: yup.number()
        .min(1, "Seleccione un proveedor")
        .required("El campo es obligatorio"),
    referencia: yup.string()
        .required("El campo es obligatorio")
});


type RecepcionMercanciaEdicionProps = {
    datos: IRecepcionMercancia | undefined,
    documentoUpload: IDocumentoRecepcionUpload,
    documentoRecepcion: File | null,
    esEdicion: boolean
}

/* COMPONENTE */
export default function RecepcionMercanciaEdicion({datos, documentoUpload, esEdicion} : RecepcionMercanciaEdicionProps) {
    /* definiciones */
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    
    const [estadoForm, setEstadoForm] = useState<RecepcionMercanciaEdicionProps>({datos, documentoUpload, documentoRecepcion: null, esEdicion});
    const { register, handleSubmit, formState: {errors}, getValues, setValue, reset, watch } = useForm<IRecepcionMercancia>({
        mode: "onSubmit",
        defaultValues: datos?? recepcionMercanciaModelDefault,
        resolver: yupResolver(schemaValidacion),
        reValidateMode: "onChange",
    });
    const [fechaEntradaInput, setFechaEntradaInput] = useState<string>(format(new Date(), "yyyy-MM-dd HH:mm"));
    const  { data: dataProveedores, isLoading: isLoadingProveedores } = useQuery<IProveedor[], Error>(queryListarProveedores(undefined));
    
    const agregarRecepcionMercancia = useAgregarRecepcionMercancia();
    const editarRecepcionMercancia = useEditarRecepcionMercancia();
    const eliminarRecepcionMercancia = useEliminarRecepcionMercancia();
    const [visibleModalConfirmacion, setVisibleModalConfirmacion] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState("");
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const [heightListado, setHeightListado] = useState<number>(0);
    const formCabeceraRef = React.useRef<HTMLFormElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);

    const [filtros, setFiltros] = useState<IFiltrosMovimientosStock>({idRecepcion: estadoForm.datos?.id});
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModalConfirmacionMovimientoStock, setVisibleModalConfirmacionMovimientoStock] = useState(false);
    const [mensajeConfirmacionMovimientoStock, setMensajeConfirmacionMovimientoStock] = useState("");
    const [datosMovimientoStockInsertable, setDatosMovimientoStockInsertable] = useState(movimientoStockEntradaModelDefault);
    const [datosMovimientoStock, setDatosMovimientoStock] = useState<IMovimientoStock>(movimientoStockModelDefault);
    const agregarMovimientoStock = useAgregarMovimientoStock();
    const eliminarMovimientoStock = useEliminarMovimientoStock();

    const guardarDocumento = useGuardarDocumento();
    const obtenerDocumento = useObtenerDocumento();
    const eliminarDocumento = useEliminarDocumento();
    
    const [visibleModalImpresionEtiqueta, setVisibleModalImpresionEtiqueta] = useState(false);
    const [visibleModalImpresionEtiquetas, setVisibleModalImpresionEtiquetas] = useState(false);
    const agregarImpresionEtiquetaProducto = useAgregarImpresionEtiquetaProducto();
    const  { data : listaMovimientoStock, refetch } = useListarMovimientosStock(filtros);
    const [listaMovimientosEtiquetas, setListaMovimientosEtiquetas] = useState<IMovimientoStock[]>(new Array(0));
    
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    
    const infoNotificacionDefault : InfoNotificacion = {
        tipo: ETipoNotificacion.INFO,
        titulo: "",
        fecha: "",
        mensaje: ""
    }
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    const refFicheroUpload = React.useRef<HTMLInputElement>(null);

    const { getItem } = useLocalStorage();
    const [accesoEditar, setAccesoEditar] = useState<boolean>(false);
    const [accesoImprimirProducto, setAccesoImprimirProducto] = useState<boolean>(false);

    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
       
    function useListarMovimientosStock(filtrosQuery: IFiltrosMovimientosStock) {
        return useQuery<IMovimientoStock[], Error>(queryListarMovimientosStock(filtrosQuery));
    }        
    
    const handleCambioFecha = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            setValue("fechaEntrada", new Date(event.target.value));
            setFechaEntradaInput(format(new Date(event.target.value), "yyyy-MM-dd HH:mm"));
            //actualizamos el formulario...
            let estado = estadoForm;
            if (estado.datos)
                estado.datos.fechaEntrada = new Date(event.target.value);
            setEstadoForm(estado);
        }
        else setValue("fechaEntrada", null);
    }

    function useAgregarRecepcionMercancia() {
        return useMutation({
          mutationFn: (datosModelo : IRecepcionMercancia) => {
            return APIRecepcionesMercancia.AgregarRecepcionMercancia(datosModelo);
          },
          onSuccess: (datosRespuesta: IRecepcionMercancia) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYRecepcionesMercanciaListaPaginada]});
            setEstadoForm({
                ...estadoForm,
                datos: datosRespuesta,
                esEdicion: true
            });
            setFiltros({
                ...filtros,
                idRecepcion: datosRespuesta.id
            });
            reset(datosRespuesta);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>entrada de mercancía</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>entrada de mercancía</strong>: <em>${variables.referencia}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    function useEditarRecepcionMercancia() {
        return useMutation({
          mutationFn: (datosModelo : IRecepcionMercancia) => {
            return APIRecepcionesMercancia.EditarRecepcionMercancia(datosModelo);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYRecepcionesMercanciaListaPaginada]});
            setEstadoForm({
                ...estadoForm,
                datos: getValues(),
            });
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Editar entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>entrada de mercancía</strong> se ha editado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Editar entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error editando la <strong>entrada de mercancía</strong>: <em>${variables.referencia}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    function useEliminarRecepcionMercancia() {
        return useMutation({
          mutationFn: (datosModelo : IRecepcionMercancia) => {
            return APIRecepcionesMercancia.EliminarRecepcionMercancia(datosModelo.id);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYRecepcionesMercanciaListaPaginada]});
            reset(recepcionMercanciaModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Eliminar entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>entrada de mercancía</strong> se ha eliminado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Eliminar entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error eliminando la <strong>entrada de mercancía</strong>: <em>${variables.referencia}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
      
    const guardarDatos = (datosModelo : IRecepcionMercancia) => {        
        if (!esEdicion) {
            agregarRecepcionMercancia.mutate(datosModelo);
        } else {
            editarRecepcionMercancia.mutate(datosModelo);
        }
    }
    
    const handlerEliminar = () => {
        setMensajeConfirmacion(`Se va a eliminar la entrada de mercancía: <strong>${getValues().referencia}</strong>`);
        setVisibleModalConfirmacion(true);
    }
    
    const handlerConfirmarEliminar = () => {
        eliminarRecepcionMercancia.mutate(getValues());
    }
      
    function useAgregarMovimientoStock() {
        return useMutation({
          mutationFn: (datosModelo : IMovimientoStockInsertable) => {
            return APIMovimientosStock.AgregarMovimientoStock(datosModelo);
          },
          onSuccess: (datosRespuesta: IMovimientoStockInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPMovimientosStockListaPaginada]});
            setDatosMovimientoStockInsertable(movimientoStockEntradaModelDefault);

            //actualizamos el formulario...
            let datos = estadoForm.datos;
            if (datos) datos.numeroMovimientos += 1;
            setEstadoForm({
                ...estadoForm,
                datos
            });
            refetch();
                        
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar producto de entrada",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>El <strong>producto de entrada</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar producto de entrada",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando el <strong>producto de entrada</strong>: <em>${error.message}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    function useEliminarMovimientoStock() {
        return useMutation({
          mutationFn: (datosModelo : IMovimientoStockInsertable) => {
            return APIMovimientosStock.EliminarMovimientoStock(datosModelo.id);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPMovimientosStockListaPaginada]});
            setDatosMovimientoStockInsertable(movimientoStockEntradaModelDefault);
            
            //actualizamos el formulario...
            let datos = estadoForm.datos;
            if (datos) datos.numeroMovimientos -= 1;
            setEstadoForm({
                ...estadoForm,
                datos
            });
            refetch();

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Eliminar producto de la entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>El <strong>producto de la entrada de mercancía</strong> se ha eliminado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Eliminar producto de la entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error eliminando el <strong>producto de la entrada de mercancía</strong>: <em>${error.message}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
     
    const handlerNuevoProducto = () => {
        setDatosMovimientoStockInsertable({
            ...movimientoStockEntradaModelDefault,
            idRecepcion: estadoForm?.datos?.id?? 0,
        });
        setVisibleModal(true);
    }

    const guardarDatosProducto = (datosModelo : IMovimientoStockInsertable) => {
        setVisibleModal(false);
        if (datosModelo.id === 0) {
            agregarMovimientoStock.mutate(datosModelo);
        } 
    }
    
    const handlerEliminarMovimientoStock = (datosModelo : IMovimientoStockInsertable) => {
        setDatosMovimientoStockInsertable(datosModelo);
        setMensajeConfirmacionMovimientoStock(`Se va a eliminar el producto de la entrada de mercancía: <strong>${getValues().referencia}</strong>`);
        setVisibleModalConfirmacionMovimientoStock(true);
    }
    
    const handlerConfirmarEliminarMovimientoStock = () => {
        eliminarMovimientoStock.mutate(datosMovimientoStockInsertable);
    }
      
    const handlerGuardarDocumento = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files) {
            let datosDocumento : IDocumentoRecepcionUpload = {
                idRecepcion: datos?.id?? 0,
                upload: event.currentTarget?.files[0],
            };
            guardarDocumento.mutateAsync(datosDocumento);
        }
    }

    function useGuardarDocumento() {
        return useMutation({
          mutationFn: (datosModelo : IDocumentoRecepcionUpload) => {
            return APIRecepcionesMercancia.GuardarDocumento(datosModelo);
          },
          onSuccess: (datosResponse : IDocumentoRecepcionUpload) => {            
            //actualizamos el formulario...
            let estado = estadoForm;
            estado.documentoRecepcion = datosResponse.upload?? null;
            if (estado.datos){
                estado.datos.idDocumento = datosResponse.idDocumento?? null;
                estado.datos.nombreDocumento = datosResponse.upload?.name?? null;
            }
            setEstadoForm(estado);

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Guardar documento",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>El <strong>documento de la entrada de mercancía</strong> se ha guardado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Guardar documento",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error guardando el <strong>documento de la entrada de mercancía</strong>: <em>${error.message}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    function useObtenerDocumento() {
        return useMutation({
          mutationFn: (idRecepcion : number) => {
            return APIRecepcionesMercancia.ObtenerDocumentoRecepcionMercancia(idRecepcion);
          },
          onSuccess: async (datosResponse : File) => {            
            //actualizamos el formulario...
            setEstadoForm({
                ...estadoForm,
                documentoRecepcion: datosResponse
            });
            if (datosResponse)
                Download(await datosResponse.slice(), datosResponse.type, datosResponse.name);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Ver documento",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error cargando el <strong>documento de la entrada de mercancía</strong>: <em>${error.message}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    function Download(arrayBuffer : BlobPart, type : string, name : string) {
        var blob = new Blob([arrayBuffer], { type: type });
        var url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute("target", "_blank");
        //link.setAttribute('download', name);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode?.removeChild(link);
    }

    const handlerVerDocumento = async () => {
        if (estadoForm?.documentoRecepcion) {
            Download(await estadoForm.documentoRecepcion.arrayBuffer(), estadoForm.documentoRecepcion.type, estadoForm.documentoRecepcion.name);
        } else if (estadoForm?.datos) {
            obtenerDocumento.mutate(estadoForm.datos.id);
        } else {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Ver documento",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error cargando el <strong>documento de la entrada de mercancía</strong>. No se pueden encontrar datos.</span>`
            });
            setVisibleNotificacion(true);
        }
    }

    function useEliminarDocumento() {
        return useMutation({
          mutationFn: (idRecepcion : number) => {
            return APIRecepcionesMercancia.EliminarDocumentoRecepcionMercancia(idRecepcion);
          },
          onSuccess: async () => {            
            //actualizamos el formulario...
            let estado = estadoForm;
            if (estado.datos){
                estado.datos.idDocumento = null;
                estado.datos.nombreDocumento = null;
            }
            estado.documentoRecepcion = null;
            setEstadoForm(estado);

            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Eliminar documento",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>El <strong>documento de la entrada de mercancía</strong> se ha eliminado correctamente.</span>`
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Eliminar documento",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error eliminando el <strong>documento de la entrada de mercancía</strong>: <em>${error.message}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    const handlerEliminarDocumento = () => {
        if (estadoForm?.datos) {
            eliminarDocumento.mutate(estadoForm.datos.id);
        } else {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Ver documento",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error cargando el <strong>documento de la entrada de mercancía</strong>. No se pueden encontrar datos.</span>`
            });
            setVisibleNotificacion(true);
        }
    }
 
    function useAgregarImpresionEtiquetaProducto() {
        return useMutation({
          mutationFn: (datosModelo : IImpresionInsertable) => {
            return APIImpresiones.AgregarImpresion(datosModelo);
          },
          onSuccess: (datosRespuesta: IImpresionInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPColaImpresionListaPaginada]});
            setVisibleModalImpresionEtiqueta(false);

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar impresión de producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>impresión de etiqueta del producto</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar impresión de producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>impresión de etiqueta del producto</strong>:${error.message}</span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
      
    const onClickCancelarImprimirEtiqueta = () => {
        setDatosMovimientoStock(movimientoStockModelDefault);
        setVisibleModalImpresionEtiqueta(false);
    }

    const onClickImprimirEtiqueta = (datosModelo : IMovimientoStock) => {
        setDatosMovimientoStock(datosModelo);
        setVisibleModalImpresionEtiqueta(true);
    }

    const onClickCancelarImprimirEtiquetas = () => {
        setVisibleModalImpresionEtiquetas(false);
    }

    const onClickImprimirEtiquetas = () => {

        if (listaMovimientoStock)
            setListaMovimientosEtiquetas(listaMovimientoStock);
        setVisibleModalImpresionEtiquetas(true);
    }

    const guardarEtiquetasMovimientosStock = (listaImpresiones : IImpresionInsertable[]) => {
        setVisibleModalImpresionEtiquetas(false);
        listaImpresiones?.forEach(item => {
                agregarImpresionEtiquetaProducto.mutate(item);
        });
    }

    const guardarEtiquetaMovimientoStock = (datosModelo : IEtiquetaProducto) => {
        let nuevaImpresion : IImpresionInsertable = {
            id: 0,
            idImpresora: datosModelo.idImpresora,
            idPlantilla: PLANTILLA_PRODUCTO_ID, //plantilla - producto.
            fecha:  new Date(),
            textoImpresion: TransformarPlantillaProducto(datosModelo),
            fechaImpresion: null
        };
        agregarImpresionEtiquetaProducto.mutate(nuevaImpresion);
    }


    /* efectos */
    React.useEffect(() => {
        //actualizamos el formulario...
        let estado = estadoForm;
        if (estado.datos?.fechaEntrada) {
            setValue("fechaEntrada", estado.datos?.fechaEntrada);
            setFechaEntradaInput(format(new Date(estado.datos?.fechaEntrada), "yyyy-MM-dd HH:mm"));
            setEstadoForm(estado);       
        } 
    }, [fechaEntradaInput, estadoForm, setValue]);

    React.useEffect(() => {
        reset(datos);
        queryClient.invalidateQueries({ queryKey: [KEYRecepcionesMercanciaListaPaginada]});
    }, [datos, queryClient, reset]);
    
    React.useEffect(() => {
        if (!visibleNotificacion && eliminarRecepcionMercancia.isSuccess) {
            //navegamos al listado...
            navigate(Rutas.RUTA_ENTRADAS);
        }
    }, [visibleNotificacion, eliminarRecepcionMercancia, navigate]);
        
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    React.useEffect(() => {
        let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
        if (usuarioSesion) {
            setAccesoEditar(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_ENTRADAMERCANCIA_EDICION));
            setAccesoImprimirProducto(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_PRODUCTOS_IMPRIMIR_ETIQUETA));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /* renderizado */
    return (
        <Form noValidate id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef} key={datos?.id}>
            <div className="container-fluid py-2" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="row">
                    <div className="col col-sm-12 col-md-8">
                        <Breadcrumb>
                            <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">Almacén</Breadcrumb.Item>
                            <Breadcrumb.Item href={Rutas.RUTA_ENTRADAS}>Entradas de mercancía</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edición</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="col col-sm-2 col-md-4 d-flex  justify-content-end">
                        <Link to={Rutas.RUTA_ENTRADAS} className="nav-link link-dark cursor-pointer d-flex align-center text-right">
                            <span className="material-symbols-outlined">west</span>&nbsp;
                            volver al listado
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-sm-12 col-md-7">
                        <h3>Detalles de la entrada de mercancía</h3>
                        {!estadoForm.esEdicion && <h5 className="text-info">RECEPCIÓN NUEVA</h5>}
                        {estadoForm.esEdicion && 
                            <>
                                <h5><span className="text-info">{estadoForm.datos?.referencia?? "SIN REFERENCIA"}</span></h5>
                                <h6><span className="text-secondary">Productos</span>({estadoForm.datos?.numeroMovimientos})</h6>
                            </>
                        }
                    </div>
                    <div className="col col-sm-12 col-md-5 d-flex justify-content-end align-items-end">
                        {accesoEditar && (
                        <button className="btn btn-primary" onClick={handleSubmit(guardarDatos)}>
                            <div className=" d-flex align-center">
                                <span className="material-symbols-outlined">edit_square</span>&nbsp;
                                guardar
                            </div>
                        </button>)}
                        {estadoForm.esEdicion && (<>
                        {accesoEditar && (
                        <button type="button" className="btn btn-light ms-2" onClick={ () => { handlerEliminar(); } }>
                            <div className="d-flex align-center text-danger">
                                <span className="material-symbols-outlined">delete</span>&nbsp;
                                eliminar
                            </div>
                        </button>)}                            
                        {(accesoEditar || accesoImprimirProducto) && (<>
                        <button type="button" className="btn btn-light ms-2 dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="d-flex align-center">
                                <span className="material-symbols-outlined">more_horiz</span>
                            </div>
                        </button>
                        <ul className="dropdown-menu">
                            {accesoEditar && (
                            <li>
                                <button type="button" className="dropdown-item d-flex align-items-center" onClick={handlerNuevoProducto}>
                                    <span className="material-symbols-outlined">nutrition</span>&nbsp;
                                    añadir producto
                                </button>
                            </li>)}
                            {accesoImprimirProducto && (
                            <li>
                                <button type="button" className="dropdown-item d-flex align-items-center" onClick={onClickImprimirEtiquetas}>
                                    <span className="material-symbols-outlined">flex_direction</span>&nbsp;
                                    imprimir etiquetas de la entrada
                                </button>
                            </li>)}
                        </ul></>)}
                        </>)}
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid pt-3 scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}
                onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button type="button" id="pills-datos-tab" className="nav-link active" 
                            data-bs-toggle="pill" data-bs-target="#pills-datos" role="tab" 
                            aria-controls="pills-datos" aria-selected="true">Datos</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button type="button" id="pills-productos-tab" className="nav-link"  
                            data-bs-toggle="pill" data-bs-target="#pills-productos" role="tab" 
                            aria-controls="pills-productos" aria-selected="false">Productos ({estadoForm.datos?.numeroMovimientos?? 0})</button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContenido">
                    <div className="tab-pane fade show active" id="pills-datos" role="tabpanel" aria-labelledby="pills-datos-tab">
                        <div className="row">
                            <div className="col col-sm-12 col-md-6">
                                <Form.Group className="mb-3" controlId="txtReferencia">
                                    <Form.Label>Proveedor</Form.Label>
                                    <Form.Select aria-label="Proveedores" {...register("idProveedor")}
                                        required={true}  isInvalid={!!errors.idProveedor}>
                                            {isLoadingProveedores && (<option key="" value="">cargando...</option>)}
                                            {dataProveedores && [
                                                ...[{id:0, nombre:"seleccione un proveedor..."}],
                                                ...dataProveedores
                                            ].map((option) => {
                                                return (
                                                    <option key={option?.id} value={option.id?? ""}>{option.nombre}</option>
                                                );
                                            })}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.idProveedor?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col col-sm-12 col-md-6">
                                <Form.Group className="mb-3" controlId="txtFechaEntrada">
                                    <Form.Label>Fecha de entrada</Form.Label>
                                    <Form.Control type="datetime-local"
                                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => {handleCambioFecha(e);}}
                                        required={true}  isInvalid={!!errors.fechaEntrada}
                                        value={fechaEntradaInput} />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.fechaEntrada?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-sm-12 col-md-6">
                                <Form.Group className="mb-3" controlId="txtReferencia">
                                    <Form.Label>Referencia</Form.Label>
                                    <Form.Control type="text" maxLength={20} {...register("referencia")}
                                        required={true}  isInvalid={!!errors.referencia}
                                        placeholder="escribe la referencia..." />
                                    <Form.Text className="text-muted" hidden={(watch('referencia')?.length??0) === 0}>
                                        {`${watch('referencia')?.length?? 0}/20`}
                                    </Form.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.referencia?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div> 
                        <hr/>
                        {(datos?.id?? 0)!==0 && accesoEditar && (estadoForm.datos?.idDocumento === null) && (
                            <div className="row">
                                <div className="col col-sm-12">
                                    <Form.Group controlId="documentoUpload" className="mb-3">
                                        <Form.Label>Documento de la recepción</Form.Label>
                                        <Form.Control type="file" ref={refFicheroUpload} onChange={handlerGuardarDocumento} />
                                        <Form.Text className="text-muted">
                                            Adjunte aquí el documento de la recepción de mercancía
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                            </div>
                        )}  
                        {(datos?.id?? 0)!==0 && (estadoForm.datos?.idDocumento !== null) && (
                            <div className="row">
                                <div className="col col-sm-12">
                                    <Form.Label>Documento de la recepción</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text><span className="material-symbols-outlined">upload_file</span></InputGroup.Text>
                                        <Form.Control type="text" value={estadoForm.datos?.nombreDocumento?? ""} readOnly={true} />
                                        <Button variant='outline-primary' id="btnAbrirDocumento"
                                            title="abrir documento" onClick={handlerVerDocumento}>
                                            <span className="material-symbols-outlined">preview</span>
                                        </Button>
                                        {accesoEditar && (
                                        <Button variant='outline-warning' id="btnEliminarDocumento"
                                            title="eliminar documento" onClick={handlerEliminarDocumento}>
                                            <span className="material-symbols-outlined">delete</span>
                                        </Button>
                                        )}
                                    </InputGroup>
                                </div>    
                            </div>
                        )}                        
                    </div>
                    <div className="tab-pane fade" id="pills-productos" role="tabpanel" aria-labelledby="pills-productos-tab">
                        <MovimientosStockQuery
                            filtrosQuery={filtros} 
                            handlerImprimir={onClickImprimirEtiqueta}
                            handlerEliminar= {handlerEliminarMovimientoStock}
                            pintarMasFilas= {pintarMasFilas}
                        />
                        <EtiquetaProductosModal
                            mostrarModal={visibleModalImpresionEtiquetas}
                            listaMovimientoStock={listaMovimientosEtiquetas}
                            handlerCancelar={onClickCancelarImprimirEtiquetas}
                            guardarDatos={guardarEtiquetasMovimientosStock}
                        />
                        <EtiquetaProductoModal
                            mostrarModal={visibleModalImpresionEtiqueta}
                            setMostrarModal={setVisibleModalImpresionEtiqueta}
                            datosMovimientoStock={datosMovimientoStock}
                            handlerCancelar={onClickCancelarImprimirEtiqueta}
                            guardarDatos={guardarEtiquetaMovimientoStock}
                        />
                    </div>
                </div>              
            </aside>
            <RecepcionProductoModal 
                datosIniciales={datosMovimientoStockInsertable}
                mostrarModal={visibleModal} 
                setMostrarModal={setVisibleModal}  
                guardarDatos={guardarDatosProducto} 
            />
            <Notificacion
                tipo={infoNotificacion.tipo}
                titulo={infoNotificacion.titulo}
                fecha={infoNotificacion.fecha}
                mensaje={infoNotificacion.mensaje}
                mostrar={visibleNotificacion}
                setMostrar={setVisibleNotificacion} />
            <ConfirmacionModal 
                mostrarModal = {visibleModalConfirmacion}
                setMostrarModal = {setVisibleModalConfirmacion}
                mensajeConfirmacion = {mensajeConfirmacion}
                handlerSI = {handlerConfirmarEliminar}/>
            <ConfirmacionModal 
                mostrarModal = {visibleModalConfirmacionMovimientoStock}
                setMostrarModal = {setVisibleModalConfirmacionMovimientoStock}
                mensajeConfirmacion = {mensajeConfirmacionMovimientoStock}
                handlerSI = {handlerConfirmarEliminarMovimientoStock}/>
        </Form>
    );
}