/* DEPENDENCIAS */
import { Fragment } from "react";
import ProductosTable from "./ProductosTable";
import { ColumnDef, SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { IProducto } from "../../../tipos/ProductosType";
import { Button, Form } from "react-bootstrap";
import {TiArrowUnsorted} from "react-icons/ti";
import React from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { ObtenerCssBadgeStock } from "../../../util/tsx-ayuda/Funciones";

/* DEFINICIONES */
type ProductosInstanceSeleccionarProps = {
  tableData: IProducto[],
  isError: boolean,
  error: Error | null,
  isLoading: boolean,
  isFetching: boolean,
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<IProducto[], Error>>,
  pintarMasFilas : boolean,
  handlerSeleccionar : (datosModelo: IProducto) => void
}

/* COMPONENTE */
const ProductosInstanceSeleccionar = ({ 
    tableData, 
    isError, error, isLoading, 
    isFetching, fetchNextPage,
    pintarMasFilas,
    handlerSeleccionar
  }: ProductosInstanceSeleccionarProps) => {
    /* definiciones */
    const [sorting, setSorting] = React.useState<SortingState>([{
      id: "nombre",
      desc: false
    }]); 
    const columnsDef: ColumnDef<IProducto>[] = [
      {
        accessorKey: "codigoEAN",
        header: 'EAN',
        size: 120,
        cell: info => info.getValue(),
        meta: {
          className: "align-middle",
        }
      },
      {
        accessorKey: "nombre",
        header: () => {
          return(
            <div className="d-flex cursor-pointer">
              <span>Nombre&nbsp;</span>
              <TiArrowUnsorted style={{ marginTop:2 }} />
            </div>
          );
        },
        cell: info => info.getValue(), 
        meta: {
          className: "align-middle",
        }
      },
      {
        accessorKey: "nombreFamilia",
        header: 'Familia',
        size: 120,
        cell: info => info.getValue(),
        meta: {
          className: "align-middle text-truncate",
          style: {maxWidth:120}
        }
      },
      {
        accessorKey: "nombreSeccion",
        header: 'Sección',
        size: 120,
        cell: info => info.getValue(),
        meta: {
          className: "align-middle text-truncate",
          style: {maxWidth:120}
        }
      },
      {
        accessorKey: "stock",
        header: 'Stock',
        size: 70,
        cell: info => (
          <span className={`badge ${ObtenerCssBadgeStock(info.row.original.stock, info.row.original.stockMinimo)}`}>
            {info.row.original.stock}
          </span>
        ),
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center",
        }
      },
      {
        accessorKey: "usaControlLotes",
        header: 'C.Lotes',
        size: 100,
        cell: info => (
          <Form.Check 
            disabled
            type="switch" checked={info.row.getValue("usaControlLotes")}
            aria-label="control por lotes"
          />
        ),
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center",
        }
      },
      {
        id: 'opciones',
        header: '',
        size: 50,
        cell: (info) => {
          const datos = info.row.original;
          return (
          <Fragment>
            <Button type="button" variant="primary" size="sm" className="me-1" title="seleccionar producto"
              onClick={ () => { handlerSeleccionar(datos); } }>
              <div className="d-flex">
                  <span className="material-symbols-outlined">task_alt</span>
              </div>
            </Button>
          </Fragment>);
        }, 
        meta: {
          className: "align-middle",
        }      
      },
    ];

    const tableInstance = useReactTable({ 
      data: tableData, 
      columns: columnsDef,
      state: {
        sorting,
      },
      enableSortingRemoval: false,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });
  
    /* renderizado */
    return (
      <ProductosTable instanciaTabla={tableInstance}
        isError={isError} error={error} isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        pintarMasFilas={pintarMasFilas} />
    );
  }
  export default ProductosInstanceSeleccionar;