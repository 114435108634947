/* DEPENDENCIAS */
import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Col, Container, FloatingLabel, Row } from 'react-bootstrap';
import { format } from 'date-fns';

import { ObtenerCssBadgeStock } from '../../../util/tsx-ayuda/Funciones';
import { ILote } from "../../../tipos/LotesType";
import { IFiltrosMovimientosStock } from '../../../tipos/MovimientosStockType';
import useResizeObserver from '../../../hooks/useResizeObserver';
import TrazabilidadLoteQuery from '../trazabilidad/TrazabilidadLoteQuery';

/* DECLARACIONES */
type LoteTrazabilidadModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: ILote,
    handlerCancelar: () => void
}

  
/* COMPONENTE */
export default function LoteTrazabilidadModal({datosIniciales, mostrarModal, setMostrarModal, handlerCancelar} : LoteTrazabilidadModalProps) {
    /* definiciones */
    const [heightModalBody, setHeightModalBody] = useState<number>(500);
    const [heightListado, setHeightListado] = useState<number>(0);
    const formCabeceraRef = React.useRef<HTMLFormElement>(null);
    const modalHeaderRef = React.useRef<HTMLDivElement>(null);
    const modalFooterRef = React.useRef<HTMLDivElement>(null);
    const contenidoInfoRef = React.useRef<HTMLDivElement>(null);
    const contenidoListadoRef = React.useRef<HTMLDivElement>(null);
    
    const [datosLote, setDatosLote] = useState(datosIniciales);  
    const [filtrosTrazabilidad, setFiltrosTrazabilidad] = useState<IFiltrosMovimientosStock>({
        idProducto: datosLote?.idProducto,
        codigoLote: datosLote?.codigo,
        fechaFin: new Date(),
    });
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    
    /* funciones */  
    const onResizeModel = useCallback((target: HTMLDivElement) => {
        let heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (modalHeaderRef?.current?.clientHeight?? 0);
        heightCalc = heightCalc - (modalFooterRef?.current?.clientHeight?? 0);

        setHeightModalBody(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const ss = useResizeObserver(onResizeModel);
       
    
    const onChangeFechaInicio = (event: ChangeEvent<HTMLInputElement>) => {
        setFiltrosTrazabilidad({
            ...filtrosTrazabilidad,
            fechaInicio: new Date(event.currentTarget.value),
        })
    }
    const onChangeFechaFin = (event: ChangeEvent<HTMLInputElement>) => {
        setFiltrosTrazabilidad({
            ...filtrosTrazabilidad,
            fechaFin: new Date(event.currentTarget.value),
        })
    }

    /* efectos */
    useEffect(() => {
        let heightModalBody = (formCabeceraRef?.current?.clientHeight?? 0) - (modalHeaderRef?.current?.clientHeight?? 0) - (modalFooterRef?.current?.clientHeight?? 0)
        heightModalBody -= 2;
        setHeightModalBody(heightModalBody);

        let heightListado = heightModalBody - (contenidoInfoRef?.current?.offsetHeight?? 0);
        setHeightListado(heightListado - 35);
    }, []);
    
    useEffect(() => {
        setDatosLote(datosIniciales);
        setFiltrosTrazabilidad({
            idProducto: datosIniciales?.idProducto,
            codigoLote: datosIniciales?.codigo,
            fechaFin: new Date(),
        });
    }, [datosIniciales, setDatosLote, setFiltrosTrazabilidad]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal} size="lg"
            onHide={handlerCancelar}
            backdrop="static"
            keyboard={false}
            fullscreen>
            <Form noValidate ref={formCabeceraRef}>
                <Modal.Header closeButton ref={modalHeaderRef}>
                    <Modal.Title>Trazabilidad del Lote</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height:heightModalBody}}>
                    <Container fluid ref={contenidoInfoRef}>
                        <Row>
                            <Col sm={6}>
                                <FloatingLabel className="mb-3" controlId="txtNombreProducto" label="Producto">
                                    <Form.Control plaintext className="text-dark" value={datosLote.producto.nombre} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={3}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Stock mínimo">
                                    <Form.Control plaintext className="text-dark" value={datosLote.producto.stockMinimo} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={3}>
                                <Form.Floating>
                                    <span id="txtStock" className="form-control-plaintext">
                                        <span className={`badge ${ObtenerCssBadgeStock(datosLote.stock, datosLote.producto.stockMinimo)}`}>
                                            {datosLote.stock}
                                        </span>
                                    </span>
                                    <label htmlFor="txtStock">Stock actual</label>
                                </Form.Floating>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Código">
                                    <Form.Control plaintext className="text-dark" value={datosLote.codigo} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={4}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Caducidad">
                                    <Form.Control plaintext className="text-dark" 
                                        value={(datosLote.caducidad)? format(new Date(datosLote.caducidad), "dd/MM/yyyy") : "-"} />
                                </FloatingLabel>                                              
                            </Col>
                            <Col sm={4}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Consumo preferente">
                                    <Form.Control plaintext className="text-dark" 
                                        value={(datosLote.consumoPreferente)? format(new Date(datosLote.consumoPreferente), "dd/MM/yyyy") : "-"} />
                                </FloatingLabel>                                               
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <Container fluid>
                                    <Row>
                                        <Col sm={12} lg={6}>
                                            <div className="input-group">
                                                <span className="input-group-text">Fecha</span>
                                                <input type="date" aria-label="fecha desde" className="form-control" value={
                                                    (filtrosTrazabilidad.fechaInicio)? 
                                                        format(new Date(filtrosTrazabilidad.fechaInicio), "yyyy-MM-dd") : ""} 
                                                    onChange={onChangeFechaInicio}/>
                                                <input type="date" aria-label="fecha hasta" className="form-control" value={
                                                    (filtrosTrazabilidad.fechaFin)? 
                                                        format(new Date(filtrosTrazabilidad.fechaFin), "yyyy-MM-dd") : 
                                                        format(new Date(), "yyyy-MM-dd")} 
                                                    onChange={onChangeFechaFin}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>                        
                                <hr/>
                            </Col>
                        </Row>
                    </Container> 
                    <Container fluid  className="scrollbar-overlay my-0 py-0" 
                        ref={contenidoListadoRef} style={{height:heightListado}}>
                        <TrazabilidadLoteQuery
                            filtrosQuery={filtrosTrazabilidad}
                            pintarMasFilas= {pintarMasFilas}
                        />
                    </Container>                   
                </Modal.Body>
                <Modal.Footer ref={modalFooterRef}>
                    <Button variant="light" onClick={handlerCancelar}>
                        <div className="d-flex">
                            <span className="material-symbols-outlined">block</span>&nbsp;
                            Cancelar
                        </div>
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}