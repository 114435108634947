import { ILoteInsertable } from "./LotesType"
import { IProductoLote, productoLoteDefault } from "./ProductosType"

/**
 * Tipo enumerado con los tipos de movimientos de stock.
 */
enum ETipoMovimientoStock { ENTRADA = 0, SALIDA = 1, REGULARIZACION = 2 }

/**
 * Interfaz que define un objeto de un movimiento de stock en la IU.
 */
interface IMovimientoStock {
   id : number,
   idRecepcion? : number,
   idProducto : number,
   producto: IProductoLote,
   idLote : number,
   codigoLote : string,
   caducidadLote : Date | null,
   tipo : ETipoMovimientoStock,
   fecha : Date,
   cantidad : number,
   precio? : number,
   valorIVA? : number,
   observaciones? : string,
   referencia? : string
}

/**
 * Interfaz que define un objeto de un movimiento de stock en la IU.
 */
interface IMovimientoStockInsertable {
   id : number,
   idRecepcion? : number,
   idProducto : number,
   tipo : ETipoMovimientoStock,
   fecha : Date,
   cantidad : number,
   precio? : number,
   valorIVA? : number,
   observaciones? : string,
   lote? : ILoteInsertable | null
}

/**
 * Interfaz que define un objeto de un movimiento de stock en la IU.
 */
interface IMovimientoStockInsertableForm extends IMovimientoStockInsertable {
   usaControlLotes?: boolean,
}

/**
 * Constante con la definición del modelo de datos por defecto.
 */
const movimientoStockModelDefault : IMovimientoStock = {
   id : 0,
   idProducto : 0,
   producto: productoLoteDefault,
   idLote : 0,
   codigoLote : "",
   caducidadLote : null,
   tipo : ETipoMovimientoStock.ENTRADA,
   fecha : new Date(),
   cantidad : 0,
}

/**
 * Constante con la definición del modelo de datos por defecto para una entrada de stock.
 */
const movimientoStockEntradaModelDefault : IMovimientoStockInsertable = {
   id : 0,
   idProducto : 0,
   tipo : ETipoMovimientoStock.ENTRADA,
   fecha : new Date(),
   cantidad : 1,
   precio: 0,
   valorIVA: 0,   
   lote : null
}

/**
 * Constante con la definición del modelo de datos por defecto para una regularización de stock.
 */
const movimientoStockRegularizacionModelDefault : IMovimientoStockInsertable = {
   id : 0,
   idProducto : 0,
   tipo : ETipoMovimientoStock.REGULARIZACION,
   fecha : new Date(),
   cantidad : 1,
   lote : null,
   observaciones : "",
}

/**
 * Constante con la definición del modelo de datos por defecto para una entrada de stock.
 */
const movimientoStockSalidaModelDefault : IMovimientoStockInsertable = {
   id : 0,
   idProducto : 0,
   tipo : ETipoMovimientoStock.SALIDA,
   fecha : new Date(),
   cantidad : 1,  
   lote : null
}

/**
 * Interfaz que define los filtros para los movimientos de stock.
 */
interface IFiltrosMovimientosStock {
   idRecepcion?: number | null,
   idProducto? : number | null,
   idFamilia? : number | null,
   idSeccion? : number | null,
   modelo? : string | null,
   idLote? : number | null,
   codigoLote? : string | null,
   tipo? : ETipoMovimientoStock | null,
   fecha? : Date | null,
   fechaInicio? : Date | null,
   fechaFin? : Date | null,
}

const filtrosMovimientosStockDefault : IFiltrosMovimientosStock = {
   fechaInicio: undefined,
   fechaFin: undefined,
}


const ETipoMovimientoStockToString = (tipoMovimientoStock : ETipoMovimientoStock) : string => {
   //Método.
   switch (tipoMovimientoStock) {
       case ETipoMovimientoStock.ENTRADA: return "entrada";
       case ETipoMovimientoStock.SALIDA: return "salida";
       case ETipoMovimientoStock.REGULARIZACION: return "regularización";
       default: return "";
   }
}

 


export type { 
   IMovimientoStock, IMovimientoStockInsertable, IMovimientoStockInsertableForm, IFiltrosMovimientosStock
}

export {
   ETipoMovimientoStock,
   ETipoMovimientoStockToString,
   movimientoStockModelDefault, 
   movimientoStockEntradaModelDefault,
   movimientoStockRegularizacionModelDefault,
   movimientoStockSalidaModelDefault, 
   filtrosMovimientosStockDefault
}