/* DEPENDENCIAS */
import { Dispatch, SetStateAction, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { IFormCambiarClave, modelFormCambiarClave } from '../../../tipos/UsuariosType';

/* DECLARACIONES */
const schemaValidacion = yup.object().shape({
    clave: yup.string()
        .required("El campo es obligatorio")
        .min(8, "La clave debe ser al menos de 8 caracteres."),
    claveCopia: yup.string()
        .required("El campo es obligatorio")
        .min(8, "La clave debe ser al menos de 8 caracteres.")
        .oneOf([yup.ref('clave')], "Las cleves deben coincidir."),
});

type CambiarClaveModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    guardarDatos: (datosModelo: IFormCambiarClave) => void
}

/* COMPONENTE */
export default function CambiarClaveModal({mostrarModal, setMostrarModal, guardarDatos} : CambiarClaveModalProps) {
    /* definiciones */
    const { register, handleSubmit, formState: {errors}, getValues, reset } = useForm<IFormCambiarClave>({
        mode: "onSubmit",
        defaultValues: modelFormCambiarClave,
        resolver: yupResolver(schemaValidacion),
    });
    
    /* funciones */
    const onClickGuardar = () => {
        let datosModelo : IFormCambiarClave = getValues();
        guardarDatos(datosModelo);
        reset(modelFormCambiarClave);
    }
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>Cambiar mi contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Form.Group className="mb-3" controlId="txtClave">
                            <Form.Label>Nueva contraseña</Form.Label>
                            <Form.Control type="password" {...register("clave")} 
                                required={true} isInvalid={!!errors.clave} />
                            <Form.Control.Feedback type='invalid'>
                                {errors.clave?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="txtClaveCopia">
                            <Form.Label>Repetir clave</Form.Label>
                            <Form.Control type="password" {...register("claveCopia")}
                                required={true} isInvalid={!!errors.claveCopia} />
                            <Form.Control.Feedback type='invalid'>
                                {errors.claveCopia?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button type="button" variant="light" onClick={() => {reset(modelFormCambiarClave); setMostrarModal(!mostrarModal)}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button type="button" variant="primary" onClick={handleSubmit(onClickGuardar)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
      </Modal>
    );
}