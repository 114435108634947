/* DEPENDENCIAS */
import { Fragment } from "react";
import format from 'date-fns/format'
import ColaImpresionTable from "./ColaImpresionTable";
import { ColumnDef, SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { IImpresion } from "../../../tipos/ColaImpresionType";
import { Button } from "react-bootstrap";
import {TiArrowUnsorted} from "react-icons/ti";
import React from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";

/* DEFINICIONES */
type ColaImpresionInstanceProps = {
  tableData: IImpresion[],
  isError: boolean,
  error: Error | null,
  isLoading: boolean,
  isFetching: boolean,
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<IImpresion[], Error>>,
  pintarMasFilas : boolean,
  handlerVer : (datosModelo: IImpresion) => void,
}

/* COMPONENTE */
const ColaImpresionInstance = ({ 
    tableData, 
    isError, error, isLoading, 
    isFetching, fetchNextPage,
    pintarMasFilas,
    handlerVer
  }: ColaImpresionInstanceProps) => {
    /* definiciones */
    const [sorting, setSorting] = React.useState<SortingState>([{
      id: "fecha",
      desc: false
    }]); 
    const columnsDef: ColumnDef<IImpresion>[] = [
      {
        accessorKey: "fecha",
        header: () => {
          return(
            <div className="d-flex cursor-pointer">
              <span>Fecha&nbsp;</span>
              <TiArrowUnsorted style={{ marginTop:2 }} />
            </div>
          );
        },
        size: 200,
        cell: (info) => format(new Date(info.row.original.fecha), "dd/MM/yyyy HH:mm"),
        meta: {
          className: "align-middle",
        }
      },
      {
        accessorKey: "fechaImpresion",
        header: () => {
          return(
            <div className="d-flex cursor-pointer">
              <span>Fecha impresión&nbsp;</span>
              <TiArrowUnsorted style={{ marginTop:2 }} />
            </div>
          );
        },
        size: 200,
        cell: (info) => {
          return (
            <>
              {info.row.original.fechaImpresion === null && <span className="badge text-bg-light px-3">imprimiendo...</span>}
              {!!info.row.original.fechaImpresion && 
                <span className="badge text-bg-info px-3">
                  {format(new Date(info.row.original.fechaImpresion), "dd/MM/yyyy HH:mm")}
                </span>
              }
            </>
          );
        },
        meta: {
          className: "align-middle",
        }
      },
      {
        accessorFn: (row) => row.nombreImpresora?? "-",
        id: "nombreimpresora",
        header: "Impresora",
        cell: (info) => {
          return info.getValue();
        },
        meta: {
          className: "align-middle",
        }
      },
      {
        id: 'opciones',
        header: '',
        size: 40,
        cell: (info) => {
          const datos = info.row.original;
          return (
          <Fragment>
            <Button type="button" variant="success" size="sm" className="me-1"
              onClick={ () => { handlerVer(datos); } }>
              <div className="d-flex">
                  <span className="material-symbols-outlined">frame_source</span>
              </div>
            </Button>
          </Fragment>);
        }, 
        meta: {
          className: "align-middle",
        }      
      },
    ];

    const tableInstance = useReactTable({ 
      data: tableData, 
      columns: columnsDef,
      state: {
        sorting,
      },
      enableSortingRemoval: false,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });
  
    /* renderizado */
    return (
      <ColaImpresionTable instanciaTabla={tableInstance}
        isError={isError} error={error} isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        pintarMasFilas={pintarMasFilas} />
    );
  }
  export default ColaImpresionInstance;