/* DEPENDENCIAS */
import React, { Fragment, useState } from "react";
import { ColumnDef, SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import {TiArrowUnsorted} from "react-icons/ti";

import LotesTable from "./LotesTable";
import { ILote } from "../../../tipos/LotesType";
import { ObtenerCssBadgeStock, ObtenerCssFecha, TienePermiso } from "../../../util/tsx-ayuda/Funciones";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { IUsuarioSesion } from "../../../tipos/UsuariosType";
import { EFuncionalidad, VAR_SESION_USUARIO } from "../../../tipos/CommonTypes";

/* DEFINICIONES */
type LotesInstanceProps = {
  tableData: ILote[],
  isError: boolean,
  error: Error | null,
  isLoading: boolean,
  isFetching: boolean,
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<ILote[], Error>>,
  pintarMasFilas : boolean,
  handlerEditar? : (datosModelo: ILote) => void,
  handlerRegularizarStock : (datosModelo: ILote) => void,
  handlerRegistrarSalida : (datosModelo: ILote) => void,
  handlerVerTrazabilidad : (datosModelo: ILote) => void,
  handlerImprimirEtiquetaLote? : (datosModelo: ILote) => void,
}

/* COMPONENTE */
const LotesInstance = ({ 
    tableData, 
    isError, error, isLoading, 
    isFetching, fetchNextPage,
    pintarMasFilas,
    handlerEditar,
    handlerRegularizarStock,
    handlerRegistrarSalida,
    handlerVerTrazabilidad,
    handlerImprimirEtiquetaLote
  }: LotesInstanceProps) => {
    /* definiciones */
    const [sorting, setSorting] = React.useState<SortingState>([{
      id: "codigo",
      desc: false
    }]); 
    const { getItem } = useLocalStorage();
    const [accesoEditar, setAccesoEditar] = useState<boolean>(false);
    const [accesorRegularizarStock, setAccesoRegularizarStock] = useState<boolean>(false);
    const [accesoSalidaMercancia, setAccesoSalidaMercancia] = useState<boolean>(false);
    const [accesoVerTrazabilidad, setAccesoVerTrazabilidad] = useState<boolean>(false);
    const [accesoImprimirLote, setAccesoImprimirLote] = useState<boolean>(false);
    
    const columnsDef: ColumnDef<ILote>[] = [
      {
        accessorKey: "codigo",
        header: () => {
          return(
            <div className="d-flex cursor-pointer">
              <span>Código&nbsp;</span>
              <TiArrowUnsorted style={{ marginTop:2 }} />
            </div>
          );
        },
        size: 140,
        cell: info => info.getValue(),
        meta: {
          className: "align-middle",
        }
      },
      {
        accessorKey: "producto.nombre",
        header: () => {
          return(
            <div className="d-flex cursor-pointer">
              <span>Producto&nbsp;</span>
              <TiArrowUnsorted style={{ marginTop:2 }} />
            </div>
          );
        },
        cell: info => info.getValue(),
        meta: {
          className: "align-middle",
        }
      },
      {
        accessorKey: "caducidad",
        header: 'Caducidad',
        size: 110,
        cell: info => (
          <span className={ObtenerCssFecha(info.row.original.caducidad?? null)}>
            {(info.row.original.caducidad)? format(new Date(info.row.original.caducidad), "dd/MM/yyyy") : "-"}
          </span>
        ),
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center",
        }
      },
      {
        accessorKey: "consumoPreferente",
        header: 'Preferente',
        size: 110,
        cell: info => (
          <span className={ObtenerCssFecha(info.row.original.consumoPreferente?? null)}>
            {(info.row.original.consumoPreferente)? format(new Date(info.row.original.consumoPreferente), "dd/MM/yyyy") : "-"}
          </span>
        ),
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center",
        }
      },
      {
        accessorKey: "stock",
        header: 'Stock',
        size: 100,
        cell: info => (
          <span className={`badge ${ObtenerCssBadgeStock(info.row.original.stock, info.row.original.producto.stockMinimo)}`}>
            {info.row.original.stock}
          </span>
        ),
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center",
        }
      },
      {
        id: 'opciones',
        header: '',
        size:  handlerEditar? 140 : 60,
        cell: (info) => {
          const datos = info.row.original;
          return (
          <Fragment>
            {handlerEditar && (
              <span className={`${!accesoEditar? 'disabled-button-wrapper' : ''}`}>
                <Button type="button" variant="success" size="sm" className="me-1" disabled={!accesoEditar}
                  onClick={ () => { handlerEditar(datos); } }>
                  <div className="d-flex">
                      <span className="material-symbols-outlined">edit_note</span>&nbsp;editar
                  </div>
                </Button>
              </span>
            )}      
            {(accesoSalidaMercancia || accesoVerTrazabilidad || accesorRegularizarStock) && (<>                                          
            <Button type="button" variant="outline-info" className="btn btn-light ms-2 dropdown" title="más opciones"
                data-bs-toggle="dropdown" aria-expanded="false"
                style={{backgroundColor:"transparent", width:30, padding:"0.2rem"}}>
                <span className="material-symbols-outlined">more_vert</span>
            </Button>
            <ul className="dropdown-menu">
                <li>
                    {accesorRegularizarStock && (
                    <Button type="button" variant="primary" size="sm" className="dropdown-item d-flex align-items-center"
                      onClick={ () => { handlerRegularizarStock(datos); } }>
                      <span className="material-symbols-outlined">score</span>&nbsp;
                      regularizar stock
                    </Button>)}
                    {accesoSalidaMercancia && (
                    <Button type="button" variant="primary" size="sm" className="dropdown-item d-flex align-items-center"
                      onClick={ () => { handlerRegistrarSalida(datos); } }>
                      <span className="material-symbols-outlined">tab_new_right</span>&nbsp;
                      salida de mercancía
                    </Button>)}
                    {accesoVerTrazabilidad && (
                    <Button type="button" variant="primary" size="sm" className="dropdown-item d-flex align-items-center"
                      onClick={ () => { handlerVerTrazabilidad(datos); } }>
                      <span className="material-symbols-outlined">table_chart_view</span>&nbsp;
                      ver trazabilidad
                    </Button>)}
                    {accesoImprimirLote && handlerImprimirEtiquetaLote && (
                    <Button type="button" variant="primary" size="sm" className="dropdown-item d-flex align-items-center"
                      onClick={ () => { handlerImprimirEtiquetaLote(datos); } }>
                      <span className="material-symbols-outlined">table_chart_view</span>&nbsp;
                      Imprimir etiqueta lote
                    </Button>)}
                </li>
            </ul>
            </>)}
          </Fragment>);
        }, 
        meta: {
          className: "align-middle d-flex justify-content-end",
        }      
      },
    ];

    const tableInstance = useReactTable({ 
      data: tableData, 
      columns: columnsDef,
      state: {
        sorting,
      },
      enableSortingRemoval: false,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });
  
    /* efectos */    
    React.useEffect(() => {
        let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
        if (usuarioSesion) {
          setAccesoEditar(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_LOTES_EDICION));
          setAccesoRegularizarStock(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_LOTES_REGULARIZAR));
          setAccesoSalidaMercancia(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_LOTES_SALIDA));
          setAccesoVerTrazabilidad(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_PRODUCTOS_VER_TRAZABILIDAD));
          setAccesoImprimirLote(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_PRODUCTOS_IMPRIMIR_ETIQUETA));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* renderizado */
    return (
      <LotesTable instanciaTabla={tableInstance}
        isError={isError} error={error} isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        pintarMasFilas={pintarMasFilas} />
    );
  }
  export default LotesInstance;