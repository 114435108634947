/* DEPENDENCIAS */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import PanelError from '../../../componentes-com/PanelError';
import PanelCargando from '../../../componentes-com/PanelCargando';
import ProductoEdicion from './ProductoEdicion';
import { productoModelDefault } from '../../../tipos/ProductosType';
import { queryObtenerProducto } from '../../../util/querys/ProductosQuerys';

/* DECLARACIONES */ 

/* COMPONENTE */
export default function ProductoSwitch() {
    /* definiciones */
    const routerParams = useParams();
    const id : number = Number(routerParams?.ID);
    const esEdicion : boolean = !!id;
    const  { data, isLoading, isError } = useObtenerProducto(id);
    
    /* funciones */
    function useObtenerProducto(id: number) {
        return useQuery(queryObtenerProducto(id)); 
    } 

    /* efectos */
    React.useEffect(() => {  
    }, []);
    
    /* renderizado */
    if (isError) <PanelError mensaje={"Error cargando los datos del producto"} />
    if (isLoading) <PanelCargando />
    return <ProductoEdicion datos={data?? productoModelDefault} esEdicion={esEdicion} />;
}