/* DEPENDENCIAS */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery,useQueryClient } from '@tanstack/react-query';

import PanelError from '../../../componentes-com/PanelError';
import PanelCargando from '../../../componentes-com/PanelCargando';
import { queryObtenerRecepcionMercancia } from '../../../util/querys/RecepcionesMercanciaQuerys';
import { recepcionMercanciaModelDefault } from '../../../tipos/RecepcionesMercanciaType';
import RecepcionMercanciaEdicion from './RecepcionMercanciaEdicion';
import { documentoRecepcionUploadModelDefault } from '../../../tipos/DocumentosType';

/* DECLARACIONES */ 

/* COMPONENTE */
export default function RecepcionMercanciaSwitch() {
    /* definiciones */
    const queryClient = useQueryClient();
    const routerParams = useParams();
    const id : number = Number(routerParams?.ID);
    const esEdicion : boolean = !!id;
    const  { data, isLoading, isError } = useQuery(queryObtenerRecepcionMercancia(id, queryClient));
        
    /* renderizado */
    if (isError) <PanelError mensaje={"Error cargando los datos de la entrada de mercancía"} />
    if (isLoading && esEdicion) return <PanelCargando />
    return <RecepcionMercanciaEdicion key={id}
        datos={data?? recepcionMercanciaModelDefault} 
        documentoUpload={documentoRecepcionUploadModelDefault}
        documentoRecepcion={null}
        esEdicion={esEdicion} />;
}