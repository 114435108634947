/**
 * Interfaz que define un objeto de una recepción de mercancía en la IU.
 */
interface IRecepcionMercancia {
    id: number,
    idProveedor: number,
    nombreProveedor?: string,
    referencia: string | null,
    fecha: Date,
    fechaEntrada: Date | null,
    idDocumento: number | null,
    nombreDocumento: string | null,
    numeroMovimientos: number,
}

/**
 * Constante con un objeto inicializado con los valores por defecto.
 */
const recepcionMercanciaModelDefault : IRecepcionMercancia = {
   id: 0,
   idProveedor: 0,
   referencia: null,
   fecha: new Date(),
   fechaEntrada: new Date(),
   idDocumento: null,
   nombreDocumento: null,
   numeroMovimientos: 0,
}

/**
 * Interfaz que define un objeto de los filtros de recepciones de mercancía en la IU.
 */
interface IFiltrosRecepcionesMercancia {
   IDProveedor?: number | null,
   Referencia?: string | null,
   FechaEntradaDesde?: Date | null,
   FechaEntradaHasta?: Date | null,
}

/**
 * Constante con un objeto inicializado para el filtrado con los valores por defecto.
 */
const filtrosRecepcionMercanciaDefault : IFiltrosRecepcionesMercancia = {
   IDProveedor: null,
   Referencia: null,
   FechaEntradaDesde: null,
   FechaEntradaHasta: null
}

export type { 
   IRecepcionMercancia, IFiltrosRecepcionesMercancia,
}

export {
   recepcionMercanciaModelDefault,
   filtrosRecepcionMercanciaDefault
}