/* DEPENDENCIAS */
import { useInfiniteQuery } from "@tanstack/react-query";
import ImpresorasInstance from "./ImpresorasInstance";
import { IImpresoraInsertable } from "../../../tipos/ImpresorasType";
import * as APIImpresoras from '../../../servicios/APIImpresiones';
import React from "react";

/* DEFINICIONES */
type IImpresorasQueryProps = {
  filtroQuery : string | undefined,
  pintarMasFilas : boolean,
  handlerEditar : (datosModelo: IImpresoraInsertable) => void,
  handlerEliminar : (datosModelo : IImpresoraInsertable) => void,
}

/* COMPONENTE */
const ImpresorasQuery = ({filtroQuery, pintarMasFilas, handlerEditar, handlerEliminar} : IImpresorasQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useListarImpresoras(filtroQuery);
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
    
    /* funciones */
    function useListarImpresoras(filtroQuery: string | undefined) {
        return useInfiniteQuery<IImpresoraInsertable[], Error>({
          queryKey: ['listadoImpresoras', filtroQuery],
          queryFn: ({ pageParam = 0 }) => APIImpresoras.ListarImpresorasPaginacion(pageParam, 20, filtroQuery),
          getNextPageParam: (lastPage, allPages) => allPages.length,
          keepPreviousData: true,
          refetchOnWindowFocus: false,
        });
    }        
    
    /* renderizado */    
    return (
      <ImpresorasInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerEditar={handlerEditar}
        handlerEliminar={handlerEliminar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default ImpresorasQuery;