/* DEPENDENCIAS */
import React, { useCallback, useState } from 'react';
import { Button, Breadcrumb, Row, Col } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import * as APILotes from '../../../servicios/APILotes'
import * as APIMovimientosStock from '../../../servicios/APIMovimientosStock'
import useResizeObserver from '../../../hooks/useResizeObserver';
import * as Rutas from '../../../rutas/Rutas';
import { ICabeceraCaducidades, IFiltrosLotes, ILote, ILoteInsertable, filtrosLoteDefault, loteModelDefault } from '../../../tipos/LotesType';
import Notificacion, { ETipoNotificacion } from '../../../componentes-com/Notificacion';
import LotesQuery from './LotesQuery';
import { IProducto, productoModelDefault } from '../../../tipos/ProductosType';
import { format } from 'date-fns';
import { KEYPLotesLista, queryObtenerCabeceraCaducidades } from '../../../util/querys/LotesQuerys';
import LoteEdicionModal from './LoteEdicionModal';
import { IMovimientoStockInsertable } from '../../../tipos/MovimientosStockType';
import { KEYPMovimientosStockListaPaginada } from '../../../util/querys/MovimientosStockQuerys';
import LoteRegularizarModal from './LoteRegularizarModal';
import SalidaProductoModal from '../salidasmercancia/SalidaProductoLoteModal';
import { ETipoRangoCaducidad } from '../../../tipos/CommonTypes';
import LoteTrazabilidadModal from './LoteTrazabilidadModal';


/* DECLARACIONES */
type InfoNotificacion = {
    tipo: ETipoNotificacion,
    titulo: string,
    fecha: string,
    mensaje: string,
}

const infoNotificacionDefault : InfoNotificacion = {
    tipo: ETipoNotificacion.INFO,
    titulo: "",
    fecha: "",
    mensaje: ""
}

/* componente */
export default function Caducidades() { 
    /* definiciones */
    const queryClient = useQueryClient();
    const [filtros, setFiltros] = useState<IFiltrosLotes>({
        ...filtrosLoteDefault,
        caducados: true,
    });
    const { reset, getValues, setValue } = useForm<IFiltrosLotes>({
        defaultValues: {
                ...filtrosLoteDefault,
                caducados: true,
            }
    });
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);

    const {data:datosCabecera, isLoading } = useQuery<ICabeceraCaducidades>(queryObtenerCabeceraCaducidades());

    const [datosProducto, setDatosProducto] = useState<IProducto>(productoModelDefault);
    const [visibleModalProductos, setVisibleModalProductos] = useState(false);
    
    const [datosLote, setDatosLote] = useState<ILote>(loteModelDefault);
    const [visibleModalLote, setVisibleModalLote] = useState(false);
    const editarLote = useEditarLote();

    const [visibleModalRegularizacionLote, setVisibleModalRegularizacionLote] = useState(false);
    const [visibleModalSalidaStockLote, setVisibleModalSalidaStockLote] = useState(false);
    const [visibleTrazabilidadLote, setVisibleTrazabilidadLote] = useState(false);
    const agregarMovimientoStock = useAgregarMovimientoStock();
    

    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);


    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
  
       
    const onClickEnlaceListado = (tipoRangoCaducidad : ETipoRangoCaducidad) => {
       switch (tipoRangoCaducidad) {
            case ETipoRangoCaducidad.CADUCADO:
                setFiltros({
                    ...filtros, 
                    caducados: true,
                    caducanHoy: undefined,
                    caducanSemana: undefined,
                    caducanMes: undefined
                });
                break;
                
            case ETipoRangoCaducidad.CADUCA_HOY:
                setFiltros({
                    ...filtros, 
                    caducados: undefined,
                    caducanHoy: true,
                    caducanSemana: undefined,
                    caducanMes: undefined
                });
                break;
                
            case ETipoRangoCaducidad.CADUCA_7:
                setFiltros({
                    ...filtros, 
                    caducados: undefined,
                    caducanHoy: undefined,
                    caducanSemana: true,
                    caducanMes: undefined
                });
                break;
                
            case ETipoRangoCaducidad.CADUCA_30:
                setFiltros({
                    ...filtros, 
                    caducados: undefined,
                    caducanHoy: undefined,
                    caducanSemana: undefined,
                    caducanMes: true 
                });
                break;
       } 
    }
    
    const onClickCancelarEdicion = () => {
        setVisibleModalLote(false);
        setDatosLote(loteModelDefault);
    }

    const onClickEditar = (datosModelo : ILote) => {   
        setDatosLote(datosModelo);     
        setVisibleModalLote(true);
    }
    
    const guardarDatosLote = (datosModelo : ILote) => {
        let datosModeloInsertable : ILoteInsertable = { 
            ...datosModelo 
        }
        
        setVisibleModalLote(false);
        editarLote.mutate(datosModeloInsertable);
    }

    function useEditarLote() {
        return useMutation({
          mutationFn: (datosModelo : ILoteInsertable) => {
            return APILotes.EditarLote(datosModelo);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPLotesLista]});
            setDatosLote(loteModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Editar lote",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>El <strong>lote</strong> se ha editado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Editar lote",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error editando el <strong>lote</strong>: <em>${variables.codigo}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    const onClickCancelarRegularizacion = () => {
        setVisibleModalRegularizacionLote(false);
        setDatosLote(loteModelDefault);
    }

    const onClickRegularizarStock = (datosModelo : ILote) => {
        setDatosLote(datosModelo);     
        setVisibleModalRegularizacionLote(true);
    }
    
    const guardarDatosRegularizacionLote = (datosModelo : IMovimientoStockInsertable) => {        
        setVisibleModalRegularizacionLote(false);
        setDatosLote(loteModelDefault);
        agregarMovimientoStock.mutate(datosModelo);
    }

    function useAgregarMovimientoStock() {
        return useMutation({
          mutationFn: (datosModelo : IMovimientoStockInsertable) => {
            return APIMovimientosStock.AgregarMovimientoStock(datosModelo);
          },
          onSuccess: (datosRespuesta: IMovimientoStockInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPLotesLista]});
            queryClient.invalidateQueries({ queryKey: [KEYPMovimientosStockListaPaginada]});
            setDatosLote(loteModelDefault);
         
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar movimiento de stock",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>El movimiento de stock del lote <strong>${datosRespuesta.lote?.codigo}</strong> se ha hecho correctamente.</span>`
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar movimiento de stock",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error guardando un <strong>movimiento de stock</strong>: <em>${error.message}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    const onClickCancelarSalidaStock = () => {
        setVisibleModalSalidaStockLote(false);
        setDatosLote(loteModelDefault);
    }

    const onClickRegistrarSalidaStock = (datosModelo : ILote) => {
        setDatosLote(datosModelo);     
        setVisibleModalSalidaStockLote(true);
    }

    const guardarDatosSalidaStock = (datosModelo : IMovimientoStockInsertable) => {        
        setVisibleModalSalidaStockLote(false);
        setDatosLote(loteModelDefault);
        agregarMovimientoStock.mutate(datosModelo);
    }

    const onClickCancelarVerTrazabilidad = () => {
        setVisibleTrazabilidadLote(false);
        setDatosLote(loteModelDefault);
    }

    const onClickVerTrazabilidad = (datosModelo : ILote) => {
        setDatosLote(datosModelo);     
        setVisibleTrazabilidadLote(true);
    }

    /* efectos */
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);


    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="accordion" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="accordion-item">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-7 pt-3 ps-4">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Almacén</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Caducidades</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelCabecera"
                            aria-expanded="true" aria-controls="panelCabecera">
                            <div className="container-fluid px-0">
                                <h2>Caducidades</h2>
                                <p className="lead">
                                    {filtros.caducados && <>Lotes caducados</>}
                                    {filtros.caducanHoy && <>Lotes que caducan hoy</>}
                                    {filtros.caducanSemana && <>Lotes que caducan en una semana</>}
                                    {filtros.caducanMes && <>Lotes que caducan en 30 días</>}
                                </p>
                            </div>
                        </button>
                    </h2>
                    <div id="panelCabecera" className="accordion-collapse collapse show" data-bs-parent="#contenedorCabeceraListado">
                        <div className="accordion-body">
                            <Row>
                                <Col md={6} lg={3}>
                                    <Button variant="link" style={{padding:"0.3rem"}} onClick={()=> {onClickEnlaceListado(ETipoRangoCaducidad.CADUCADO);}}>
                                        Caducados
                                    </Button>(
                                        {isLoading && <>...</>}
                                        {datosCabecera && <>{datosCabecera.lotesCaducados}</>})
                                </Col>
                                <Col md={6} lg={3}>
                                    <Button variant="link" style={{padding:"0.3rem"}} onClick={()=> {onClickEnlaceListado(ETipoRangoCaducidad.CADUCA_HOY);}}>
                                        Caducan hoy
                                    </Button>(
                                        {isLoading && <>...</>}
                                        {datosCabecera && <>{datosCabecera.lotesCaducanHoy}</>})
                                </Col>
                                <Col md={6} lg={3}>
                                    <Button variant="link" style={{padding:"0.3rem"}} onClick={()=> {onClickEnlaceListado(ETipoRangoCaducidad.CADUCA_7);}}>
                                        Caducan en 7 días
                                    </Button>(
                                        {isLoading && <>...</>}
                                        {datosCabecera && <>{datosCabecera.lotesCaducanSemana}</>})
                                </Col>
                                <Col md={6} lg={3}>
                                    <Button variant="link" style={{padding:"0.3rem"}} onClick={()=> {onClickEnlaceListado(ETipoRangoCaducidad.CADUCA_30);}}>
                                        Caducan en 30 días
                                    </Button>(
                                        {isLoading && <>...</>}
                                        {datosCabecera && <>{datosCabecera.lotesCaducanMes}</>})
                                </Col>
                            </Row>
                            {/*<Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="txtProducto">
                                        <InputGroup className="mb-3">
                                            <Button variant="outline-info" id="btnSeleccionarProducto" onClick={onClickSeleccionarProducto}>
                                                seleccione un producto
                                            </Button>
                                            <Form.Control type="hidden" value={datosProducto.id} {...register("idProducto")} />
                                            <Form.Control readOnly value={datosProducto.nombre}
                                                aria-label="filtro por producto"
                                                aria-describedby="btnSeleccionarProducto"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <ProductosSeleccionModal
                                        mostrarModal={visibleModalProductos}
                                        setMostrarModal={setVisibleModalProductos}                                        
                                        seleccionarProducto={handlerProductoSeleccionado}
                                    />
                                </Col>
                            </Row>
                            <div className="row g-sm-2">
                                <div className="col-lg-6 col-md-4">
                                    <Form.Control type="text" maxLength={300} {...register("codigo")}
                                        placeholder="filtrar por código..." />
                                </div>                              
                                <div className="col-lg-6 col-md-8 d-flex align-items-end  justify-content-end">
                                    <div className="d-flex">
                                        <Button variant='link' className="nav-link me-3" onClick={handleSubmit(handlerResetFiltros)}>
                                            <div className='d-flex'>
                                                    <span className="material-symbols-outlined">filter_alt_off</span>
                                                    reiniciar filtros
                                            </div> 
                                        </Button>
                                        <Button variant='info' className='mx-1' onClick={handleSubmit(handlerBuscar)}>
                                            <div className='d-flex'>
                                                    <span className="material-symbols-outlined">filter_alt</span>
                                                    buscar
                                            </div> 
                                        </Button> 
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}
                onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                <LotesQuery  
                    filtrosQuery={filtros} 
                    handlerEditar={onClickEditar}
                    handlerRegularizarStock= {onClickRegularizarStock}
                    handlerRegistrarSalida= {onClickRegistrarSalidaStock}
                    handlerVerTrazabilidad={onClickVerTrazabilidad}
                    pintarMasFilas= {pintarMasFilas}
                />
                <LoteEdicionModal
                    datosIniciales={datosLote}
                    mostrarModal={visibleModalLote}
                    setMostrarModal={setVisibleModalLote}
                    handlerCancelar={onClickCancelarEdicion}
                    guardarDatos={guardarDatosLote}
                />
                <LoteRegularizarModal
                    datosIniciales={datosLote}
                    mostrarModal={visibleModalRegularizacionLote}
                    setMostrarModal={setVisibleModalRegularizacionLote}
                    handlerCancelar={onClickCancelarRegularizacion}
                    guardarDatos={guardarDatosRegularizacionLote}
                />
                <SalidaProductoModal 
                        datosIniciales={datosLote}
                        mostrarModal={visibleModalSalidaStockLote} 
                        setMostrarModal={setVisibleModalSalidaStockLote}  
                        guardarDatos={guardarDatosSalidaStock} 
                    />
                <LoteTrazabilidadModal
                    datosIniciales={datosLote}
                    mostrarModal={visibleTrazabilidadLote}
                    setMostrarModal={setVisibleTrazabilidadLote}
                    handlerCancelar={onClickCancelarVerTrazabilidad}
                    />
                <Notificacion
                    tipo={infoNotificacion.tipo}
                    titulo={infoNotificacion.titulo}
                    fecha={infoNotificacion.fecha}
                    mensaje={infoNotificacion.mensaje}
                    mostrar={visibleNotificacion}
                    setMostrar={setVisibleNotificacion} />
            </aside>
        </section>
    );
};