/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useState} from 'react';
import { Button, Row, Col, FloatingLabel, Modal, Form, Container } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useQuery } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { IEtiquetaProducto, etiquetaProductoModelDefault } from '../../../tipos/ColaImpresionType';
import { queryListarImpresoras, queryObtenerPlantillaImpresion } from '../../../util/querys/ImpresionesQuerys';
import { PLANTILLA_PRODUCTO_ID } from '../../../util/tsx-ayuda/Constantes';
import { format } from 'date-fns';
import { IMovimientoStock } from '../../../tipos/MovimientosStockType';
import { impresionModelDefault } from './ColaImpresion';

/* DECLARACIONES */
const schemaValidacion = yup.object().shape({
    idImpresora: yup.number()
        .min(1, "Seleccione una impresora")
        .required("El campo es obligatorio"),
    copias: yup.number()
        .min(1, "Debe solicitar al menos 1 copia")
        .required("El campo es obligatorio"),
});

type EtiquetaProductoModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosMovimientoStock: IMovimientoStock | undefined,
    guardarDatos: (datosModelo: IEtiquetaProducto) => void
    handlerCancelar: () => void
}
    
/* COMPONENTE */
export default function EtiquetaProductoModal({datosMovimientoStock, mostrarModal, setMostrarModal, guardarDatos, handlerCancelar} : EtiquetaProductoModalProps) {
    /* definiciones */
    const [movimientoStock, setMovimientoStock] = useState<IMovimientoStock | undefined>(datosMovimientoStock);
    const { data: opcionesImpresoras, isLoading: isLoadingImpresoras } = useQuery(queryListarImpresoras());
    const { data: datosPlantilla, isLoading: isLoadingPlantilla } = useQuery(queryObtenerPlantillaImpresion(PLANTILLA_PRODUCTO_ID));
    const { register, handleSubmit, formState: {errors}, setValue, reset} = useForm<IEtiquetaProducto>({
        mode: "onSubmit", reValidateMode: "onChange",
        defaultValues: etiquetaProductoModelDefault,
        values: etiquetaProductoModelDefault,
        resolver: yupResolver(schemaValidacion),
    });


    /* funciones */
    

    
    /* efectos */ 
    React.useEffect(() => {      
        setMovimientoStock(datosMovimientoStock);
        setValue("datosMovimiento", datosMovimientoStock?? null);  
    }, [datosMovimientoStock, setValue, movimientoStock]); 

    React.useEffect(() => {
        if (!isLoadingPlantilla) {            
            setValue("plantilla", datosPlantilla?? null); 
        }
    }, [datosPlantilla, isLoadingPlantilla, setValue]);  
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal} size="lg"
            onHide={() => {reset(impresionModelDefault); handlerCancelar();}}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Impresión de etiqueta de producto
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="txtProducto">
                                    <Form.Label>Producto:</Form.Label>
                                    <Form.Control plaintext readOnly className="text-info"
                                        value={movimientoStock?.producto.nombreCorto?? movimientoStock?.producto.nombre?? "-"} />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="txtCodigo">
                                    <Form.Label >Código:</Form.Label>
                                        <Form.Control plaintext readOnly className="text-info"
                                           value={movimientoStock?.producto.codigo?? "-"} />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="txtCodigoEAN">
                                    <Form.Label >Código EAN:</Form.Label>
                                        <Form.Control plaintext readOnly className="text-info"
                                            value={movimientoStock?.producto.codigoEAN?? "-"} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="txtCodigoLote">
                                    <Form.Label>Lote:</Form.Label>
                                        <Form.Control plaintext readOnly className="text-info"
                                            value={movimientoStock?.codigoLote?? "-"} />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="txtCaducidad">
                                    <Form.Label>Fecha caducidad:</Form.Label>
                                        <Form.Control plaintext readOnly className="text-info"
                                            value={
                                                (movimientoStock?.caducidadLote !== null)? format(new Date(movimientoStock?.caducidadLote?? new Date()), "dd/MM/yyyy HH:mm") : "-"} />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="txtCopias">
                                    <Form.Label>Nº de copias</Form.Label>
                                    <Form.Control type="number" style={{width:"100px"}} maxLength={3} {...register("copias")}
                                        required={true} isInvalid={!!errors.copias}/>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.copias?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="selImpresora">
                                    <Form.Label>Impresora</Form.Label>
                                    <Form.Select {...register("idImpresora")} required={true} isInvalid={!!errors.idImpresora}>
                                        {isLoadingImpresoras && (<option value="">cargando...</option>)}
                                        {opcionesImpresoras && 
                                            [
                                                ...[{id:0, nombre: "seleccione una impresora..."}],
                                                ...opcionesImpresoras
                                            ].map((option) => {
                                            return (
                                                <option key={option.id?? 0} value={option.id?? 0}>{option.nombre}</option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.idImpresora?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() => { reset(etiquetaProductoModelDefault); handlerCancelar();}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)} disabled={datosPlantilla === null}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}