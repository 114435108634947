/* DEPENDENCIAS */
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { RUTA_LOGIN } from "../../rutas/Rutas";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { IFormCambiarClave, IUsuarioSesion, modelUsuarioSesionDefault } from "../../tipos/UsuariosType";
import { VAR_SESION_USUARIO } from "../../tipos/CommonTypes";
import CambiarClaveModal from "../panelcontrol/usuarios/CambiarClaveModal";


/* DECLARACIONES */
interface IMenuUsuarioProps {
    guardarDatosNuevaClave: (datosModelo: IFormCambiarClave) => void
}

/* COMPONENTE */
export default function MenuUsuario({guardarDatosNuevaClave} : IMenuUsuarioProps) {
    /* variables */
    const navigate = useNavigate();
    const { getItem, setItem } = useLocalStorage();

    const [usuarioSesion, setUsuarioSesion] = useState<IUsuarioSesion>(modelUsuarioSesionDefault);
    const [mostrarMenu, setMostrarMenu] = useState(false);
    const [visibleModalCambiarClave, setVisibleModalCambiarClave] = useState(false);

    /* funciones */
    const onClickDesconectar = () => {
        setItem(VAR_SESION_USUARIO, JSON.stringify(modelUsuarioSesionDefault));
        navigate(RUTA_LOGIN);
    }

    const onClickCambiarClave = () => {
        setMostrarMenu(false);
        setVisibleModalCambiarClave(true);
    }

    const guardarDatosCambiarClave = (datosForm: IFormCambiarClave) => {
        setVisibleModalCambiarClave(false);
        guardarDatosNuevaClave(datosForm);
    }

    /* efectos */
    useEffect(() => {
        const user = getItem(VAR_SESION_USUARIO);
        if (user) {
            setUsuarioSesion(JSON.parse(user));
        }
    }, []);
    
    return (
        <Fragment>
            <ul className="navbar-nav">
                <li className="nav-item dropdown">
                    <button className="nav-link" id="linkMenuUsuario"
                        data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded={mostrarMenu}>
                        <span className="material-symbols-outlined fs-2">account_circle</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end perfil"
                        aria-labelledby="linkMenuUsuario" data-bs-popper="static">
                        <div className="card position-relative border-0">
                            <div className="card-body p-0">
                                <div className="text-center pt-4 pb-3">
                                    <span className="material-symbols-outlined fs-1">account_circle</span>
                                    <h6 className="mt-2 text-primary fs-menu">{`${usuarioSesion?.nombre} ${usuarioSesion?.apellido1} ${usuarioSesion?.apellido2?? ""}`}</h6>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <ul className="nav d-flex flex-column">
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link cursor-pointer d-flex align-center" onClick={onClickCambiarClave}>
                                            <span className="material-symbols-outlined fs-menu">password</span>&nbsp;
                                            cambiar mi contraseña
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link cursor-pointer d-flex align-center">
                                            <span className="material-symbols-outlined fs-menu">contact_support</span>&nbsp;
                                            ayuda
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body px-0 border-top">
                                <p className="text-muted text-center font-weight-light">{usuarioSesion.perfil.nombre}</p>
                                <hr />
                                <div className="px-3">
                                    <button className="btn btn-light d-flex flex-center w-100" onClick={onClickDesconectar}>
                                        <span className="material-symbols-outlined me-1">login</span>
                                        Desconectar
                                    </button>
                                </div>
                                <div className="my-2 text-center fs--1 link-dark">
                                    &#x2022;
                                    <a className="text-300 mx-1 link-dark" href="https://www.softmarka.es" target="_blank" rel="noreferrer">Marka Inform&aacute;tica</a>
                                    &#x2022;
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <CambiarClaveModal
                mostrarModal={visibleModalCambiarClave}
                setMostrarModal={setVisibleModalCambiarClave}
                guardarDatos={guardarDatosCambiarClave}
            />            
        </Fragment>
    );
}