//DEPENDENCIAS
import { ChangeEvent, ChangeEventHandler, Fragment, useState } from "react";
import { Form } from "react-bootstrap";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/esm/helpers";


//DECLARACIONES
type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
interface InputTextCounterProps extends React.PropsWithChildren<ReplaceProps<React.ElementType, BsPrefixProps<React.ElementType> & unknown>> {
    maxLength: number,
    onChange?: ChangeEventHandler<FormControlElement>,
}


//COMPONENTE
const InputTextCounter = ({maxLength, onChange, ...rest } : InputTextCounterProps) => {
    /* definiciones */
    const [inputText, setInputText] = useState("");

    
    /* funciones */
    function handlerChange(event: ChangeEvent<FormControlElement>): void {
        setInputText(event.target.value);
        if (onChange) onChange(event);
    }


    /* renderizado */
    return (
        <Fragment>
            <Form.Control {...rest}
                maxLength={maxLength}
                value={inputText}
                onChange={handlerChange}
            />
            <Form.Text className="text-muted">
                {`${inputText.length}/${maxLength}`}
            </Form.Text>
        </Fragment>
    );
}

export default InputTextCounter;