 
import {Table, flexRender} from "@tanstack/react-table";
import PanelError from '../../../componentes-com/PanelError';
import PanelCargando from '../../../componentes-com/PanelCargando';
import { IFamiliaProducto } from '../../../tipos/FamiliasProductosType';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import React, { useEffect } from "react";
import PanelSinResultados from "../../../componentes-com/PanelSinResultados";

type FamiliasProductosTableProps = {
    instanciaTabla : Table<IFamiliaProducto>,
    isError: boolean,
    error: Error | null,
    isLoading: boolean,
    isFetching: boolean,
    fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<IFamiliaProducto[], Error>>,
    pintarMasFilas : boolean,
  }
  
const FamiliasProductosTable = ({
        instanciaTabla, 
        isError, error, isLoading,
        isFetching, fetchNextPage,
        pintarMasFilas
    } : FamiliasProductosTableProps) => {
    /* definiciones */
    
    /* funciones */
    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = React.useCallback(
        () => {
            if (pintarMasFilas && !isFetching /*&& totalFetched < totalDBRowCount*/) {
                fetchNextPage();
            }
        },
        [fetchNextPage, isFetching, pintarMasFilas, /*totalFetched, totalDBRowCount*/]
    );

    /* efectos */
    useEffect(() => {
        fetchMoreOnBottomReached();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pintarMasFilas]);

    /* renderizado */
    return (
        <div className="container-fluid p-0 m-0">
        <table className="table table-responsive">
            <thead>
            {instanciaTabla.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                    <th 
                        key={header.id}
                        style={{
                            width: header.getSize() !== 150 ? header.getSize() : undefined
                        }}
                        {...{
                            className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                            onClick: header.column.getToggleSortingHandler(),
                        }}
                        >
                        {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                            )}
                    </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody className="table-group-divider">
                {isError && (
                    <tr>
                        <td colSpan={3} className="text-center">
                            <PanelError mensaje={`Ha ocurrido un error cargando los datos: ${error?.message}`} />
                        </td>
                    </tr>)
                }
                { isLoading && (<tr><td colSpan={3}><PanelCargando /></td></tr>)}
                { (!isLoading && instanciaTabla.getRowModel().rows.length === 0) && 
                    (<tr><td colSpan={3} className="text-center"><PanelSinResultados mensaje="--- no se han encontrado resultados ---" /></td></tr>)}
                {instanciaTabla.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className={cell.column.columnDef.meta?.className}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                    </tr>
                ))}                      
            </tbody>
        </table>
        </div>
    );
}
export default FamiliasProductosTable;