/* dependencias */
import { useLayoutEffect, useRef } from 'react';

/* hook */
export default function useResizeObserver<T extends HTMLElement>(callback: (target: T, entry: ResizeObserverEntry) => void) {
    /* definiciones */
    const ref = useRef<T>(null)

    /* efectos */
    useLayoutEffect(() => {
        const element = ref?.current;

        if (!element)  return;
        const observer = new ResizeObserver((entries) => {
            callback(element, entries[0]);
        });

        observer.observe(element);
        return () => {
            observer.disconnect();
        };
    }, [callback, ref]);

    /* salida */
    return ref;
}