/**
 * Interfaz que define un objeto de un proveedor en la IU.
 */
interface IProveedor {
    id: number,
    nombre: string,
    nombreFiscal: string,
    cif?: string | null,
    domicilio?: string | null,
    poblacion?: string | null,
    provincia?: string | null,
    pais?: string | null,
    codigoPostal?: string | null,
    email?: string | null,
    telefonos?: string | null,
    formaPago?: string | null,
    modoPago?: string | null
}

const proveedorModelDefault : IProveedor = {
    id: 0,
    nombre: "",
    nombreFiscal: "",
    cif: null,
    domicilio: null,
    poblacion: null,
    provincia: null,
    pais: null,
    codigoPostal: null,
    email: null,
    telefonos: null,
    formaPago: null,
    modoPago: null
}

/* EXPORTS */
export type {
    IProveedor
}

export {
    proveedorModelDefault
}