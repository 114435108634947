/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useEffect, useState} from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Col, Container, FloatingLabel, Row } from 'react-bootstrap';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { ObtenerCssBadgeStock } from '../../../util/tsx-ayuda/Funciones';
import { ILote, loteInsertableModelDefault, loteModelDefault } from "../../../tipos/LotesType";
import { ETipoMovimientoStock, IMovimientoStockInsertable, movimientoStockRegularizacionModelDefault } from '../../../tipos/MovimientosStockType';

/* DECLARACIONES */
const schemaValidacion = yup.object().shape({
    cantidad: yup.number().min(0, "La regularización de stock tiene que ser mayor o igual a 0.")
        .required("El campo es obligatorio"),
});

type LoteRegularizarModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: ILote,
    handlerCancelar: () => void,
    guardarDatos: (datosModelo: IMovimientoStockInsertable) => void
}

  
/* COMPONENTE */
export default function LoteRegularizarModal({datosIniciales, mostrarModal, setMostrarModal, handlerCancelar, guardarDatos} : LoteRegularizarModalProps) {
    /* definiciones */
    const [datosLote, setDatosLote] = useState(loteModelDefault);
    const { register, handleSubmit, formState: {errors}, getValues, watch, reset } = useForm<IMovimientoStockInsertable>({
        mode: "onTouched",
        defaultValues: {
            ...movimientoStockRegularizacionModelDefault,
            cantidad: datosIniciales.stock
        },
        values: {
            ...movimientoStockRegularizacionModelDefault,
            idProducto: datosIniciales.idProducto,
            tipo: ETipoMovimientoStock.REGULARIZACION,
            lote: datosIniciales,
        },
        resolver: yupResolver(schemaValidacion),
    });
    
    /* funciones */
    useEffect(() => {
            setDatosLote(datosIniciales);
        if (datosIniciales) {
            reset({
                ...movimientoStockRegularizacionModelDefault,
                idProducto: datosIniciales.idProducto,
                tipo: ETipoMovimientoStock.REGULARIZACION,
                lote: datosIniciales,
            })
        } else {
            reset(loteModelDefault);
        }

        
    }, [reset, datosIniciales, setDatosLote]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal} size="lg"
            onHide={() => {reset(loteInsertableModelDefault); handlerCancelar();}}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Lote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <FloatingLabel className="mb-3" controlId="txtNombreProducto" label="Producto">
                                    <Form.Control plaintext className="text-dark" value={datosLote.producto.nombre} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={3}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Stock mínimo">
                                    <Form.Control plaintext className="text-dark" value={datosLote.producto.stockMinimo} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={3}>
                                <Form.Floating>
                                    <span id="txtStock" className="form-control-plaintext">
                                        <span className={`badge ${ObtenerCssBadgeStock(datosLote.stock, datosLote.producto.stockMinimo)}`}>
                                            {datosLote.stock}
                                        </span>
                                    </span>
                                    <label htmlFor="txtStock">Stock actual</label>
                                </Form.Floating>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Código">
                                    <Form.Control plaintext className="text-dark" value={datosLote.codigo} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={4}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Caducidad">
                                    <Form.Control plaintext className="text-dark" 
                                        value={(datosLote.caducidad)? format(new Date(datosLote.caducidad), "dd/MM/yyyy") : "-"} />
                                </FloatingLabel>                                              
                            </Col>
                            <Col sm={4}>
                                <FloatingLabel className="mb-3" controlId="txtStockMinimo" label="Consumo preferente">
                                    <Form.Control plaintext className="text-dark" 
                                        value={(datosLote.consumoPreferente)? format(new Date(datosLote.consumoPreferente), "dd/MM/yyyy") : "-"} />
                                </FloatingLabel>                                               
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="txtNuevoStock">
                                    <Form.Label>Nuevo stock</Form.Label>
                                    <Form.Control type="number" required={true} {...register("cantidad")} 
                                        isInvalid={!!errors.cantidad} style={{width: 120}} />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.cantidad?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel controlId="txtObservaciones" label="Observaciones sobre la regularización">
                                    <Form.Control as="textarea" {...register("observaciones")} 
                                        defaultValue={getValues("observaciones")} maxLength={300} 
                                        rows={5} style={{height:"120px"}} />
                                    <Form.Text className="text-muted" hidden={watch("observaciones")?.length === 0}>
                                        {`${watch("observaciones")?.length?? 0}/300`}
                                    </Form.Text>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Container>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() =>  {reset(loteInsertableModelDefault); handlerCancelar();}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}