/* DEPENDENCIAS */
import React, { Fragment, useState } from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { ColumnDef, SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Button } from "react-bootstrap";
import {TiArrowUnsorted} from "react-icons/ti";

import { ICama } from "../../../tipos/HabitacionesType";
import CamasTable from "./CamasTable";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { IUsuarioSesion } from "../../../tipos/UsuariosType";
import { EFuncionalidad, VAR_SESION_USUARIO } from "../../../tipos/CommonTypes";
import { TienePermiso } from "../../../util/tsx-ayuda/Funciones";

/* DEFINICIONES */
type CamasInstanceProps = {
  tableData: ICama[],
  isError: boolean,
  error: Error | null,
  isLoading: boolean,
  isFetching: boolean,
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<ICama[], Error>>,
  pintarMasFilas : boolean,
  handlerImprimirEtiqueta : (datosModelo: ICama) => void,
  handlerEditar : (datosModelo: ICama) => void,
  handlerEliminar : (datosModelo : ICama) => void,
}

/* COMPONENTE */
const ProductosInstance = ({ 
    tableData, 
    isError, error, isLoading, 
    isFetching, fetchNextPage,
    pintarMasFilas,
    handlerImprimirEtiqueta, handlerEditar, handlerEliminar
  }: CamasInstanceProps) => {
    /* definiciones */
    const [sorting, setSorting] = React.useState<SortingState>([{
      id: "nombre",
      desc: false
    }]); 
    const { getItem } = useLocalStorage();
    const [accesoEditar, setAccesoEditar] = useState<boolean>(false);
    const [accesoImprimir, setAccesoImprimir] = useState<boolean>(false);

    const columnsDef: ColumnDef<ICama>[] = [
      {
        accessorKey: "codigo",
        header: 'Código',
        size: 120,
        cell: info => info.getValue(),
        meta: {
          className: "align-middle",
        }
      },
      {
        accessorKey: "nombre",
        header: () => {
          return(
            <div className="d-flex cursor-pointer">
              <span>Nombre&nbsp;</span>
              <TiArrowUnsorted style={{ marginTop:2 }} />
            </div>
          );
        },
        cell: info => info.getValue(), 
        meta: {
          className: "align-middle",
        }      
      },
      {
        id: 'opciones',
        header: '',
        size: 180,
        cell: (info) => {
          const datos = info.row.original;
          return (
          <Fragment>
            <span className={`${!accesoImprimir? 'disabled-button-wrapper' : ''}`}>
              <Button type="button" variant="outline-info" size="sm" className="me-1" title="imprimir etiqueta" disabled={!accesoImprimir}
                onClick={ () => { handlerImprimirEtiqueta(datos); } }>
                <div className="d-flex">
                    <span className="material-symbols-outlined">print</span>
                </div>
              </Button>
            </span>
            <span className={`${!accesoEditar? 'disabled-button-wrapper' : ''}`}>
              <Button type="button" variant="success" size="sm" className="me-1" disabled={!accesoEditar}
                onClick={ () => { handlerEditar(datos); } }>
                <div className="d-flex">
                    <span className="material-symbols-outlined">edit_note</span>&nbsp;editar
                </div>
              </Button>
            </span>
            <span className={`${!accesoEditar? 'disabled-button-wrapper' : ''}`}>
              <Button type="button" variant="outline-warning" size="sm" title="eliminar proveedor" disabled={!accesoEditar}
                onClick={ () => { handlerEliminar(datos); } }>
                <span className="material-symbols-outlined">delete</span>
              </Button>
            </span>
          </Fragment>);
        }, 
        meta: {
          className: "align-middle",
        }      
      },
    ];

    const tableInstance = useReactTable({ 
      data: tableData, 
      columns: columnsDef,
      state: {
        sorting,
      },
      enableSortingRemoval: false,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });
  
    /* efectos */    
    React.useEffect(() => {
        let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
        if (usuarioSesion) {
          setAccesoEditar(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.HOSPITAL_HABITACIONES_EDICION));
          setAccesoImprimir(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.HOSPITAL_HABITACIONES_IMPRIMIR_ETIQUETA));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    /* renderizado */
    return (
      <CamasTable instanciaTabla={tableInstance}
        isError={isError} error={error} isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        pintarMasFilas={pintarMasFilas} />
    );
  }
  export default ProductosInstance;