/* DEPENDENCIAS */
import { IProductoLote, productoLoteDefault } from "./ProductosType";


/**
 * Interfaz que define un objeto de una habitación en la IU.
 */
interface ILote {
   id : number,
   idProducto : number,
   producto: IProductoLote,
   codigo : string;
   caducidad? : Date | null,
   consumoPreferente? : Date | null,
   stock: number,
   general: boolean,
}

const loteModelDefault : ILote = {
   id : 0,
   idProducto : 0,
   producto: productoLoteDefault,
   codigo : "",
   stock:0,
   general: false
}

/**
 * Interfaz que define un objeto de una habitación en la IU.
 */
interface ILoteInsertable {
   id : number,
   idProducto : number,
   codigo : string;
   caducidad? : Date | null,
   consumoPreferente? : Date | null,
   stock: number,
   general: boolean,
}

const loteInsertableModelDefault : ILoteInsertable = {
   id : 0,
   idProducto : 0,
   codigo : "",
   stock:0,
   general: false
}

interface IFiltrosLotes {
   idProducto?: number,
   codigo?: string,
   esGeneral?: boolean,
   constock?: boolean,
   caducados?: boolean,
   caducanHoy?: boolean,
   caducanSemana?: boolean,
   caducanMes?: boolean,
}

const filtrosLoteDefault : IFiltrosLotes = {
   idProducto: undefined,
   codigo: undefined,
   esGeneral: false,
   constock: true,
}

interface ICabeceraCaducidades {
   lotesCaducados: number,
   lotesCaducanHoy:number,
   lotesCaducanSemana:number,
   lotesCaducanMes:number,
}

const cabeceraCaducidadesModelDefault : ICabeceraCaducidades = {
   lotesCaducados: 0,
   lotesCaducanHoy:0,
   lotesCaducanSemana:0,
   lotesCaducanMes:0,
}

/* EXPORTS */
export type { 
   ILote, ILoteInsertable, IFiltrosLotes,   
   ICabeceraCaducidades,
}

export {
   loteModelDefault, loteInsertableModelDefault, filtrosLoteDefault,
   cabeceraCaducidadesModelDefault
}