/* DEPENDENCIAS */
import React, { useCallback, useState } from 'react';
import { Button, Form, Breadcrumb, Row, Col, InputGroup, FloatingLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import * as APILotes from '../../../servicios/APILotes'
import * as APIMovimientosStock from '../../../servicios/APIMovimientosStock'
import * as APIImpresiones from '../../../servicios/APIImpresiones';
import useResizeObserver from '../../../hooks/useResizeObserver';
import * as Rutas from '../../../rutas/Rutas';
import { IFiltrosLotes, ILote, ILoteInsertable, filtrosLoteDefault, loteModelDefault } from '../../../tipos/LotesType';
import Notificacion, { ETipoNotificacion } from '../../../componentes-com/Notificacion';
import LotesQuery from './LotesQuery';
import ProductosSeleccionModal from '../productos/ProductosSeleccionModal';
import { IProducto, productoModelDefault } from '../../../tipos/ProductosType';
import { format } from 'date-fns';
import { KEYPLotesLista } from '../../../util/querys/LotesQuerys';
import LoteEdicionModal from './LoteEdicionModal';
import { IMovimientoStock, IMovimientoStockInsertable, movimientoStockModelDefault } from '../../../tipos/MovimientosStockType';
import { KEYPMovimientosStockListaPaginada } from '../../../util/querys/MovimientosStockQuerys';
import LoteRegularizarModal from './LoteRegularizarModal';
import SalidaProductoLoteModal from '../salidasmercancia/SalidaProductoLoteModal';
import LoteTrazabilidadModal from './LoteTrazabilidadModal';
import { IEtiquetaProducto, IImpresionInsertable } from '../../../tipos/ColaImpresionType';
import { KEYPColaImpresionListaPaginada } from '../../../util/querys/ImpresionesQuerys';
import EtiquetaProductoModal from '../colaimpresion/EtiquetaProductoModal';
import { PLANTILLA_PRODUCTO_ID } from '../../../util/tsx-ayuda/Constantes';
import { TransformarPlantillaProducto } from '../../../util/tsx-ayuda/Funciones';


/* DECLARACIONES */
type InfoNotificacion = {
    tipo: ETipoNotificacion,
    titulo: string,
    fecha: string,
    mensaje: string,
}

const infoNotificacionDefault : InfoNotificacion = {
    tipo: ETipoNotificacion.INFO,
    titulo: "",
    fecha: "",
    mensaje: ""
}

/* componente */
export default function Lotes() { 
    /* definiciones */
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [filtros, setFiltros] = useState<IFiltrosLotes>(filtrosLoteDefault);
    const { register, reset, getValues, setValue, handleSubmit } = useForm<IFiltrosLotes>({
        defaultValues: filtrosLoteDefault,
    });
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);

    const [datosProducto, setDatosProducto] = useState<IProducto>(productoModelDefault);
    const [visibleModalProductos, setVisibleModalProductos] = useState(false);
    
    const [datosLote, setDatosLote] = useState<ILote>(loteModelDefault);
    const [visibleModalLote, setVisibleModalLote] = useState(false);
    const editarLote = useEditarLote();

    const [visibleModalRegularizacionLote, setVisibleModalRegularizacionLote] = useState(false);
    const [visibleModalSalidaStockLote, setVisibleModalSalidaStockLote] = useState(false);
    const [visibleTrazabilidadLote, setVisibleTrazabilidadLote] = useState(false);
    const agregarMovimientoStock = useAgregarMovimientoStock();
    

    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);

    const [visibleModalEtiquetaLote, setVisibleModalEtiquetaLote] = useState(false);
    const [datosMovimientoStock, setDatosMovimientoStock] = useState<IMovimientoStock>(movimientoStockModelDefault);
    const agregarImpresionEtiquetaLote = useAgregarImpresionEtiquetaLote();


    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);

    
    const onClickSeleccionarProducto = () => {
        setVisibleModalProductos(true);
    }

    const handlerProductoSeleccionado = (datosModal: IProducto) => {
        setVisibleModalProductos(false);
        setDatosProducto({
            ...datosProducto,
            ...datosModal
        });
        setValue("idProducto", datosModal.id);
    }    
       
    const handlerBuscar = () => {
        let filtrosForm : IFiltrosLotes = {
            idProducto: ((getValues().idProducto?? -1) > 0)? getValues().idProducto : undefined,
            codigo: (getValues().codigo !== "")? getValues().codigo : undefined,
            esGeneral: getValues().esGeneral,
            constock: getValues().constock,
        }
        setFiltros(filtrosForm);
    };

    const handlerResetFiltros = () => {
        reset(filtrosLoteDefault);
        setDatosProducto(productoModelDefault);
        setFiltros(filtrosLoteDefault);
    };

    
    const onClickCancelarEdicion = () => {
        setVisibleModalLote(false);
        setDatosLote(loteModelDefault);
    }

    const onClickEditar = (datosModelo : ILote) => {   
        setDatosLote(datosModelo);     
        setVisibleModalLote(true);
    }
    
    const guardarDatosLote = (datosModelo : ILote) => {
        let datosModeloInsertable : ILoteInsertable = { 
            ...datosModelo 
        }
        
        setVisibleModalLote(false);
        editarLote.mutate(datosModeloInsertable);
    }

    function useEditarLote() {
        return useMutation({
          mutationFn: (datosModelo : ILoteInsertable) => {
            return APILotes.EditarLote(datosModelo);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPLotesLista]});
            setDatosLote(loteModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Editar lote",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>El <strong>lote</strong> se ha editado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Editar lote",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error editando el <strong>lote</strong>: <em>${variables.codigo}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    const onClickCancelarRegularizacion = () => {
        setVisibleModalRegularizacionLote(false);
        setDatosLote(loteModelDefault);
    }

    const onClickRegularizarStock = (datosModelo : ILote) => {
        setDatosLote(datosModelo);     
        setVisibleModalRegularizacionLote(true);
    }
    
    const guardarDatosRegularizacionLote = (datosModelo : IMovimientoStockInsertable) => {        
        setVisibleModalRegularizacionLote(false);
        setDatosLote(loteModelDefault);
        agregarMovimientoStock.mutate(datosModelo);
    }

    function useAgregarMovimientoStock() {
        return useMutation({
          mutationFn: (datosModelo : IMovimientoStockInsertable) => {
            return APIMovimientosStock.AgregarMovimientoStock(datosModelo);
          },
          onSuccess: (datosRespuesta: IMovimientoStockInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPLotesLista]});
            queryClient.invalidateQueries({ queryKey: [KEYPMovimientosStockListaPaginada]});
            setDatosLote(loteModelDefault);
         
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar movimiento de stock",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>El movimiento de stock del lote <strong>${datosRespuesta.lote?.codigo}</strong> se ha hecho correctamente.</span>`
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar movimiento de stock",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error guardando un <strong>movimiento de stock</strong>: <em>${error.message}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    const onClickRegistrarSalidaStock = (datosModelo : ILote) => {
        setDatosLote(datosModelo);     
        setVisibleModalSalidaStockLote(true);
    }

    const guardarDatosSalidaStock = (datosModelo : IMovimientoStockInsertable) => {        
        setVisibleModalSalidaStockLote(false);
        setDatosLote(loteModelDefault);
        agregarMovimientoStock.mutate(datosModelo);
    }

    const onClickCancelarVerTrazabilidad = () => {
        setVisibleTrazabilidadLote(false);
        setDatosLote(loteModelDefault);
    }

    const onClickVerTrazabilidad = (datosModelo : ILote) => {
        setDatosLote(datosModelo);     
        setVisibleTrazabilidadLote(true);
    }

    
    function useAgregarImpresionEtiquetaLote() {
        return useMutation({
          mutationFn: (datosModelo : IImpresionInsertable) => {
            return APIImpresiones.AgregarImpresion(datosModelo);
          },
          onSuccess: (datosRespuesta: IImpresionInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPColaImpresionListaPaginada]});
            setVisibleModalEtiquetaLote(false);

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar impresión de lote",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>impresión de etiqueta del lote</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar impresión de producto",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>impresión de etiqueta del lote</strong>:${error.message}</span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
      
    const onClickCancelarImprimirEtiquetaLote = () => {
        setDatosMovimientoStock(movimientoStockModelDefault);
        setVisibleModalEtiquetaLote(false);
    }

    const onClickImprimirEtiquetaLote = (itemListado : ILote) => {
        let datosModelo : IMovimientoStock;

        datosModelo = {
            ...movimientoStockModelDefault,
            idProducto: itemListado.idProducto,
            producto: itemListado.producto,
            idLote: itemListado.id,
            codigoLote: itemListado.codigo,
            caducidadLote: itemListado.caducidad?? null,            
        }
        setDatosMovimientoStock(datosModelo);
        setVisibleModalEtiquetaLote(true);
    }
    
    const guardarEtiquetaLote = (datosModelo : IEtiquetaProducto) => {
        let nuevaImpresion : IImpresionInsertable = {
            id: 0,
            idImpresora: datosModelo.idImpresora,
            idPlantilla: PLANTILLA_PRODUCTO_ID, //plantilla - producto.
            fecha:  new Date(),
            textoImpresion: TransformarPlantillaProducto(datosModelo),
            fechaImpresion: null
        };
        agregarImpresionEtiquetaLote.mutate(nuevaImpresion);
    }

    /* efectos */
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="accordion" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="accordion-item">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-7 pt-3 ps-4">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Almacén</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Lotes</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelCabecera"
                            aria-expanded="true" aria-controls="panelCabecera">
                            <div className="container-fluid px-0"><h2>Lotes</h2></div>
                        </button>
                    </h2>
                    <div id="panelCabecera" className="accordion-collapse collapse show" data-bs-parent="#contenedorCabeceraListado">
                        <div className="accordion-body">
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="txtProducto">
                                        <InputGroup className="mb-3">
                                            <Button variant="outline-info" id="btnSeleccionarProducto" onClick={onClickSeleccionarProducto}>
                                                seleccione un producto
                                            </Button>
                                            <Form.Control type="hidden" value={datosProducto.id} {...register("idProducto")} />
                                            <Form.Control readOnly value={datosProducto.nombre}
                                                aria-label="filtro por producto"
                                                aria-describedby="btnSeleccionarProducto"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <ProductosSeleccionModal
                                        mostrarModal={visibleModalProductos}
                                        setMostrarModal={setVisibleModalProductos}                                        
                                        seleccionarProducto={handlerProductoSeleccionado}
                                    />
                                </Col>
                            </Row>
                            <div className="row g-sm-2">
                                <div className="col-lg-3 col-md-4">
                                    <Form.Control type="text" maxLength={300} {...register("codigo")}
                                        placeholder="filtrar por código..." />
                                </div> 
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FloatingLabel controlId="selConStock" label="¿con stock?">
                                        <Form.Select aria-label="¿con stock?" {...register("constock")}>
                                            <option value="">Todos</option>
                                            <option value="true">Si</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>                              
                                <div className="col-lg-6 col-md-4 d-flex align-items-end  justify-content-end">
                                    <div className="d-flex">
                                        <Button variant='link' className="nav-link me-3" onClick={handleSubmit(handlerResetFiltros)}>
                                            <div className='d-flex'>
                                                    <span className="material-symbols-outlined">filter_alt_off</span>
                                                    reiniciar filtros
                                            </div> 
                                        </Button>
                                        <Button variant='info' className='mx-1' onClick={handleSubmit(handlerBuscar)}>
                                            <div className='d-flex'>
                                                    <span className="material-symbols-outlined">filter_alt</span>
                                                    buscar
                                            </div> 
                                        </Button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}
                onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                <LotesQuery  
                    filtrosQuery={filtros} 
                    handlerEditar={onClickEditar}
                    handlerRegularizarStock= {onClickRegularizarStock}
                    handlerRegistrarSalida= {onClickRegistrarSalidaStock}
                    handlerVerTrazabilidad={onClickVerTrazabilidad}
                    handlerImprimirEtiquetaLote={onClickImprimirEtiquetaLote}
                    pintarMasFilas= {pintarMasFilas}
                />
                <LoteEdicionModal
                    datosIniciales={datosLote}
                    mostrarModal={visibleModalLote}
                    setMostrarModal={setVisibleModalLote}
                    handlerCancelar={onClickCancelarEdicion}
                    guardarDatos={guardarDatosLote}
                />
                <LoteRegularizarModal
                    datosIniciales={datosLote}
                    mostrarModal={visibleModalRegularizacionLote}
                    setMostrarModal={setVisibleModalRegularizacionLote}
                    handlerCancelar={onClickCancelarRegularizacion}
                    guardarDatos={guardarDatosRegularizacionLote}
                />
                <SalidaProductoLoteModal 
                        datosIniciales={datosLote}
                        mostrarModal={visibleModalSalidaStockLote}
                        setMostrarModal={setVisibleModalSalidaStockLote}  
                        guardarDatos={guardarDatosSalidaStock} 
                    />
                <LoteTrazabilidadModal
                    datosIniciales={datosLote}
                    mostrarModal={visibleTrazabilidadLote}
                    setMostrarModal={setVisibleTrazabilidadLote}
                    handlerCancelar={onClickCancelarVerTrazabilidad}
                />
                <EtiquetaProductoModal
                    mostrarModal={visibleModalEtiquetaLote}
                    setMostrarModal={setVisibleModalEtiquetaLote}
                    datosMovimientoStock={datosMovimientoStock}
                    handlerCancelar={onClickCancelarImprimirEtiquetaLote}
                    guardarDatos={guardarEtiquetaLote}
                />
                <Notificacion
                    tipo={infoNotificacion.tipo}
                    titulo={infoNotificacion.titulo}
                    fecha={infoNotificacion.fecha}
                    mensaje={infoNotificacion.mensaje}
                    mostrar={visibleNotificacion}
                    setMostrar={setVisibleNotificacion} />
            </aside>
        </section>
    );
};