/* dependencias */
import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

import * as APIMovimientosStock from '../../../servicios/APIMovimientosStock'
import Notificacion, { ETipoNotificacion, InfoNotificacion } from '../../../componentes-com/Notificacion';
import { IMovimientoStockInsertable } from '../../../tipos/MovimientosStockType';
import { KEYPSalidasStockLista } from '../../../util/querys/MovimientosStockQuerys';
import { productoLoteDefault } from '../../../tipos/ProductosType';
import { ILote, loteModelDefault } from '../../../tipos/LotesType';
import { PLANTILLA_PRODUCTO_ID } from '../../../util/tsx-ayuda/Constantes';
import SalidaProductoLoteModal from '../salidasmercancia/SalidaProductoLoteModal';
import { queryObtenerLote, queryObtenerLoteGeneralProducto } from '../../../util/querys/LotesQuerys';
import { queryObtenerProducto } from '../../../util/querys/ProductosQuerys';
import PanelCargando from '../../../componentes-com/PanelCargando';


/* declaraciones */
const infoNotificacionDefault : InfoNotificacion = {
    tipo: ETipoNotificacion.INFO,
    titulo: "",
    fecha: "",
    mensaje: ""
}

/* componente */
export default function QRSalidaMercancia() { 
    /* definiciones */
    const queryClient = useQueryClient();
    const [textoInformacion, setTextoInformacion] = useState<string>("Por favor, lea la etiqueta con el scanner");
    const [inputQRValue, setInputQRValue] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    let datosLoteFetch : ILote = loteModelDefault;
    const [datosLote, setDatosLote] = useState<ILote>(loteModelDefault);
    
    const [visibleModalSalidaStock, setVisibleModalSalidaStock] = useState(false);
    const agregarSalidaMercancia = useAgregarSalidaMercancia();
    

    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const inputQRRef = React.useRef<HTMLInputElement>(null);
    
    /* funciones */ 
    const ObtenerInformacionProducto = async (id:number) => {
        try {
            setTextoInformacion("Cargando datos del producto...");
            setIsLoading(true);
            const dataFetchProducto = await queryClient.fetchQuery(queryObtenerProducto(id));
            if (dataFetchProducto != null) {
                datosLoteFetch = {
                    ...loteModelDefault,
                    idProducto: dataFetchProducto.id,                    
                    producto: {
                        ...productoLoteDefault,
                        ...dataFetchProducto,                                            
                    },
                };
            }
            else {
                //lanzamos aviso...
                setInfoNotificacion({
                    ...infoNotificacion,
                    tipo: ETipoNotificacion.EXITO,
                    titulo: "Agregar salida de mercancía",
                    fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                    mensaje: "<span>No se encuentra el <strong>producto</strong> leido a través de QR para la salida de mercancía.</span>"
                });
                setVisibleNotificacion(true);
            }
          } catch (e:any) {
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar salida de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error al cargar los datos del <strong>producto</strong> leido a través de QR para la salida de mercancía:${e.message}</span>`
            });
            setVisibleNotificacion(true);
          }
          setIsLoading(false);
    }
 
    const ObtenerInformacionLote = async (id:number) => {
        try {
            setTextoInformacion("Cargando datos del lote...");
            setIsLoading(true);
            if (id > 0) {
                const dataFetchLote = await queryClient.fetchQuery(queryObtenerLote(id));
                if (dataFetchLote != null) {
                    datosLoteFetch = {
                        ...datosLoteFetch,
                        ...dataFetchLote,
                    };
                } else {
                    //lanzamos aviso...
                    setInfoNotificacion({
                        ...infoNotificacion,
                        tipo: ETipoNotificacion.EXITO,
                        titulo: "Agregar salida de mercancía",
                        fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                        mensaje: "<span>No se encuentra el <strong>lote</strong> leido a través de QR para la salida de mercancía.</span>"
                    });
                    setVisibleNotificacion(true);
                }
            } else {
                //solución a cagada de etiquetas con idLote=0 (03/10/2023)
                const dataFetchLote = await queryClient.fetchQuery(queryObtenerLoteGeneralProducto(datosLoteFetch?.idProducto?? null));
                if (dataFetchLote != null) {
                    datosLoteFetch = {
                        ...datosLoteFetch,
                        ...dataFetchLote,
                    };
                } else {
                    //lanzamos aviso...
                    setInfoNotificacion({
                        ...infoNotificacion,
                        tipo: ETipoNotificacion.EXITO,
                        titulo: "Agregar salida de mercancía",
                        fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                        mensaje: "<span>No se encuentra el <strong>lote</strong> leido a través de QR para la salida de mercancía.</span>"
                    });
                    setVisibleNotificacion(true);
                }
            }
          } catch (e:any) {
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar salida de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error al cargar los datos del <strong>lote</strong> leido a través de QR para la salida de mercancía:${e.message}</span>`
            });
            setVisibleNotificacion(true);
          }
          setIsLoading(false);
    }

    function guardarDatosNuevaSalidaStock(datosModelo: IMovimientoStockInsertable): void {
        agregarSalidaMercancia.mutate(datosModelo);
    }
  
    function useAgregarSalidaMercancia() {
        return useMutation({
          mutationFn: (datosModelo : IMovimientoStockInsertable) => {
            return APIMovimientosStock.AgregarMovimientoStock(datosModelo);
          },
          onSuccess: (datosRespuesta: IMovimientoStockInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPSalidasStockLista]});
            datosLoteFetch = loteModelDefault;
            setDatosLote(datosLoteFetch);         
            setVisibleModalSalidaStock(false);
            setInputQRValue("");

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar salida de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>salida de mercancía</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar salida de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>salida de mercancía</strong>.</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    /* efectos */
    React.useEffect(() => {
        inputQRRef.current?.focus();
    }, []);

    React.useEffect(() => {
        const onChangeInputQR = async () => {
            let cadenaEntrada = inputQRValue;
            let arrayQR : string[];
            if (cadenaEntrada.slice(0,5) === "[BOF]" && cadenaEntrada.slice(cadenaEntrada.length-5) === "[EOF]") {
                cadenaEntrada = cadenaEntrada.replace("[BOF]","");
                cadenaEntrada = cadenaEntrada.replace("[EOF]","");
                cadenaEntrada = cadenaEntrada.trim();
                arrayQR = cadenaEntrada.split('|');
                if (parseInt(arrayQR[0]) !== PLANTILLA_PRODUCTO_ID) {
                    setInputQRValue("");
    
                    //lanzamos aviso...
                    setInfoNotificacion({
                        ...infoNotificacion,
                        tipo: ETipoNotificacion.EXITO,
                        titulo: "Agregar salida de mercancía",
                        fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                        mensaje: "<span>La <strong>etiqueta</strong> leida a través de QR no se corresponde con una etiqueta de producto.</span>"
                    });
                    setVisibleNotificacion(true);
                } else {
                    await ObtenerInformacionProducto(parseInt(arrayQR[1]));
                    await ObtenerInformacionLote(parseInt(arrayQR[2]));
                    setTextoInformacion("Por favor, lea la etiqueta con el scanner");
                    setDatosLote(datosLoteFetch);
                    setVisibleModalSalidaStock(true);
                }
            } else setInputQRValue("");
        }

        if (inputQRValue.slice(0,5) === "[BOF]" && inputQRValue.slice(inputQRValue.length-5) === "[EOF]")
            onChangeInputQR();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputQRValue]);

    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid" ref={formCabeceraRef} style={{height:"100%"}}>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay p-3 m-0" 
                ref={contenidoFormularioRef} style={{height:"100%"}}>
                <h3 className='text-center'>SALIDA DE MERCANCÍA CON [QR]</h3>
                <Container fluid className="text-center">
                    <Row className="align-items-center">
                        <Col sm={12} className="align-self-center">
                            <span className="material-symbols-outlined" style={{fontSize:"5rem"}}>qr_code_2_add</span>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col sm={12} className="align-self-center">
                        <p className="lead">                            
                            { isLoading && (<PanelCargando texto={textoInformacion} />)}
                            { !isLoading && textoInformacion}
                        </p>
                        <Form.Control type="text" ref={inputQRRef}
                            value={inputQRValue} onChange={(e)=> setInputQRValue(e.currentTarget.value)}
                        />
                        </Col>
                    </Row>
                </Container>
                <SalidaProductoLoteModal 
                    datosIniciales={datosLote}
                    mostrarModal={visibleModalSalidaStock} 
                    
                    setMostrarModal={setVisibleModalSalidaStock}  
                    guardarDatos={guardarDatosNuevaSalidaStock} 
                />
                <Notificacion
                    tipo={infoNotificacion.tipo}
                    titulo={infoNotificacion.titulo}
                    fecha={infoNotificacion.fecha}
                    mensaje={infoNotificacion.mensaje}
                    mostrar={visibleNotificacion}
                    setMostrar={setVisibleNotificacion} />
            </aside>
        </section>
    );
};