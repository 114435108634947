/* DEPENDENCIAS */
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import { IProducto, IFiltrosProductos } from "../../../tipos/ProductosType";
import ProductosInstanceSeleccionar from "./ProductosInstanceSeleccionar";
import { queryListarProductosPaginacion } from "../../../util/querys/ProductosQuerys";

/* DEFINICIONES */
type IProductosQueryProps = {
  filtrosQuery : IFiltrosProductos,
  pintarMasFilas : boolean,
  handlerSeleccionar : (datosModelo: IProducto) => void
}

/* COMPONENTE */
const ProductosQuerySeleccionar = ({filtrosQuery, pintarMasFilas, handlerSeleccionar} : IProductosQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useInfiniteQuery<IProducto[], Error>(queryListarProductosPaginacion(filtrosQuery));
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
     
    /* renderizado */    
    return (
      <ProductosInstanceSeleccionar tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerSeleccionar={handlerSeleccionar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default ProductosQuerySeleccionar;