/* DEPENDENCIAS */
import React, { Fragment } from "react";
import { ColumnDef, SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Form } from "react-bootstrap";

import { ICama } from "../../../tipos/HabitacionesType";
import CamasEtiquetasTable from "./CamasEtiquetasTable";
import { IEtiquetaHabitacion } from "../../../tipos/ColaImpresionType";
import InputTextCounter from "../../../componentes-com/InputTextCounter";

/* DEFINICIONES */
type CamasEtiquetasInstanceProps = {
  tableData: ICama[],
  arrayEtiquetas: IEtiquetaHabitacion[],
  isError: boolean,
  error: Error | null,
  isLoading: boolean,
  isFetching: boolean,
  onChangeTextoDieta1: (idCama:number, idHabitacion:number, textoDieta1:string) => void,
  onChangeTextoDieta2: (idCama:number, idHabitacion:number, textoDieta2:string) => void,
  onChangeCopias: (idCama:number, idHabitacion:number, copias:number) => void
}

/* COMPONENTE */
const CamasEtiquetasInstance = ({ 
    tableData, arrayEtiquetas,
    isError, error, isLoading, 
    isFetching,
    onChangeTextoDieta1, onChangeTextoDieta2, onChangeCopias
  }: CamasEtiquetasInstanceProps) => {
    /* definiciones */
    const [sorting, setSorting] = React.useState<SortingState>([{
      id: "codigo",
      desc: false
    }]); 

    const columnsDef: ColumnDef<ICama>[] = [
      {
        accessorKey: "codigo",
        header: 'Cama',
        size: 120,
        cell: info => info.getValue(),
        meta: {
          className: "align-middle",
        }
      },
      {
        accessorKey: "nombreHabitacion",
        header: 'Habitación',
        cell: info => info.getValue(), 
        meta: {
          className: "align-middle",
        }      
      },
      {
        id: 'textoDieta1',
        header: 'Fila 1 dieta',
        size: 260,
        cell: (info) => {
          const datos = info.row.original;
          return (
            <InputTextCounter
                id={`txtDieta1_${datos.id}`}
                data-id={datos.id}
                data-idHabitacion={datos.idHabitacion}
                required={true} maxLength={25}
                placeholder="1ª fila de la etiqueta..."
                onChange={(e) => {onChangeTextoDieta1(datos.id, datos.idHabitacion, e.target.value);} } />);
        }     
      },
      {
        id: 'textoDieta2',
        header: 'Fila 2 dieta',
        size: 260,
        cell: (info) => {
          const datos = info.row.original; 
          return (
            <InputTextCounter
                id={`txtDieta2_${datos.id}`}
                data-id={datos.id}
                data-idHabitacion={datos.idHabitacion}
                maxLength={25}
                placeholder="2ª fila de la etiqueta..."
                onChange={(e:any) => {
                  onChangeTextoDieta2(datos.id, datos.idHabitacion, e.target.value);
                }}/>);
        }     
      },
      {
        id: 'txtCantidad',
        header: 'Copias',
        size: 80,
        cell: (info) => {
          const datos = info.row.original;
          return (
          <Fragment>
            <Form.Control type="number" style={{width:"100px"}} maxLength={3}
              id={`txtCopias_${datos.id}`} defaultValue={1}
              required={true} 
              onChange={(e) => {onChangeCopias(datos.id, datos.idHabitacion, parseInt(e.target.value));} }/>
          </Fragment>);
        }     
      },
    ];

    const tableInstance = useReactTable({ 
      data: tableData, 
      columns: columnsDef,
      state: {
        sorting,
      },
      enableSortingRemoval: false,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });
  
    /* funciones */
    const ObtenerValorTextoDieta1 = (idCama:number, idHabitacion:number) : string => {
      //Campos.
      let valor:string = "";
      let etiqueta:IEtiquetaHabitacion | undefined;

      //Método.
      etiqueta = arrayEtiquetas.find(item => item.cama?.id === idCama && item.cama?.idHabitacion === idHabitacion);
      if (etiqueta) valor = etiqueta.textoDieta1;
      
      //devolvemos el valor obtenido...
      return valor;
    }

    const ObtenerValorTextoDieta2 = (idCama:number, idHabitacion:number) : string => {
      //Campos.
      let valor:string = "";
      let etiqueta:IEtiquetaHabitacion | undefined;
      
      //Método.
      etiqueta = arrayEtiquetas.find(item => item.cama?.id === idCama && item.cama?.idHabitacion === idHabitacion);
      if (etiqueta) valor = etiqueta.textoDieta2;
      
      //devolvemos el valor obtenido...
      return valor;
    }

    const ObtenerValorCopias = (idCama:number, idHabitacion:number) : number => {
      //Campos.
      let valor:number = 0;
      let etiqueta:IEtiquetaHabitacion | undefined;
      //Método.
      etiqueta = arrayEtiquetas.find(item => item.cama?.id === idCama && item.cama?.idHabitacion === idHabitacion);
      if (etiqueta) valor = etiqueta.copias;
      
      //devolvemos el valor obtenido...
      return valor;
    }
    
    /* renderizado */
    return (
      <CamasEtiquetasTable instanciaTabla={tableInstance}
        isError={isError} error={error} isLoading={isLoading}
        isFetching={isFetching} />
    );
  }
  export default CamasEtiquetasInstance;