/* dependencias */
import React, { Dispatch, SetStateAction } from 'react';
import { ToastContainer } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import parse from 'html-react-parser';
import RutaLogo20 from '../util/imagenes/logo_fremap_20.png';

//declaraciones
export enum ETipoNotificacion { INFO, EXITO, AVISO, ERROR }

type NotificacionProps = {
  tipo: ETipoNotificacion,
  titulo: string,
  fecha: string,
  mensaje: string,
  mostrar: boolean,
  setMostrar: Dispatch<SetStateAction<boolean>>,
}

export type InfoNotificacion = {
  tipo: ETipoNotificacion,
  titulo: string,
  fecha: string,
  mensaje: string,
}

export const infoNotificacionDefault : InfoNotificacion = {
  tipo: ETipoNotificacion.INFO,
  titulo: "",
  fecha: "",
  mensaje: ""
}

/* componente */
export default function Notificacion({
    tipo,  
    titulo, fecha, mensaje,
    mostrar, setMostrar
  } : NotificacionProps) {
    /* definiciones */

    /* funciones */
    const getClassMensaje = (tipo : ETipoNotificacion) => {
      switch(tipo) {
         case ETipoNotificacion.AVISO: return "text-warning";
         case ETipoNotificacion.EXITO: return "text-success";
         case ETipoNotificacion.ERROR: return "text-danger";
         case ETipoNotificacion.INFO: return "text-dark";
      }
    };
  
    /* renderizado */
    return (
      <ToastContainer className="p-3" position='bottom-end'>
        <Toast show={mostrar} 
          autohide={true} delay={3000}
          animation={true}
          onClose={() => setMostrar(!mostrar)}>
          <Toast.Header>
            <img src={RutaLogo20} className="rounded me-2" alt="" />
            <strong className="me-auto">{titulo}</strong>
            <small>{fecha}</small>
          </Toast.Header>
          <Toast.Body>
            <span className={getClassMensaje(tipo)}>{parse(mensaje)}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    );
}