/* DEPENDENCIAS */
import { clienteAxios } from "./APIAxiosInstancia";
import { URL_API_MOVIMIENTOS_STOCK, URL_API_MOVIMIENTOS_STOCK_PAGINACION } from "./Endpoints";
import { IMovimientoStock, IFiltrosMovimientosStock, IMovimientoStockInsertable } from "../tipos/MovimientosStockType";

/* DECLARACIONES */
/* API */
/**
 * Función que obtiene el listado de los movimientos de stock.
 */
export const ListarMovimientosStock = async (filtros: IFiltrosMovimientosStock): Promise<IMovimientoStock[]> => {
    try {     
        const respuesta = await clienteAxios.get<IMovimientoStock[]>(
            URL_API_MOVIMIENTOS_STOCK,
            { params: filtros}
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene el listado de las recepciones de mercancías.
 */
export const ListarMovimientosStockPaginacion = async (pagina:number, filas:number, filtros: IFiltrosMovimientosStock): Promise<IMovimientoStock[]> => {
    try {     
        const respuesta = await clienteAxios.get<IMovimientoStock[]>(
            URL_API_MOVIMIENTOS_STOCK_PAGINACION,
            { params: {
                ...filtros,
                numeroPagina: pagina,
                tamanhoPagina: filas,
            }}
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene los datos de un movimiento de stock.
 */
export const ObtenerMovimientoStock = async (id: number): Promise<IMovimientoStockInsertable> => {
    try {  
        const respuesta = await clienteAxios.get<IMovimientoStockInsertable>(
            `${URL_API_MOVIMIENTOS_STOCK}/${id}`
        );
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que guarda los datos de una nueva recepción de mercancía.
 */
export const AgregarMovimientoStock = async (datos : IMovimientoStockInsertable): Promise<IMovimientoStockInsertable> => {
    try {    
        const respuesta = await clienteAxios.post(
            URL_API_MOVIMIENTOS_STOCK, datos
        );
        return respuesta.data;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al agregar un movimiento de stock.");
    }
}

/**
 * Función que guarda los datos de edición de un movimiento de stock.
 */
export const EditarMovimientoStock = async (datos : IMovimientoStockInsertable): Promise<void> => {
    try {     
        await clienteAxios.put(URL_API_MOVIMIENTOS_STOCK + `/${datos.id}`, datos);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al editar un movimiento de stock.");
    }
}

/**
 * Función que elimina los datos de un movimiento de stock.
 */
export const EliminarMovimientoStock = async (id : number): Promise<void> => {
    try {     
        await clienteAxios.delete(URL_API_MOVIMIENTOS_STOCK + `/${id}`);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al eliminar un movimiento de stock.");
    }
}
