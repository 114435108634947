/* DEPENDENCIAS */
import { InfiniteData, QueryClient } from '@tanstack/react-query';
import * as APIRecepcionesMercancia from '../../servicios/APIRecepcionesMercancia'
import { 
    IRecepcionMercancia, IFiltrosRecepcionesMercancia
} from '../../tipos/RecepcionesMercanciaType';


/* QUERYS KEYS */
const KEYRecepcionesMercanciaListaPaginada = "listaRecepcionesMercanciaPaginacion";
const KEYRecepcionMercanciaDatos = "datosRecepcionMercancia";

export {
    KEYRecepcionesMercanciaListaPaginada,
    KEYRecepcionMercanciaDatos
}

/* QUERYS */
/* recepción de mercancías */
const queryListarRecepcionMercanciaesPaginacion = (filtros: IFiltrosRecepcionesMercancia) => ({
    queryKey: [KEYRecepcionesMercanciaListaPaginada, filtros],
    queryFn: ({ pageParam = 0 }) => APIRecepcionesMercancia.ListarRecepcionesMercanciaPaginacion(pageParam, 20, filtros),
    getNextPageParam: (lastPage: IRecepcionMercancia[], allPages: IRecepcionMercancia[][]) => allPages.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

const queryObtenerRecepcionMercancia = (id : number, queryClient: QueryClient) => ({
    queryKey: [KEYRecepcionMercanciaDatos, id],
    queryFn: () => APIRecepcionesMercancia.ObtenerRecepcionMercancia(id),
    initialData: () => {
        let datosIniciales : IRecepcionMercancia | undefined = undefined;
        let cacheRecepcionMercanciaesData = queryClient.getQueryData<InfiniteData<IRecepcionMercancia>>([KEYRecepcionesMercanciaListaPaginada]);
        if (cacheRecepcionMercanciaesData) {
            datosIniciales = cacheRecepcionMercanciaesData?.pages.flatMap(page => page)?.find((d:IRecepcionMercancia) => d.id === id);            
        } 
        return datosIniciales;
    },
    enabled: !!id,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});
 
export {
    queryListarRecepcionMercanciaesPaginacion,
    queryObtenerRecepcionMercancia,
}