/* DEPENDENCIAS */
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import { IMovimientoStock, IFiltrosMovimientosStock } from "../../../tipos/MovimientosStockType";
import { queryListarSalidasStockPaginacion } from "../../../util/querys/MovimientosStockQuerys";
import SalidasStockInstance from "./SalidasStockInstance";

/* DEFINICIONES */
type ISalidasStockQueryProps = {
  filtrosQuery : IFiltrosMovimientosStock,
  pintarMasFilas : boolean,
  handlerEditar : (datosModelo : IMovimientoStock) => void,
  handlerEliminar : (datosModelo : IMovimientoStock) => void,
}

/* COMPONENTE */
const SalidasStockQuery = ({filtrosQuery, pintarMasFilas, handlerEditar, handlerEliminar} : ISalidasStockQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useListarMovimientosStock(filtrosQuery);
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
    
    /* funciones */
    function useListarMovimientosStock(filtrosQuery: IFiltrosMovimientosStock) {
        return useInfiniteQuery<IMovimientoStock[], Error>(queryListarSalidasStockPaginacion(filtrosQuery));
    }        
    
    /* renderizado */    
    return (
      <SalidasStockInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerEditar={handlerEditar}
        handlerEliminar={handlerEliminar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default SalidasStockQuery;