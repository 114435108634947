/* DEPENDENCIAS */
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import { ILote, IFiltrosLotes } from "../../../tipos/LotesType";
import LotesInstanceSeleccionar from "./LotesInstanceSeleccionar";
import { queryListarLotesPaginacion } from "../../../util/querys/LotesQuerys";

/* DEFINICIONES */
type ILotesQueryProps = {
  filtrosQuery : IFiltrosLotes,
  pintarMasFilas : boolean,
  handlerSeleccionar : (datosModelo: ILote) => void
}

/* COMPONENTE */
const LotesQuerySeleccionar = ({filtrosQuery, pintarMasFilas, handlerSeleccionar} : ILotesQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useInfiniteQuery<ILote[], Error>(queryListarLotesPaginacion(filtrosQuery));
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
    
    /* funciones */      
    
    /* renderizado */    
    return (
      <LotesInstanceSeleccionar tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerSeleccionar={handlerSeleccionar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default LotesQuerySeleccionar;