/* DEPENDENCIAS */

/* DEFINICIONES */
/**
 * Interfaz que define un objeto de una sección de almacén en la IU.
 */
interface ISeccionAlmacen {
    id: number | null,
    nombre: string
}

const seccionAlmacenModelDefault : ISeccionAlmacen = {
    id: 0,
    nombre: ""
}

/* EXPORTS */
export type {
    ISeccionAlmacen
}

export {
    seccionAlmacenModelDefault
}