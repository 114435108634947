/* dependencias */
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { format } from 'date-fns'
import * as Rutas from '../../../rutas/Rutas';
import PlantillasImpresionQuery from './PlantillasImpresionQuery';
import { useCallback, useState } from 'react';
import PlantillasImpresionModal from './PlantillasImpresionModal';
import { IPlantillaImpresionInsertable } from '../../../tipos/PlantillasImpresionType';
import * as APIImpresiones from '../../../servicios/APIImpresiones';
import Notificacion, { ETipoNotificacion } from '../../../componentes-com/Notificacion';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import useResizeObserver from '../../../hooks/useResizeObserver';


/* declaraciones */
const plantillaImpresionModelDefault : IPlantillaImpresionInsertable = {
    id: 0,
    nombre: "",
    texto: "",
}

type InfoNotificacion = {
    tipo: ETipoNotificacion,
    titulo: string,
    fecha: string,
    mensaje: string,
}

/* componente */
export default function PlantillasImpresion() { 
    /* definiciones */
    const queryClient = useQueryClient();
    const [filtroNombre, setFiltroNombre] = useState<string>("");
    const [datos, setDatos] = useState(plantillaImpresionModelDefault);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const editarPlantillaImpresion = useEditarPlantillaImpresion();
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);

    const infoNotificacionDefault : InfoNotificacion = {
        tipo: ETipoNotificacion.INFO,
        titulo: "",
        fecha: "",
        mensaje: ""
    }
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
      
    const handleChange = (event : any) => {
        setFiltroNombre(event.target.value);
    };

    const handleKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            // Get input value
            setFiltroNombre(filtroNombre);
        }
    };

    const handlerEditar = (datosModelo : IPlantillaImpresionInsertable) => {
        setDatos(datosModelo);
        setVisibleModal(true);
    }

    const guardarDatos = (datosModelo : IPlantillaImpresionInsertable) => {
        setVisibleModal(false);
        editarPlantillaImpresion.mutate(datosModelo);
    }
    
    function useEditarPlantillaImpresion() {
        return useMutation({
          mutationFn: (datosModelo : IPlantillaImpresionInsertable) => {
            return APIImpresiones.EditarPlantillaImpresion(datosModelo);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: ['listadoPlantillasImpresion']});
            setDatos(plantillaImpresionModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Editar plantilla de impresión",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>plantilla de impresión</strong> se ha editado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Editar plantilla de impresión",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error editando la <strong>plantilla de impresión</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    /* efectos */
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="accordion" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="accordion-item">
                    <Breadcrumb className="px-4 pt-3">
                        <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                        <Breadcrumb.Item>Impresiones</Breadcrumb.Item>
                        <Breadcrumb.Item active>Plantillas de impresión</Breadcrumb.Item>
                    </Breadcrumb>
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelCabecera"
                            aria-expanded="true" aria-controls="panelCabecera">
                            <div className="container-fluid px-0"><h2>Plantillas de impresión</h2></div>
                        </button>
                    </h2>
                    <div id="panelCabecera" className="accordion-collapse collapse show" data-bs-parent="#contenedorCabeceraListado">
                        <div className="accordion-body">
                            <div className="row g-sm-2">
                                <div className="col-md-7 col-sm-12 input-grupo">
                                    <input id="txtBuscadorTexto" type="search" className="form-control icono"
                                        placeholder="buscar plantilla..." value={filtroNombre}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        onChange={(e) => handleChange(e)} />
                                    <label className="iconoInput" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}
                onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                <PlantillasImpresionQuery 
                    filtroQuery={filtroNombre} 
                    handlerEditar= {handlerEditar}
                    pintarMasFilas= {pintarMasFilas}
                />
                <PlantillasImpresionModal 
                    datosIniciales={datos}
                    mostrarModal={visibleModal} 
                    setMostrarModal={setVisibleModal}  
                    guardarDatos={guardarDatos} 
                />
                <Notificacion
                    tipo={infoNotificacion.tipo}
                    titulo={infoNotificacion.titulo}
                    fecha={infoNotificacion.fecha}
                    mensaje={infoNotificacion.mensaje}
                    mostrar={visibleNotificacion}
                    setMostrar={setVisibleNotificacion} />
            </aside>
        </section>
    );
};