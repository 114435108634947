/* DEPENDENCIAS */
import React from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { ColumnDef, SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import {TiArrowUnsorted} from "react-icons/ti";

import { ETipoMovimientoStockToString, IMovimientoStock } from "../../../tipos/MovimientosStockType";
import { format } from "date-fns";
import TrazabilidadTable from "./TrazabilidadTable";

/* DEFINICIONES */
type TrazabilidadLoteInstanceProps = {
  tableData: IMovimientoStock[],
  isError: boolean,
  error: Error | null,
  isLoading: boolean,
  isFetching: boolean,
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<IMovimientoStock[], Error>>,
  pintarMasFilas : boolean,
}

/* COMPONENTE */
const TrazabilidadLoteInstance = ({ 
    tableData, 
    isError, error, isLoading, 
    isFetching, fetchNextPage,
    pintarMasFilas,
  }: TrazabilidadLoteInstanceProps) => {
    /* definiciones */

    const [sorting, setSorting] = React.useState<SortingState>([{
      id: "fecha",
      desc: true
    }]); 
    const columnsDef: ColumnDef<IMovimientoStock>[] = [
      {
        accessorKey: "fecha",
        header: () => {
          return(
            <div className="d-flex cursor-pointer justify-content-center">
              <span>Fecha&nbsp;</span>
              <TiArrowUnsorted style={{ marginTop:2 }} />
            </div>
          );
        },
        size: 130, 
        cell: info => format(new Date(info.row.original.fecha), "dd/MM/yyyy HH:mm"), 
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center fs--1"
        }
      },
      {
        accessorKey: "tipo",
        header: "Tipo",
        cell: info => ETipoMovimientoStockToString(info.row.original.tipo), 
        size: 110, 
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center fs--1"
        }      
      },
      {
        accessorKey: "cantidad",
        header: "Cantidad",
        size: 90,
        cell: info => `${info.getValue()} (${info.row.original.producto.abreviaturaTipoUnidad})`, 
        meta: {
          className: "align-middle fs--1",
        }      
      },
      {
        accessorKey: "observaciones",
        header: "Obs.",
        size: 50,
        cell: info => {
          if (info.row.original.observaciones != null && info.row.original.observaciones !== "")
            return (
              <>
                <div className="d-flex cursor-pointer justify-content-center" id="linkMenuUsuario"
                      data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                  <span className="material-symbols-outlined">comment</span>
                </div>
                <div className="dropdown-menu dropdown-menu-end px-3 pt-3" aria-labelledby="linkMenuUsuario"
                  style={{maxWidth:"300px"}}>
                  <p className="lead fs--1">{info.row.original.observaciones}</p>        
                </div>
              </>
            );
          else 
            return (
              <div className="d-flex text-light justify-content-center">
                <span className="material-symbols-outlined">mode_comment</span>
              </div>
            );
        }, 
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center"
        }      
      },
      {
        accessorKey: "codigoLote",
        header: "Lote",
        cell: info => (info.row.original.producto.usaControlLotes)? info.getValue() : "", 
        meta: {
          className: "align-middle fs--1",
        }      
      }
    ];

    const tableInstance = useReactTable({ 
      data: tableData, 
      columns: columnsDef,
      state: {
        sorting,
      },
      enableSortingRemoval: false,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });
  
    /* renderizado */
    return (
      <TrazabilidadTable instanciaTabla={tableInstance}
        isError={isError} error={error} isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        pintarMasFilas={pintarMasFilas} />
    );
  }
  export default TrazabilidadLoteInstance;