/* DEPENDENCIAS */
import React, { useCallback, useState} from 'react';
import {Breadcrumb, Col, FloatingLabel, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import * as yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import * as Rutas from '../../../rutas/Rutas';
import { habitacionModelDefault } from './Habitaciones';
import { ICama, IFiltrosCamas, IHabitacion } from "../../../tipos/HabitacionesType";
import * as APIImpresiones from '../../../servicios/APIImpresiones';
import Notificacion, { ETipoNotificacion, InfoNotificacion } from '../../../componentes-com/Notificacion';
import { format } from 'date-fns';
import { IEtiquetaHabitacion, IImpresionInsertable } from '../../../tipos/ColaImpresionType';
import { KEYPColaImpresionListaPaginada, queryListarImpresoras, queryObtenerPlantillaImpresion } from '../../../util/querys/ImpresionesQuerys';
import { PLANTILLA_HABITACION_ID, PLANTILLA_LABORATORIO_ID } from '../../../util/tsx-ayuda/Constantes';
import { TienePermiso, TransformarPlantillaHabitacion } from '../../../util/tsx-ayuda/Funciones';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { IUsuarioSesion } from '../../../tipos/UsuariosType';
import { EFuncionalidad, VAR_SESION_USUARIO } from '../../../tipos/CommonTypes';
import CamasEtiquetasQuery from './CamasEtiquetasQuery';
import useResizeObserver from '../../../hooks/useResizeObserver';

/* DECLARACIONES */  
const schemaValidacion = yup.object().shape({
    nombre: yup.string()
        .required("El campo es obligatorio"),
});


/* COMPONENTE */
export default function HabitacionesEtiquetas() {
    /* definiciones */
    const queryClient = useQueryClient();
    const agregarImpresionEtiquetaHabitacion = useAgregarImpresionEtiquetaHabitacion();
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const { data: datosPlantilla, isLoading: isLoadingPlantilla } = useQuery(queryObtenerPlantillaImpresion(PLANTILLA_HABITACION_ID));
    
    const [idImpresoraSeleccionada, setIDImpresoraSeleccionada] = useState<number>(0);
    const [errorImpresora, setErrorImpresora] = useState<string | null>(null);
    const { data: opcionesImpresoras, isLoading: isLoadingImpresoras } = useQuery(queryListarImpresoras());
    let impresionesEtiquetas: IEtiquetaHabitacion[] = new Array(0);

    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);

    const infoNotificacionDefault : InfoNotificacion = {
        tipo: ETipoNotificacion.INFO,
        titulo: "",
        fecha: "",
        mensaje: ""
    }
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    const { getItem } = useLocalStorage();
    const [accesoImprimir, setAccesoImprimir] = useState<boolean>(false);


    /* funciones */  
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);

    const cargarListaImpresiones = (_listaImpresiones: IEtiquetaHabitacion[]) : void => {
        impresionesEtiquetas = _listaImpresiones;
    } 

    const onChangeTextoDieta1 = (idCama:number, idHabitacion:number, textoDieta1:string) => {
        let listadoEtiquetas : IEtiquetaHabitacion[] = impresionesEtiquetas
          .map(item => {
            if (item.cama?.id === idCama && item.cama?.idHabitacion === idHabitacion)
              item.textoDieta1 = textoDieta1;
            return item;
          });
          impresionesEtiquetas = listadoEtiquetas;
      }
  
      const onChangeTextoDieta2 = (idCama:number, idHabitacion:number, textoDieta2:string) => {
        let listadoEtiquetas : IEtiquetaHabitacion[] = impresionesEtiquetas
          .map(item => {
            if (item.cama?.id === idCama && item.cama?.idHabitacion === idHabitacion)
              item.textoDieta2 = textoDieta2;
            return item;
          });
          impresionesEtiquetas = listadoEtiquetas;
      }
  
      const onChangeCopias = (idCama:number, idHabitacion:number, copias:number) => {
        let listadoEtiquetas : IEtiquetaHabitacion[] = impresionesEtiquetas
          .map(item => {
            if (item.cama?.id === idCama && item.cama?.idHabitacion === idHabitacion)
              item.copias = copias;
            return item;
          });
          impresionesEtiquetas = listadoEtiquetas;
      }
  
    function useAgregarImpresionEtiquetaHabitacion() {
        return useMutation({
          mutationFn: (datosModelo : IImpresionInsertable) => {
            return APIImpresiones.AgregarImpresion(datosModelo);
          },
          onSuccess: (datosRespuesta: IImpresionInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPColaImpresionListaPaginada]});

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>impresión de etiqueta de laboratorio</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar habitación",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>impresión de etiqueta de laboratorio</strong>:${error.message}</span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    const onClickImprimirEtiquetas = () => {
        //recorremos el listado de impresiones para guardarlas y enviarlas a la impresora...
        if (datosPlantilla && idImpresoraSeleccionada > 0) {
            setErrorImpresora(null);
            impresionesEtiquetas.forEach(item => {
                if (item.textoDieta1 !== "") {
                    item.idImpresora = idImpresoraSeleccionada;
                    item.fecha = new Date();
                    item.plantilla = datosPlantilla;
                    guardarEtiquetaHabitacion(item); 
                }
                item.textoDieta1 = "";
                item.textoDieta2 = "";
                item.copias = 1;
            });

        }
        else if (idImpresoraSeleccionada === 0)
            setErrorImpresora("Debe seleccionar una impresora para imprimir las etiquetas.");
    }

    const guardarEtiquetaHabitacion = (datosModelo : IEtiquetaHabitacion) => {
        let nuevaImpresion : IImpresionInsertable = {
            id: 0,
            idImpresora: datosModelo.idImpresora,
            idPlantilla: PLANTILLA_HABITACION_ID, //plantilla - habitación.
            fecha:  new Date(),
            textoImpresion: TransformarPlantillaHabitacion(datosModelo),
            fechaImpresion: null
        };
        agregarImpresionEtiquetaHabitacion.mutate(nuevaImpresion);
    }


    /* efectos */ 
    React.useEffect(() => {
        let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
        if (usuarioSesion) {
            setAccesoImprimir(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.HOSPITAL_HABITACIONES_IMPRIMIR_ETIQUETA));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="accordion" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="accordion-item">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col col-sm-12 col-md-8 pt-3 ps-4">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Hospital</Breadcrumb.Item>
                                    <Breadcrumb.Item href={Rutas.RUTA_HABITACIONES}>Habitaciones</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Imprimir etiquetas</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="col-sm-12 col-md-4 p-3 d-flex justify-content-end">
                                <Link to={Rutas.RUTA_HABITACIONES} className="nav-link link-dark cursor-pointer d-flex align-center text-right">
                                    <span className="material-symbols-outlined">west</span>&nbsp;
                                    volver al listado
                                </Link>
                            </div>
                        </div>
                    </div>
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelCabecera"
                            aria-expanded="true" aria-controls="panelCabecera">
                            <div className="container-fluid px-0"><h2>Imprimir etiquetas</h2></div>
                        </button>
                    </h2>
                    <div id="panelCabecera" className="accordion-collapse collapse show" data-bs-parent="#contenedorCabeceraListado">
                        <div className="accordion-body">
                            <div className="row g-sm-2">
                                <div className="col col-sm-12 d-flex justify-content-end align-items-end">
                                    { accesoImprimir &&
                                    (<>
                                    <button className="btn btn-primary" onClick={onClickImprimirEtiquetas}>
                                        <div className=" d-flex align-center">
                                            <span className="material-symbols-outlined">print</span>&nbsp;
                                            imprimir
                                        </div>
                                    </button>
                                    </>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}>
                <Row className="pt-2">
                    <Col sm={12}>
                        <Form.Group className="mb-3" controlId="selImpresora">
                            <Form.Label>Impresora</Form.Label>
                            <Form.Select required={true} isInvalid={!!errorImpresora}
                                onChange={(e) => {setErrorImpresora(null); setIDImpresoraSeleccionada(parseInt(e.target.value));}}>
                                {isLoadingImpresoras && (<option value="">cargando...</option>)}
                                {opcionesImpresoras && 
                                    [
                                        ...[{id:0, nombre: "seleccione una impresora..."}],
                                        ...opcionesImpresoras
                                    ].map((option) => {
                                    return (
                                        <option key={option.id?? 0} value={option.id?? 0}>{option.nombre}</option>
                                    );
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                                {errorImpresora}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={1} sm={2} className="text-warning align-middle">
                        <span className="material-symbols-outlined fs-3">print_connect</span>
                    </Col>
                    <Col md={11} sm={10} className="text-warning d-flex align-items-center mt-2">
                        <p>Solamente se imprimirán aquellas etiquetas de las habitaciones que tengan algún texto en la fila 1 de la dieta.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <CamasEtiquetasQuery 
                            arrayEtiquetas={impresionesEtiquetas}
                            cargarListaImpresiones={cargarListaImpresiones}
                            onChangeTextoDieta1={onChangeTextoDieta1}
                            onChangeTextoDieta2={onChangeTextoDieta2}
                            onChangeCopias={onChangeCopias} />
                    </Col>
                </Row>              
            </aside>
            <Notificacion
                tipo={infoNotificacion.tipo}
                titulo={infoNotificacion.titulo}
                fecha={infoNotificacion.fecha}
                mensaje={infoNotificacion.mensaje}
                mostrar={visibleNotificacion}
                setMostrar={setVisibleNotificacion} />
        </section>
    );
}