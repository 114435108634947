/* DEPENDENCIAS */
import { Dispatch, SetStateAction, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ITipoUnidad } from "../../../tipos/TiposUnidadesType";

/* DECLARACIONES */
type TiposUnidadesModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: ITipoUnidad | null,
    guardarDatos: (datosModelo: ITipoUnidad) => void
}

const tipoUnidadModelDefault : ITipoUnidad = {
    id: 0,
    nombre: "",
    abreviatura: "",
}

const mensajesError = {
    requerido: "Este campo es obligatorio",
    abreviaturaMaxLength: "El campo abreviatura solo admite 5 caracteres",
   };
  
/* COMPONENTE */
export default function TiposUnidadesModal({datosIniciales, mostrarModal, setMostrarModal, guardarDatos} : TiposUnidadesModalProps) {
    /* definiciones */
    const { register, handleSubmit, formState: {errors}, getValues, reset } = useForm<ITipoUnidad>({
        mode: "onTouched",
        defaultValues: tipoUnidadModelDefault,
        values: datosIniciales?? tipoUnidadModelDefault
    });
    
    /* funciones */
    useEffect(() => {
        reset(datosIniciales?? tipoUnidadModelDefault);
    }, [reset, datosIniciales]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { (getValues().id === 0)  && "Añadir nuevo tipo de unidad"}
                        { (getValues().id > 0)  && "Editar tipo de unidad"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Form.Group className="mb-3" controlId="txtNombre">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text" {...register("nombre", {
                                required: mensajesError.requerido
                            })} 
                                required={true} isInvalid={!!errors.nombre}
                                placeholder="escribe el nombre del tipo de unidad..." />
                            <Form.Control.Feedback type='invalid'>
                                {errors.nombre?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="txtAbreviatura">
                            <Form.Label>Abreviatura</Form.Label>
                            <Form.Control type="text" {...register("abreviatura", {
                                required: mensajesError.requerido,
                                maxLength: {
                                    value: 5,
                                    message: mensajesError.abreviaturaMaxLength,
                                }
                            })}
                                name="abreviatura" required={true} isInvalid={!!errors.abreviatura}
                                placeholder="escribe la abreviatura del tipo de unidad..." />
                            <Form.Control.Feedback type='invalid'>
                                {errors.abreviatura?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button type="button" variant="light" onClick={() => {reset(tipoUnidadModelDefault); setMostrarModal(!mostrarModal)}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button type="button" variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
      </Modal>
    );
}