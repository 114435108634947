/* DEPENDENCIAS */
import * as APIProductos from '../../servicios/APIProductos';
import { IFiltrosProductos, IProducto } from '../../tipos/ProductosType';

/* QUERYS KEYS */
const KEYProductosLista = "listaProductos";
const KEYPProductosListaPaginada = "listaProductosPaginacion";
const KEYInformacionStocks = "obtenerInformacionStocks";
const KEYProductoDatos = "productoDatos";

export {
    KEYProductosLista,
    KEYPProductosListaPaginada,
    KEYInformacionStocks,
    KEYProductoDatos
}

/* QUERYS */
const queryListarProductosPaginacion = (filtrosQuery: IFiltrosProductos) => ({
    queryKey: [KEYPProductosListaPaginada, filtrosQuery],
    queryFn: ({ pageParam = 0 }) => {
      return APIProductos.ListarProductosPaginacion(pageParam, 20, filtrosQuery);
    },
    getNextPageParam: (lastPage: IProducto[], allPages: IProducto[][]) => allPages.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

const queryObtenerInformacionStocks = () => ({
    queryKey: [KEYInformacionStocks],
    queryFn: () => APIProductos.ObtenerInformacionStocks(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

const queryObtenerProducto = (id : number) => ({
    queryKey: [KEYProductoDatos, id],
    queryFn: () => APIProductos.ObtenerProducto(id),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!id
  });
 
/* EXPORTACIONES */
export {
    queryListarProductosPaginacion,
    queryObtenerInformacionStocks,
    queryObtenerProducto
}