import { Component } from 'react';
import { Outlet } from 'react-router-dom';

export default class Contenido extends Component {
    render() {
        return (
            <div className="content">
                <Outlet />
            </div>
        );
    }
}