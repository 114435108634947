/* DEPENDENCIAS */
import React, { useCallback, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns'

import * as Rutas from '../../../rutas/Rutas';
import SeccionesAlmacenQuery from './SeccionesAlmacenQuery';
import SeccionesAlmacenModal from './SeccionesAlmacenModal';
import { ISeccionAlmacen, seccionAlmacenModelDefault } from '../../../tipos/SeccionesAlmacenType';
import * as APISeccionesAlmacen from '../../../servicios/APISeccionesAlmacen';
import Notificacion, { ETipoNotificacion } from '../../../componentes-com/Notificacion';
import ConfirmacionModal from '../../../componentes-com/ConfirmacionModal';
import useResizeObserver from '../../../hooks/useResizeObserver';
import { KEYPSeccionesAlmacenLista } from '../../../util/querys/SeccionesAlmacenQuerys';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { IUsuarioSesion } from '../../../tipos/UsuariosType';
import { EFuncionalidad, VAR_SESION_USUARIO } from '../../../tipos/CommonTypes';
import { TienePermiso } from '../../../util/tsx-ayuda/Funciones';


/* DECLARACIONES */
type InfoNotificacion = {
    tipo: ETipoNotificacion,
    titulo: string,
    fecha: string,
    mensaje: string,
}

/* COMPONENTE */
export default function SeccionesAlmacen() { 
    /* definiciones */
    const queryClient = useQueryClient();
    const [filtroNombre, setFiltroNombre] = useState<string>("");
    const [datos, setDatos] = useState(seccionAlmacenModelDefault);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModalConfirmacion, setVisibleModalConfirmacion] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState("");
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const agregarSeccionAlmacen = useAgregarSeccionAlmacen();
    const editarSeccionAlmacen = useEditarSeccionAlmacen();
    const eliminarSeccionAlmacen = useEliminarSeccionAlmacen();
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);

    const infoNotificacionDefault : InfoNotificacion = {
        tipo: ETipoNotificacion.INFO,
        titulo: "",
        fecha: "",
        mensaje: ""
    }
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    const { getItem } = useLocalStorage();
    const [accesoNuevo, setAccesoNuevo] = useState<boolean>(false);

    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
      
    const handleChange = (event : any) => {
        setFiltroNombre(event.target.value);
    };

    const handleKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            // Get input value
            setFiltroNombre(filtroNombre);
        }
    };

    const handlerEditar = (datosModelo : ISeccionAlmacen) => {
        setDatos(datosModelo);
        setVisibleModal(true);
    }

    const handlerEliminar = (datosModelo : ISeccionAlmacen) => {
        setDatos(datosModelo);
        setMensajeConfirmacion(`Se va a eliminar la sección de almacén: <strong>${datosModelo.nombre}</strong>`);
        setVisibleModalConfirmacion(true);
    }
    
    const handlerConfirmarEliminar = () => {
        eliminarSeccionAlmacen.mutate(datos);
    }

    const guardarDatos = (datosModelo : ISeccionAlmacen) => {
        setVisibleModal(false);
        if (datosModelo.id === null) return;
        if (datosModelo.id <= 0) {
            agregarSeccionAlmacen.mutate(datosModelo);
        } else {
            editarSeccionAlmacen.mutate(datosModelo);
        }
    }
    
    function useAgregarSeccionAlmacen() {
        return useMutation({
          mutationFn: (datosModelo : ISeccionAlmacen) => {
            return APISeccionesAlmacen.AgregarSeccionAlmacen(datosModelo);
          },
          onSuccess: (datosRespuesta: ISeccionAlmacen) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPSeccionesAlmacenLista]});
            setDatos(seccionAlmacenModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar sección de almacén",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>sección de almacén de productos</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar sección de almacén",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>sección de almacén de productos</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    function useEditarSeccionAlmacen() {
        return useMutation({
          mutationFn: (datosModelo : ISeccionAlmacen) => {
            return APISeccionesAlmacen.EditarSeccionAlmacen(datosModelo);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPSeccionesAlmacenLista]});
            setDatos(seccionAlmacenModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Editar sección de almacén",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>sección de almacén de productos</strong> se ha editado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Editar sección de almacén",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error editando la <strong>sección de almacén de productos</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    function useEliminarSeccionAlmacen() {
        return useMutation({
          mutationFn: (datosModelo : ISeccionAlmacen) => {
            if (datosModelo?.id === null)throw new Error("El ID del objeto es null.");
            return APISeccionesAlmacen.EliminarSeccionAlmacen(datosModelo.id);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPSeccionesAlmacenLista]});
            setDatos(seccionAlmacenModelDefault);
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Eliminar sección de almacén",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>sección de almacén de productos</strong> se ha eliminado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Eliminar sección de almacén",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error eliminando la <strong>sección de almacén de productos</strong>: <em>${variables.nombre}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 

    /* efectos */
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    React.useEffect(() => {
        let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
        if (usuarioSesion) {
            setAccesoNuevo(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.CONFIGURACION_SECCIONES_EDICION));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="accordion" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="accordion-item">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-7 pt-3 ps-4">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Configuración</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Secciones de almacén</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="col-sm-12 col-md-5 p-3">
                                <div className="d-flex justify-content-end">
                                    <div className={`${!accesoNuevo? 'disabled-button-wrapper' : ''}`}>
                                        <button type="button" className={`btn btn-primary ${!accesoNuevo? 'disabled' : ''}`} aria-disabled={!accesoNuevo} 
                                            onClick={() => setVisibleModal(true)}>
                                            <div className="d-flex">
                                                <span className="material-symbols-outlined">add</span>&nbsp;
                                                nueva sección
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelCabecera"
                            aria-expanded="true" aria-controls="panelCabecera">
                            <div className="container-fluid px-0"><h2>Secciones de almacén</h2></div>
                        </button>
                    </h2>
                    <div id="panelCabecera" className="accordion-collapse collapse show" data-bs-parent="#contenedorCabeceraListado">
                        <div className="accordion-body">
                            <div className="row g-sm-2">
                                <div className="col-md-7 col-sm-12 input-grupo">
                                    <input id="txtBuscadorTexto" type="search" className="form-control icono"
                                        placeholder="buscar sección de almacén..." value={filtroNombre}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        onChange={(e) => handleChange(e)} />
                                    <label className="iconoInput" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}
                onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                <SeccionesAlmacenQuery 
                    filtroQuery={filtroNombre} 
                    handlerEditar= {handlerEditar}
                    handlerEliminar= {handlerEliminar}
                    pintarMasFilas= {pintarMasFilas}
                />
                <SeccionesAlmacenModal 
                    datosIniciales={datos}
                    mostrarModal={visibleModal} 
                    setMostrarModal={setVisibleModal}  
                    guardarDatos={guardarDatos} 
                />
                <Notificacion
                    tipo={infoNotificacion.tipo}
                    titulo={infoNotificacion.titulo}
                    fecha={infoNotificacion.fecha}
                    mensaje={infoNotificacion.mensaje}
                    mostrar={visibleNotificacion}
                    setMostrar={setVisibleNotificacion} />
                <ConfirmacionModal 
                    mostrarModal = {visibleModalConfirmacion}
                    setMostrarModal = {setVisibleModalConfirmacion}
                    mensajeConfirmacion = {mensajeConfirmacion}
                    handlerSI = {handlerConfirmarEliminar}
                />
            </aside>
        </section>
    );
};