/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";

import { ISeccionAlmacen, seccionAlmacenModelDefault } from "../../../tipos/SeccionesAlmacenType";

/* DECLARACIONES */
type SeccionesAlmacenModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: ISeccionAlmacen | null,
    guardarDatos: (datosModelo: ISeccionAlmacen) => void
}
  
const mensajesError = {
    requerido: "Este campo es obligatorio",
};
  
/* COMPONENTE */
export default function SeccionesAlmacenModal({datosIniciales, mostrarModal, setMostrarModal, guardarDatos} : SeccionesAlmacenModalProps) {
    /* definiciones */
    const { register, handleSubmit, formState: {errors}, getValues, reset } = useForm<ISeccionAlmacen>({
        mode: "onTouched",
        defaultValues: seccionAlmacenModelDefault,
        values: datosIniciales?? seccionAlmacenModelDefault
    });
    
    /* funciones */
    useEffect(() => {
        reset(datosIniciales?? seccionAlmacenModelDefault);
    }, [reset, datosIniciales]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { (getValues().id === 0)  && "Añadir nueva sección de almacén"}
                        { ((getValues().id?? -1) > 0)  && "Editar sección de almacén"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="txtNombre">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" {...register("nombre", {
                                required: mensajesError.requerido
                            })}
                            required={true}  isInvalid={!!errors.nombre}
                            placeholder="escribe el nombre de la sección de almacén..." />
                        <Form.Control.Feedback type='invalid'>
                            {errors.nombre?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() => { reset(seccionAlmacenModelDefault); setMostrarModal(!mostrarModal)}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}