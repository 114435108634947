/* DEPENDENCIAS */
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import SeccionesAlmacenInstance from "./SeccionesAlmacenInstance";
import { ISeccionAlmacen } from "../../../tipos/SeccionesAlmacenType";
import { queryListarSeccionesAPISeccionesAlmacen } from "../../../util/querys/SeccionesAlmacenQuerys";

type ISeccionesAlmacenQueryProps = {
  filtroQuery : string | undefined,
  pintarMasFilas : boolean,
  handlerEditar : (datosModelo: ISeccionAlmacen) => void,
  handlerEliminar : (datosModelo : ISeccionAlmacen) => void,
}

const SeccionesAlmacenQuery = ({filtroQuery, pintarMasFilas, handlerEditar, handlerEliminar} : ISeccionesAlmacenQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useInfiniteQuery<ISeccionAlmacen[], Error>(queryListarSeccionesAPISeccionesAlmacen(filtroQuery));
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
         
    /* renderizado */    
    return (
      <SeccionesAlmacenInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerEditar={handlerEditar}
        handlerEliminar={handlerEliminar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default SeccionesAlmacenQuery;