/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { IPlantillaImpresionInsertable } from "../../../tipos/PlantillasImpresionType";
import { FloatingLabel } from 'react-bootstrap';

/* DECLARACIONES */
type PlantillasImpresionModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: IPlantillaImpresionInsertable | null,
    guardarDatos: (datosModelo: IPlantillaImpresionInsertable) => void
}
const plantillaImpresionModalDefault : IPlantillaImpresionInsertable = {
    id: 0,
    nombre: "",
    texto: "",
}
  
const mensajesError = {
    requerido: "Este campo es obligatorio",
};
  
/* COMPONENTE */
export default function PlantillasImpresionModal({datosIniciales, mostrarModal, setMostrarModal, guardarDatos} : PlantillasImpresionModalProps) {
    /* definiciones */
    const { register, handleSubmit, formState: {errors}, reset, watch } = useForm<IPlantillaImpresionInsertable>({
        mode: "onTouched",
        defaultValues: plantillaImpresionModalDefault,
        values: datosIniciales?? plantillaImpresionModalDefault
    });

    /* funciones */
    useEffect(() => {
        reset(datosIniciales?? plantillaImpresionModalDefault);
    }, [reset, datosIniciales]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            size='lg'
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>Editar plantilla de impresión</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="txtNombre">
                        <Form.Control plaintext readOnly  className="text-primary"
                            {...register("nombre",)} />
                    </Form.Group>
                    <FloatingLabel className="mb-3" controlId="txtDescripción" label="texto">
                        <Form.Control as="textarea" rows={20} 
                            {...register("texto", {
                                required: mensajesError.requerido
                            })}
                            required={true} maxLength={5000}  isInvalid={!!errors.texto}
                            placeholder="escribe descripción de la impresora..."
                            style={{ height: '300px' }} />
                        <Form.Text className="text-muted" hidden={watch('texto').length === 0}>
                            {`${watch('texto')?.length?? 0}/5000`}
                        </Form.Text>
                        <Form.Control.Feedback type='invalid'>
                            {errors.texto?.message}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() => { reset(plantillaImpresionModalDefault); setMostrarModal(!mostrarModal)}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}