
type TPanelCargandoProps = {
    texto?: string | null
}

export default function PanelCargando({texto} : TPanelCargandoProps) {

    return (
        <div className="d-flex align-items-center">
            <strong>{(texto!=null)? texto : "Cargando datos..."}</strong>
            <div className="spinner-border spinner-border-sm ms-auto m-2" role="status" aria-hidden="true"></div>
        </div>
    );
}