/* DEPENDENCIAS */
import * as APISeccionesAlmacen from '../../servicios/APISeccionesAlmacen';
import { ISeccionAlmacen } from '../../tipos/SeccionesAlmacenType';


/* QUERYS KEYS */
const KEYPSeccionesAlmacenLista = "listaSeccionesAlmacenPaginada";


/* QUERYS */
const queryListarSeccionesAPISeccionesAlmacen = (filtro: string | undefined) => ({
    queryKey: [KEYPSeccionesAlmacenLista, filtro],
    queryFn: ({ pageParam = 0 }) => APISeccionesAlmacen.ListarSeccionesAlmacenPaginacion(pageParam, 20, filtro),
    getNextPageParam: (lastPage: ISeccionAlmacen[], allPages: ISeccionAlmacen[][]) => allPages.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });


/* EXPORTS */
export {
    queryListarSeccionesAPISeccionesAlmacen,
}

export {
    KEYPSeccionesAlmacenLista,
}