/**
 * Tipo enumerado con los tipos de IVA.
 */
enum EFuncionalidad { 
   /// <summary>General - Cambiar contraseña.</summary>
   GENERAL_CLAVE = 1,
   /// <summary>General - Salida de mercancía con QR.</summary>
   GENERAL_SALIDA_MERCANCIA = 2,
   /// <summary>General - Información de lote con QR.</summary>
   GENERAL_INFORMACION_LOTE = 3,

   /// <summary>Configuración - Acceso a menú.</summary>
   CONFIGURACION_MENU_ACCESO = 10,
   /// <summary>Configuración - Acceso a listado de proveedores / ver información de proveedor.</summary>
   CONFIGURACION_PROVEEDORES_ACCESO = 11,
   /// <summary>Editar / eliminar proveedor.</summary>
   CONFIGURACION_PROVEEDORES_EDICION = 12,
   /// <summary>Acceder al listado de familias de productos / ver información de producto.</summary>
   CONFIGURACION_FAMILIAS_ACCESO = 13,
   /// <summary>Editar / eliminar familia de productos.</summary>
   CONFIGURACION_FAMILIAS_EDICION = 14,
   /// <summary>Acceder al listado de secciones de almacén / ver información de sección de almacén.</summary>
   CONFIGURACION_SECCIONES_ACCESO = 15,
   /// <summary>Editar / eliminar tipo de unidad.</summary>
   CONFIGURACION_SECCIONES_EDICION = 16,
   /// <summary>Acceder al listado de tipos de unidades / ver información de tipo de unidad.</summary>
   CONFIGURACION_TIPOSUNIDAD_ACCESO = 17,
   /// <summary>Editar / eliminar tipo de unidad.</summary>
   CONFIGURACION_TIPOSUNIDAD_EDICION = 18,

   /// <summary>Acceder al menú de informes.</summary>
   INFORMES_MENU_ACCESO = 30,
   /// <summary>Acceder al informe de consumos.</summary>
   INFORMES_CONSUMOS_ACCESO = 31,
   /// <summary>Acceder al informe de trazabilidad.</summary>
   INFORMES_TRAZABILIDAD_ACCESO = 32,

   /// <summary>Acceder al menú de impresoras.</summary>
   IMPRESORAS_MENU_ACCESO = 40,
   /// <summary>Acceder al listado de impresoras / ver información de impresora.</summary>
   IMPRESORAS_IMPRESORAS_ACCESO = 41,
   /// <summary>Editar / eliminar impresora.</summary>
   IMPRESORAS_IMPRESORAS_EDICION = 42,
   /// <summary>Acceder al listado de plantillas de impresión / ver información de plantilla.</summary>
   IMPRESORAS_PLANTILLAS_ACCESO = 43,
   /// <summary>Editar / eliminar plantilla de impresión.</summary>
   IMPRESORAS_PLANTILLAS_EDICION = 44,
   /// <summary>Accedera al listado de la cola de impresión / ver código de una impresión.</summary>
   IMPRESORAS_COLA_ACCESO = 45,
   
   /// <summary>Acceder al menú de hospital.</summary>
   HOSPITAL_MENU_ACCESO = 50,
   /// <summary>Acceder al listado de habitaciones / ver información de la habitación.</summary>
   HOSPITAL_HABITACIONES_ACCESO = 51,
   /// <summary>Editar / eliminar habitación. Crear / editar / eliminar camas.</summary>
   HOSPITAL_HABITACIONES_EDICION = 52,
   /// <summary>Imprimir etiqueta de habitación.</summary>
   HOSPITAL_HABITACIONES_IMPRIMIR_ETIQUETA = 53,
   
   /// <summary>Acceder al menú de almacén.</summary>
   ALMACEN_MENU_ACCESO = 100,
   /// <summary>Acceder al listado de productos / ver información de producto.</summary>
   ALMACEN_PRODUCTOS_ACCESO = 101,
   /// <summary>Editar / eliminar producto.</summary>
   ALMACEN_PRODUCTOS_EDICION = 102,
   /// <summary>Salida de stock de producto.</summary>
   ALMACEN_PRODUCTOS_STOCK_SALIDA = 103,
   /// <summary>Regularizar stock de producto.</summary>
   ALMACEN_PRODUCTOS_STOCK_REGULARIZAR = 104,
   /// <summary>Ver trazabilidad de producto.</summary>
   ALMACEN_PRODUCTOS_VER_TRAZABILIDAD = 105,
   /// <summary>Imprimir etiqueta de laboratorio.</summary>
   ALMACEN_LABORATORIO_IMPRIMIR_ETIQUETA = 106,
   /// <summary>Acceder al listado de lotes / ver información de lote.</summary>
   ALMACEN_LOTES_ACCESO = 107,
   /// <summary>Editar / eliminar lote.</summary>
   ALMACEN_LOTES_EDICION = 108,
   /// <summary>Salida de stock de lote.</summary>
   ALMACEN_LOTES_SALIDA = 109,
   /// <summary>Regularizar stock de lote.</summary>
   ALMACEN_LOTES_REGULARIZAR = 110,
   /// <summary>Acceder al listado de caducidades.</summary>
   ALMACEN_CADUCIDADES_ACCESO = 111,
   /// <summary>Acceder al listado de stocks mínimos.</summary>
   ALMACEN_STOCKS_MINIMOS_ACCESO = 112,
   /// <summary>Acceder al listado de entradas de mercancía / ver información de una entrada.</summary>
   ALMACEN_ENTRADAMERCANCIA_ACCESO = 113,
   /// <summary>Editar / eliminar entrada de mercancía.</summary>
   ALMACEN_ENTRADAMERCANCIA_EDICION = 114,
   /// <summary>Imprimir etiqueta de producto.</summary>
   ALMACEN_PRODUCTOS_IMPRIMIR_ETIQUETA = 115,
   /// <summary>Acceder al listado de pedidos (creados) / ver información de pedido.</summary>
   ALMACEN_PEDIDOS_ACCESO = 116,
   /// <summary>Editar / eliminar información de pedido.</summary>
   ALMACEN_PEDIDOS_EDICION = 117,
   /// <summary>Acceder al listado de salida de mercancía / ver información de la salida de mercancía.</summary>
   ALMACEN_SALIDAMERCANCIA_ACCESO = 118,
   /// <summary>Editar / eliminar salida de mercancía.</summary>
   ALMACEN_SALIDAMERCANCIA_EDICION = 119,
}

/**
 * Tipo enumerado con los tipos de IVA.
 */
enum ETipoIVA { 
    /// <summary>IVA exento (0%).</summary>
    EXENTO = 0,
    /// <summary>IVA general (21%).</summary>
    GENERAL = 1,
    /// <summary>IVA reducido (10%).</summary>
    REDUCIDO = 2,
    /// <summary>IVA superreducido (4%).</summary>
    SUPERREDUCIDO = 3,
    /// <summary>IVA superreducido tipo 2 (5%).</summary>
    SUPERREDUCIDO_2 = 4,
    /// <summary>IVA superreducido tipo 3(exento) (0%).</summary>
    SUPERREDUCIDO_3 = 5
 }

 enum ETipoRangoCaducidad { 
   CADUCADO,
   CADUCA_HOY,
   CADUCA_7,
   CADUCA_30,
}

enum ERangoStocksMinimos { 
   CONSUMIDO,
   MINIMOS,
   ACTIVOS,
   ACTIVOS_REVISAR,
}

const VAR_SESION_USUARIO : string = "UsuarioSesion";

type JWTHeader = {
   alg: string,
   typ: string
}

const JWTHeaderDefault : JWTHeader = {
   alg: process.env.REACT_APP_JWT_ALG?? "",
   typ: process.env.REACT_APP_JWT_TYP?? ""
}

type JWTPayload = {
   sub: string,
   exp: number,
   iss: string,
   aud: string
 }

const JWTPayloadDefault : JWTPayload = {
   sub: process.env.REACT_APP_JWT_SUB?? "",
   exp: 0,
   iss: process.env.REACT_APP_JWT_ISS?? "",
   aud: process.env.REACT_APP_JWT_AUD?? ""
 }

 export type {
   JWTPayload,
}

 export {
   EFuncionalidad,
   ETipoIVA,
   ETipoRangoCaducidad,
   ERangoStocksMinimos,
   VAR_SESION_USUARIO,
   JWTHeaderDefault, JWTPayloadDefault
 }


