/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useCallback, useState} from 'react';
import { Button, Row, Col, FloatingLabel, Form, Container, Breadcrumb } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { format } from 'date-fns';

import * as Rutas from '../../../rutas/Rutas';
import { IEtiquetaLaboratorio, IImpresionInsertable, etiquetaLaboratorioModelDefault } from '../../../tipos/ColaImpresionType';
import { KEYPColaImpresionListaPaginada, queryListarImpresoras, queryObtenerPlantillaImpresion } from '../../../util/querys/ImpresionesQuerys';
import { PLANTILLA_LABORATORIO_ID } from '../../../util/tsx-ayuda/Constantes';
import * as APIImpresiones from '../../../servicios/APIImpresiones';
import { TienePermiso, TransformarPlantillaLaboratorio } from '../../../util/tsx-ayuda/Funciones';
import Notificacion, { ETipoNotificacion, InfoNotificacion } from '../../../componentes-com/Notificacion';
import useResizeObserver from '../../../hooks/useResizeObserver';
import { Link } from 'react-router-dom';
import { EFuncionalidad, VAR_SESION_USUARIO } from '../../../tipos/CommonTypes';
import { IUsuarioSesion } from '../../../tipos/UsuariosType';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

/* DECLARACIONES */
const schemaValidacion = yup.object().shape({
    idImpresora: yup.number()
        .min(1, "Seleccione una impresora")
        .required("El campo es obligatorio"),
    copias: yup.number()
        .min(1, "Debe solicitar al menos 1 copia")
        .required("El campo es obligatorio"),
    textoFila1: yup.string()
        .required("El campo es obligatorio"),
});

/* COMPONENTE */
export default function EtiquetaLaboratorio() {
    /* definiciones */
    const queryClient = useQueryClient();
    const { getItem } = useLocalStorage();
    const { data: opcionesImpresoras, isLoading: isLoadingImpresoras } = useQuery(queryListarImpresoras());
    const { data: datosPlantilla, isLoading: isLoadingPlantilla } = useQuery(queryObtenerPlantillaImpresion(PLANTILLA_LABORATORIO_ID));
    const { register, handleSubmit, formState: {errors}, getValues, setValue, reset, watch } = useForm<IEtiquetaLaboratorio>({
        mode: "onSubmit", reValidateMode: "onChange",
        defaultValues: etiquetaLaboratorioModelDefault,
        values: etiquetaLaboratorioModelDefault,
        resolver: yupResolver(schemaValidacion),
    });
    
    const infoNotificacionDefault : InfoNotificacion = {
        tipo: ETipoNotificacion.INFO,
        titulo: "",
        fecha: "",
        mensaje: ""
    }
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    const [accesoImprimirLaboratorio, setAccesoImprimirLaboratorio] = useState<boolean>(false);
    
    const agregarImpresionEtiquetaLaboratorio = useAgregarImpresionEtiquetaLaboratorio();
    
    const [fechaImpresion, setFechaImpresion] = useState<string>("-");
    const [heightListado, setHeightListado] = useState<number>(0);
    const formCabeceraRef = React.useRef<HTMLFormElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);


    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
    
    function useAgregarImpresionEtiquetaLaboratorio() {
        return useMutation({
          mutationFn: (datosModelo : IImpresionInsertable) => {
            return APIImpresiones.AgregarImpresion(datosModelo);
          },
          onSuccess: (datosRespuesta: IImpresionInsertable) => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYPColaImpresionListaPaginada]});
            reset({
                ...etiquetaLaboratorioModelDefault,
                fecha: new Date(),
            });
            setValue("plantilla", datosPlantilla?? null); 
            setFechaID(new Date().getMilliseconds());

            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Agregar impresión de laboratorio",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>impresión de etiqueta de laboratorio</strong> se ha creado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Agregar impresión de laboratorio",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error creando la <strong>impresión de etiqueta de laboratorio</strong>:${error.message}</span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
    
    const guardarEtiquetaLaboratorio = (datosModelo : IEtiquetaLaboratorio) => {
        let nuevaImpresion : IImpresionInsertable = {
            id: 0,
            idImpresora: datosModelo.idImpresora,
            idPlantilla: PLANTILLA_LABORATORIO_ID, //plantilla - laboratorio.
            fecha:  new Date(),
            textoImpresion: TransformarPlantillaLaboratorio(datosModelo),
            fechaImpresion: null
        };
        agregarImpresionEtiquetaLaboratorio.mutate(nuevaImpresion);
    }

    const [fechaID, setFechaID] = useState(new Date().getMilliseconds());
    
    /* efectos */ 
    React.useEffect(() => {
        if (!isLoadingPlantilla) {            
            setValue("plantilla", datosPlantilla?? null); 
        }
    }, [datosPlantilla, isLoadingPlantilla, setValue]);  
    
    React.useEffect(() => {
        let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
        if (usuarioSesion) {
            setAccesoImprimirLaboratorio(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_LABORATORIO_IMPRIMIR_ETIQUETA));
            reset({
                ...etiquetaLaboratorioModelDefault,
                fecha: new Date(),
            });
        }
        setFechaImpresion(format(getValues().fecha, "dd/MM/yyyy HH:mm"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* renderizado */
    return (
        <Form noValidate id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef} key={fechaID}>
            <div className="container-fluid py-2" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="row">
                    <div className="col col-sm-12 col-md-8">
                        <Breadcrumb>
                            <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">Impresión de etiqueta de laboratorio</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-sm-12 col-md-7">
                        <h3>Impresión de etiqueta de laboratorio</h3>
                    </div>
                    <div className="col col-sm-12 col-md-5 d-flex justify-content-end align-items-end">
                        {accesoImprimirLaboratorio && (
                        <button className="btn btn-primary" onClick={handleSubmit(guardarEtiquetaLaboratorio)}>
                            <div className=" d-flex align-center">
                                <span className="material-symbols-outlined">edit_square</span>&nbsp;
                                guardar
                            </div>
                        </button>)} 
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid pt-3 scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}>                    
                <Container>
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="txtFecha">
                                <Form.Label column sm="2">Fecha:</Form.Label>
                                    <Form.Control plaintext readOnly className="text-info" value={fechaImpresion} />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="txtCopias">
                                <Form.Label>Nº de copias</Form.Label>
                                <Form.Control type="number" style={{width:"100px"}} maxLength={3} {...register("copias")}
                                    required={true} isInvalid={!!errors.copias}/>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.copias?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="selImpresora">
                                <Form.Label>Impresora</Form.Label>
                                <Form.Select {...register("idImpresora")} required={true} isInvalid={!!errors.idImpresora}>
                                    {isLoadingImpresoras && (<option value="">cargando...</option>)}
                                    {opcionesImpresoras && 
                                        [
                                            ...[{id:0, nombre: "seleccione una impresora..."}],
                                            ...opcionesImpresoras
                                        ].map((option) => {
                                        return (
                                            <option key={option.id?? 0} value={option.id?? 0}>{option.nombre}</option>
                                        );
                                    })}
                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.idImpresora?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FloatingLabel className="mb-3" controlId="txtTexto" label="texto de la primera fila">
                                <Form.Control 
                                    {...register("textoFila1")}
                                    required={true} maxLength={30}  isInvalid={!!errors.textoFila1}
                                    placeholder="escribe texto para imprimir en la etiqueta..."/>
                                <Form.Text className="text-muted" hidden={watch('textoFila1').length === 0}>
                                    {`${watch('textoFila1')?.length?? 0}/30`}
                                </Form.Text>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.textoFila1?.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FloatingLabel className="mb-3" controlId="txtTexto" label="texto de la segunda fila">
                                <Form.Control 
                                    {...register("textoFila2")} maxLength={30}  isInvalid={!!errors.textoFila2}
                                    placeholder="escribe texto para imprimir en la etiqueta..."/>
                                <Form.Text className="text-muted" hidden={watch('textoFila2').length === 0}>
                                    {`${watch('textoFila2')?.length?? 0}/30`}
                                </Form.Text>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Container>
            
                <Notificacion
                    tipo={infoNotificacion.tipo}
                    titulo={infoNotificacion.titulo}
                    fecha={infoNotificacion.fecha}
                    mensaje={infoNotificacion.mensaje}
                    mostrar={visibleNotificacion}
                    setMostrar={setVisibleNotificacion} />
            </aside>   
        </Form>
    );
}