/* DEPENDENCIAS */
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import MovimientosStockInstance from "./MovimientosStockInstance";
import { IMovimientoStock, IFiltrosMovimientosStock } from "../../../tipos/MovimientosStockType";
import { queryListarMovimientosStockPaginacion } from "../../../util/querys/MovimientosStockQuerys";

/* DEFINICIONES */
type IMovimientosStockQueryProps = {
  filtrosQuery : IFiltrosMovimientosStock,
  pintarMasFilas : boolean,
  handlerImprimir : (datosModelo : IMovimientoStock) => void,
  handlerEliminar : (datosModelo : IMovimientoStock) => void,
}

/* COMPONENTE */
const MovimientosStockQuery = ({filtrosQuery, pintarMasFilas, handlerImprimir, handlerEliminar} : IMovimientosStockQueryProps) => {
    /* definiciones */
    const  { data, fetchNextPage, isFetching, isLoading, error, isError } = useListarMovimientosStock(filtrosQuery);
    const flatData = React.useMemo(
      () => data?.pages?.flatMap(page => page) ?? [],
      [data]
    )
    
    /* funciones */
    function useListarMovimientosStock(filtrosQuery: IFiltrosMovimientosStock) {
        return useInfiniteQuery<IMovimientoStock[], Error>(queryListarMovimientosStockPaginacion(filtrosQuery));
    }        
    
    /* renderizado */    
    return (
      <MovimientosStockInstance tableData={flatData?? []}
        isError={isError} 
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        handlerImprimir={handlerImprimir}
        handlerEliminar={handlerEliminar}
        pintarMasFilas= {pintarMasFilas}
      />
    );
  }
  export default MovimientosStockQuery;