import { EFuncionalidad } from "./CommonTypes"

/**
 * Interfaz que define un objeto de la funcionalidad en la IU.
 */
interface IFuncionalidad {
    id: EFuncionalidad,
    nombre: string,
}

const modelFuncionalidadDefault : IFuncionalidad = {
    id: EFuncionalidad.HOSPITAL_MENU_ACCESO,
    nombre: "",
}

/**
 * Interfaz que define un objeto de un perfil en la IU.
 */
interface IPerfil {
    id: number,
    nombre: string,
}

const modelPerfilDefault : IPerfil = {
    id: 0,
    nombre: "",
}

/**
 * Interfaz que define un objeto de un perfil del usuario de sesión en la IU.
 */
interface IPerfilSesion {
    id: number,
    nombre: string,
    funcionalidades: IFuncionalidad[],
}

const modelPerfilSesionDefault : IPerfilSesion = {
    id: 0,
    nombre: "",
    funcionalidades: [],
}

/**
 * Interfaz que define un objeto de usuario en la IU.
 */
interface IUsuario {
    id: number,
    login: string,
    clave: string,
    nombre: string,
    apellido1: string,
    apellido2: string | null,
    idperfil: number,
    perfil: IPerfil,
}
  
const modelUsuarioDefault : IUsuario = {
    id: 0,
    login: "",
    clave: "",
    nombre: "",
    apellido1: "",
    apellido2: null,
    idperfil: 0,
    perfil: modelPerfilDefault,
}
 
/**
 * Interfaz que define un objeto de usuario de sesión en la IU.
 */
interface IUsuarioSesion {
    id: number,
    login: string,
    clave: string,
    nombre: string,
    apellido1: string,
    apellido2: string | null,
    idperfil: number,
    perfil: IPerfilSesion,

    logueado: boolean,
    status?: number,
    mensaje?: string,
    authToken?: string,
}
 
const modelUsuarioSesionDefault : IUsuarioSesion = {
    id: 0,
    login: "",
    clave: "",
    nombre: "",
    apellido1: "",
    apellido2: null,
    idperfil: 0,
    perfil: modelPerfilSesionDefault,

    logueado: false,
}
  
/**
 * Interfaz que define un objeto de usuario de sesión en la IU.
 */
interface IFiltrosUsuarios {
    texto?: string,
 }
 
const modelFiltrosUsuariosDefault : IFiltrosUsuarios = {
    texto: undefined,
}

interface IFormCambiarClave {
    clave: string,
    claveCopia: string,
}

interface IAPICambiarClave {
    id: number,
    clave: string,
}

const modelFormCambiarClave : IFormCambiarClave = {
    clave: "",
    claveCopia: "",
}

 export type {
    IFuncionalidad, IPerfil, IPerfilSesion,
    IUsuario, IUsuarioSesion, IFiltrosUsuarios,
    IFormCambiarClave, IAPICambiarClave
 }
 
 export {
    modelFuncionalidadDefault, modelPerfilDefault, modelPerfilSesionDefault,
    modelUsuarioDefault, modelUsuarioSesionDefault, modelFiltrosUsuariosDefault,
    modelFormCambiarClave
 }