/**
 * Interfaz que define un objeto de un documento en la IU.
 */
interface IDocumento {
    id: number,
    nombre: string,
}

/**
 * Interfaz que define un objeto de un documento de una recepción de mercancía para guardar en la IU.
 */
interface IDocumentoRecepcionUpload {
    idRecepcion: number,
    upload: File | undefined,
    idDocumento?: number
}

/**
 * Interfaz que define un objeto de un documento de una recepción de mercancía en la IU.
 */
interface IDocumentoRecepcion {
    idRecepcion: number,
    idProveedor: number,
    nombreProveedor?: string,
    referencia: string | null,
    idDocumento: number | null,
    nombreDocumento: string | null,
    contenido: BlobPart | null,
}

/**
 * Constante con el modelo de datos inicial de un documento.
 */
const documentoModelDefault : IDocumento = {
    id:0,
    nombre: ""
}

/**
 * Constante con el modelo de datos inicial de un documento.
 */
const documentoRecepcionUploadModelDefault : IDocumentoRecepcionUpload = {
    idRecepcion:0,
    upload: undefined
}

/**
 * Constante con el modelo de datos inicial de un documento.
 */
const documentoRecepcionModelDefault : IDocumentoRecepcion = {
    idRecepcion:0,
    idProveedor: 0,
    referencia: null,
    idDocumento: null,
    nombreDocumento: null,
    contenido: null,
}


export type {IDocumento, IDocumentoRecepcion, IDocumentoRecepcionUpload}

export {
    documentoModelDefault, 
    documentoRecepcionModelDefault,
    documentoRecepcionUploadModelDefault
}