/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import useResizeObserver from '../../../hooks/useResizeObserver';
import { IFiltrosLotes, ILoteInsertable, filtrosLoteDefault } from '../../../tipos/LotesType';
import { Button, Form, Modal } from 'react-bootstrap';
import LotesQuerySeleccionar from './LotesQuerySeleccionar';


/* DECLARACIONES */
type LotesProductoSeleccionModalProps = {
    idProducto: number,
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    handlerCancelar: () => void
    seleccionarLote: (datosModelo: ILoteInsertable) => void
}


/* componente */
export default function LotesProductoSeleccionModal({
    idProducto, 
    mostrarModal, setMostrarModal, 
    handlerCancelar, seleccionarLote
} : LotesProductoSeleccionModalProps) { 
    /* definiciones */
    const [filtros, setFiltros] = useState<IFiltrosLotes>({
        ...filtrosLoteDefault,
        idProducto: idProducto
    });
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    const { register, reset, getValues, handleSubmit } = useForm<IFiltrosLotes>({
        defaultValues: filtros,
    });

    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
       
    const handlerBuscar = () => {
        let filtrosForm : IFiltrosLotes = {
            ...filtros,
            codigo: (getValues().codigo !== "")? getValues().codigo : undefined,
            esGeneral: getValues().esGeneral
        }
        setFiltros(filtrosForm);
    };

    const handlerResetFiltros = () => {
        reset({
            ...filtrosLoteDefault,
            idProducto: idProducto
        });
        setFiltros({
            ...filtrosLoteDefault,
            idProducto: idProducto
        });
    };

    const handlerSeleccionar = (datosModelo : ILoteInsertable) => {
        seleccionarLote(datosModelo);
        setMostrarModal(!mostrarModal);
    }
    
    /* efectos */
    React.useEffect(() => {   
        if (mostrarModal) {
            const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
            setHeightListado(heightCalc);
        }
    }, [contenedorCabeceraRef, mostrarModal]);
    
    const fetchMas = React.useCallback((containerRefElement?: HTMLDivElement | null) => {
        if (containerRefElement) {
            const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
            setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
        }
    }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={handlerCancelar}
            backdrop="static"
            keyboard={false}
            fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>Seleccionar lote</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <section id="form-cabecera" className="content-fluid px-0 mb-2" style={{height: "100%"}} ref={formCabeceraRef}>
                <div className="row g-sm-2" ref={contenedorCabeceraRef}>                    
                    <div className="col-lg-6 col-md-12">
                        <Form.Control type="text" maxLength={300} {...register("codigo")}
                            placeholder="filtrar por código..." />
                    </div>                               
                    <div className="col-lg-6 col-md-12 d-flex align-items-end  justify-content-end">
                        <div className="d-flex">
                            <Button variant='link' className="nav-link me-3" onClick={handleSubmit(handlerResetFiltros)}>
                                <div className='d-flex'>
                                        <span className="material-symbols-outlined">filter_alt_off</span>
                                        reiniciar filtros
                                </div> 
                            </Button>
                            <Button variant='info' className='mx-1' onClick={handleSubmit(handlerBuscar)}>
                                <div className='d-flex'>
                                        <span className="material-symbols-outlined">filter_alt</span>
                                        buscar
                                </div> 
                            </Button>
                        </div>
                    </div>
                </div>
                <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                    ref={contenidoFormularioRef} style={{height:heightListado}}
                    onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                    <LotesQuerySeleccionar 
                        filtrosQuery={filtros} 
                        handlerSeleccionar= {handlerSeleccionar}
                        pintarMasFilas= {pintarMasFilas}
                    />
                </aside>
            </section>
            </Modal.Body>
      </Modal>
    );
};