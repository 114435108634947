/* DEPENDENCIAS */
import React, { useCallback, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useForm } from 'react-hook-form';

import useResizeObserver from '../../../hooks/useResizeObserver';
import * as Rutas from '../../../rutas/Rutas';
import { IProducto, productoModelDefault } from '../../../tipos/ProductosType';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import { ETipoMovimientoStock, IFiltrosMovimientosStock, filtrosMovimientosStockDefault } from '../../../tipos/MovimientosStockType';
import TrazabilidadQuery from '../trazabilidad/TrazabilidadQuery';
import ProductosSeleccionModal from '../productos/ProductosSeleccionModal';


/* DECLARACIONES */

/* componente */
export default function InformeTrazabilidad() { 
    /* definiciones */
    const [filtros, setFiltros] = useState<IFiltrosMovimientosStock>(filtrosMovimientosStockDefault);
    const { register, setValue, reset, getValues, handleSubmit } = useForm<IFiltrosMovimientosStock>({
        defaultValues: filtrosMovimientosStockDefault,
    });

    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    
    const [datosProducto, setDatosProducto] = useState<IProducto>(productoModelDefault);
    const [visibleModalProductos, setVisibleModalProductos] = useState(false);
    
    
    const onClickSeleccionarProducto = () => {
        setVisibleModalProductos(true);
    }

    const handlerProductoSeleccionado = (datosModal: IProducto) => {
        setVisibleModalProductos(false);
        setDatosProducto({
            ...datosProducto,
            ...datosModal
        });
        setValue("idProducto", datosModal.id);
        setFiltros({
            ...filtros,
            idProducto: datosModal.id?? undefined,
        })
    }   



    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
       
    const handlerBuscar = () => {
        let filtrosForm : IFiltrosMovimientosStock = {
            ...filtros,
            codigoLote:  (getValues().codigoLote !== "")? getValues().codigoLote : undefined,
            fechaInicio: (getValues().fechaInicio !== null)? getValues().fechaInicio : undefined,
            fechaFin: (getValues().fechaFin !== null)? getValues().fechaFin : undefined,  
        }
        setFiltros(filtrosForm);
    };

    const handlerResetFiltros = () => {
        reset({
                ...filtrosMovimientosStockDefault,
                codigoLote: null,
                fechaInicio: null,
                fechaFin: null,
            });
        setDatosProducto(productoModelDefault);
        setFiltros({
            ...filtrosMovimientosStockDefault,
            codigoLote: undefined,
            fechaInicio: undefined,
            fechaFin: undefined,
        });
    };

    /* efectos */    
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="accordion" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="accordion-item">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-7 pt-3 ps-4">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Informes</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Trazabilidad</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelCabecera"
                            aria-expanded="true" aria-controls="panelCabecera">
                            <div className="container-fluid px-0"><h2>Trazabilidad</h2></div>
                        </button>
                    </h2>
                    <div id="panelCabecera" className="accordion-collapse collapse show" data-bs-parent="#contenedorCabeceraListado">
                        <div className="accordion-body">
                            <div className="row g-sm-2">
                                <Col sm={12}>
                                    <Form.Group className="mb-3" controlId="txtProducto">
                                        <InputGroup className="mb-3">
                                            <Button variant="outline-info" id="btnSeleccionarProducto" onClick={onClickSeleccionarProducto}>
                                                seleccione un producto
                                            </Button>
                                            <Form.Control type="hidden" value={datosProducto.id} {...register("idProducto")} />
                                            <Form.Control readOnly value={datosProducto.nombre}
                                                aria-label="filtro por producto"
                                                aria-describedby="btnSeleccionarProducto"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <ProductosSeleccionModal
                                        mostrarModal={visibleModalProductos}
                                        setMostrarModal={setVisibleModalProductos}                                        
                                        seleccionarProducto={handlerProductoSeleccionado}
                                    />
                                </Col>
                                <div className="col-lg-6 col-md-6">
                                    <Form.Control type="text" maxLength={300} {...register("codigoLote")}
                                        placeholder="filtrar por código de lote..." />
                                </div>    
                                <div className="col-lg-6 col-md-12">
                                    <div className="input-group">
                                        <span className="input-group-text">Fecha</span>
                                        <input type="date" aria-label="fecha desde" className="form-control" {...register("fechaInicio")} />
                                        <input type="date" aria-label="fecha hasta" className="form-control" {...register("fechaFin")} />
                                    </div>
                                </div>                            
                                <div className="col-md-12 d-flex align-items-end  justify-content-end">
                                    <div className="d-flex">
                                        <Button variant='link' className="nav-link me-3" onClick={handleSubmit(handlerResetFiltros)}>
                                            <div className='d-flex'>
                                                    <span className="material-symbols-outlined">filter_alt_off</span>
                                                    reiniciar filtros
                                            </div> 
                                        </Button>
                                        <Button variant='info' className='mx-1' onClick={handleSubmit(handlerBuscar)}>
                                            <div className='d-flex'>
                                                    <span className="material-symbols-outlined">filter_alt</span>
                                                    buscar
                                            </div> 
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}
                onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                <TrazabilidadQuery
                    filtrosQuery={filtros}
                    pintarMasFilas= {pintarMasFilas}
                />
            </aside>
        </section>
    );
};