/* dependencias */
import React, { useCallback, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate, } from 'react-router-dom';
import { format } from 'date-fns';

import * as Rutas from '../../../rutas/Rutas';
import RecepcionesMercanciaQuery from './RecepcionesMercanciaQuery';
import { IRecepcionMercancia, IFiltrosRecepcionesMercancia, recepcionMercanciaModelDefault, filtrosRecepcionMercanciaDefault } from '../../../tipos/RecepcionesMercanciaType';
import useResizeObserver from '../../../hooks/useResizeObserver';
import * as APIRecepcionesMercancia from '../../../servicios/APIRecepcionesMercancia'
import Notificacion, { ETipoNotificacion, InfoNotificacion } from '../../../componentes-com/Notificacion';
import ConfirmacionModal from '../../../componentes-com/ConfirmacionModal';
import { KEYRecepcionesMercanciaListaPaginada } from '../../../util/querys/RecepcionesMercanciaQuerys';
import { IProveedor } from '../../../tipos/ProveedoresType';
import { queryListarProveedores } from '../../../util/querys/ProveedoresQuerys';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { IUsuarioSesion, modelUsuarioSesionDefault } from '../../../tipos/UsuariosType';
import { EFuncionalidad, VAR_SESION_USUARIO } from '../../../tipos/CommonTypes';
import { TienePermiso } from '../../../util/tsx-ayuda/Funciones';

/* declaraciones */
const infoNotificacionDefault : InfoNotificacion = {
    tipo: ETipoNotificacion.INFO,
    titulo: "",
    fecha: "",
    mensaje: ""
}

/* componente */
export default function RecepcionesMercancia() { 
    /* definiciones */
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [datos, setDatos] = useState(recepcionMercanciaModelDefault);
    const [filtros, setFiltros] = useState<IFiltrosRecepcionesMercancia>(filtrosRecepcionMercanciaDefault);
    const { register, reset, getValues, handleSubmit } = useForm<IFiltrosRecepcionesMercancia>({
        mode: "onTouched",
        defaultValues: filtrosRecepcionMercanciaDefault,
    });
    const  { data: dataProveedores, isLoading: isLoadingProveedores } = useQuery<IProveedor[], Error>(queryListarProveedores(undefined));
    const [visibleModalConfirmacion, setVisibleModalConfirmacion] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState("");
    const [visibleNotificacion, setVisibleNotificacion] = useState(false);
    
    
    const [infoNotificacion, setInfoNotificacion] = useState(infoNotificacionDefault);

    
    const formCabeceraRef = React.useRef<HTMLDivElement>(null);
    const contenidoFormularioRef = React.useRef<HTMLDivElement>(null);
    const [heightListado, setHeightListado] = useState<number>(0);
    const [pintarMasFilas, setPintarMasFilas] = useState<boolean>(false);
    
    const eliminarEntradaMercancia = useEliminarEntradaMercancia();

    const { getItem } = useLocalStorage();
    const [accesoNuevo, setAccesoNuevo] = useState<boolean>(false);

    /* funciones */
    const onResizeAcordeon = useCallback((target: HTMLDivElement) => {
        const heightCalc = (formCabeceraRef?.current?.clientHeight?? 0) - (contenedorCabeceraRef?.current?.clientHeight?? 0);
        setHeightListado(heightCalc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contenedorCabeceraRef = useResizeObserver(onResizeAcordeon);
      
    const handlerBuscar = () => {
        let filtrosForm : IFiltrosRecepcionesMercancia = {
            IDProveedor: ((getValues().IDProveedor?? -1) > 0)? getValues().IDProveedor : undefined,
            Referencia: getValues().Referencia,
            FechaEntradaDesde: (getValues().FechaEntradaDesde !== null)? getValues().FechaEntradaDesde : undefined,
            FechaEntradaHasta: (getValues().FechaEntradaHasta !== null)? getValues().FechaEntradaHasta : undefined,
        }
        setFiltros(filtrosForm);
    };

    const handlerResetFiltros = () => {
        reset(filtrosRecepcionMercanciaDefault);
        setFiltros(filtrosRecepcionMercanciaDefault);
    };

    const handlerEditar = (datosModelo : IRecepcionMercancia) => {
        navigate(`${Rutas.RUTA_ENTRADAS_EDICION}${datosModelo.id}`);
    }

    const handlerEliminar = (datosModelo : IRecepcionMercancia) => {
        setDatos(datosModelo);
        setMensajeConfirmacion(`Se va a eliminar la entrada de mercancía: <strong>${datosModelo.referencia}</strong>`);
        setVisibleModalConfirmacion(true);
    }
    
    const handlerConfirmarEliminar = () => {
        eliminarEntradaMercancia.mutate(datos);
    }
    
    function useEliminarEntradaMercancia() {
        return useMutation({
          mutationFn: (datosModelo : IRecepcionMercancia) => {
            return APIRecepcionesMercancia.EliminarRecepcionMercancia(datosModelo.id);
          },
          onSuccess: () => {
            //actualizamos la lista...
            queryClient.invalidateQueries({ queryKey: [KEYRecepcionesMercanciaListaPaginada]});
            
            //lanzamos aviso...
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.EXITO,
                titulo: "Eliminar entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: "<span>La <strong>entrada de mercancía</strong> se ha eliminado correctamente.</span>"
            });
            setVisibleNotificacion(true);
          },
          onError: (error: any, variables, context: any) => {
            setInfoNotificacion({
                ...infoNotificacion,
                tipo: ETipoNotificacion.ERROR,
                titulo: "Eliminar entrada de mercancía",
                fecha: format(new Date(), 'dd/MM/yyyy H:mm'),
                mensaje: `<span>Error eliminando la <strong>entrada de mercancía</strong>: <em>${variables.referencia}</em></span>`
            });
            setVisibleNotificacion(true);
          }
        });
    } 
       
    /* efectos */
    const fetchMas = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                setPintarMasFilas(scrollHeight - scrollTop - clientHeight < 300);
            }
        }, []);

    React.useEffect(() => {
        fetchMas(contenidoFormularioRef.current)
    }, [fetchMas]);

    React.useEffect(() => {
        let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
        if (usuarioSesion) {
            setAccesoNuevo(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_ENTRADAMERCANCIA_EDICION));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* renderizado */
    return (
        <section id="form-cabecera" className="content-fluid px-0 mb-2" ref={formCabeceraRef}>
            <div className="accordion" id="contenedorCabeceraListado" ref={contenedorCabeceraRef}>
                <div className="accordion-item">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-7 pt-3 ps-4">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={Rutas.RUTA_RAIZ}>Inicio</Breadcrumb.Item>
                                    <Breadcrumb.Item>Almacén</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Entradas de mercancía</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="col-sm-12 col-md-5 p-3 d-flex justify-content-end">
                                <div className={`${!accesoNuevo? 'disabled-button-wrapper' : ''}`}>
                                    <Link to={Rutas.RUTA_ENTRADAS_NUEVO}  className={`btn btn-primary ${!accesoNuevo? 'disabled' : ''}`} aria-disabled={!accesoNuevo}>
                                        <div className="d-flex align-items-center">
                                            <span className="material-symbols-outlined">add</span>&nbsp;
                                            nueva recepción
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelCabecera"
                            aria-expanded="true" aria-controls="panelCabecera">
                            <div className="container-fluid px-0"><h2>Entradas de mercancía</h2></div>
                        </button>
                    </h2>
                    <div id="panelCabecera" className="accordion-collapse collapse show" data-bs-parent="#contenedorCabeceraListado">
                        <div className="accordion-body">
                            <div className="row g-sm-2">
                                <div className="cols-sm-12 col-md-6">
                                    <div className="input-group">
                                        <span className="input-group-text">F.Entrada</span>
                                        <input type="date" aria-label="fecha desde" className="form-control" {...register("FechaEntradaDesde")} />
                                        <input type="date" aria-label="fecha hasta" className="form-control" {...register("FechaEntradaHasta")} />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <select className="form-select" aria-label="Proveedores" {...register("IDProveedor")}>
                                        {isLoadingProveedores && (<option key="" value="">cargando...</option>)}
                                        {dataProveedores && [
                                            ...[{id:null, nombre:"--- todos los proveedores ---"}],
                                            ...dataProveedores
                                        ].map((option) => {
                                            return (
                                                <option key={option?.id} value={option.id?? ""}>{option.nombre}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <Form.Group className="mb-3" controlId="txtReferencia">
                                        <Form.Control type="text" {...register("Referencia")}
                                            placeholder="buscar por referencia..." />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-end">
                                        <Button variant='link' className='me-3 nav-link' onClick={handleSubmit(handlerResetFiltros)}>
                                            <div className='d-flex'>
                                                    <span className="material-symbols-outlined">filter_alt_off</span>
                                                    reiniciar filtros
                                            </div> 
                                        </Button>
                                        <Button variant='info' onClick={handleSubmit(handlerBuscar)}>
                                            <div className='d-flex'>
                                                    <span className="material-symbols-outlined">filter_alt</span>
                                                    buscar
                                            </div> 
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <aside id="contenidoFormulario" className="container-fluid scrollbar-overlay" 
                ref={contenidoFormularioRef} style={{height:heightListado}}
                onScroll={e => fetchMas(e.target as HTMLDivElement)}>
                <RecepcionesMercanciaQuery 
                    filtrosQuery={filtros} 
                    pintarMasFilas= {pintarMasFilas}
                    handlerEditar= {handlerEditar}
                    handlerEliminar= {handlerEliminar}
                />
                <Notificacion
                    tipo={infoNotificacion.tipo}
                    titulo={infoNotificacion.titulo}
                    fecha={infoNotificacion.fecha}
                    mensaje={infoNotificacion.mensaje}
                    mostrar={visibleNotificacion}
                    setMostrar={setVisibleNotificacion} />
                <ConfirmacionModal 
                    mostrarModal = {visibleModalConfirmacion}
                    setMostrarModal = {setVisibleModalConfirmacion}
                    mensajeConfirmacion = {mensajeConfirmacion}
                    handlerSI = {handlerConfirmarEliminar}
                />
            </aside>
        </section>
    );
};