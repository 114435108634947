/* DEPENDENCIAS */
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { useLocalStorage } from "../../hooks/useLocalStorage";
import * as Rutas from '../../rutas/Rutas';
import { EFuncionalidad, VAR_SESION_USUARIO } from "../../tipos/CommonTypes";
import { IUsuarioSesion, modelUsuarioSesionDefault } from "../../tipos/UsuariosType";
import { TienePermiso } from "../../util/tsx-ayuda/Funciones";
import { Badge } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { ICabeceraCaducidades } from "../../tipos/LotesType";
import { queryObtenerCabeceraCaducidades } from "../../util/querys/LotesQuerys";


/* DECLARACIONES */
interface IMenuProps {
    mostrarMenu: boolean,
    setMostrarMenu: Dispatch<SetStateAction<boolean>>,
}


/* COMPONENTE */
export default function Menu({mostrarMenu, setMostrarMenu} : IMenuProps)  {
    /* definiciones */
    const { getItem } = useLocalStorage();
    const [usuarioSesion, setUsuarioSesion] = useState<IUsuarioSesion>(modelUsuarioSesionDefault);
    const {data:datosCaducidades, isLoading: isLoadingCaducidades } = useQuery<ICabeceraCaducidades>(queryObtenerCabeceraCaducidades());

    
    
    /* efectos */
    useEffect(() => {
        const user = getItem(VAR_SESION_USUARIO);
        if (user) {
            setUsuarioSesion(JSON.parse(user));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    /* renderizado */ 
    return (
        <section className="navbar navbar-expand-md py-0">
            <nav className={`navbar-collapse collapse${mostrarMenu? " show" : ""}`} id="navbarMenuPrincipal">
                <ul className="navbar-nav list-group list-unstyled p-2">
                    <li>
                        <NavLink to={Rutas.RUTA_ADMIN} className="btn btn-toggle d-inline-flex align-items-center rounded border-0">
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><span className="material-symbols-outlined">location_away</span></span>
                                <span className="nav-link-text">Inicio</span>
                            </div>
                        </NavLink>
                        <ul id="inicio-collapse" data-bs-parent="#navbarMenuPrincipal" />
                    </li>
                    <li><hr/></li>
                    { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.GENERAL_SALIDA_MERCANCIA) &&
                    (<li className="mb-1">
                        <NavLink to={Rutas.RUTA_QR_SALIDA} className="btn btn-toggle d-inline-flex align-items-center rounded border-0"
                            onClick={() => setMostrarMenu(!mostrarMenu)}>
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><span className="material-symbols-outlined">qr_code_scanner</span></span>
                                <span className="nav-link-text">Salida mercancía</span>
                            </div>
                        </NavLink>
                        <ul id="salidamercancia-collapse" data-bs-parent="#navbarMenuPrincipal" />
                    </li>)
                    }
                    { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.GENERAL_SALIDA_MERCANCIA) &&
                    (<li>
                        <NavLink to={Rutas.RUTA_QR_ESTADO_LOTE} className="btn btn-toggle d-inline-flex align-items-center rounded border-0"
                            onClick={() => setMostrarMenu(!mostrarMenu)}>
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><span className="material-symbols-outlined">qr_code_scanner</span></span>
                                <span className="nav-link-text">Estado de lote</span>
                            </div>
                        </NavLink>
                        <ul id="revisarlote-collapse" data-bs-parent="#navbarMenuPrincipal" />
                    </li>)
                    }    
                    { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_LABORATORIO_IMPRIMIR_ETIQUETA) &&
                    (<li>
                        <NavLink to={Rutas.RUTA_IMPRIMIR_ETIQUETA_LABORATORIO} className="btn btn-toggle d-inline-flex align-items-center rounded border-0"
                            onClick={() => setMostrarMenu(!mostrarMenu)}>
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><span className="material-symbols-outlined">print_add</span></span>
                                <span className="nav-link-text">Etiqueta de laboratorio</span>
                            </div>
                        </NavLink>
                        <ul id="revisarlote-collapse" data-bs-parent="#navbarMenuPrincipal" />
                    </li>)
                    }                   
                    { (TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.GENERAL_SALIDA_MERCANCIA) ||
                        TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.GENERAL_SALIDA_MERCANCIA)) &&
                    (<li><hr/></li>)
                    }
                    { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.HOSPITAL_MENU_ACCESO) &&
                    (<li className="mb-1">
                        <button className="btn btn-toggle dropdown-indicador d-inline-flex align-items-center rounded border-0"
                            data-bs-toggle="collapse" data-bs-target="#hospital-collapse">
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><span className="material-symbols-outlined">home_health</span></span>
                                <span className="nav-link-text">Hospital</span>
                            </div>
                        </button>
                        <ul id="hospital-collapse" className="btn-toggle-nav list-unstyled fw-normal pb-1 collapse" data-bs-parent="#navbarMenuPrincipal">
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.HOSPITAL_HABITACIONES_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_HABITACIONES} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Habitaciones</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                        </ul>
                    </li>)
                    }
                    { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_MENU_ACCESO) &&
                    (<li className="mb-1">
                        <button className="btn btn-toggle dropdown-indicador d-inline-flex align-items-center rounded border-0"
                            data-bs-toggle="collapse" data-bs-target="#almacen-collapse">
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><span className="material-symbols-outlined">store</span></span>
                                <span className="nav-link-text">Almacén</span>
                            </div>
                        </button>
                        <ul id="almacen-collapse" className="btn-toggle-nav list-unstyled fw-normal pb-1 collapse" data-bs-parent="#navbarMenuPrincipal">
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_PRODUCTOS_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_PRODUCTOS} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Productos</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_LOTES_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_LOTES} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Lotes</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_CADUCIDADES_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_CADUCIDADES} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">
                                            Caducidades
                                            {!isLoadingCaducidades && ((datosCaducidades?.lotesCaducanHoy?? 0) > 0) && (
                                            <Fragment>
                                                &nbsp;&nbsp;  
                                                <Badge pill bg="warning" text="dark">{datosCaducidades?.lotesCaducanHoy}</Badge>
                                            </Fragment>
                                            )}
                                        </span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_STOCKS_MINIMOS_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_STOCKSMINIMOS} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Stocks mínimos</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_ENTRADAMERCANCIA_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_ENTRADAS} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Entrada de mercancía</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_SALIDAMERCANCIA_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_SALIDAS} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Salida de mercancía</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                        </ul>
                    </li>)
                    }
                    { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.IMPRESORAS_MENU_ACCESO) &&
                    (<li className="mb-1">
                        <button className="btn btn-toggle dropdown-indicador d-inline-flex align-items-center rounded border-0"
                            data-bs-toggle="collapse" data-bs-target="#impresiones-collapse">
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><span className="material-symbols-outlined">print</span></span>
                                <span className="nav-link-text">Impresiones</span>
                            </div>
                        </button>
                        <ul id="impresiones-collapse" className="btn-toggle-nav list-unstyled fw-normal pb-1 collapse" data-bs-parent="#navbarMenuPrincipal">
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.IMPRESORAS_IMPRESORAS_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_IMPRESORAS} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Impresoras</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.IMPRESORAS_PLANTILLAS_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_PLANTILLAS_IMPRESION} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Plantillas de impresión</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.IMPRESORAS_COLA_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_COLA_IMPRESION} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Cola de impresión</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                        </ul>
                    </li>)
                    }
                    { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.INFORMES_MENU_ACCESO) &&
                    (<li className="mb-1">
                        <button className="btn btn-toggle dropdown-indicador d-inline-flex align-items-center rounded border-0"
                            data-bs-toggle="collapse" data-bs-target="#informes-collapse">
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><span className="material-symbols-outlined">query_stats</span></span>
                                <span className="nav-link-text">Informes</span>
                            </div>
                        </button>
                        <ul id="informes-collapse" className="btn-toggle-nav list-unstyled fw-normal pb-1 collapse" data-bs-parent="#navbarMenuPrincipal">
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.INFORMES_CONSUMOS_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_INFORME_CONSUMOS} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Consumos</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.INFORMES_TRAZABILIDAD_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_INFORME_TRAZABILIDAD} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Trazabilidad</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                        </ul>
                    </li>)
                    }
                    { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.CONFIGURACION_MENU_ACCESO) &&
                        (<li className="mb-1">
                        <button className="btn btn-toggle dropdown-indicador d-inline-flex align-items-center rounded border-0"
                            data-bs-toggle="collapse" data-bs-target="#configuracion-collapse">
                            <div className="d-flex align-items-center">
                                <span className="nav-link-icon"><span className="material-symbols-outlined">settings</span></span>
                                <span className="nav-link-text">Configuración</span>
                            </div>
                        </button>
                        <ul id="configuracion-collapse" className="btn-toggle-nav list-unstyled fw-normal pb-1 collapse" data-bs-parent="#navbarMenuPrincipal">
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.CONFIGURACION_PROVEEDORES_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_PROVEEDORES} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Proveedores</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.CONFIGURACION_FAMILIAS_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_FAMILIAS} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Familias de productos</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.CONFIGURACION_SECCIONES_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_SECCIONES} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Secciones de almacén</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                            { TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.CONFIGURACION_TIPOSUNIDAD_ACCESO) &&
                            (<li>
                                <NavLink to={Rutas.RUTA_TIPOS_UNIDADES} className="nav-link d-inline-flex text-decoration-none rounded"
                                    onClick={() => setMostrarMenu(!mostrarMenu)}>
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-text">Tipos de unidades</span>
                                    </div>
                                </NavLink>
                            </li>)
                            }
                        </ul>
                    </li>)
                }
                </ul>
            </nav>
        </section >
    );
}