/* DEPENDENCIAS */
import { clienteAxios } from "./APIAxiosInstancia";
import { URL_API_SECCIONESALMACEN, URL_API_SECCIONESALMACEN_PAGINACION } from "./Endpoints";
import { ISeccionAlmacen } from "../tipos/SeccionesAlmacenType";

/* DECLARACIONES */

/* API */
/**
 * Función que obtiene el listado de secciones de almacén.
 */
export const ListarSeccionesAlmacen = async (filtro: string | undefined): Promise<ISeccionAlmacen[]> => {
    try {     
        const respuesta = filtro? 
            await clienteAxios.get<ISeccionAlmacen[]>(
                URL_API_SECCIONESALMACEN,
                { params: {filtro: filtro}}
            ) :
            await clienteAxios.get<ISeccionAlmacen[]>(URL_API_SECCIONESALMACEN);
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que obtiene el listado de secciones de almacén con paginación.
 */
export const ListarSeccionesAlmacenPaginacion = async (pagina:number, filas:number, filtro: string | undefined): Promise<ISeccionAlmacen[]> => {
    try {     
        const respuesta = filtro? 
            await clienteAxios.get<ISeccionAlmacen[]>(
                URL_API_SECCIONESALMACEN_PAGINACION,
                { params: {
                    filtro: filtro, 
                    numeroPagina: pagina,
                    tamanhoPagina: filas,
                }}
            ) :
            await clienteAxios.get<ISeccionAlmacen[]>(URL_API_SECCIONESALMACEN_PAGINACION,
                { params: {
                    numeroPagina: pagina,
                    tamanhoPagina: filas,
                }});
        return respuesta.data;
    } catch(error: any) {
        let mensajeError:string;

        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
            mensajeError = error.response.data;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
            mensajeError = error.message;
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
            mensajeError = error.message;
        }
        console.log("config:", error.config);
        throw new Error(mensajeError);
    }
}

/**
 * Función que guarda los datos de un nuevo tipo de unidad.
 */
export const AgregarSeccionAlmacen = async (datos : ISeccionAlmacen): Promise<ISeccionAlmacen> => {
    try {     
        const respuesta = await clienteAxios.post(
            URL_API_SECCIONESALMACEN, datos
        );
        return respuesta.data;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al agregar una sección de almacén.");
    }
}

/**
 * Función que guarda los datos de edición de una sección de almacén.
 */
export const EditarSeccionAlmacen = async (datos : ISeccionAlmacen): Promise<void> => {
    try {     
        await clienteAxios.put(URL_API_SECCIONESALMACEN + `/${datos.id}`, datos);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al editar una sección de almacén.");
    }
}

/**
 * Función que elimina los datos de una sección de almacén.
 */
export const EliminarSeccionAlmacen = async (id : number): Promise<void> => {
    try {     
        await clienteAxios.delete(URL_API_SECCIONESALMACEN + `/${id}`);
        return;
    } catch(error: any) {
        if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log("data:", error.response.data);
            console.log("status:", error.response.status);
            console.log("headers:", error.response.headers);
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log("request:", error.request);
        } else {
            // Algo paso al preparar la petición que lanzo un error
            console.log('Error:', error.message);
        }
        console.log("config:", error.config);
        throw new Error("Error al eliminar una sección de almacén.");
    }
}