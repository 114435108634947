/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { IFamiliaProducto } from "../../../tipos/FamiliasProductosType";

/* DECLARACIONES */
type FamiliasProductosModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: IFamiliaProducto | null,
    guardarDatos: (datosModelo: IFamiliaProducto) => void
}
const familiaModelDefault : IFamiliaProducto = {
    id: null,
    nombre: ""
}
  
const mensajesError = {
    requerido: "Este campo es obligatorio",
};
  
/* COMPONENTE */
export default function FamiliasProductosModal({datosIniciales, mostrarModal, setMostrarModal, guardarDatos} : FamiliasProductosModalProps) {
    /* definiciones */
    const { register, handleSubmit, formState: {errors}, getValues, reset } = useForm<IFamiliaProducto>({
        mode: "onTouched",
        defaultValues: familiaModelDefault,
        values: datosIniciales?? familiaModelDefault
    });
    
    /* funciones */
    useEffect(() => {
        reset(datosIniciales?? familiaModelDefault);
    }, [reset, datosIniciales]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { (getValues().id === 0)  && "Añadir nueva familia"}
                        { ((getValues().id?? -1) > 0)  && "Editar familia"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="txtNombre">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" {...register("nombre", {
                                required: mensajesError.requerido
                            })}
                            required={true}  isInvalid={!!errors.nombre}
                            placeholder="escribe el nombre de la familia..." />
                        <Form.Control.Feedback type='invalid'>
                            {errors.nombre?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() => { reset(familiaModelDefault); setMostrarModal(!mostrarModal)}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}