import { ICama } from "./HabitacionesType"
import { IMovimientoStock } from "./MovimientosStockType"
import { IPlantillaImpresion } from "./PlantillasImpresionType"
import { IProducto } from "./ProductosType"

 /**
 * Interfaz que define un objeto de una plantilla para imprimir en la IU.
 */
interface IImpresion {
   id: number,
   idImpresora: number,
   nombreImpresora: string,
   nombrePlantilla: string,
   fecha: Date,
   textoImpresion: string,
   fechaImpresion: Date | null
}
 
interface IImpresionInsertable {
   id: number,
   idImpresora: number,
   idPlantilla: number,
   nombreImpresora?: string,
   nombrePlantilla?: string,
   fecha: Date,
   textoImpresion: string,
   fechaImpresion: Date | null
}

interface IFiltrosImpresion {
   IDImpresora?: number,
   FechaDesde?: Date | null,
   FechaHasta?: Date | null,
   FechaImpresionDesde?: Date | null,
   FechaImpresionHasta?: Date | null,
   Impreso?: boolean,
}
 
interface IEtiquetaLaboratorio {
   plantilla: IPlantillaImpresion | null,
   idImpresora: number,
   fecha: Date,
   producto: IProducto | null,
   copias: number,
   textoFila1: string,
   textoFila2: string,
}

const etiquetaLaboratorioModelDefault : IEtiquetaLaboratorio = {
   plantilla: null,
   idImpresora: 0,
   fecha: new Date(),
   producto: null,
   copias: 1,
   textoFila1: "",
   textoFila2: "",
}

interface IEtiquetaHabitacion {
   plantilla: IPlantillaImpresion | null,
   idImpresora: number,
   fecha: Date,
   cama: ICama | null,
   copias: number,
   textoDieta1: string,
   textoDieta2: string,
}

const etiquetaHabitacionModelDefault : IEtiquetaHabitacion = {
   plantilla: null,
   idImpresora: 0,
   fecha: new Date(),
   cama: null,
   copias: 1,
   textoDieta1: "",
   textoDieta2: "",
}

interface IEtiquetaProducto {
   plantilla: IPlantillaImpresion | null,
   idImpresora: number,
   copias: number,
   fecha: Date,
   datosMovimiento: IMovimientoStock | null,
}

const etiquetaProductoModelDefault : IEtiquetaProducto = {
   plantilla: null,
   idImpresora: 0,
   copias: 1,
   fecha: new Date(),
   datosMovimiento: null,
}

export type { 
   IImpresion, 
   IImpresionInsertable,
   IFiltrosImpresion, 
   IEtiquetaLaboratorio, 
   IEtiquetaHabitacion, 
   IEtiquetaProducto 
}

export {
   etiquetaLaboratorioModelDefault,
   etiquetaHabitacionModelDefault,
   etiquetaProductoModelDefault
}