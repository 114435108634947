/* DEPENDENCIAS */
import { Fragment, useState } from "react";
import ProductosTable from "./ProductosTable";
import { ColumnDef, SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { IProducto } from "../../../tipos/ProductosType";
import { Button, Form } from "react-bootstrap";
import {TiArrowUnsorted} from "react-icons/ti";
import React from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { ObtenerCssBadgeStock, TienePermiso } from "../../../util/tsx-ayuda/Funciones";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { IUsuarioSesion } from "../../../tipos/UsuariosType";
import { EFuncionalidad, VAR_SESION_USUARIO } from "../../../tipos/CommonTypes";

/* DEFINICIONES */
type ProductosInstanceProps = {
  tableData: IProducto[],
  isError: boolean,
  error: Error | null,
  isLoading: boolean,
  isFetching: boolean,
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<IProducto[], Error>>,
  pintarMasFilas : boolean,
  handlerEditar : (datosModelo: IProducto) => void,
  handlerEliminar : (datosModelo : IProducto) => void,
}

/* COMPONENTE */
const ProductosInstance = ({ 
    tableData, 
    isError, error, isLoading, 
    isFetching, fetchNextPage,
    pintarMasFilas,
    handlerEditar, handlerEliminar
  }: ProductosInstanceProps) => {
    /* definiciones */
    const [sorting, setSorting] = React.useState<SortingState>([{
      id: "nombre",
      desc: false
    }]); 
    const { getItem } = useLocalStorage();
    const [accesoEditar, setAccesoEditar] = useState<boolean>(false);
    
    const columnsDef: ColumnDef<IProducto>[] = [
      {
        accessorKey: "codigo",
        header: 'Código',
        size: 80,
        cell: info => info.getValue(),
        meta: {
          className: "align-middle",
        }
      },
      {
        accessorKey: "nombre",
        header: () => {
          return(
            <div className="d-flex cursor-pointer">
              <span>Nombre&nbsp;</span>
              <TiArrowUnsorted style={{ marginTop:2 }} />
            </div>
          );
        },
        cell: info => info.getValue(), 
        meta: {
          className: "align-middle",
        }      
      },
      {
        accessorKey: "nombreFamilia",
        header: 'Familia',
        size: 170,
        cell: info => info.getValue(),
        meta: {
          className: "align-middle text-truncate",
          style: {maxWidth:120}
        }
      },
      {
        accessorKey: "nombreSeccion",
        header: 'Sección',
        size: 130,
        cell: info => info.getValue(),
        meta: {
          className: "align-middle text-truncate",
          style: {maxWidth:120}
        }
      },
      {
        accessorKey: "stock",
        header: 'Stock',
        size: 70,
        cell: info => (
          <span className={`badge ${ObtenerCssBadgeStock(info.row.original.stock, info.row.original.stockMinimo)}`}>
            {info.row.original.stock}
          </span>
        ),
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center",
        }
      },
      {
        accessorKey: "usaControlLotes",
        header: 'C.Lotes',
        size: 90,
        cell: info => (
          <Form.Check 
            disabled
            type="switch" checked={info.row.getValue("usaControlLotes")}
            aria-label="control por lotes"
          />
        ),
        meta: {
          headerClassName: "text-center",
          className: "align-middle text-center",
        }
      },
      {
        id: 'opciones',
        header: '',
        size: 130,
        cell: (info) => {
          const datos = info.row.original;
          return (
          <Fragment>
            <span className={`${!accesoEditar? 'disabled-button-wrapper' : ''}`}>
              <Button type="button" variant="success" size="sm" className="me-1" disabled={!accesoEditar}
                onClick={ () => { handlerEditar(datos); } }>
                <div className="d-flex">
                    <span className="material-symbols-outlined">edit_note</span>&nbsp;editar
                </div>
              </Button>
            </span>
            <span className={`${!accesoEditar? 'disabled-button-wrapper' : ''}`}>
              <Button type="button" variant="outline-warning" size="sm" title="eliminar proveedor" disabled={!accesoEditar}
                onClick={ () => { handlerEliminar(datos); } }>
                  <span className="material-symbols-outlined">delete</span>
              </Button>
            </span>
          </Fragment>);
        }, 
        meta: {
          className: "align-middle",
        }      
      },
    ];

    const tableInstance = useReactTable({ 
      data: tableData, 
      columns: columnsDef,
      state: {
        sorting,
      },
      enableSortingRemoval: false,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });
  
    /* efectos */    
    React.useEffect(() => {
      let usuarioSesion : IUsuarioSesion = JSON.parse(getItem(VAR_SESION_USUARIO)?? "");
      if (usuarioSesion) {
        setAccesoEditar(TienePermiso(usuarioSesion.perfil.funcionalidades, EFuncionalidad.ALMACEN_PRODUCTOS_EDICION));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
    /* renderizado */
    return (
      <ProductosTable instanciaTabla={tableInstance}
        isError={isError} error={error} isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        pintarMasFilas={pintarMasFilas} />
    );
  }
  export default ProductosInstance;