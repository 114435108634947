/* DEPENDENCIAS */
import React, { Dispatch, SetStateAction, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { IImpresoraInsertable } from "../../../tipos/ImpresorasType";
import { FloatingLabel } from 'react-bootstrap';

/* DECLARACIONES */
type ImpresorasModalProps = {
    mostrarModal: boolean,
    setMostrarModal: Dispatch<SetStateAction<boolean>>,
    datosIniciales: IImpresoraInsertable | null,
    guardarDatos: (datosModelo: IImpresoraInsertable) => void
}
const impresoraModelDefault : IImpresoraInsertable = {
    id: 0,
    nombre: "",
    descripcion: "",
    ubicacion: ""
}
  
const mensajesError = {
    requerido: "Este campo es obligatorio",
    nombre: "El campo nombre no es correcto",
};
  
/* COMPONENTE */
export default function ImpresorasModal({datosIniciales, mostrarModal, setMostrarModal, guardarDatos} : ImpresorasModalProps) {
    /* definiciones */
    const { register, handleSubmit, formState: {errors}, getValues, reset, watch } = useForm<IImpresoraInsertable>({
        mode: "onTouched",
        defaultValues: impresoraModelDefault,
        values: datosIniciales?? impresoraModelDefault
    });

    /* funciones */
    useEffect(() => {
        reset(datosIniciales?? impresoraModelDefault);
    }, [reset, datosIniciales]);
    
    /* renderizado */
    return (
        <Modal
            show={mostrarModal}
            onHide={() => {setMostrarModal(!mostrarModal);}}
            backdrop="static"
            keyboard={false}
            size='lg'
            fullscreen='md-down'>
            <Form noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { (getValues().id === 0)  && "Añadir nueva impresora"}
                        { (getValues().id > 0)  && "Editar impresora"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="txtNombre">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" {...register("nombre", {
                                required: mensajesError.requerido
                            })}
                            required={true}  isInvalid={!!errors.nombre}
                            placeholder="escribe el nombre de la impresora..." />
                        <Form.Control.Feedback type='invalid'>
                            {errors.nombre?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <FloatingLabel className="mb-3" controlId="txtDescripción" label="descripción">
                        <Form.Control as="textarea" rows={10} {...register("descripcion")}
                            required={false} maxLength={2000}  isInvalid={!!errors.descripcion}
                            placeholder="escribe descripción de la impresora..."
                            style={{ height: '120px' }} />
                        <Form.Text className="text-muted" hidden={watch('descripcion').length === 0}>
                            {`${watch('descripcion')?.length?? 0}/2000`}
                        </Form.Text>
                        <Form.Control.Feedback type='invalid'>
                            {errors.descripcion?.message}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel className="mb-3" controlId="txtUbicacion" label="ubicación">
                        <Form.Control as="textarea" rows={10} {...register("ubicacion")}
                            required={false} maxLength={2000}  isInvalid={!!errors.ubicacion}
                            placeholder="escribe la ubicación de la impresora..."
                            style={{ height: '120px' }} />
                        <Form.Text className="text-muted" hidden={watch('ubicacion').length === 0}>
                            {`${watch('ubicacion')?.length?? 0}/2000`}
                        </Form.Text>
                        <Form.Control.Feedback type='invalid'>
                            {errors.ubicacion?.message}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={() => { reset(impresoraModelDefault); setMostrarModal(!mostrarModal)}}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">block</span>&nbsp;
                        Cancelar
                    </div>
                </Button>
                <Button variant="primary" onClick={handleSubmit(guardarDatos)}>
                    <div className="d-flex">
                        <span className="material-symbols-outlined">task_alt</span>&nbsp;
                        Guardar
                    </div>
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}