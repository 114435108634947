/* DEPENDENCIAS */
import * as APIProveedores from '../../servicios/APIProveedores';


/* QUERYS KEYS */
const KEYPProveedoresLista = "listaHabitacionesPaginacion";

export {
    KEYPProveedoresLista,
}

/* QUERYS */
const queryListarProveedores = (filtro: string | undefined) => ({
    queryKey: [KEYPProveedoresLista, filtro],
    queryFn: ({ pageParam = 0 }) => APIProveedores.ListarProveedores(filtro),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});


export {
    queryListarProveedores,
}